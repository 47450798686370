import { memo } from 'react';

import { Button, Divider, LIcon, Typography } from '@s_mart/core';
import { lineChevronRight } from '@s_mart/regular-icons';
import { lineUser } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import useAuth from 'store/auth/auth';
import { corPrimariaSolarfy } from 'styles/theme/theme';

import { MenuPageDiv } from './menuPage.styles';
import useMenuPage from './useMenuPage';
import { useBottomBarContext } from '../bottomBar.context';

const MenuPage = () => {
  const { profile } = useAuth();
  const { menuPages } = useMenuPage();
  const { paginaAtual } = useBottomBarContext();

  return (
    <MenuPageDiv>
      <div className='header'>
        <div className='icone'>
          <LIcon icon={lineUser} size='24px' removeMargin color={corPrimariaSolarfy} />
        </div>
        <Typography variant='bodySM' color='white'>
          Olá, <Typography variant='titleXS'>{profile?.nome}</Typography>
        </Typography>
      </div>
      <div className='body'>
        {menuPages
          .filter(({ hide }) => !hide)
          .map(({ icon, label, path, onClick }, index) => (
            <>
              <Button
                variant='text'
                startIcon={
                  <LIcon
                    icon={icon}
                    removeMargin
                    size='24px'
                    color={path && paginaAtual(path) ? corPrimariaSolarfy : colorPalette.neutral[600]}
                  />
                }
                endIcon={<LIcon icon={lineChevronRight} removeMargin size='24px' />}
                onClick={onClick}
                key={`bottom-bar-menu-${label}`}
              >
                <Typography
                  variant='bodySM'
                  width='100%'
                  textAlign='left'
                  color={path && paginaAtual(path) ? corPrimariaSolarfy : colorPalette.neutral[600]}
                >
                  {label}
                </Typography>
              </Button>
              {index !== menuPages.length - 1 && <Divider orientation='horizontal' />}
            </>
          ))}
      </div>
    </MenuPageDiv>
  );
};

export default memo(MenuPage);
