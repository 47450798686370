import { Typography } from '@s_mart/core';

import type {
  IAdicionarEditarMotivoDialogProps,
  IAdicionarEditarMotivoFormValues,
} from './adicionarEditarMotivoDialog.types';

import { criarMotivoPerda, editarMotivoPerda } from 'api/motivosPerda/motivosPerda';
import useApp from 'store/app/app';

import useMarcarComoPerdidaDialogStore from '../marcarComoPerdidaDialog/store/marcarComoPerdidaDialog.store';

export function useAdicionarEditarMotivoDialog({ motivo, handleClose }: IAdicionarEditarMotivoDialogProps) {
  const { handleError, setLoading, setNotification } = useApp();
  const { buscarMotivosPerda, setUltimoMotivoCadastrado } = useMarcarComoPerdidaDialogStore();

  async function _adicionarMotivo({ nomeMotivo }: IAdicionarEditarMotivoFormValues) {
    setLoading(true);
    try {
      const { data } = await criarMotivoPerda({ nome: nomeMotivo });

      setNotification({
        title: (
          <Typography variant='bodyMD'>
            Motivo <strong>{nomeMotivo}</strong> criado com sucesso!
          </Typography>
        ),
      });
      setUltimoMotivoCadastrado({ codigo: data?.data?.codigo, nome: nomeMotivo });
      buscarMotivosPerda();
      handleClose();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }

  async function _editarMotivo({ nomeMotivo }: IAdicionarEditarMotivoFormValues) {
    setLoading(true);

    try {
      if (!motivo) {
        throw new Error('Código do motivo não informado, por favor, tente novamente.');
      }

      await editarMotivoPerda({
        codigo: motivo.codigo,
        nome: nomeMotivo,
      });

      setNotification({
        title: <Typography variant='bodyMD'>Edição realizada com sucesso!</Typography>,
      });

      buscarMotivosPerda();
      handleClose();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleAdicionarEditarMotivo(values: IAdicionarEditarMotivoFormValues) {
    if (motivo) {
      _editarMotivo(values);
    } else {
      _adicionarMotivo(values);
    }
  }

  return {
    handleAdicionarEditarMotivo,
  };
}
