import { memo } from 'react';
import ReactJoyride from 'react-joyride';

import { useMediaQuery } from '@s_mart/hooks';

import { corPrimariaSolarfy } from 'styles/theme/theme';

import ApresentacaoSimultaneidadeContent from './content/content';

const ApresentacaoSimultaneidade = ({ onClose }: { onClose: () => void }) => {
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  return (
    <ReactJoyride
      disableCloseOnEsc
      disableOverlayClose
      hideCloseButton
      continuous
      run
      steps={[
        {
          content: <ApresentacaoSimultaneidadeContent onClose={onClose} />,
          target: '#button-base-calculo-payback',
          placement: 'auto',
          hideCloseButton: true,
          hideFooter: true,
          disableBeacon: true,
        },
      ]}
      stepIndex={0}
      floaterProps={{
        hideArrow: true,
      }}
      styles={{
        options: { primaryColor: corPrimariaSolarfy },
        tooltip: {
          width: '100%',
          maxWidth: telaMenorQue600 ? 'calc(100% - 64px)' : '550px',
          left: telaMenorQue600 ? '42px' : undefined,
        },
        overlay: { zIndex: 103 },
        spotlight: { borderRadius: '6px' },
      }}
    />
  );
};

export default memo(ApresentacaoSimultaneidade);
