import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { decimal } from '@s_mart/masks';

import useGeradorDePropostaCalcularDimensionamento from 'shared/modais/geradorDeProposta/useGeradorDePropostaCalcularDimensionamento';

const useBaseDeCalculoConsumoFields = () => {
  const { setValue, watch } = useFormContext();
  const isMounted = useRef(false);

  const { handleCalcularDimensionamentoGrupoB, handleCalcularDimensionamentoGrupoA } =
    useGeradorDePropostaCalcularDimensionamento();

  const _formValues = watch();

  const controleConteudoRenderizado = () => {
    if (_formValues?.tipoLigacao?.value === '3') {
      if (_formValues?.tipoDimensionamento === '0') {
        return {
          geracao: decimal.format(_formValues?.geracaoSistemaForaPonta),
          quantidadePaineis: decimal.format(_formValues?.quantidadePaineisForaPonta, 0),
          potenciaSistema: decimal.format(_formValues?.potenciaSistemaForaPonta),
          areaMinima: decimal.format(_formValues?.areaMinimaForaPonta),
        };
      } else {
        return {
          geracao: decimal.format(_formValues?.geracaoSistemaForaPontaMaisPonta),
          quantidadePaineis: decimal.format(_formValues?.quantidadePaineisForaPontaMaisPonta, 0),
          potenciaSistema: decimal.format(_formValues?.potenciaSistemaForaPontaMaisPonta),
          areaMinima: decimal.format(_formValues?.areaMinimaForaPontaMaisPonta),
        };
      }
    } else {
      return {
        geracao: decimal.format(_formValues?.geracaoSistema),
        quantidadePaineis: decimal.format(_formValues?.quantidadePaineis, 0),
        potenciaSistema: decimal.format(_formValues?.potenciaInstalada || _formValues?.potenciaInversor),
        areaMinima: decimal.format(_formValues?.areaMinima),
      };
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      if (_formValues?.tipoLigacao?.value === '3') {
        handleCalcularDimensionamentoGrupoA(_formValues, setValue);
      } else {
        handleCalcularDimensionamentoGrupoB(_formValues, setValue);
      }
    } else {
      isMounted.current = true;
    }
  }, [
    handleCalcularDimensionamentoGrupoA,
    handleCalcularDimensionamentoGrupoB,
    setValue,
    _formValues.potenciaPaineis,
    _formValues.perdaSistema,
    _formValues.larguraPainel,
    _formValues.alturaPainel,
    _formValues.aumentoConsumo,
  ]);

  return { controleConteudoRenderizado };
};

export default useBaseDeCalculoConsumoFields;
