import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import throttle from 'lodash.throttle';

import { getCep } from 'api/cep/cep';
import useApp from 'store/app/app';
import useCidades from 'hooks/useCidades/useCidades';
import useConfiguracoes from 'store/configuracoes/configuracoes';

const useConfirmacaoDadosContratacao = () => {
  const { setLoading, handleError } = useApp();
  const { cidades, handlePesquisarCidades } = useCidades();
  const { meusDados } = useConfiguracoes();
  const { setValue, reset } = useFormContext();

  const cidadesOptions = useMemo(() => {
    if (cidades?.length) {
      return cidades?.map((item) => ({
        value: String(item.codigo),
        label: `${item.nome} - ${item.uf}`,
      }));
    }
  }, [cidades]);

  const handleBuscarInformacoesEmpresa = useCallback(async () => {
    try {
      reset({
        ...meusDados,
        cep: meusDados?.cep === '00000000' ? '' : meusDados?.cep,
        cidade: meusDados?.cidade && {
          value: String(meusDados?.codigoCidade),
          label: `${meusDados?.cidade} - ${meusDados?.uf}`,
        },
      });
    } catch (err) {
      handleError(err);
    }
  }, [handleError, reset, meusDados]);

  const buscarCep = throttle(
    async (input?: string) => {
      if (!input) return;
      if (input.length === 8) {
        try {
          setLoading(true);

          const { data } = await getCep(input);

          data?.data.bairro && setValue('bairro', data?.data.bairro);
          setValue('cidade', {
            label: `${data?.data.cidade} - ${data?.data.uf}`,
            value: String(data?.data.codigo_ibge),
          });
          data?.data.complemento && setValue('complemento', data?.data.complemento);
          data?.data.logradouro && setValue('rua', data?.data.logradouro);

          setLoading(false);
        } catch (err) {
          handleError(err);
        }
      }
    },
    100,
    { leading: false }
  );

  useEffect(() => {
    handleBuscarInformacoesEmpresa();
  }, [handleBuscarInformacoesEmpresa]);

  return { buscarCep, cidadesOptions, handlePesquisarCidades };
};

export default useConfirmacaoDadosContratacao;
