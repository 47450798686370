import { Suspense } from 'react';
import { Route, Routes as ReactRoutes, Navigate } from 'react-router-dom';

import Loading from 'components/loadings/loading/loading';
import Public from 'components/layouts/public/public';
import Private from 'components/layouts/private/private';
import ErrorBoundary from 'components/errorBoundary/errorBoundary';
import { NPSProvider } from 'shared/nps/npsContext';
import useAuth from 'store/auth/auth';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import lazyWithRetry from 'utils/lazyWithRetry/lazyWithRetry';

const Login = lazyWithRetry(() => import('containers/login/login' /* webpackChunkName: "login" */), 'login');
const EsqueciMinhaSenha = lazyWithRetry(
  () => import('containers/esqueciMinhaSenha/esqueciMinhaSenha' /* webpackChunkName: "esqueciMinhaSenha" */),
  'esqueciMinhaSenha'
);
const VisaoGeral = lazyWithRetry(
  () => import('containers/visaoGeral/visaoGeral' /* webpackChunkName: "visaoGeral" */),
  'visaoGeral'
);
const Propostas = lazyWithRetry(
  () => import('containers/propostas/propostas' /* webpackChunkName: "propostas" */),
  'propostas'
);
const Vendedores = lazyWithRetry(
  () => import('containers/vendedores/vendedores' /* webpackChunkName: "vendedores" */),
  'vendedores'
);
const Clientes = lazyWithRetry(
  () => import('containers/clientes/clientes' /* webpackChunkName: "clientes" */),
  'clientes'
);
const Produtos = lazyWithRetry(
  () => import('containers/produtos/produtos' /* webpackChunkName: "produtos" */),
  'produtos'
);
const Usuarios = lazyWithRetry(
  () => import('containers/usuarios/usuarios' /* webpackChunkName: "usuarios" */),
  'usuarios'
);
const MinhaConta = lazyWithRetry(
  () => import('containers/minhaConta/minhaConta' /* webpackChunkName: "minhaConta" */),
  'minhaConta'
);
const TrocarSenha = lazyWithRetry(
  () => import('containers/trocarSenha/trocarSenha' /* webpackChunkName: "trocarSenha" */),
  'trocarSenha'
);
const CadastrarSe = lazyWithRetry(
  () => import('containers/cadastrarSe/cadastrarSe' /* webpackChunkName: "cadastrarSe" */),
  'cadastrarSe'
);
const Error404 = lazyWithRetry(
  () => import('containers/error404/error404' /* webpackChunkName: "error404" */),
  'error404'
);
const Error500 = lazyWithRetry(
  () => import('containers/error500/error500' /* webpackChunkName: "error500" */),
  'error500'
);
const ConfirmarAtualizacaoEmailEmpresa = lazyWithRetry(
  () =>
    import(
      'containers/confirmarAtualizacaoEmailEmpresa/confirmarAtualizacaoEmailEmpresa' /* webpackChunkName: "confirmarAtualizacaoEmailEmpresa" */
    ),
  'confirmarAtualizacaoEmailEmpresa'
);
const ConfirmarAtualizacaoEmailResponsavel = lazyWithRetry(
  () =>
    import(
      'containers/confirmarAtualizacaoEmailResponsavel/confirmarAtualizacaoEmailResponsavel' /* webpackChunkName: "confirmarAtualizacaoEmailResponsavel" */
    ),
  'confirmarAtualizacaoEmailResponsavel'
);
const Relatorios = lazyWithRetry(
  () => import('containers/relatorios/relatorios' /* webpackChunkName: "relatorios" */),
  'relatorios'
);
const Redirect = lazyWithRetry(
  () => import('containers/redirect/redirect' /* webpackChunkName: "redirect" */),
  'redirect'
);
const ConfiguracoesFornecedores = lazyWithRetry(
  () =>
    import(
      'containers/configuracoesFornecedores/configuracoesFornecedores' /* webpackChunkName: "configuracoesFornecedores" */
    ),
  'configuracoesFornecedores'
);

const Routes = () => {
  const { isAuthenticated, profile } = useAuth();
  const { recursosContratados } = useConfiguracoes();

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <ReactRoutes>
          {!isAuthenticated ? (
            <Route path='/' element={<Public />}>
              <Route path='/login' element={<Login />} />
              <Route path='/esqueci-minha-senha' element={<EsqueciMinhaSenha />} />
              <Route path='/resetpassword' element={<TrocarSenha />} />
              <Route path='/cadastrar-se' element={<CadastrarSe />} />
              <Route path='/finalizar-contratacao' element={<CadastrarSe />} />
              <Route path='/redirect' element={<Redirect />} />

              <Route path='/' element={<Navigate to='/login' />} />
              <Route path='*' element={<Navigate to='/login' />} />
            </Route>
          ) : (
            <>
              <Route path='/login' element={<Login />} />
              <Route path='/cadastrar-se' element={<CadastrarSe />} />
              <Route path='/finalizar-contratacao' element={<CadastrarSe />} />
              <Route path='/redirect' element={<Redirect />} />
              <Route path='/confirmar-atualizacao-email-empresa' element={<ConfirmarAtualizacaoEmailEmpresa />} />
              <Route
                path='/confirmar-atualizacao-email-responsavel'
                element={<ConfirmarAtualizacaoEmailResponsavel />}
              />
              <Route
                path='/'
                element={
                  <NPSProvider>
                    <Private />
                  </NPSProvider>
                }
              >
                <Route path='*' element={<Navigate to='/404' />} />
                <Route path='/404' element={<Error404 />} />
                <Route path='/500' element={<Error500 />} />
                <Route path='/' element={<Navigate to='/visao-geral' />} />
                <Route path='/visao-geral' element={<VisaoGeral />} />
                <Route path='/propostas' element={<Propostas />} />
                <Route
                  path='/vendedores'
                  element={profile?.tipo === 0 ? <Navigate to='/visao-geral' /> : <Vendedores />}
                />
                <Route path='/clientes' element={<Clientes />} />
                <Route path='/produtos' element={profile?.tipo === 2 ? <Navigate to='/visao-geral' /> : <Produtos />} />
                <Route path='/usuarios' element={profile?.tipo === 0 ? <Navigate to='/visao-geral' /> : <Usuarios />} />
                <Route
                  path='/minha-conta'
                  element={profile?.tipo === 0 ? <Navigate to='/visao-geral' /> : <MinhaConta />}
                />
                <Route
                  path='/relatorios'
                  element={
                    profile?.tipo === 0 || !recursosContratados?.crm ? <Navigate to='/visao-geral' /> : <Relatorios />
                  }
                />
                <Route path='/configuracoes-fornecedores' element={<ConfiguracoesFornecedores />} />
              </Route>
            </>
          )}
        </ReactRoutes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Routes;
