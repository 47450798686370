import styled from '@emotion/styled';

export const DivAppBarEMain = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 70px);
  height: 100%;
`;

export const Main = styled.main<{ mobile: boolean; banner: boolean }>`
  height: ${({ mobile, banner }) =>
    mobile ? `calc(100vh - ${banner ? 130 : 90}px)` : `calc(100vh - ${banner ? 144 : 104}px)`};
  padding: ${({ mobile }) => (mobile ? '16px 16px 0 16px' : '16px 32px 0 32px')};
`;
