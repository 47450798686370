import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colorPalette } from '@s_mart/tokens';

export const DivImagesEButtons = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
`;

export const DivLogoBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 124.5px;
  border: 1px solid ${colorPalette.neutral[100]};
  border-radius: 4px;
`;

export const ImgLogoBanner = styled.img`
  max-height: 124.5px;
  max-width: 166px;
`;

export const DivExternaPreviewFakePdf = styled.div<{ firefox: boolean; width: number; mobile?: boolean }>`
  display: flex;
  padding: 8px;
  background-color: ${colorPalette.neutral[600]};
  overflow-y: auto;
  justify-content: center;

  //Lógica tem que ser feita pois na desgraça do firefox o css zoom não funciona,
  //então tem que usar transform e fazer essa manobra do if
  ${({ firefox, mobile, width }) => {
    if (firefox) {
      return css(`
      height: ${mobile ? '250px' : '550px'};
      width: ${mobile ? '90%' : '440px'};
      overflow-x: hidden;
    
      > div {
        transform: ${mobile ? `scale(${width / 1075})` : 'scale(0.55)'};
        transform-origin: 50% 0%;
      }
      `);
    } else {
      return css(`
      zoom: ${
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        document.getElementById('grid-preview-fake-pdf')?.offsetWidth! / 790
      };`);
    }
  }}
`;
