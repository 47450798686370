import { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Typography } from '@s_mart/core';
import { TextField } from '@s_mart/form';
import { valor } from '@s_mart/masks';
import { colorPalette } from '@s_mart/tokens';
import { ellipsis } from '@s_mart/utils';

import { useIsAdmin } from 'hooks/useIsAdmin/useIsAdmin';

import { useFields } from '../../useFields';

const FieldValorVendaPaineis = () => {
  const methods = useFormContext();
  const { handleCalcularValorTotal } = useFields();
  const isAdmin = useIsAdmin();

  const _formValuesQuantidadePaineis = methods.watch('quantidadePaineis');
  const _formValuesValorVendaPaineis = methods.watch('valorVendaPaineis');

  useEffect(() => {
    handleCalcularValorTotal({ values: methods.getValues(), methods });
  }, [_formValuesValorVendaPaineis]);

  return (
    <TextField
      name='valorVendaPaineis'
      label='Valor de venda'
      value={valor.format(_formValuesValorVendaPaineis)}
      helperText={
        <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
          {ellipsis(
            `Total: ${valor.format((_formValuesValorVendaPaineis || 0) * (_formValuesQuantidadePaineis || 0))}`,
            19
          )}
        </Typography>
      }
      mask='valor'
      placeholder='R$ 0,00'
      disabled={!isAdmin}
    />
  );
};

export default memo(FieldValorVendaPaineis);
