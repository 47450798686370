import useConfiguracoes from 'store/configuracoes/configuracoes';

export function useIsTesteGratis() {
  const { meusDados } = useConfiguracoes();

  if (meusDados?.dataContratacao === null) {
    return true;
  }

  return false;
}
