import { FC, useEffect, memo, useCallback, useMemo, useState } from 'react';

import { Grid, LIcon, Typography, Tooltip } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { colorPalette } from '@s_mart/tokens';

import { Badge, BotaoAberto, BotaoFechado, IconeBotao, TextoBotao } from './botaoAppBar.styles';
import { BotaoAppBarProps } from './botaoAppBar.types';

const BotaoAppBar: FC<BotaoAppBarProps> = ({ texto, onClick, status, icone }) => {
  const _telaMenorQue405 = useMediaQuery(`(max-width: 405px)`);
  const [classeHover, setClassHover] = useState('hover');

  const _onMouseEnter = useCallback(() => {
    setClassHover('hover');
  }, []);

  const _onMouseLeave = useCallback(() => setTimeout(setClassHover, 1000, ''), []);

  useEffect(() => {
    _onMouseLeave();
  }, []);

  const _iconeBoleto = useMemo(() => {
    return (
      <IconeBotao>
        <LIcon icon={icone} size='24px' color={status >= 3 ? colorPalette.red[300] : undefined} />
        {status !== 1 && <Badge />}
      </IconeBotao>
    );
  }, [status, icone]);

  if (_telaMenorQue405 && status) {
    return (
      <Tooltip title={texto} placement='bottom-start'>
        <Grid item>
          <BotaoFechado status={status} className={classeHover} onClick={onClick} disabled={false}>
            {_iconeBoleto}
          </BotaoFechado>
        </Grid>
      </Tooltip>
    );
  } else if (status) {
    return (
      <BotaoAberto
        status={status}
        className={classeHover}
        onClick={onClick}
        onMouseEnter={_onMouseEnter}
        onMouseLeave={_onMouseLeave}
        disabled={false}
      >
        {_iconeBoleto}
        <Typography variant='bodySM'>
          <TextoBotao className='texto'>{texto}</TextoBotao>
        </Typography>
      </BotaoAberto>
    );
  }
  return null;
};

export default memo(BotaoAppBar);
