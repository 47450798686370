import { create } from 'zustand';

import {
  GeradorDePropostaEtapa,
  GeradorDePropostaStates,
  GeradorDePropostaStore,
} from './geradorDeProposta.store.types';

const initialState: GeradorDePropostaStates = {
  etapa: GeradorDePropostaEtapa.DADOS_CLIENTE,
  geradorDeProposta: { aberto: false },
};

const useGeradorDeProposta = create<GeradorDePropostaStore>((set) => ({
  ...initialState,
  setGeradorDeProposta: (geradorDeProposta) => {
    if (geradorDeProposta.aberto === false) {
      set((state) => ({ ...state, etapa: GeradorDePropostaEtapa.DADOS_CLIENTE }));
    }

    set((state) => ({ ...state, geradorDeProposta: { ...state.geradorDeProposta, ...geradorDeProposta } }));
  },
  setEtapa: (etapa) => set((state) => ({ ...state, etapa })),
}));

export default useGeradorDeProposta;
