import { memo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid, LIcon, Tooltip, Typography } from '@s_mart/core';
import { TextField } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { composeValidators, required, maiorQue } from '@s_mart/rules';
import { lineInfoCircle } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import GeradorDePropostaDimensionamento from '../geradorDePropostaDimensionamento/geradorDePropostaDimensionamento';

const FluxoGrupoA = () => {
  const { watch } = useFormContext();
  const telaMenorQue420 = useMediaQuery(`(max-width: 420px)`);
  const telaMenorQue780 = useMediaQuery(`(max-width: 780px)`);
  const telaMenorQue900 = useMediaQuery(`(max-width: 900px)`);

  const _formValuesIrradiacaoMedia = watch('irradiacaoMedia');
  const _formValuesDemandaContratada = watch('demandaContratada');
  const _formValuesConsumoMedioPonta = watch('consumoMedioPonta');
  const _formValuesConsumoMedioForaPonta = watch('consumoMedioForaPonta');
  const _formValuesTarifaPonta = watch('tarifaPonta');
  const _formValuesTarifaForaPonta = watch('tarifaForaPonta');
  const _formValuesTarifaTusdG = watch('tarifaTusdg');

  const _handleTamanhoGrid = useCallback(
    (consumoMedioForaPonta?: boolean) => {
      if (telaMenorQue780) {
        return 6;
      } else if (telaMenorQue900 || consumoMedioForaPonta) {
        return 4;
      } else {
        return 3;
      }
    },
    [telaMenorQue780, telaMenorQue900]
  );

  return (
    <>
      <Grid item xs={telaMenorQue420 ? 12 : 6} sm={_handleTamanhoGrid()}>
        <TextField
          name='demandaContratada'
          label='Demanda contratada'
          required
          mask='numeroPontuacao'
          rules={composeValidators([maiorQue(0), required])}
          InputProps={{ endAdornment: <Typography variant='bodySM'>kW</Typography> }}
          inputProps={{ maxLength: 8 }}
        />
      </Grid>
      <Grid item xs={telaMenorQue420 ? 12 : 6} sm={_handleTamanhoGrid()}>
        <TextField
          name='consumoMedioPonta'
          label='Consumo médio Ponta'
          required
          mask='numeroPontuacao'
          rules={composeValidators([maiorQue(0), required])}
          InputProps={{ endAdornment: <Typography variant='bodySM'>kWh</Typography> }}
          inputProps={{ maxLength: 8 }}
        />
      </Grid>
      <Grid item xs={telaMenorQue420 ? 12 : 6} sm={_handleTamanhoGrid(true)}>
        <TextField
          name='consumoMedioForaPonta'
          label='Consumo médio Fora Ponta'
          required
          mask='numeroPontuacao'
          rules={composeValidators([maiorQue(0), required])}
          InputProps={{ endAdornment: <Typography variant='bodySM'>kWh</Typography> }}
          inputProps={{ maxLength: 8 }}
        />
      </Grid>
      <Grid item xs={telaMenorQue420 ? 12 : 6} sm={_handleTamanhoGrid()}>
        <TextField
          name='tarifaDemandaContratada'
          label='Tarifa demanda contratada'
          required
          mask='decimal3'
          InputProps={{
            startAdornment: (
              <Typography variant='bodySM' style={{ paddingTop: '1px', paddingLeft: '6px', marginRight: '-4px' }}>
                R$
              </Typography>
            ),
          }}
          rules={composeValidators([
            {
              validate: {
                valorMinimo: (value: string | number) => {
                  if (String(value) && Number(value) < 0.1) {
                    return `Valor mínimo de ${0.1}`;
                  }
                },
              },
            },
            required,
          ])}
          inputProps={{ maxLength: 7 }}
        />
      </Grid>
      <Grid item xs={telaMenorQue420 ? 12 : 6} sm={_handleTamanhoGrid()}>
        <TextField
          name='tarifaPonta'
          label='Tarifa Ponta'
          required
          mask='decimal3'
          InputProps={{
            startAdornment: (
              <Typography variant='bodySM' style={{ paddingTop: '1px', paddingLeft: '6px', marginRight: '-4px' }}>
                R$
              </Typography>
            ),
          }}
          rules={composeValidators([
            {
              validate: {
                valorMinimo: (value: string | number) => {
                  if (String(value) && Number(value) < 0.1) {
                    return `Valor mínimo de ${0.1}`;
                  }
                },
              },
            },
            required,
          ])}
          inputProps={{ maxLength: 6 }}
        />
      </Grid>
      <Grid item xs={telaMenorQue420 ? 12 : 6} sm={_handleTamanhoGrid()}>
        <TextField
          name='tarifaForaPonta'
          label='Tarifa Fora Ponta'
          required
          mask='decimal3'
          InputProps={{
            startAdornment: (
              <Typography variant='bodySM' style={{ paddingTop: '1px', paddingLeft: '6px', marginRight: '-4px' }}>
                R$
              </Typography>
            ),
          }}
          rules={composeValidators([
            {
              validate: {
                valorMinimo: (value: string | number) => {
                  if (String(value) && Number(value) < 0.1) {
                    return `Valor mínimo de ${0.1}`;
                  }
                },
              },
            },
            required,
          ])}
          inputProps={{ maxLength: 6 }}
        />
      </Grid>
      <Grid item xs={telaMenorQue420 ? 12 : 6} sm={_handleTamanhoGrid()}>
        <TextField
          name='tarifaTusdg'
          label={
            <div style={{ display: 'flex', gap: '4px' }}>
              <Typography variant='titleXXS'>Tarifa TUSDg</Typography>
              <Tooltip
                title='Conforme a nova Lei 14.000/300 toda potência (kW) que passar da demanda contratada, passará a ser cobrado a Tarifa TUSDg e não mais uma multa. Viabilizando o projeto fotovoltaico.'
                PopperProps={{ popperOptions: { placement: 'top' } }}
              >
                <div style={{ display: 'flex' }}>
                  <LIcon icon={lineInfoCircle} removeMargin size='16px' color={colorPalette.neutral[100]} />
                </div>
              </Tooltip>
            </div>
          }
          required
          mask='decimal3'
          InputProps={{
            startAdornment: (
              <Typography variant='bodySM' style={{ paddingTop: '1px', paddingLeft: '6px', marginRight: '-4px' }}>
                R$
              </Typography>
            ),
          }}
          rules={composeValidators([
            {
              validate: {
                valorMinimo: (value: string | number) => {
                  if (String(value) && Number(value) < 0.01) {
                    return `Valor mínimo de ${0.01}`;
                  }
                },
              },
            },
            required,
          ])}
          inputProps={{ maxLength: 7 }}
        />
      </Grid>
      {Boolean(
        Number(_formValuesIrradiacaoMedia) &&
          Number(_formValuesDemandaContratada) &&
          Number(_formValuesConsumoMedioPonta) &&
          Number(_formValuesConsumoMedioForaPonta) &&
          Number(_formValuesTarifaPonta) &&
          Number(_formValuesTarifaForaPonta) &&
          Number(_formValuesTarifaTusdG)
      ) && (
        <Grid item xs={12}>
          <GeradorDePropostaDimensionamento grupoA />
        </Grid>
      )}
    </>
  );
};

export default memo(FluxoGrupoA);
