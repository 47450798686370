import { Button, Dialog, Grid, Typography } from '@s_mart/core';

import usePlanosDialog from '../planosDialog/store/planosDialog.store';
import useExcedeuBilhetagemDialog from './excedeuBilhetagemDialog.store';

const ExcedeuBilhetagemDialog = () => {
  const { setPlanosDialog } = usePlanosDialog();
  const { setExcedeuBilhetagemDialog } = useExcedeuBilhetagemDialog();

  return (
    <Dialog
      open
      onClose={() => setExcedeuBilhetagemDialog({ aberto: false })}
      disableBackdropClick
      disableEscapeKeyDown
      disablePortal
      closeIcon
      maxWidth='xs'
      fullWidth
    >
      <Dialog.Header>Uau, você já atingiu o limite de geração</Dialog.Header>
      <Dialog.Content>
        <Grid container spacing={3} padding='1rem'>
          <Grid item xs={12} display='flex' justifyContent='center' textAlign='center'>
            <Typography variant='bodyLG'>
              Escolha o plano que mais se adequa a sua empresa e continue gerando suas propostas sem limites!
            </Typography>
          </Grid>
        </Grid>
      </Dialog.Content>
      <Dialog.Footer>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <Button
            fullWidth
            onClick={() => {
              setExcedeuBilhetagemDialog({ aberto: false });
              setPlanosDialog({ aberto: true });
            }}
          >
            Ver planos
          </Button>
        </div>
      </Dialog.Footer>
    </Dialog>
  );
};

export default ExcedeuBilhetagemDialog;
