import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@s_mart/hooks';
import { base64ToBlob } from '@s_mart/utils';

import {
  buscarInformacoesCompartilharProposta,
  gerarPdfProposta,
  salvarHistoricoAcaoProposta,
} from 'api/propostas/propostas';
import { TipoAcaoProposta } from 'api/propostas/propostas.types';
import { isProduction } from 'api/variables';
import { useNPSContext } from 'shared/nps/npsContext';
import { usePainelClienteStore } from 'shared/painelCliente/painelCliente.store';
import useApp from 'store/app/app';
import useAuth from 'store/auth/auth';
import useConfiguracoes from 'store/configuracoes/configuracoes';

import useVisualizarPropostaDialog from './store/visualizarPropostaDialog.store';
import { IPopoverCopiarLink } from './visualizarPropostaDialog.types';
import useOnBoarding from '../onBoarding/store/onBoarding.store';

const useVisualizarPropostaDialogFunctions = () => {
  const { setNotification, handleError, setLoading } = useApp();
  const { profile } = useAuth();
  const {
    linkProposta,
    setPdfProposta,
    setLinkProposta,
    visualizarPropostaDialog,
    setVisualizarPropostaDialog,
    pdfProposta,
  } = useVisualizarPropostaDialog();
  const { onBoardingFinalizado, setOnBoardingFinalizacao, setOnBoardingFinalizado } = useOnBoarding();
  const { handleAbrirNps } = useNPSContext();
  const telaMenorQue1480 = useMediaQuery(`(max-width: 1480px)`);
  const telaMenorQue1600 = useMediaQuery(`(max-width: 1600px)`);
  const { handleBuscarAtividades } = usePainelClienteStore();
  const { recursosContratados, informacoesPlano } = useConfiguracoes();
  const _navigate = useNavigate();

  const _planoGeradorComCRM = isProduction
    ? informacoesPlano?.codigoPlano === 170
    : informacoesPlano?.codigoPlano === 475;

  const controlePosicaoDivOnboarding = useMemo(() => {
    if (telaMenorQue1480) {
      return '5vw';
    }
    if (telaMenorQue1600) {
      return '10vw';
    }
    return '15vw';
  }, [telaMenorQue1480, telaMenorQue1600]);

  const handleCompartilharPorWhatsApp = useCallback(async () => {
    if (recursosContratados?.crm) {
      try {
        if (!visualizarPropostaDialog?.dadosProposta?.codigo) {
          throw new Error('Não foi possível compartilhar a proposta');
        }

        await salvarHistoricoAcaoProposta({
          tipoAcao: TipoAcaoProposta.COMPARTILHOU_PROPOSTA,
          id: visualizarPropostaDialog?.dadosProposta?.codigo,
        });

        handleBuscarAtividades({});
      } catch {
        //Ignorar erro caso endpoint de algum erro
      }
    }

    if (visualizarPropostaDialog?.dadosProposta?.codigo) {
      const { data } = await buscarInformacoesCompartilharProposta(visualizarPropostaDialog?.dadosProposta?.codigo);

      const text = `Olá, ${
        data?.data?.cliente || visualizarPropostaDialog?.dadosProposta?.cliente
      }!%0A%0ASua proposta solar está pronta!%0A%0APara visualizá-la, basta acessar o link abaixo:%0A%0A${
        data?.data?.link || linkProposta
      }%0A%0AQualquer dúvida, entre em contato conosco. Estamos sempre à disposição para conversarmos!%0A%0AAtenciosamente${
        profile?.nome && `, ${profile.nome}`
      }.`;

      const api = `https://api.whatsapp.com/send?phone=55${data?.data?.telefone?.replace(
        / |-|[)(]/g,
        ''
      )}&text=${text}&app_absent=0`;

      //Foi necessário passar um setTimeout no window.open para funcionar no Safari, não me pergunte o pq que da certo
      setTimeout(() => window.open(api, '_blank', 'noopener noreferrer'), 0);
    }
  }, [linkProposta, profile?.nome, visualizarPropostaDialog?.dadosProposta]);

  const handleCompartilharPorImpressao = useCallback(async () => {
    if (recursosContratados?.crm) {
      try {
        if (!visualizarPropostaDialog?.dadosProposta?.codigo) {
          throw new Error('Não foi possível compartilhar a proposta');
        }

        await salvarHistoricoAcaoProposta({
          tipoAcao: TipoAcaoProposta.IMPRIMIU_PROPOSTA,
          id: visualizarPropostaDialog?.dadosProposta?.codigo,
        });

        handleBuscarAtividades({});
      } catch {
        //Ignorar erro caso endpoint de algum erro
      }
    }

    const _pdfImpressao = window.open(
      URL.createObjectURL(
        base64ToBlob(
          visualizarPropostaDialog?.dadosProposta?.pdf ? visualizarPropostaDialog?.dadosProposta?.pdf : pdfProposta,
          'application/pdf'
        )
      )
    );
    _pdfImpressao?.print();
  }, [visualizarPropostaDialog?.dadosProposta?.pdf, pdfProposta]);

  const handleCompartilharPorCopiarLink = useCallback(
    async (
      open: HTMLButtonElement,
      setPopoverCopiarLinkSafari: React.Dispatch<React.SetStateAction<IPopoverCopiarLink>>
    ) => {
      if (visualizarPropostaDialog?.dadosProposta?.codigo) {
        const { data } = await buscarInformacoesCompartilharProposta(visualizarPropostaDialog?.dadosProposta?.codigo);

        //validação para saber se o navegador é Safari, pois o writeText não funciona neste navegador
        //portanto é renderizada a popover com o link explícito para que o usuário consiga selecionar e copiar
        if ((navigator.vendor.match(/[Aa]+pple/g)?.length || 0) > 0) {
          setPopoverCopiarLinkSafari({ open, link: data?.data?.link });
        } else {
          await navigator.clipboard.writeText(data?.data?.link);
          setNotification({ title: 'Link copiado!' });
        }
      } else {
        //validação para saber se o navegador é Safari, pois o writeText não funciona neste navegador
        //portanto é renderizada a popover com o link explícito para que o usuário consiga selecionar e copiar
        if ((navigator.vendor.match(/[Aa]+pple/g)?.length || 0) > 0) {
          setPopoverCopiarLinkSafari({ open, link: linkProposta });
        } else {
          await navigator.clipboard.writeText(linkProposta);
          setNotification({ title: 'Link copiado!' });
        }
      }
    },
    [linkProposta, setNotification, visualizarPropostaDialog?.dadosProposta?.codigo]
  );

  const handleVisualizarPdfProposta = useCallback(
    async (codigoProposta: number) => {
      setLoading(true);
      try {
        const { data: pdf } = await gerarPdfProposta({ acao: 1, codigoProposta });

        setPdfProposta(pdf.data.pdf);
        setLinkProposta(pdf.data.link);
        setLoading(false);
      } catch (err) {
        setPdfProposta('erro');
        handleError(err);
      }
    },
    [setPdfProposta, setLinkProposta, setLoading, handleError]
  );

  const handleClose = () => {
    if (!onBoardingFinalizado) {
      if (_planoGeradorComCRM) {
        setOnBoardingFinalizado(true);

        _navigate('/propostas');
      } else {
        setOnBoardingFinalizacao({ aberto: true });
      }
    } else if (visualizarPropostaDialog.fluxoGerador) {
      handleAbrirNps();
    }
    setVisualizarPropostaDialog({ aberto: false, dadosProposta: undefined, fluxoGerador: undefined });
    setPdfProposta('');
  };

  const handleAbrirPdf = useCallback(
    (url: string) => {
      // create <a> tag dinamically
      const fileLink = document.createElement('a');
      fileLink.href = url;

      // it forces the name of the downloaded file
      fileLink.download = `Proposta comercial ${profile?.razao_social}`;

      // triggers the click event
      fileLink.click();
    },
    [profile?.razao_social]
  );

  return {
    handleCompartilharPorImpressao,
    handleCompartilharPorWhatsApp,
    handleCompartilharPorCopiarLink,
    handleVisualizarPdfProposta,
    handleClose,
    controlePosicaoDivOnboarding,
    handleAbrirPdf,
  };
};

export default useVisualizarPropostaDialogFunctions;
