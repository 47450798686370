import { memo } from 'react';

import { Grid, LIcon, Typography, Button } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { linePlusCircle } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import SimulacaoPreviewPdfProposta from 'components/simulacaoPreviewPdfProposta/simulacaoPreviewPdfProposta';
import { useAnalytics } from 'hooks/useAnalytics/useAnalytics';

import useOnBoardingBoasVindas from './useOnBoardingBoasVindas';
import useConfiguracoes from 'store/configuracoes/configuracoes';

const OnBoardingBoasVindasConteudo = () => {
  const { handleProximoESubmit } = useOnBoardingBoasVindas();
  const { gerais } = useConfiguracoes();
  const { analyticsEventTracker } = useAnalytics({ category: 'onboarding' });
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  return (
    <Grid container spacing={3} mb={2}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
        <Typography variant='titleXS'>
          Estamos animados para mostrar como é fácil gerar uma proposta com o Solarfy. Vamos gerar sua primeira
          proposta!
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
        {telaMenorQue600 === false && <img src='static/svgs/onBoarding/onBoardingBro.svg' alt='onBoardingBro' />}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px',
            height: '265px',
            width: '183px',
            backgroundColor: colorPalette.neutral[600],
          }}
        >
          <div style={{ transform: 'scale(0.25)' }}>
            <SimulacaoPreviewPdfProposta banner={gerais?.banner} logo={gerais?.logotipo} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          startIcon={<LIcon icon={linePlusCircle} removeMargin />}
          onClick={() => {
            analyticsEventTracker({ action: 'Gerar proposta - Onboarding' });
            handleProximoESubmit();
          }}
        >
          Gerar minha primeira proposta
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(OnBoardingBoasVindasConteudo);
