import { Divider, Typography } from '@s_mart/core';
import { telefone } from '@s_mart/masks';
import { colorPalette } from '@s_mart/tokens';

import useConfiguracoes from 'store/configuracoes/configuracoes';

import { FolhaA4 } from '../simulacaoPreviewPdfProposta.styles';
import type { SimulacaoPreviewPdfPropostaProps } from '../simulacaoPreviewPdfProposta.types';
import * as styled from './simulaCapaPropostaNova.styles';

const SimulaCapaPropostaNova = ({ logo, cor, banner, whatsapp, site }: SimulacaoPreviewPdfPropostaProps) => {
  const { meusDados } = useConfiguracoes();

  const _dadosFicticiosProposta: { titulo: string; descricao: string }[] = [
    { titulo: 'Potência instalada:', descricao: '100,00 kWp' },
    { titulo: 'Validade da proposta:', descricao: '17/03/2023' },
    { titulo: 'Cidade de instalação:', descricao: 'Marau/RS' },
    { titulo: 'Vendedor responsável:', descricao: 'Nome do vendedor' },
  ];

  return (
    <FolhaA4>
      <styled.DivSuperior>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'center' }}>
            <div className='logotipo'>
              <img src={logo ? `data:image/png;base64,${logo}` : ''} alt='logo' />
            </div>
            <div className='propostaENomeCliente'>
              <Typography variant='titleMD'>
                Proposta comercial{' '}
                <Typography variant='titleMD' color={colorPalette.neutral[100]}>
                  01
                </Typography>
              </Typography>
              <Typography variant='bodySM' color={colorPalette.neutral[100]}>
                Nome completo do cliente
              </Typography>
            </div>
          </div>
          <div className='tituloColorido'>
            <Typography variant='titleXL' color={cor}>
              Sistema Fotovoltaico
            </Typography>
            <Divider orientation='horizontal' />
          </div>
          <div className='telefonesESite'>
            <Typography variant='bodySM' color={colorPalette.neutral[100]}>
              {telefone.format(meusDados?.fone || 9933421234)}
            </Typography>
            <Typography variant='bodySM' color={colorPalette.neutral[100]}>
              -
            </Typography>
            <Typography variant='bodySM' color={colorPalette.neutral[100]}>
              {telefone.format(whatsapp || 99999999999)}
            </Typography>
            <Typography variant='bodySM' color={colorPalette.neutral[100]}>
              -
            </Typography>
            <Typography variant='bodySM' color={colorPalette.neutral[100]}>
              {site || 'www.sitedasuaempresa.com.br'}
            </Typography>
          </div>
          <div className='dadosFakeProposta'>
            {_dadosFicticiosProposta.map((it, index) => (
              <div
                key={`dados-proposta-${index}`}
                style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}
              >
                <Typography variant='titleSM' color={colorPalette.neutral[100]}>
                  {it.titulo}
                </Typography>
                <Typography variant='bodyMD'>{it.descricao}</Typography>
              </div>
            ))}
          </div>
        </div>
      </styled.DivSuperior>
      <styled.DivInferior banner={banner || ''} />
    </FolhaA4>
  );
};

export default SimulaCapaPropostaNova;
