import { memo } from 'react';
import { Breakpoint } from '@mui/material';

import { Dialog } from '@s_mart/core';
import { Form } from '@s_mart/form';

import useOnBoardingBoasVindas from './useOnBoardingBoasVindas';
import useOnBoarding from '../store/onBoarding.store';

const OnBoardingBoasVindas = () => {
  const { handleCloseOnBoardingBoasVindas, handleControleOnBoardingBoasVindas, handleProximoESubmit } =
    useOnBoardingBoasVindas();
  const { etapa } = useOnBoarding();

  return (
    <Dialog
      onClose={handleCloseOnBoardingBoasVindas}
      open
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth={handleControleOnBoardingBoasVindas()?.tamanhoModal as Breakpoint}
      sx={{ '.MuiDialogTitle-root': { display: 'flex', justifyContent: etapa === 0 ? 'center' : 'normal' } }}
    >
      <Form onSubmit={handleProximoESubmit}>
        {() => (
          <>
            <Dialog.Header>{handleControleOnBoardingBoasVindas()?.title}</Dialog.Header>
            <Dialog.Content>{handleControleOnBoardingBoasVindas()?.conteudo}</Dialog.Content>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default memo(OnBoardingBoasVindas);
