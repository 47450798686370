import { AxiosResponse } from 'axios';

import { api } from '../api';
import { urls } from 'api/urls';

import type {
  AtualizarDadosEmpresaTypes,
  BuscaBoletoResponse,
  DadoCobranca,
  MeusDados,
  RecursosPlano,
} from './meusDados.types';

const buscarDadosEmpresa = async (): Promise<AxiosResponse<{ data: MeusDados }>> => await api.get(urls.meusDados);

const atualizarDadosEmpresa = async (payload: Partial<AtualizarDadosEmpresaTypes>) =>
  await api.patch(`${urls.meusDados}`, payload);

const converterTesteGratis = async () => await api.patch(`${urls.meusDados}/converter-teste-gratis`, undefined);

const consultarCobranca = async (): Promise<AxiosResponse<{ data: DadoCobranca[] }>> =>
  await api.get(`${urls.meusDados}/cobrancas`);

const baixarBoleto = async (identificador: string): Promise<AxiosResponse<{ data: BuscaBoletoResponse }>> =>
  await api.get(`${urls.meusDados}/cobrancas/baixar-boleto/${identificador}`);

const solicitarDesbloqueio = async () => await api.post(`${urls.meusDados}/solicitar-desbloqueio`, undefined);

const gerarFaturaPrepago = async () => await api.post(`${urls.meusDados}/gerar-fatura-prepago`, undefined);

const buscarRecursosPlano = async (): Promise<AxiosResponse<{ data: RecursosPlano }>> =>
  await api.get(`${urls.meusDados}/meu-plano/recursos`);

export {
  buscarDadosEmpresa,
  atualizarDadosEmpresa,
  converterTesteGratis,
  consultarCobranca,
  baixarBoleto,
  solicitarDesbloqueio,
  gerarFaturaPrepago,
  buscarRecursosPlano,
};
