import { memo, useMemo } from 'react';

import { Button, LIcon, Typography } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';
import { lineWhatsappBrands } from '@s_mart/solid-icons';

import { FooterContainer } from './footer.styles';
import { useFooter } from './useFooter';
import { useIntegracaoFornecedoresContext } from '../geradorDePropostaIntegracaoFornecedores.context';
import { useFormContext } from 'react-hook-form';

function Footer() {
  const { getValues } = useFormContext();
  const { kitsSelecionados } = useIntegracaoFornecedoresContext();
  const { handleNaoEncontreiFornecedor, handleVoltar } = useFooter();

  const textoBotaoAvancar = useMemo(() => {
    const _temKitsFotovoltaicosForm = Boolean(getValues().kitsFotovoltaicos);

    switch (kitsSelecionados?.length) {
      case 0:
        if (_temKitsFotovoltaicosForm) {
          return 'Avançar sem alterar produtos já selecionados';
        } else {
          return 'Avançar sem produto de fornecedor';
        }
      case 1:
        return 'Utilizar produto selecionado';
      default:
        return `Utilizar produtos selecionados`;
    }
  }, [kitsSelecionados]);

  return (
    <FooterContainer>
      <div>
        <Button
          startIcon={<LIcon icon={lineWhatsappBrands} removeMargin color={colorPalette.neutral[100]} />}
          type='button'
          variant='text'
          color='neutral'
          fullWidth
          onClick={handleNaoEncontreiFornecedor}
        >
          <Typography variant='bodySM' color={colorPalette.neutral[900]}>
            Não encontrei meu fornecedor
          </Typography>
        </Button>
      </div>
      <div>
        <Button type='button' variant='neutral' onClick={handleVoltar}>
          Voltar
        </Button>
        <Button variant={kitsSelecionados?.length ? 'contained' : 'outlined'} type='submit'>
          {textoBotaoAvancar}
        </Button>
      </div>
    </FooterContainer>
  );
}

export default memo(Footer);
