export const loginLogoUrl = '/static/svgs/login/logo.svg';

export const loginLogoNatalinoUrl = '/static/svgs/login/logoNatalino.svg';

export const loginBannerUrl = '/static/pngs/login/banner.png';

export const loginSloganUrl = '/static/svgs/login/slogan.svg';

export const telefoneSuporte = '(54) 9 8434-6205';

export const telefoneComercial = '(54) 3698-7168';

export const linkTermosDeContratacao = 'https://solarfy.com.br/termos/';

export const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const dashboardSemPropostasVisualizadasSvg = 'static/svgs/dashboard/noContent.svg';
