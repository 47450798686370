import { useCallback, useReducer, useState } from 'react';
import throttle from 'lodash.throttle';

import { listarClientesSelect } from 'api/clientes/clientes';
import { ListagemSimplesClientesTypes } from 'api/clientes/clientes.types';
import { listarVendedores } from 'api/vendedores/vendedores';
import type { ListagemVendedoresTypes } from 'containers/vendedores/vendedores.types';
import useApp from 'store/app/app';

import { initialValue, reducer } from './useSearchableReducer';

const useSearchable = () => {
  const { handleError } = useApp();

  const [loadingUseSearchable, _setLoadingUseSearchable] = useState(false);
  const [resultados, setResultados] = useReducer(reducer, initialValue);

  const handleSearchClientes = useCallback(
    throttle(
      async (term: string) => {
        _setLoadingUseSearchable(true);
        try {
          const { data } = await listarClientesSelect({ search: term });

          const _resultadoMapeado = data?.data?.map((cliente: ListagemSimplesClientesTypes) => ({
            value: String(cliente.codigo),
            label: cliente.nome,
          }));
          setResultados({ type: 'clientes', clientesPayload: _resultadoMapeado });
          _setLoadingUseSearchable(false);
        } catch (err) {
          _setLoadingUseSearchable(false);
          handleError(err);
        }
      },
      500,
      { leading: false }
    ),
    []
  );

  const handleSearchVendedores = useCallback(
    throttle(
      async (term: string) => {
        try {
          const { data } = await listarVendedores({ search: term });

          const _resultadoMapeado = data?.data?.map((vendedor: ListagemVendedoresTypes) => ({
            value: String(vendedor.codigo),
            label: vendedor.nome,
          }));
          setResultados({ type: 'vendedores', vendedoresPayload: _resultadoMapeado });
        } catch (err) {
          handleError(err);
        }
      },
      500,
      { leading: false }
    ),
    []
  );

  return {
    handleSearchClientes,
    handleSearchVendedores,
    resultados,
    loadingUseSearchable,
  };
};

export default useSearchable;
