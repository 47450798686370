import usePlanosDialog from 'shared/modais/planosDialog/store/planosDialog.store';

const useBannerContratacao = () => {
  const { setPlanosDialog } = usePlanosDialog();

  const handleAbrirPlanosDialog = () => {
    setPlanosDialog({ aberto: true });
  };

  return { handleAbrirPlanosDialog };
};

export default useBannerContratacao;
