import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { buscarProduto, listarProdutos } from 'api/produtos/produtos';
import { BuscarProdutoDados, ListarProdutosTypes } from 'api/produtos/produtos.types';
import { ListagemProdutosTypes } from 'containers/produtos/produtos.types';
import useApp from 'store/app/app';

const useFieldsKitsFotovoltaicos = () => {
  const { setValue } = useFormContext();
  const { handleError } = useApp();

  const [kitsFotovoltaicos, _setKitsFotovoltaicos] = useState<ListagemProdutosTypes[]>();
  const [loadingKitsFotovoltaicos, _setLoadingKitsFotovoltaicos] = useState(false);

  const handleListarKitsFotovoltaicos = useCallback(async () => {
    _setLoadingKitsFotovoltaicos(true);
    try {
      const { data } = await listarProdutos({ tipoProduto: '3', all: true });

      if (data) {
        _setKitsFotovoltaicos(data?.data);
        setValue(
          'listagemKitsFotovoltaicos',
          data?.data.reduce((acc: ListarProdutosTypes[], item: ListarProdutosTypes) => {
            acc[item.codigo] = item;
            return acc;
          }, {})
        );
      }
      _setLoadingKitsFotovoltaicos(false);
    } catch (err) {
      _setLoadingKitsFotovoltaicos(false);
      handleError(err);
    }
  }, [handleError]);

  const handleBuscarKitFotovoltaicoSelecionado = useCallback(
    async (index: number, values: any) => {
      if (values?.value) {
        try {
          const { data }: { data: { data: BuscarProdutoDados } } = await buscarProduto(values?.value);

          setValue(`kitsFotovoltaicos.${index}.potenciaKitsFotovoltaicos`, String(data?.data?.potencia));
          setValue(`kitsFotovoltaicos.${index}.descricaoKitsFotovoltaicos`, data?.data?.descricao || '');
          setValue(
            `kitsFotovoltaicos.${index}.descricaoGarantiaKitsFotovoltaicos`,
            data?.data?.descricaoGarantia || ''
          );
          setValue(
            `kitsFotovoltaicos.${index}.valorVendaKitsFotovoltaicos`,
            data?.data?.valorVenda ? String(data?.data?.valorVenda) : ''
          );
          setValue(
            `kitsFotovoltaicos.${index}.mostrarDetalhes`,
            Boolean(data?.data?.descricao || data?.data?.descricaoGarantia)
          );
        } catch (err) {
          handleError(err);
        }
      }
    },
    [handleError, setValue]
  );

  useEffect(() => {
    handleListarKitsFotovoltaicos();
  }, []);

  return { kitsFotovoltaicos, loadingKitsFotovoltaicos, handleBuscarKitFotovoltaicoSelecionado };
};

export default useFieldsKitsFotovoltaicos;
