import { useState } from 'react';

import { Button, Dialog, Grid, LIcon, Typography } from '@s_mart/core';
import { Form, Select } from '@s_mart/form';
import { lineCog } from '@s_mart/regular-icons';
import { required } from '@s_mart/rules';

import { useMarcarComoPerdidaDialog } from './useMarcarComoPerdidaDialog';
import useMarcarComoPerdidaDialogStore from './store/marcarComoPerdidaDialog.store';
import { ConfigurarMotivosPerdaDialog } from '../configurarMotivosPerdaDialog/configurarMotivosPerdaDialog';
import { useIsAdmin } from 'hooks/useIsAdmin/useIsAdmin';

export function MarcarComoPerdidaDialog() {
  const { motivosPerda, ultimoMotivoCadastrado } = useMarcarComoPerdidaDialogStore();
  const { handleClose, handleMarcarComoPerdida } = useMarcarComoPerdidaDialog();
  const [configurarMotivosPerdaDialog, setConfigurarMotivosPerdaDialog] = useState(false);

  const _isAdmin = useIsAdmin();

  return (
    <>
      <Dialog open onClose={handleClose} maxWidth='xs' fullWidth disableEscapeKeyDown disableBackdropClick closeIcon>
        <Form
          onSubmit={handleMarcarComoPerdida}
          defaultValues={{
            motivoPerda: { label: ultimoMotivoCadastrado?.nome, value: ultimoMotivoCadastrado?.codigo },
          }}
        >
          <Dialog.Header>
            <Typography variant='titleMD'>Motivo de perda</Typography>
          </Dialog.Header>
          <Dialog.Content>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  name='motivoPerda'
                  label='Selecione o motivo da perda'
                  required
                  rules={required}
                  options={
                    motivosPerda?.map((motivo) => ({
                      label: motivo.nome,
                      value: motivo.codigo,
                    })) ?? []
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                  footer={
                    _isAdmin
                      ? ({ closeSelect }) => (
                          <Button
                            startIcon={<LIcon icon={lineCog} size='18px' removeMargin />}
                            variant='text'
                            size='small'
                            fullWidth
                            style={{ justifyContent: 'left', paddingLeft: 16, paddingRight: 16 }}
                            onClick={() => {
                              setConfigurarMotivosPerdaDialog(true);
                              closeSelect();
                            }}
                          >
                            <Typography variant='bodySM'>Configurar motivos de perda</Typography>
                          </Button>
                        )
                      : undefined
                  }
                />
              </Grid>
            </Grid>
          </Dialog.Content>
          <Dialog.Footer>
            <Button onClick={handleClose} variant='neutral'>
              Cancelar
            </Button>
            <Button type='submit'>Marcar como perdido</Button>
          </Dialog.Footer>
        </Form>
      </Dialog>
      {configurarMotivosPerdaDialog && (
        <ConfigurarMotivosPerdaDialog handleClose={() => setConfigurarMotivosPerdaDialog(false)} />
      )}
    </>
  );
}
