const urls = {
  anexos: '/v2/anexos',
  amocrm: '/v2/amocrm',
  auth: '/auth',
  cep: '/cep',
  cidades: '/cidades',
  clientes: '/v2/clientes',
  cnpj: '/cnpj',
  concessionarias: '/v2/concessionarias',
  configuracoes: '/v2/configuracoes',
  dashboardAnalise: '/v2/dashboard-analise',
  dashboardControle: '/v2/dashboard-controle',
  dashboardPropostas: '/v2/dashboard-propostas',
  finalizarOnBoarding: '/onboarding',
  irradiacao: '/v2/irradiacao',
  historicoAcoes: '/v2/historico-acoes',
  meusDados: '/meus-dados',
  nps: '/v2/nps',
  produtos: '/v2/produtos',
  propostas: '/v2/propostas',
  publico: '/v2/publico',
  usuarios: '/v2/usuarios',
  vendedores: '/v2/vendedores',
  etapas: '/v2/etapas-funil',
  leads: '/v2/leads',
  atividades: '/v2/atividades',
  motivosPerda: '/v2/motivos-perda',
  relatorios: '/v2/relatorios',
  fornecedores: '/v2/fornecedores',
};

export { urls };
