export const base64ToFile = (base64: string, fileName: string, fileType?: string): File => {
  const byteString = atob(base64.includes('base64,') ? base64.split('base64,')[1] : base64);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: fileType || 'application/octet-stream' });
  return new File([blob], fileName, { type: fileType || 'application/octet-stream' });
};
