import { create } from 'zustand';

import { PropostaExcluirDialogStates, PropostaExcluirDialogStore } from './propostaExcluirDialog.store.types';

const initialState: PropostaExcluirDialogStates = {
  propostaExcluirDialog: { aberto: false },
};

const usePropostaExcluirDialog = create<PropostaExcluirDialogStore>((set) => ({
  ...initialState,
  setPropostaExcluirDialog: (propostaExcluirDialog) => set((state) => ({ ...state, propostaExcluirDialog })),
}));

export default usePropostaExcluirDialog;
