import { UseSearchableReducerAction, UseSearchableReducerState } from './useSearchable.types';

export const initialValue: UseSearchableReducerState = {};

export function reducer(
  state: UseSearchableReducerState,
  action: UseSearchableReducerAction
): UseSearchableReducerState {
  switch (action.type) {
    case 'clientes':
      return { ...state, clientes: action.clientesPayload };
    case 'vendedores':
      return { ...state, vendedores: action.vendedoresPayload };
    case 'vendedoresProposta':
      return { ...state, vendedoresProposta: action.vendedoresPropostaPayload };
  }
}
