import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { DataTable } from '@s_mart/data';
import { useMediaQuery } from '@s_mart/hooks';
import { CircularProgress } from '@s_mart/core';

import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';

import { getColunasTabelaFornecedores } from './tabelaFornecedoresColunas';
import { useIntegracaoFornecedoresContext } from '../geradorDePropostaIntegracaoFornecedores.context';
import EmptyState from './emptyState/emptyState';
import { ListMobile } from './listMobile/listMobile';

function TabelaFornecedores() {
  const { setValue } = useFormContext();
  const {
    data,
    isLoading,
    isFetching,
    hasLimitReached,
    apiRef,
    meta,
    handleFetchNextPage,
    alterarKitsSelecionados,
    fornecedor,
  } = useIntegracaoFornecedoresContext();
  const isMobile = useIsMobile();
  const heightMenorQue800 = useMediaQuery('(max-height: 800px)');

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  if (isMobile) {
    return <ListMobile data={data} isLoading={isFetching} fornecedor={fornecedor} />;
  }

  return (
    <DataTable
      columns={getColunasTabelaFornecedores(fornecedor)}
      rows={data}
      getRowId={(row) => row.codigo}
      loading={isFetching}
      autoHeight={!data.length}
      style={{ height: !data.length ? '300px' : heightMenorQue800 ? 'calc(100vh - 350px)' : 'calc(100vh - 500px)' }}
      infiniteScroll={{
        chegouNoLimite: hasLimitReached,
        onFetch: () => {
          if (isFetching) return;

          handleFetchNextPage({ page: meta?.current_page + 1 });
        },
      }}
      slots={{ noRowsOverlay: () => <EmptyState /> }}
      checkboxSelection
      onRowSelectionModelChange={(newRowSelectionModel) => {
        const linhasSelecionadas = data.filter((row) => newRowSelectionModel.includes(row.codigo)) || [];

        alterarKitsSelecionados(linhasSelecionadas);
        setValue('kitsFornecedoresSelecionados', linhasSelecionadas);
      }}
      apiRef={apiRef}
    />
  );
}

export default memo(TabelaFornecedores);
