export const taxaFioBLei14300 = (ano: number) => {
  //Valores de taxação do Fio B conforme Lei 14300
  switch (ano) {
    case 2023:
      //15%
      return 0.15;
    case 2024:
      //30%
      return 0.3;
    case 2025:
      //45%
      return 0.45;
    case 2026:
      //60%
      return 0.6;
    case 2027:
      //75%
      return 0.75;
    case 2028:
      //90%
      return 0.9;
    default:
      //100%
      return 1;
  }
};
