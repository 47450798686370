import { create } from 'zustand';

import { ClienteFormDialogStates, ClienteFormDialogStore } from './clienteFormDialog.store.types';

const initialState: ClienteFormDialogStates = {
  clienteFormDialog: { aberto: false },
};

const useClienteFormDialog = create<ClienteFormDialogStore>((set) => ({
  ...initialState,
  setClienteFormDialog: (clienteFormDialog) => set((state) => ({ ...state, clienteFormDialog })),
}));

export default useClienteFormDialog;
