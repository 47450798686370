import { api, apiPublic } from 'api/api';
import { urls } from 'api/urls';

import type { LoginPayload, TrocarSenhaPayload } from './auth.types';

const login = async (payload: LoginPayload) => await apiPublic.post(`${urls.auth}/login`, payload);

const logout = async () => await api.get(`${urls.auth}/logout`);

const esqueciSenha = async (payload: { email: string }) =>
  await apiPublic.post(`${urls.auth}/password/create`, payload);

const trocarSenha = async (payload: TrocarSenhaPayload) => await apiPublic.post(`${urls.auth}/password/reset`, payload);

export { login, logout, esqueciSenha, trocarSenha };
