import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import useGeradorDeProposta from '../../store/geradorDeProposta.store';

export interface IBaseDeCalculoPaybackSubmit {
  degradacaoSistema: string;
  taxaInflacao: string;
  precoKwh: string;
  simultaneidade: string;
}

const useBaseDeCalculoPaybackDialog = () => {
  const { setValue } = useFormContext();
  const { setGeradorDeProposta } = useGeradorDeProposta();

  const handleSubmitBaseDeCalculoPayback = useCallback(
    (values: IBaseDeCalculoPaybackSubmit) => {
      setValue('degradacaoSistema', values?.degradacaoSistema);
      setValue('taxaInflacao', values?.taxaInflacao);
      setValue('precoKwh', values?.precoKwh);
      setValue('simultaneidade', values?.simultaneidade);

      setGeradorDeProposta({ alertaBaseCalculo: { payback: false } });
    },
    [setValue, setGeradorDeProposta]
  );

  return { handleSubmitBaseDeCalculoPayback };
};

export default useBaseDeCalculoPaybackDialog;
