import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

import { getNps } from 'api/nps/nps';
import useApp from 'store/app/app';

import { NPSContextTypes } from './nps.types';

const NPSContext = createContext({} as NPSContextTypes);

const NPSProvider = ({ children }: PropsWithChildren) => {
  const { handleError } = useApp();

  const [mostrarNps, setMostrarNps] = useState(false);

  const handleAbrirNps = useCallback(async () => {
    try {
      const { data } = await getNps();

      if (data?.data?.exibirNps) {
        setMostrarNps(true);
      }
    } catch (err) {
      handleError(err);
    }
  }, [setMostrarNps, handleError]);

  return (
    <NPSContext.Provider
      value={{
        mostrarNps,
        setMostrarNps,
        handleAbrirNps,
      }}
    >
      {children}
    </NPSContext.Provider>
  );
};

const useNPSContext = () => {
  const context = useContext(NPSContext);

  if (!context) {
    throw new Error('useNPS() deve ser usado com um <NPSProvider />');
  }

  return context;
};

export { NPSProvider, useNPSContext, NPSContext };
