import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid, Typography } from '@s_mart/core';
import { TextField, Searchable } from '@s_mart/form';
import { cep, composeValidators, required } from '@s_mart/rules';

import useConfirmacaoDadosContratacao from './useConfirmacaoDadosContratacao';

const ConfirmacaoDadosContratacao = () => {
  const {
    formState: { isDirty },
  } = useFormContext();
  const { buscarCep, cidadesOptions, handlePesquisarCidades } = useConfirmacaoDadosContratacao();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='titleXS'>Vamos confirmar seus dados ?</Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextField name='nomeFantasia' label='Nome fantasia' required rules={required} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name='cep'
          label='CEP'
          onInputChange={(value) => {
            if (isDirty) {
              buscarCep(value);
            }
          }}
          required
          rules={composeValidators([cep, required])}
          inputProps={{ maxLength: 9 }}
          mask='cep'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='rua' label='Rua' required rules={required} />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField name='numero' label='Número' required rules={required} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField name='bairro' label='Bairro' required rules={required} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='complemento' label='Complemento' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Searchable
          name='cidade'
          label='Cidade'
          options={cidadesOptions || []}
          onInputChange={(value) => handlePesquisarCidades(value as string)}
          required
          rules={required}
        />
      </Grid>
    </Grid>
  );
};

export default memo(ConfirmacaoDadosContratacao);
