import { Button, Dialog, Grid, LIcon, Typography } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { lineWhatsApp } from '@s_mart/regular-icons';

import useAtualizarModeloPropostaDialog from './useAtualizarModeloPropostaDialog';

const AtualizarModeloPropostaDialog = ({ onClose }: { onClose: () => void }) => {
  const { handleAbrirConversaComSuporte } = useAtualizarModeloPropostaDialog();
  const telaMenorQue600 = useMediaQuery('(max-width: 600px)');

  return (
    <Dialog open disableBackdropClick disableEscapeKeyDown maxWidth='xs'>
      <Dialog.Header>
        <Typography variant={telaMenorQue600 ? 'titleMS' : 'titleMD'}>Aproveite ao máximo o Solarfy</Typography>
      </Dialog.Header>
      <Dialog.Content>
        <div style={{ display: 'flex', gap: '16px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant={telaMenorQue600 ? 'bodySM' : 'bodyMD'}>
              Sua proposta está desatualizada e não permite o uso do Grupo A. Vamos atualizar a sua proposta para o novo
              modelo, sem custos?
            </Typography>
            <Typography variant={telaMenorQue600 ? 'bodySM' : 'bodyMD'}>
              Não perca tempo, atualize sua proposta e use o Grupo A.
            </Typography>
          </div>
          {!telaMenorQue600 && (
            <img src='static/svgs/onBoarding/onBoardingBroIndicando.svg' alt='' style={{ maxHeight: '110px' }} />
          )}
        </div>
      </Dialog.Content>
      <Dialog.Footer>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              startIcon={<LIcon icon={lineWhatsApp} removeMargin size='22px' />}
              onClick={handleAbrirConversaComSuporte}
              fullWidth
            >
              {telaMenorQue600 ? 'Atualizar proposta!' : 'Quero atualizar minha proposta!'}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant='neutral' onClick={onClose} fullWidth>
              Não quero atualizar
            </Button>
          </Grid>
        </Grid>
      </Dialog.Footer>
    </Dialog>
  );
};

export default AtualizarModeloPropostaDialog;
