import { memo } from 'react';

import { Button, Grid, Typography } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';

const ApresentacaoGrupoAContent = ({ onClose }: { onClose: () => void }) => {
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='titleMD'>Integrador, atualizamos nossos cálculos! 🚀</Typography>
      </Grid>
      {telaMenorQue600 === false ? (
        <Grid item xs={12} sm={3}>
          <img alt='imagem-campo-tipo-ligacao' src='/static/pngs/onboardingGrupoA/fieldTipoLigacao.png' />
        </Grid>
      ) : undefined}
      <Grid item xs={12} sm={9}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: 'flex', textAlign: 'left' }}>
            <Typography variant='bodyMD'>
              Agora você pode dimensionar sistemas para empresas do <Typography variant='titleSM'>Grupo A</Typography>,
              diretamente do Solarfy!{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', textAlign: 'left' }}>
            <Typography variant='bodyMD'>
              Para isso, basta selecionar a opção <Typography variant='titleSM'>Grupo A</Typography> no campo{' '}
              <Typography variant='titleSM'>Tipo de ligação</Typography>.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
        <Button onClick={onClose}>Entendi</Button>
      </Grid>
    </Grid>
  );
};

export default memo(ApresentacaoGrupoAContent);
