import styled from '@emotion/styled';

export const SLoadingDots = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #fff;
  box-shadow: -16px 0 #fff, 16px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;

  @keyframes shadowPulse {
    33% {
      background: #fff;
      box-shadow: -16px 0 #ff3d00, 16px 0 #fff;
    }
    66% {
      background: #ff3d00;
      box-shadow: -16px 0 #fff, 16px 0 #fff;
    }
    100% {
      background: #fff;
      box-shadow: -16px 0 #fff, 16px 0 #ff3d00;
    }
  }
`;
