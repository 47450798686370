import { memo } from 'react';

import { TextField } from '@s_mart/form';

import { ButtonTipoGarantia } from '../../fields.styles';
import { useFormContext } from 'react-hook-form';

const FieldGarantiaOutros = ({ index }: { index: number }) => {
  const { setValue, watch } = useFormContext();

  const _formValuesOutros = watch('outros');

  return (
    <TextField
      name={`outros.${index}.garantiaOutros`}
      label='Garantia'
      value={watch(`outros.${index}.garantiaOutros`)}
      placeholder='20'
      InputProps={{
        endAdornment: (
          <ButtonTipoGarantia
            variant='neutral'
            onClick={() =>
              setValue(
                `outros.${index}.tipoGarantiaOutros`,
                _formValuesOutros?.[index || 0]?.tipoGarantiaOutros === 0 ? 1 : 0
              )
            }
          >
            {_formValuesOutros?.[index || 0]?.tipoGarantiaOutros === 0 ? 'Meses' : 'Anos'}
          </ButtonTipoGarantia>
        ),
      }}
      mask='numero'
    />
  );
};

export default memo(FieldGarantiaOutros);
