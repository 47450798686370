import { useFormContext } from 'react-hook-form';

import { Grid, Typography } from '@s_mart/core';
import { decimal } from '@s_mart/masks';
import { colorPalette } from '@s_mart/tokens';

import { DadosDimensionamentoGrupoBDiv } from './grupoB.styles';

const GrupoB = () => {
  const { watch } = useFormContext();

  const _formValuesGeracaoSistema = watch('geracaoSistema');
  const _formValuesQuantidadePaineis = watch('quantidadePaineis');
  const _formValuesPotenciaInstalada = watch('potenciaInstalada');
  const _formValuesPotenciaInversor = watch('potenciaInversor');
  const _formValuesAreaMinima = watch('areaMinima');

  return (
    <Grid item xs={12}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          padding: '16px 8px',
          borderRadius: '8px',
          border: `1px solid ${colorPalette.neutral[50]}`,
        }}
      >
        <Typography variant='titleXS'>Resultado do dimensionamento:</Typography>
        <DadosDimensionamentoGrupoBDiv>
          <div className='geracao'>
            <Typography variant='bodyXS' color={colorPalette.neutral[100]} noWrap>
              Geração sistema:
            </Typography>
            <Typography variant='titleXS' color={colorPalette.neutral[900]} noWrap>
              {decimal.format(_formValuesGeracaoSistema)} kWh
            </Typography>
          </div>

          <div className='potencia'>
            <Typography variant='bodyXS' color={colorPalette.neutral[100]} noWrap>
              Potência sistema:
            </Typography>
            <Typography variant='titleXS' color={colorPalette.neutral[900]} noWrap>
              {decimal.format(_formValuesPotenciaInstalada || _formValuesPotenciaInversor)} kWp
            </Typography>
          </div>

          <div className='quantidade'>
            <Typography variant='bodyXS' color={colorPalette.neutral[100]} noWrap>
              Qtd. painéis:
            </Typography>
            <Typography variant='titleXS' color={colorPalette.neutral[900]} noWrap>
              {decimal.format(_formValuesQuantidadePaineis, 0)}
            </Typography>
          </div>

          <div className='area'>
            <Typography variant='bodyXS' color={colorPalette.neutral[100]} noWrap>
              Área mínima:
            </Typography>
            <Typography variant='titleXS' color={colorPalette.neutral[900]} noWrap>
              {decimal.format(_formValuesAreaMinima)} m²
            </Typography>
          </div>
        </DadosDimensionamentoGrupoBDiv>
      </div>
    </Grid>
  );
};

export default GrupoB;
