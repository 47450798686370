import { Divider, Stack } from '@mui/material';

import { Button, Typography } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { valor } from '@s_mart/masks';
import { List } from '@s_mart/data';

import EmptyState from '../emptyState/emptyState';
import { FornecedorEnum, ProdutosFornecedores } from 'api/fornecedores/fornecedores.types';
import { useIntegracaoFornecedoresContext } from '../../geradorDePropostaIntegracaoFornecedores.context';
import { useFormContext } from 'react-hook-form';

interface ListMobileProps {
  data: ProdutosFornecedores[];
  isLoading: boolean;
  fornecedor: FornecedorEnum;
}

export function ListMobile({ data, isLoading, fornecedor }: ListMobileProps) {
  const telaMenorQue400 = useMediaQuery('(max-width: 400px)');
  const { setValue } = useFormContext();
  const { kitsSelecionados, alterarKitsSelecionados } = useIntegracaoFornecedoresContext();

  return (
    <List.Root data={data || []} isLoading={isLoading}>
      <List.InfiniteScroll
        currentOffset={0}
        hasLimitReached={true}
        onFetch={() => {}}
        style={{
          display: 'flex',
          flexDirection: 'column',
          border: 'none',
          maxHeight: `calc(100vh - ${telaMenorQue400 ? 516 : 450}px)`,
          overflow: 'auto',
          paddingRight: 8,
        }}
      >
        {(row, index, lastElementRef) => {
          const isSelected = kitsSelecionados.includes(row?.codigo);

          return (
            <List.ListItem
              index={index}
              ref={lastElementRef}
              key={`lista-kits-fornecedores-${index}`}
              style={{ borderTop: 'none', padding: 0, transition: 'opacity 0.2s' }}
            >
              <Stack gap={2} py={1.5}>
                <Divider />
                <Typography variant='bodySM' fontWeight='bold'>
                  {row?.nome}
                </Typography>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant='bodySM'>{row?.potencia ? `${row.potencia} kWp` : '-'}</Typography>
                  <Typography variant='bodySM'>{row?.tipoEstrutura}</Typography>
                  {fornecedor === FornecedorEnum.EDELTEC && (
                    <Typography variant='bodySM'>{row?.disponibilidade}</Typography>
                  )}
                  <Typography variant='bodySM'>
                    {fornecedor === FornecedorEnum.EDELTEC
                      ? valor.format(row?.precoVenda)
                      : valor.format(row?.precoCusto)}
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='center' gap={2}>
                  <Typography variant='bodySM' whiteSpace='nowrap'>
                    Ver detalhes
                  </Typography>
                  <Button
                    variant={isSelected ? 'neutral' : 'outlined'}
                    fullWidth
                    size='small'
                    onClick={() => {
                      if (isSelected) {
                        const _kitsSelecionados = kitsSelecionados.filter((codigo) => codigo !== row?.codigo);

                        alterarKitsSelecionados(_kitsSelecionados);

                        setValue(
                          'kitsFornecedoresSelecionados',
                          data.filter((row) => _kitsSelecionados.includes(String(row?.codigo)))
                        );
                      } else {
                        const _kitsSelecionados = [...kitsSelecionados, row?.codigo];

                        alterarKitsSelecionados(_kitsSelecionados);

                        setValue(
                          'kitsFornecedoresSelecionados',
                          data.filter((row) => _kitsSelecionados.includes(row.codigo))
                        );
                      }
                    }}
                  >
                    {isSelected ? 'Remover da proposta' : 'Adicionar à proposta'}
                  </Button>
                </Stack>
              </Stack>
            </List.ListItem>
          );
        }}
      </List.InfiniteScroll>
      <List.EmptyState>
        <EmptyState />
      </List.EmptyState>
    </List.Root>
  );
}
