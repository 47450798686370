import { memo, useState } from 'react';

import { Button, Grid, IconButton, LIcon, Typography } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { lineChartMixed } from '@s_mart/regular-icons';
import { colorPalette } from '@s_mart/tokens';

import ComoFuncionaSimultaneidadeDialog from 'components/comoFuncionaSimultaneidadeDialog/comoFuncionaSimultaneidadeDialog';

const ApresentacaoSimultaneidadeContent = ({ onClose }: { onClose: () => void }) => {
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  const [_comoFuncionaSimultaneidadeDialog, _setComoFuncionaSimultaneidadeDialog] = useState(false);

  return (
    <>
      {_comoFuncionaSimultaneidadeDialog && (
        <ComoFuncionaSimultaneidadeDialog onClose={() => _setComoFuncionaSimultaneidadeDialog(false)} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Typography variant='titleMD'>Novidade</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
              <img src='/static/svgs/onBoarding/onBoardingBroMegafone.svg' alt='homem-com-megafone' />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '2rem',
                textAlign: telaMenorQue600 ? 'center' : undefined,
              }}
            >
              <Typography variant='bodyLG' lineHeight={1.5}>
                Agora você pode informar no botão de payback{' '}
                <IconButton
                  variant='outlined'
                  style={{
                    color: colorPalette.neutral[500],
                    borderColor: colorPalette.neutral[500],
                    cursor: 'default',
                    padding: '6px 8px',
                  }}
                  disableTouchRipple
                >
                  <LIcon icon={lineChartMixed} size='22px' removeMargin />
                </IconButton>{' '}
                o percentual de{' '}
                <Typography variant='bodyLG' fontWeight={700}>
                  Simultaneidade
                </Typography>{' '}
                para cada proposta.
              </Typography>
              <Typography
                variant='bodySM'
                style={{ textDecoration: 'underline', cursor: 'pointer', userSelect: 'none' }}
                onClick={() => _setComoFuncionaSimultaneidadeDialog(true)}
              >
                Como funciona a simultaneidade?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button onClick={onClose}>Entendi</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(ApresentacaoSimultaneidadeContent);
