import { memo } from 'react';
import type { Breakpoint } from '@mui/material';

import { Form } from '@s_mart/form';
import { Button, Dialog, Typography } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';

import GraficoConsumo from './geradorDePropostaImagens/graficoConsumo/graficoConsumo';
import GraficoPayback from './geradorDePropostaImagens/graficoPayback/graficoPayback';
import useGeradorDePropostaFunctions from './useGeradorDePropostaFunctions';
import TabelaPayback from './geradorDePropostaImagens/tabelaPayback/tabelaPayback';
import useSaidaSemSalvarDialog from '../saidaSemSalvarDialog/store/saidaSemSalvarDialog.store';
import useGeradorDeProposta from './store/geradorDeProposta.store';
import GraficoFaturas from './geradorDePropostaImagens/graficoFaturas/graficoFaturas';
import { useReaproveitarDadosDialogStore } from './reaproveitarDadosDialog/reaproveitarDadosDialog.store';
import { ReaproveitarDadosDialog } from './reaproveitarDadosDialog/reaproveitarDadosDialog';
import useConfiguracoes from 'store/configuracoes/configuracoes';

const GeradorDeProposta = () => {
  const { setSaidaSemSalvarDialog } = useSaidaSemSalvarDialog();
  const { handleClose, handleControleGerador, handleProximoESubmit, handleVoltar, initialValues } =
    useGeradorDePropostaFunctions();
  const { geradorDeProposta } = useGeradorDeProposta();
  const { gerais } = useConfiguracoes();
  const reaproveitarDados = useReaproveitarDadosDialogStore();
  const _telaMenorQue360 = useMediaQuery(`(max-width: 360px)`);
  const _telaMenorQue480 = useMediaQuery(`(max-width: 480px)`);
  const _telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  return (
    <Form onSubmit={handleProximoESubmit} defaultValues={initialValues}>
      {(methods) => (
        <Dialog
          open
          onClose={
            // (!geradorDeProposta?.dadosClonar?.codigo && values.consumoMedio) é para poder abrir a modal de
            // saída sem salvar na 3 etapa do gerador, antes de escolher um caminho entre produtos ou kits
            methods.formState.isDirty || (!geradorDeProposta?.clonar?.codigo && Boolean(methods.watch('consumoMedio')))
              ? () =>
                  setSaidaSemSalvarDialog({
                    aberto: true,
                    onClose: () => handleClose(),
                    texto: (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                        <Typography variant='bodyXS'>
                          Notamos que você está saindo da geração de proposta. As informações inseridas não ficarão
                          salvas.
                        </Typography>
                        <Typography variant='bodyXS'>Deseja realmente sair?</Typography>
                      </div>
                    ),
                    textoPrimaryButton: 'Voltar para a geração',
                  })
              : () => handleClose()
          }
          maxWidth={handleControleGerador()?.tamanhoModal as Breakpoint}
          closeIcon
          disableBackdropClick
          disableEscapeKeyDown
          disablePortal
          fullScreen={_telaMenorQue480}
          sx={{
            '.MuiDialog-paper': {
              //     margin: _telaMenorQue480 ? '8px' : '32px',
              height: _telaMenorQue480 ? '95%' : undefined,
            },
          }}
        >
          <Dialog.Header>
            <Typography variant={_telaMenorQue360 ? 'titleSM' : 'titleMD'}>{handleControleGerador()?.title}</Typography>
          </Dialog.Header>
          <Dialog.Content>
            <div style={{ display: 'flex' }}>
              {handleControleGerador()?.conteudo}
              {geradorDeProposta.submit && (
                <>
                  <div id='grafico-consumo' style={{ position: 'absolute', right: '5000px' }}>
                    {/*Relação LARGURA/ALTURA no back = 2,44 */}
                    <div style={{ width: '757px' }}>
                      <GraficoConsumo cor={methods.watch('cor') || gerais?.hexCor} height={310} />
                    </div>
                  </div>
                  <div id='grafico-payback' style={{ position: 'absolute', right: '10000px' }}>
                    {/*Relação LARGURA/ALTURA no back = 2,9 */}
                    <div style={{ width: '759px' }}>
                      <GraficoPayback cor={methods.watch('cor') || gerais?.hexCor} height={262} />
                    </div>
                  </div>
                  {/*Relação LARGURA/ALTURA no back = 1,42 */}
                  <TabelaPayback />
                  {/*Relação LARGURA/ALTURA no back = 1,88 */}
                  <GraficoFaturas cor={methods.watch('cor') || gerais?.hexCor} />
                </>
              )}
            </div>
          </Dialog.Content>
          {!handleControleGerador()?.esconderFooterDialog && (
            <Dialog.Footer>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: handleControleGerador()?.additionalAction ? 'space-between' : 'end',
                  marginTop: '16px',
                }}
              >
                {handleControleGerador()?.additionalAction}
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Button
                    variant='neutral'
                    onClick={() => handleVoltar(methods)}
                    size={_telaMenorQue600 ? 'large' : 'medium'}
                  >
                    {handleControleGerador()?.secondaryButton}
                  </Button>
                  <Button
                    type='submit'
                    size={_telaMenorQue600 ? 'large' : 'medium'}
                    disabled={geradorDeProposta.submit}
                  >
                    {handleControleGerador()?.primaryButton}
                  </Button>
                </div>
              </div>
            </Dialog.Footer>
          )}
          {reaproveitarDados.open && <ReaproveitarDadosDialog />}
        </Dialog>
      )}
    </Form>
  );
};

export default memo(GeradorDeProposta);
