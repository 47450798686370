import styled from '@emotion/styled';
import { ToggleButtonGroup } from '@mui/material';

import { Switch } from '@s_mart/form';
import { colorPalette } from '@s_mart/tokens';

import { corPrimariaSolarfy } from 'styles/theme/theme';

export const SToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 32px;

  button[aria-pressed='true'] {
    background: ${corPrimariaSolarfy};

    &:hover {
      background: #ff3c00;
    }
  }
`;

export const StyledSwitch = styled(Switch)`
  .MuiSwitch-thumb {
    outline: 1px solid ${colorPalette.neutral[50]};
  }
`;
