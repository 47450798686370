import { useState } from 'react';

import { GridAcoes } from './acoesListagensMenu.styles';

import { LIcon, Menu, MenuItemsProps, PathSourceProps } from '@s_mart/core';
import { lineBars } from '@s_mart/regular-icons';

interface AcoesListagensMenuProps {
  acoes: MenuItemsProps[];
  icone?: PathSourceProps;
  tamanhoIcone?: string;
  style?: React.CSSProperties;
}

const AcoesListagensMenu = ({ acoes, icone, tamanhoIcone, style }: AcoesListagensMenuProps) => {
  const [menuOpen, setMenuOpen] = useState<{ open: (EventTarget & HTMLDivElement) | null }>({ open: null });

  return (
    <GridAcoes
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ ...style, height: tamanhoIcone ?? '24px' }}
      item
      xs={12}
    >
      <LIcon
        icon={icone ?? lineBars}
        style={{ cursor: 'pointer' }}
        size={tamanhoIcone ?? '24px'}
        removeMargin
        onClick={(e) => setMenuOpen({ open: e.currentTarget ?? null })}
      />
      <Menu
        open={Boolean(menuOpen.open)}
        onClose={() => setMenuOpen({ open: null })}
        anchorEl={menuOpen.open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        items={acoes}
      />
    </GridAcoes>
  );
};

export default AcoesListagensMenu;
