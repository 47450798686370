import { useCallback } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import useGeradorDeProposta from '../store/geradorDeProposta.store';
import {
  inversoresDefault,
  kitDeServicosDefault,
  kitsFotovoltaicosDefault,
  outrosDefault,
} from './controleCaminhoGerador.static';

interface TrocarCaminhoGerador {
  caminhoGerador: 'produtos' | 'kits';
  values: FieldValues;
  methods: UseFormReturn;
}

export function useControleCaminhoGerador() {
  const { geradorDeProposta, setGeradorDeProposta } = useGeradorDeProposta();

  const limparCamposCalculoPayback = useCallback(({ methods: { setValue } }: TrocarCaminhoGerador) => {
    setValue('inversores', undefined);
    setValue('outros', undefined);
    setValue('kitsFotovoltaicos', undefined);
    setValue('kitsDeServicos', undefined);
    setValue('modeloPainel', undefined);
    setValue('valorVendaPaineis', undefined);
    setValue('painelAdicionado', undefined);
  }, []);

  const preencherValoresCampos = useCallback(
    ({ caminhoGerador, methods: { setValue }, values }: TrocarCaminhoGerador) => {
      if (caminhoGerador === 'kits') {
        const prev = values?.valoresCaminhoAnteriorKits;

        setValue(
          'kitsFotovoltaicos',
          prev?.kitsFotovoltaicos ?? kitsFotovoltaicosDefault({ potenciaInstalada: values.potenciaInstalada })
        );
        setValue('kitsDeServicos', prev?.kitsDeServicos ?? kitDeServicosDefault);
        setValue('inversores', prev?.inversores || undefined);
        setValue('outros', prev?.outros ?? undefined);

        if (prev?.painel) {
          setValue('painelAdicionado', true);
          setValue('modeloPainel', prev?.painel?.modeloPainel);
          setValue('garantiaPaineis', prev?.painel?.garantiaPaineis);
          setValue('potenciaPaineis', prev?.painel?.potenciaPaineis);
          setValue('quantidadePaineis', prev?.painel?.quantidadePaineis);
          setValue('tipoGarantiaPaineis', prev?.painel?.tipoGarantiaPaineis);
          setValue('valorVendaPaineis', prev?.painel?.valorVendaPaineis);
        }
      } else if (caminhoGerador === 'produtos') {
        const prev = values?.valoresCaminhoAnteriorProdutos;

        setValue('painelAdicionado', prev?.painel ? true : false);
        setValue('modeloPainel', prev?.painel?.modeloPainel || null);
        setValue('garantiaPaineis', prev?.painel?.garantiaPaineis || null);
        setValue('potenciaPaineis', prev?.painel?.potenciaPaineis || values.potenciaPaineis);
        setValue('quantidadePaineis', prev?.painel?.quantidadePaineis || values.quantidadePaineis);
        setValue('tipoGarantiaPaineis', prev?.painel?.tipoGarantiaPaineis || null);
        setValue('valorVendaPaineis', prev?.painel?.valorVendaPaineis || null);

        setValue('inversores', prev?.inversores || inversoresDefault({ potenciaInstalada: values.potenciaInstalada }));
        setValue('outros', prev?.outros || outrosDefault);
      }
    },
    []
  );

  const setarValoresCaminhoAnterior = useCallback(({ values, methods, caminhoGerador }: TrocarCaminhoGerador) => {
    if (caminhoGerador === 'kits') {
      const prev = values?.valoresCaminhoAnteriorProdutos;

      methods.setValue('valoresCaminhoAnteriorProdutos', {
        painel: values?.modeloPainel
          ? {
              modeloPainel: values?.modeloPainel,
              garantiaPaineis: values?.garantiaPaineis,
              potenciaPaineis: values?.potenciaPaineis,
              quantidadePaineis: values?.quantidadePaineis,
              tipoGarantiaPaineis: values?.tipoGarantiaPaineis,
              valorVendaPaineis: values?.valorVendaPaineis,
            }
          : prev?.painel,
        inversores: values?.inversores?.length ? values.inversores : prev?.inversores,
        outros: values?.outros?.length ? values.outros : prev?.outros,
      });
    } else if (caminhoGerador === 'produtos') {
      const prev = values?.valoresCaminhoAnteriorKits;

      methods.setValue('valoresCaminhoAnteriorKits', {
        kitsFotovoltaicos: values?.kitsFotovoltaicos?.length ? values.kitsFotovoltaicos : prev?.kitsFotovoltaicos,
        kitsDeServicos: values?.kitsDeServicos?.length ? values.kitsDeServicos : prev?.kitsDeServicos,
        inversores: values?.inversores?.length ? values.inversores : prev?.inversores,
        outros: values?.outros?.length ? values.outros : prev?.outros,
        painel: values?.modeloPainel
          ? {
              modeloPainel: values?.modeloPainel,
              garantiaPaineis: values?.garantiaPaineis,
              potenciaPaineis: values?.potenciaPaineis,
              quantidadePaineis: values?.quantidadePaineis,
              tipoGarantiaPaineis: values?.tipoGarantiaPaineis,
              valorVendaPaineis: values?.valorVendaPaineis,
            }
          : prev?.painel,
      });
    }
  }, []);

  function handleControleCaminhoGerador(props: TrocarCaminhoGerador) {
    const _caminhoIgualAtual = geradorDeProposta?.caminhoGerador === props.caminhoGerador;
    if (_caminhoIgualAtual) return;

    setGeradorDeProposta({ caminhoGerador: props.caminhoGerador });

    limparCamposCalculoPayback(props);

    preencherValoresCampos(props);
    setarValoresCaminhoAnterior(props);
  }

  return { handleControleCaminhoGerador, setarValoresCaminhoAnterior };
}
