import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { buscarCliente } from 'api/clientes/clientes';
import { listarCidades } from 'api/cidades/cidades';
import useApp from 'store/app/app';
import { formataCpfCnpj } from 'utils/formataCpfCnpj/formataCpfCnpj';

const useGeradorDePropostaCliente = () => {
  const { setValue, watch } = useFormContext();
  const { handleError } = useApp();

  const _formValues = watch();

  const handleBuscarClienteSelecionado = useCallback(async () => {
    if (_formValues.nome?.value) {
      try {
        const { data } = await buscarCliente(_formValues.nome?.value);

        setValue('dadosCliente', data?.data);

        //tipoPessoa precisou ser 0 devido a option do toggleButton
        setValue('tipoPessoa', String(data?.data?.tipoPessoa));
        //telefone precisou ser null devido a mask
        setValue('telefone', data?.data?.telefone);
        if (
          data?.data?.cpfCnpj ||
          data?.data?.razaoSocial ||
          data?.data?.nomeResponsavel ||
          data?.data?.email ||
          data?.data?.codigoCidade
        ) {
          setValue('cadastroAvancado', true);
        } else {
          setValue('cadastroAvancado', false);
        }
        setValue('cpfCnpj', formataCpfCnpj(data?.data?.cpfCnpj));
        setValue('razaoSocial', data?.data?.razaoSocial);
        setValue('nomeResponsavel', data?.data?.nomeResponsavel);
        setValue('email', data?.data?.email);
        if (data?.data?.codigoCidade) {
          const { data: _res } = await listarCidades({ codigo_cidade: data.data.codigoCidade });

          const _cidadeSelect = {
            label: `${_res?.data[0].nome} - ${_res?.data[0].uf}`,
            value: _res?.data[0].codigo,
            key: 0,
          };

          setValue('dadosCidade', _cidadeSelect);
        } else {
          setValue('dadosCidade', undefined);
        }
        setValue('rua', data?.data?.rua);
        setValue('bairro', data?.data?.bairro);
        setValue('numero', data?.data?.numero);
        setValue('complemento', data?.data?.complemento);
      } catch (err) {
        handleError(err);
      }
    }
  }, [_formValues?.nome?.value, handleError, setValue]);

  return { handleBuscarClienteSelecionado };
};

export default useGeradorDePropostaCliente;
