import { api } from 'api/api';
import { urls } from 'api/urls';

import { NpsValues } from './nps.types';

const postNps = async (payload: NpsValues) => await api.post(urls.nps, payload);

const getNps = async () => await api.get(urls.nps);

export { postNps, getNps };
