import { memo } from 'react';

import { Button, Dialog } from '@s_mart/core';
import { Form } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';

import useEditarModeloPropostaDialog from './useEditarModeloPropostaDialog';
import Content from './content/content';

interface GeradorDePropostaEditarProps {
  onClose: () => void;
}

const EditarModeloPropostaDialog = ({ onClose }: GeradorDePropostaEditarProps) => {
  const { initialValues, handleSubmitEditarModeloProposta } = useEditarModeloPropostaDialog();
  const telaMenorQue400 = useMediaQuery(`(max-width: 400px)`);
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth='xl'
      fullWidth
      closeIcon
      disableBackdropClick
      sx={{ '.MuiDialog-paper': { margin: telaMenorQue600 ? '0px' : undefined } }}
    >
      <Form onSubmit={(values) => handleSubmitEditarModeloProposta(values, onClose)} defaultValues={initialValues}>
        {() => (
          <>
            <Dialog.Header>{telaMenorQue400 ? 'Editar' : 'Editando o'} modelo</Dialog.Header>
            <Dialog.Content>
              <Content />
            </Dialog.Content>
            <Dialog.Footer>
              <Button variant='neutral' onClick={onClose}>
                Cancelar
              </Button>
              <Button type='submit'>Salvar{telaMenorQue400 ? '' : ' alterações'}</Button>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default memo(EditarModeloPropostaDialog);
