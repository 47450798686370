import { alterarSituacaoProposta } from 'api/propostas/propostas';
import { useEffect } from 'react';
import useApp from 'store/app/app';
import useMarcarComoPerdidaDialogStore from './store/marcarComoPerdidaDialog.store';

export function useMarcarComoPerdidaDialog() {
  const { setLoading, handleError } = useApp();
  const { codigoProposta, buscarMotivosPerda, callback, onClose } = useMarcarComoPerdidaDialogStore();

  async function handleMarcarComoPerdida({ motivoPerda }: { motivoPerda: { label: string; value: number } }) {
    setLoading(true);
    try {
      if (codigoProposta === null) {
        throw new Error('Código da proposta não informado');
      }

      await alterarSituacaoProposta({
        situacao: '2',
        codigoMotivoPerda: motivoPerda?.value,
        id: codigoProposta,
      });

      callback?.();
      onClose();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    buscarMotivosPerda();
  }, []);

  return { handleMarcarComoPerdida, handleClose: onClose };
}
