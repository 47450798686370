import { memo } from 'react';

import { Typography, EmptyState } from '@s_mart/core';
import { List } from '@s_mart/data';

import { urls } from 'api/urls';
import type { ListarPropostasTypes } from 'api/propostas/propostas.types';
import { useInfiniteQuery } from 'hooks/useInfiniteQuery/useInfiniteQuery';

import VisualizarListaPropostasDialogListItem from './visualizarListaPropostasDialogListItem/visualizarListaPropostasDialogListItem';
import useVisualizarListaPropostasDialog from '../store/visualizarListaPropostasDialog.store';

const VisualizarListaPropostasDialogList = () => {
  const { visualizarListaPropostasDialog } = useVisualizarListaPropostasDialog();

  const {
    data: _propostas,
    meta,
    hasLimitReached,
    fetchNextPage,
    param,
    isFetching,
  } = useInfiniteQuery<ListarPropostasTypes>({
    name: 'propostas',
    url: `${urls.propostas}`,
    fixedParams: {
      cliente: visualizarListaPropostasDialog.codigoCliente || undefined,
      vendedor: visualizarListaPropostasDialog.codigoVendedor || undefined,
    },
  });

  return (
    <div style={{ height: '100%', maxHeight: '40vh', overflow: 'auto' }}>
      <List.Root data={_propostas || []} isLoading={isFetching}>
        <List.InfiniteScroll
          hasLimitReached={hasLimitReached}
          onFetch={(currentPage) =>
            fetchNextPage({
              pageParam: {
                ...param,
                page: currentPage + 1,
              },
            })
          }
          currentOffset={meta?.current_page}
        >
          {(row, index, lastElementRef) => (
            <List.ListItem index={index} ref={lastElementRef}>
              <VisualizarListaPropostasDialogListItem rowItem={row} />
            </List.ListItem>
          )}
        </List.InfiniteScroll>
        <List.EmptyState>
          <EmptyState
            illustration='/static/svgs/emptyStates/emptyStatePropostas.svg'
            title={<Typography variant='titleSM'>Nenhuma proposta atribuída</Typography>}
            description='Atribua propostas ao vendedor durante a geração da proposta.'
            style={{ textAlign: 'center' }}
          />
        </List.EmptyState>
      </List.Root>
    </div>
  );
};

export default memo(VisualizarListaPropostasDialogList);
