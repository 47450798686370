import { memo } from 'react';

import { Grid, LIcon, Typography, Divider, Dialog, Accordion } from '@s_mart/core';
import { Form } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { lineEnvelope, linePhone, lineUser, lineArrowUp } from '@s_mart/regular-icons';
import { colorPalette } from '@s_mart/tokens';

import { ListarPropostasTypes } from 'api/propostas/propostas.types';
import { urls } from 'api/urls';
import { useInfiniteQuery } from 'hooks/useInfiniteQuery/useInfiniteQuery';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';

import useVisualizarListaPropostasDialog from './store/visualizarListaPropostasDialog.store';
import useVisualizarListaPropostasDialogFunctions from './useVisualizarListaPropostasDialogFunctions';
import VisualizarListaPropostasDialogDataTable from './visualizarListaPropostasDialogDataTable/visualizarListaPropostasDialogDataTable';
import VisualizarListaPropostasDialogHistorico from './visualizarListaPropostasDialogHistorico/visualizarListaPropostasDialogHistorico';
import VisualizarListaPropostasDialogList from './visualizarListaPropostasDialogList/visualizarListaPropostasDialogList';

const VisualizarListaPropostasDialog = () => {
  const { informacoesCabecalho } = useVisualizarListaPropostasDialogFunctions();
  const { visualizarListaPropostasDialog, setVisualizarListaPropostasDialog } = useVisualizarListaPropostasDialog();
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);
  const isMobile = useIsMobile();

  useInfiniteQuery<ListarPropostasTypes>({
    name: 'propostas',
    url: `${urls.propostas}`,
    fixedParams: {
      cliente: visualizarListaPropostasDialog.codigoCliente || undefined,
      vendedor: visualizarListaPropostasDialog.codigoVendedor || undefined,
    },
  });

  const iconeENome = (
    <div style={{ display: 'flex', gap: '4px' }}>
      {informacoesCabecalho?.icone && <LIcon icon={informacoesCabecalho?.icone} removeMargin size='18px' />}
      <Typography variant='titleSM'>{informacoesCabecalho?.nome}</Typography>
    </div>
  );

  return (
    <Dialog
      open
      closeIcon
      disableBackdropClick
      disableEscapeKeyDown
      disablePortal
      onClose={() =>
        setVisualizarListaPropostasDialog({ aberto: false, codigoCliente: undefined, codigoVendedor: undefined })
      }
      fullWidth
      maxWidth='lg'
      //Para ficar melhor de visualizar os listItems em telas menores
      PaperProps={{
        style: { margin: telaMenorQue600 ? 0 : 32, width: telaMenorQue600 ? '90vw' : undefined, paddingBottom: 16 },
      }}
    >
      <Dialog.Header>{informacoesCabecalho?.titulo}</Dialog.Header>
      <Dialog.Content>
        <Grid container gap={4}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {isMobile && (
                <Grid item xs={12}>
                  {iconeENome}
                </Grid>
              )}
              <Grid item xs={12} style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  {!isMobile && iconeENome}
                  {informacoesCabecalho?.cpfCnpj && (
                    <Typography variant='bodyXS'>{informacoesCabecalho.cpfCnpj}</Typography>
                  )}
                  {visualizarListaPropostasDialog.cidadeCliente && (
                    <Typography variant='bodyXS'>{visualizarListaPropostasDialog.cidadeCliente}</Typography>
                  )}
                  {informacoesCabecalho?.cidade && (
                    <Typography variant='bodyXS'>{informacoesCabecalho.cidade}</Typography>
                  )}
                  {informacoesCabecalho?.ativo && (
                    <Typography variant='bodyXS'>{informacoesCabecalho.ativo}</Typography>
                  )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  {informacoesCabecalho?.nomeResponsavel && (
                    <div style={{ display: 'flex', gap: '4px' }}>
                      <LIcon icon={lineUser} removeMargin size='18px' />
                      <Typography variant='bodyXS'>{informacoesCabecalho.nomeResponsavel}</Typography>
                    </div>
                  )}
                  {informacoesCabecalho?.email && (
                    <div style={{ display: 'flex', gap: '4px' }}>
                      <LIcon icon={lineEnvelope} removeMargin size='18px' />
                      <Typography variant='bodyXS'>{informacoesCabecalho.email}</Typography>
                    </div>
                  )}
                  {informacoesCabecalho?.telefone && (
                    <div style={{ display: 'flex', gap: '4px' }}>
                      <LIcon icon={linePhone} removeMargin size='18px' />
                      <Typography variant='bodyXS'>{informacoesCabecalho.telefone}</Typography>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {isMobile === false && (
              <Grid container spacing={1}>
                <Grid item xs={visualizarListaPropostasDialog.codigoVendedor ? 12 : 8}>
                  <Typography variant='titleSM'>Propostas</Typography>
                </Grid>
                {!visualizarListaPropostasDialog.codigoVendedor && (
                  <Grid item xs={4}>
                    <Typography variant='titleSM'>Histórico de ações</Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider orientation='horizontal' />
                </Grid>
                <Grid item xs={visualizarListaPropostasDialog.codigoVendedor ? 12 : 8}>
                  <VisualizarListaPropostasDialogDataTable />
                </Grid>
                {!visualizarListaPropostasDialog.codigoVendedor && (
                  <Grid item xs={4}>
                    <VisualizarListaPropostasDialogHistorico />
                  </Grid>
                )}
              </Grid>
            )}
            {isMobile && (
              <Grid container gap={2}>
                <Grid item xs={12}>
                  <Accordion.Root title='Propostas' style={{ border: `1px solid ${colorPalette.neutral[50]}` }}>
                    <Accordion.Summary expandIcon={<LIcon icon={lineArrowUp} />}>
                      <Typography>Propostas</Typography>
                    </Accordion.Summary>
                    <Accordion.Details style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                      <VisualizarListaPropostasDialogList />
                    </Accordion.Details>
                  </Accordion.Root>
                </Grid>
                {!visualizarListaPropostasDialog.codigoVendedor && (
                  <Grid item xs={12}>
                    <Accordion.Root
                      title='Histórico de ações'
                      style={{ border: `1px solid ${colorPalette.neutral[50]}` }}
                    >
                      <Accordion.Summary expandIcon={<LIcon icon={lineArrowUp} />}>
                        <Typography>Histórico de ações</Typography>
                      </Accordion.Summary>
                      <Accordion.Details
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: 0,
                          maxHeight: '480px',
                          overflow: 'auto',
                        }}
                      >
                        <VisualizarListaPropostasDialogHistorico />
                      </Accordion.Details>
                    </Accordion.Root>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
};

const VisualizarListaPropostasDialogWrapper = () => {
  return (
    <Form onSubmit={() => {}}>
      <VisualizarListaPropostasDialog />
    </Form>
  );
};

export default memo(VisualizarListaPropostasDialogWrapper);
