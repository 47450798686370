import { api } from '../api';
import { urls } from 'api/urls';

import type {
  ListarPropostasTypes,
  GerarPdfPropostaPayloadTypes,
  GeradorDePropostaPayload,
  BuscarDadosBaseCalculo,
  SalvarHistoricoAcaoProposta,
  AlterarSituacaoProposta,
} from './propostas.types';
import type { AxiosResponse } from 'axios';

const listarPropostas = async (params: ListarPropostasTypes) => await api.get(`${urls.propostas}`, { params: params });

const cadastrarProposta = async (payload: GeradorDePropostaPayload) => await api.post(`${urls.propostas}`, payload);

const deletarProposta = async (id: number) => await api.delete(`${urls.propostas}/${id}`);

const buscarProposta = async (id: number) => await api.get(`${urls.propostas}/${id}`);

const alterarSituacaoProposta = async ({ id, situacao, codigoMotivoPerda }: AlterarSituacaoProposta) =>
  await api.patch(`${urls.propostas}/${id}/alterar-situacao`, undefined, {
    params: { situacao, codigoMotivoPerda },
  });

const gerarPdfProposta = async (payload: GerarPdfPropostaPayloadTypes) =>
  await api.post(`${urls.propostas}/gerar-pdf`, payload);

const listarVendedoresPropostas = async (params?: { search: string }) =>
  await api.get(`${urls.propostas}/listar-vendedores`, { params: params });

const buscarInformacoesCompartilharProposta = async (id: number) =>
  await api.get(`${urls.propostas}/compartilhar/${id}`);

const buscarDadosBaseCalculo = async (): Promise<AxiosResponse<{ data: BuscarDadosBaseCalculo }>> =>
  await api.get(`${urls.propostas}/base-calculo`);

const atualizarConfigTipoProdutoDaProposta = async (payload: { tipo: number }) =>
  await api.patch(`${urls.propostas}/configurar-produto`, payload);

const salvarHistoricoAcaoProposta = async ({ id, tipoAcao }: SalvarHistoricoAcaoProposta) => {
  await api.post(`${urls.propostas}/${id}/historico`, { tipoAcao });
};

export {
  listarPropostas,
  cadastrarProposta,
  deletarProposta,
  buscarProposta,
  alterarSituacaoProposta,
  gerarPdfProposta,
  listarVendedoresPropostas,
  buscarInformacoesCompartilharProposta,
  buscarDadosBaseCalculo,
  atualizarConfigTipoProdutoDaProposta,
  salvarHistoricoAcaoProposta,
};
