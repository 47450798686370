import { create } from 'zustand';

import { listarMotivosPerda } from 'api/motivosPerda/motivosPerda';
import useApp from 'store/app/app';

import { MarcarComoPerdidaDialogState, MarcarComoPerdidaDialogStore } from './marcarComoPerdidaDialog.store.types';

const initialState: MarcarComoPerdidaDialogState = {
  open: false,
  codigoProposta: null,
  motivosPerda: [],
  ultimoMotivoCadastrado: null,
};

const useMarcarComoPerdidaDialogStore = create<MarcarComoPerdidaDialogStore>((set) => ({
  ...initialState,
  setOpen: ({ open, codigoProposta, callback }) => {
    set({ open, codigoProposta, callback });
  },
  setUltimoMotivoCadastrado: (ultimoMotivoCadastrado) => set({ ultimoMotivoCadastrado }),
  async buscarMotivosPerda() {
    try {
      useApp.getState().setLoading(true);
      const { data } = await listarMotivosPerda();
      set({ motivosPerda: data.data });
    } catch (err) {
      useApp.getState().handleError(err);
    } finally {
      useApp.getState().setLoading(false);
    }
  },
  onClose: () => {
    set(initialState);
  },
}));

export default useMarcarComoPerdidaDialogStore;
