import { Outlet } from 'react-router-dom';

import useApp from 'store/app/app';
import Loading from 'components/loadings/loading/loading';
import { useNotification } from 'hooks/useNotification/useNotification';

const Public = () => {
  const { loading } = useApp();

  // Hook responsável pelas notificações
  useNotification();

  return (
    <>
      {loading && <Loading />}
      <Outlet />
    </>
  );
};

export default Public;
