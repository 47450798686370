/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { base64ToBlob } from '@s_mart/utils';

import dayjs from 'dayjs';

import type { IInformarPagamentoDialogContext, InformarPagamentoDialogEtapa } from './informarPagamentoDialog.types';

import useConfiguracoes from 'store/configuracoes/configuracoes';
import useApp from 'store/app/app';
import { baixarBoleto, buscarDadosEmpresa, solicitarDesbloqueio } from 'api/meusDados/meusDados';
import { telefoneSuporte } from 'config/constantes';
import axios, { AxiosError } from 'axios';

const InformarPagamentoDialogContext = React.createContext({} as IInformarPagamentoDialogContext);

const InformarPagamentoDialogProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const atualizarParametros = useConfiguracoes((state) => state.atualizarParametrosConfiguracoes);

  const _navigate = useNavigate();
  const [step, setStep] = React.useState<InformarPagamentoDialogEtapa>(4);

  const { dadosCobranca, setMeusDados, meusDados } = useConfiguracoes();
  const { setLoading, handleError, setNotification } = useApp();

  const [linhaDigitavel, _] = React.useState(
    dadosCobranca?.find((it) => ['EMITIDO', 'BAIXADO'].includes(it?.situacao))?.linhaDigitavel || ''
  );

  const linhaDigitavelComRegex = linhaDigitavel.replace(
    /(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})/g,
    '$1.$2 $3.$4 $5.$6 $7 $8'
  );

  const handleAbrirMeuPlano = () => {
    _navigate('minha-conta?meu-plano');
  };

  const handleAbrirContatoSuporte = () => {
    window?.open(
      `https://api.whatsapp.com/send?phone=55${telefoneSuporte.replace(/[^0-9]/g, '')}`,
      '_blank',
      'noopener noreferrer'
    );
  };

  const handleCopiarLinhaDigitavel = React.useCallback(() => {
    navigator.clipboard.writeText(linhaDigitavel);
    setNotification({
      title: 'Linha digitável copiada com sucesso!',
      autoHideDuration: 2000,
    });
  }, [linhaDigitavel, setNotification]);

  const handleBuscarInformacoesEmpresa = React.useCallback(async () => {
    try {
      const { data } = await buscarDadosEmpresa();
      setMeusDados(data.data);
    } catch (err) {
      handleError(err);
    }
  }, [handleError, setMeusDados]);

  const handleEtapaAtual = React.useCallback(() => {
    const faturas = dadosCobranca?.filter((item) => item?.situacao === 'EMITIDO' || item?.situacao === 'BAIXADO');
    const faturasVencidas = faturas?.filter((item) => item?.dataVencimento < new Date().toISOString());
    const quantidadeFaturasVencidas = faturasVencidas?.length || 0;

    if (meusDados?.situacao === 2 && quantidadeFaturasVencidas === 1) {
      setStep(0);
    } else if (meusDados?.situacao === 2 && quantidadeFaturasVencidas > 1) {
      setStep(1);
    }
  }, []);

  const handleMinhaFaturaFoiPaga = async () => {
    setLoading(true);
    try {
      const res = await solicitarDesbloqueio();

      if (res.status === 204) {
        setStep(2);
        handleBuscarInformacoesEmpresa();
      } else {
        setStep(3);
      }
    } catch (err: unknown | AxiosError) {
      if (axios.isAxiosError(err)) {
        if ((err.response?.data as { error: string })?.error) {
          setStep(3);
          setLoading(false);
        }
      } else {
        handleError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAbrirBoletoPagamento = async () => {
    setLoading(true);

    try {
      const fatura = dadosCobranca?.find((it) => it.situacao.includes('EMITIDO'));
      if (fatura) {
        const res = await baixarBoleto(fatura.identificador);
        if (res.data.data.boleto) {
          const file = base64ToBlob(res.data.data.boleto, 'application/pdf');
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          setLoading(false);
        }
      } else {
        setNotification({
          title: 'Não foi possível baixar o boleto',
          message: 'Não existe boleto para baixar no momento',
          autoHideDuration: 2000,
          variant: 'warning',
        });
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleMarcarBoletoVisualizado = async () => {
    try {
      setLoading(true);

      atualizarParametros({ visualizouAvisoNovoBoleto: dayjs().format('MM') });
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleEtapaAtual();
  }, [handleEtapaAtual]);

  return (
    <InformarPagamentoDialogContext.Provider
      value={{
        step,
        linhaDigitavelComRegex,
        handleAbrirMeuPlano,
        handleAbrirContatoSuporte,
        handleCopiarLinhaDigitavel,
        handleBuscarInformacoesEmpresa,
        handleMinhaFaturaFoiPaga,
        handleAbrirBoletoPagamento,
        handleMarcarBoletoVisualizado,
      }}
    >
      {children}
    </InformarPagamentoDialogContext.Provider>
  );
};

const useInformarPagamentoDialogContext = () => {
  const context = React.useContext<IInformarPagamentoDialogContext>(InformarPagamentoDialogContext);

  if (!context) {
    throw new Error(
      'useInformarPagamentoDialogContext deve ser usado dentro de um <InformarPagamentoDialogProvider />'
    );
  }

  return context;
};

export { InformarPagamentoDialogContext, InformarPagamentoDialogProvider, useInformarPagamentoDialogContext };
