import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import { LIcon, Tooltip, Typography } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import {
  lineFileInvoiceDollar,
  lineTrash,
  lineFire as lineFireRegular,
  lineShare,
  lineCheckCircle as lineCheckCircleRegular,
  lineTimesCircle as lineTimesCircleRegular,
  lineThumbsDown,
  lineThumbsUp,
  lineFileDoublePlus,
} from '@s_mart/regular-icons';
import {
  lineExclamationTriangle,
  lineFire as lineFireSolid,
  lineCheckCircle as lineCheckCircleSolid,
  lineTimesCircle as lineTimesCircleSolid,
} from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import * as propostasApi from 'api/propostas/propostas';
import type { ListarPropostasTypes } from 'api/propostas/propostas.types';
import { queryClient } from 'api/queryClient';
import useGeradorDeProposta from 'shared/modais/geradorDeProposta/store/geradorDeProposta.store';
import { usePainelClienteStore } from 'shared/painelCliente/painelCliente.store';
import useVisualizarPropostaDialog from 'shared/modais/visualizarPropostaDialog/store/visualizarPropostaDialog.store';
import useApp from 'store/app/app';
import useAuth from 'store/auth/auth';
import useConfiguracoes from 'store/configuracoes/configuracoes';

import usePropostaExcluirDialog from './dialogs/propostaExcluirDialog/store/propostaExcluirDialog.store';
import useMarcarComoPerdidaDialog from './dialogs/marcarComoPerdidaDialog/store/marcarComoPerdidaDialog.store';
import { IPainelClienteProps } from './propostasDataTable/propostasDatatable.types';
import useExcedeuBilhetagemDialog from 'shared/modais/excedeuBilhetagemDialog/excedeuBilhetagemDialog.store';
import { useIsTesteGratis } from 'hooks/useIsTesteGratis/useIsTesteGratis';

const usePropostasFunctions = ({ painelCliente }: { painelCliente?: IPainelClienteProps }) => {
  const { profile } = useAuth();
  const { setExcedeuBilhetagemDialog } = useExcedeuBilhetagemDialog();
  const { setNotification, handleError } = useApp();
  const { setPropostaExcluirDialog } = usePropostaExcluirDialog();
  const { setVisualizarPropostaDialog } = useVisualizarPropostaDialog();
  const { setGeradorDeProposta } = useGeradorDeProposta();

  const marcarComoPerdidaDialog = useMarcarComoPerdidaDialog();

  const { telaCliente, handleBuscarAtividades, handleBuscarPropostas, cliente } = usePainelClienteStore();

  const { recursosContratados, meusDados, propostasGeradas } = useConfiguracoes();

  const testeGratis = useIsTesteGratis();
  const _temPlanoCrm = recursosContratados?.crm;
  const telaMenorQue375 = useMediaQuery(`(max-width: 375px)`);
  const telaMenorQue425 = useMediaQuery(`(max-width: 425px)`);
  const telaMenorQue500 = useMediaQuery(`(max-width: 500px)`);

  const handleInformacoesDoChip = useCallback((situacaoProposta: number) => {
    switch (situacaoProposta) {
      case 0:
        return { texto: 'Inativa', cor: colorPalette.neutral[100] };
      default:
      case 1:
        return { texto: 'Ativa', cor: colorPalette.blue[100] };
      case 2:
        return { texto: 'Perdida', cor: colorPalette.red[300] };
      case 3:
        return { texto: 'Ganha', cor: colorPalette.green[300] };
    }
  }, []);

  const tamanhoDivClientePropostaList = useMemo(() => {
    if (telaMenorQue375) {
      return '124px';
    } else if (telaMenorQue425) {
      return '174px';
    } else if (telaMenorQue500) {
      return '224px';
    } else {
      return '294px';
    }
  }, [telaMenorQue375, telaMenorQue425, telaMenorQue500]);

  const tamanhoDivVendedorPropostaList = useMemo(() => {
    if (telaMenorQue375) {
      return '130px';
    } else if (telaMenorQue425) {
      return '150px';
    } else if (telaMenorQue500) {
      return '170px';
    } else {
      return '190px';
    }
  }, [telaMenorQue375, telaMenorQue425, telaMenorQue500]);

  interface AlertaVencimentoPropostaProps {
    dataGeracao?: string;
    dataValidade: string;
    situacaoProposta: number;
    colorirData?: boolean;
  }

  const handleAlertaVencimentoProposta = useCallback(
    ({ dataGeracao, dataValidade, situacaoProposta, colorirData }: AlertaVencimentoPropostaProps) => {
      const cor = dayjs(dataValidade).diff(dayjs(), 'days') < 0 ? colorPalette.red[300] : colorPalette.yellow[300];

      const propostaProximaAoVencimento = dayjs(dataValidade).diff(dayjs(), 'days') < 3;

      const title = () => {
        if (dayjs(dataValidade).diff(dayjs(), 'days') < 0) {
          return 'Proposta vencida';
        } else if (propostaProximaAoVencimento) {
          return 'Proposta próxima ao vencimento';
        } else {
          return undefined;
        }
      };

      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {situacaoProposta === 1 ? (
            <>
              <Typography variant='bodySM' color={propostaProximaAoVencimento && colorirData ? cor : undefined}>
                {dataGeracao ? `${dayjs(dataGeracao).utc().format('DD/MM/YY')} - ` : undefined}
                {dayjs(dataValidade).utc().format('DD/MM/YY')}
              </Typography>
              {propostaProximaAoVencimento && (
                <Tooltip title={title()}>
                  <div style={{ display: 'flex' }}>
                    <LIcon icon={lineExclamationTriangle} color={cor} removeMargin size='24px' />
                  </div>
                </Tooltip>
              )}
            </>
          ) : (
            <Typography variant='bodySM'>{dayjs(dataValidade).utc().format('DD/MM/YY')}</Typography>
          )}
        </div>
      );
    },
    []
  );

  const handleDataPropostaVisualizada = useCallback((data: string) => {
    if (!data) {
      return (
        <Typography variant='bodySM' style={{ paddingLeft: '36px' }}>
          -
        </Typography>
      );
    }
    if (dayjs(data).diff(dayjs(), 'hours') * -1 <= 24) {
      return (
        <div style={{ display: 'flex', gap: '4px' }}>
          <LIcon icon={lineFireSolid} size='18px' removeMargin />
          <Typography variant='bodySM'>
            {dayjs(data).diff(dayjs(), 'hours') * -1 < 1
              ? `${dayjs(data).diff(dayjs(), 'minutes') * -1} min`
              : dayjs(data).diff(dayjs(), 'hours') * -1 === 1
              ? `${dayjs(data).diff(dayjs(), 'hours') * -1} hora`
              : `${dayjs(data).diff(dayjs(), 'hours') * -1} horas`}
          </Typography>
        </div>
      );
    } else if (dayjs(data).diff(dayjs(), 'days') * -1 <= 30) {
      return (
        <div style={{ display: 'flex', gap: '4px' }}>
          {dayjs(data).diff(dayjs(), 'days') * -1 <= 2 && <LIcon icon={lineFireRegular} size='18px' removeMargin />}
          <Typography variant='bodySM'>
            {dayjs(data).diff(dayjs(), 'days') * -1 < 2
              ? `${dayjs(data).diff(dayjs(), 'days') * -1} dia`
              : `${dayjs(data).diff(dayjs(), 'days') * -1} dias`}
          </Typography>
        </div>
      );
    } else {
      return (
        <Typography variant='bodySM'>
          {dayjs(data).diff(dayjs(), 'months') * -1 < 2
            ? `${dayjs(data).diff(dayjs(), 'months') * -1} mês`
            : `${dayjs(data).diff(dayjs(), 'months') * -1} meses`}
        </Typography>
      );
    }
  }, []);

  const _handleAlterarSituacaoProposta = useCallback(
    async (id: number, situacao: string, callback?: () => void) => {
      //* SITUACAO '0': inativa, '1': ativa, '2': perdida, '3': ganha
      const _mensagemSnackbar = () => {
        switch (situacao) {
          case '0':
            return 'Proposta inativada.';
          case '1':
            return 'Proposta reativada.';
          case '2':
            return 'Proposta perdida.';
          default:
          case '3':
            return 'Proposta ganha!';
        }
      };
      try {
        const { status } = await propostasApi.alterarSituacaoProposta({
          id,
          situacao,
        });

        if (status === 200 || status === 204) {
          setNotification({ title: _mensagemSnackbar() });
          queryClient.clear();

          callback?.();
        }
      } catch (err) {
        handleError(err);
      }
    },
    [handleError, setNotification]
  );

  const handleMenuAcoes = useCallback((values: ListarPropostasTypes & { nomeVendedor?: string }) => {
    return [
      {
        label: 'Compartilhar proposta',
        icon: <LIcon icon={lineShare} size='18px' removeMargin />,
        hidden: values.situacao === 0,
        onClick: () => {
          setVisualizarPropostaDialog({
            aberto: true,
            dadosProposta: telaCliente.open
              ? { ...values, cliente: cliente?.nome || '', vendedor: values?.nomeVendedor || '' }
              : values,
          });
        },
      },
      {
        label: 'Visualizar proposta',
        icon: <LIcon icon={lineFileInvoiceDollar} size='18px' removeMargin />,
        onClick: () => {
          setVisualizarPropostaDialog({
            aberto: true,
            dadosProposta: telaCliente.open
              ? { ...values, cliente: cliente?.nome || '', vendedor: values?.nomeVendedor || '' }
              : values,
          });
        },
      },
      {
        label: 'Clonar proposta',
        icon: <LIcon icon={lineFileDoublePlus} size='18px' removeMargin />,
        onClick: () => {
          if (Number(propostasGeradas) >= 1 && testeGratis) {
            setExcedeuBilhetagemDialog({ aberto: true });
          } else {
            setGeradorDeProposta({
              aberto: true,
              clonar: {
                ...values,
                codigoLead: _temPlanoCrm && telaCliente.open ? telaCliente?.idLead : undefined,
              },
              callbackTelaCrm: () => {
                if (telaCliente.open) {
                  handleBuscarPropostas();
                }
              },
            });
          }
        },
        hidden: values.situacao !== 1 || !meusDados || meusDados?.situacao === 2,
      },
      {
        label: 'Marcar como ganha',
        icon: <LIcon icon={lineCheckCircleRegular} size='18px' removeMargin />,
        hidden: values.situacao !== 1,
        onClick: () =>
          _handleAlterarSituacaoProposta(values.codigo, '3', () => {
            if (telaCliente.open) {
              painelCliente?.callbackAtualizarLead(3);
              handleBuscarPropostas();
              handleBuscarAtividades({});
            }
          }),
      },
      {
        label: 'Marcar como perdida',
        icon: <LIcon icon={lineTimesCircleRegular} size='18px' removeMargin />,
        hidden: values.situacao !== 1,
        onClick: () => {
          if (!_temPlanoCrm) {
            _handleAlterarSituacaoProposta(values.codigo, '2');
          } else {
            marcarComoPerdidaDialog.setOpen({
              open: true,
              codigoProposta: values.codigo,
              callback: () => {
                if (telaCliente.open) {
                  painelCliente?.callbackAtualizarLead(2);
                  handleBuscarPropostas();
                  handleBuscarAtividades({});
                } else {
                  queryClient.clear();
                }
              },
            });
          }
        },
      },
      {
        label: 'Excluir proposta',
        icon: <LIcon icon={lineTrash} size='18px' removeMargin />,
        onClick: () =>
          setPropostaExcluirDialog({ aberto: true, codigoProposta: values.codigo, numeroProposta: values.numero }),
        hidden: values.situacao > 1 || profile?.tipo === 0,
      },
      {
        label: 'Inativar proposta',
        icon: <LIcon icon={lineThumbsDown} size='18px' removeMargin />,
        hidden: values.situacao !== 1,
        onClick: () =>
          _handleAlterarSituacaoProposta(values.codigo, '0', () => {
            if (telaCliente.open) {
              handleBuscarPropostas();
              handleBuscarAtividades({});
            }
          }),
      },
      {
        label: 'Reativar proposta',
        icon: <LIcon icon={lineThumbsUp} size='18px' removeMargin />,
        hidden: values.situacao !== 0,
        onClick: () =>
          _handleAlterarSituacaoProposta(values.codigo, '1', () => {
            if (telaCliente.open) {
              handleBuscarPropostas();
              handleBuscarAtividades({});
            }
          }),
      },
      {
        label: 'Desmarcar como ganha',
        icon: <LIcon icon={lineCheckCircleSolid} size='18px' removeMargin />,
        hidden: values.situacao !== 3,
        onClick: () =>
          _handleAlterarSituacaoProposta(values.codigo, '1', () => {
            if (telaCliente.open) {
              handleBuscarPropostas();
              handleBuscarAtividades({});
              painelCliente?.callbackAtualizarLead(1);
            }
          }),
      },
      {
        label: 'Desmarcar como perdida',
        icon: <LIcon icon={lineTimesCircleSolid} size='18px' removeMargin />,
        hidden: values.situacao !== 2,
        onClick: () =>
          _handleAlterarSituacaoProposta(values.codigo, '1', () => {
            if (telaCliente.open) {
              handleBuscarPropostas();
              handleBuscarAtividades({});
              painelCliente?.callbackAtualizarLead(1);
            }
          }),
      },
    ];
  }, []);

  return {
    handleInformacoesDoChip,
    tamanhoDivClientePropostaList,
    tamanhoDivVendedorPropostaList,
    handleAlertaVencimentoProposta,
    handleDataPropostaVisualizada,
    handleMenuAcoes,
  };
};

export default usePropostasFunctions;
