import styled from '@emotion/styled';

import { toRem } from '@s_mart/utils';

export const DivSuperior = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.51;
  //valores bizarros de padding vieram do desenho do Figma
  padding: ${toRem(37)} ${toRem(98.75)} ${toRem(8)} ${toRem(98.75)};
  align-items: center;

  .logotipo {
    display: flex;
    justify-content: center;
    max-height: ${toRem(64)};
    max-width: ${toRem(240)};

    > img {
      max-height: ${toRem(64)};
      max-width: ${toRem(240)};
    }
  }

  .propostaENomeCliente {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: center;
  }

  .tituloColorido {
    display: flex;
    flex-direction: column;
    gap: 13px;
    width: 100%;
    text-align: center;
  }

  .telefonesESite {
    display: flex;
    gap: 6px;
    justify-content: center;
  }

  .dadosFakeProposta {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: end;
  }
`;

export const DivInferior = styled.div<{ banner: string }>`
  display: flex;
  flex: 0.49;
  background-image: ${({ banner }) => `url(data:image/png;base64,${banner})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
`;
