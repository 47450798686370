import useSaidaSemSalvarDialog from './store/saidaSemSalvarDialog.store';

const useSaidaSemSalvarDialogFunctions = () => {
  const { saidaSemSalvarDialog, setSaidaSemSalvarDialog } = useSaidaSemSalvarDialog();

  const handleClose = () => {
    setSaidaSemSalvarDialog({ aberto: false, onClose: undefined, texto: undefined, textoPrimaryButton: undefined });
  };

  const handleSubmit = () => {
    if (saidaSemSalvarDialog?.onClose) {
      saidaSemSalvarDialog.onClose();
      handleClose();
    }
  };

  return { handleClose, handleSubmit };
};

export default useSaidaSemSalvarDialogFunctions;
