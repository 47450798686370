import { NotificationProps } from '@s_mart/typed';

type ILoading = boolean;
type IMensagemLoading = string;

type ILoadingGerador = { aberto?: boolean; etapa?: IEtapaLoadingGerador; produtosCadastrados?: boolean };

export enum IEtapaLoadingGerador {
  CADASTROPRODUTOS = 0,
  GERANDOGRAFICOS = 1,
  GERANDOPROPOSTA = 2,
  GERANDOPDF = 3,
}
export interface AppStates {
  loading: ILoading;
  mensagemLoading: IMensagemLoading | null;
  loadingGerador?: ILoadingGerador;
  notification: NotificationProps | null;
  lojaDeRecursos?: boolean;
  semConexao: boolean;
}
export interface AppStore extends AppStates {
  setLoading: (loading: ILoading) => void;
  setMensagemLoading: (mensagemLoading: IMensagemLoading) => void;
  setLoadingGerador: (loadingGerador: ILoadingGerador) => void;
  setNotification: (notification: NotificationProps | null) => void;
  handleError: (error: unknown) => void;
  setLojaDeRecursos: (loading: boolean) => void;
  limparApp: () => void;
  setSemConexao: (semConexao: boolean) => void;
}
