import { memo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, Grid, LIcon, Tooltip, Typography } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { decimal } from '@s_mart/masks';
import { lineEdit } from '@s_mart/regular-icons';
import { colorPalette } from '@s_mart/tokens';

import { useAnalytics } from 'hooks/useAnalytics/useAnalytics';

import BaseDeCalculoConsumoDialog from './baseDeCalculoConsumoDialog/baseDeCalculoConsumoDialog';
import GrupoB from './grupoB/grupoB';
import GrupoA from './grupoA/grupoA';
import useClarity from 'hooks/useClarity/useClarity';
import { TagsEnum } from 'hooks/useClarity/useClarity.types';

const GeradorDePropostaDimensionamento = ({ grupoA }: { grupoA?: boolean }) => {
  const { watch } = useFormContext();
  const { analyticsEventTracker } = useAnalytics({ category: 'Gerador de proposta - Instalação e Consumo' });
  const { setTagClarity } = useClarity();

  const menorQue600 = useMediaQuery(`(max-width: 600px)`);

  const [_mostrarBaseDeCalculoDialog, _setMostrarBaseDeCalculoDialog] = useState(false);

  const _formValuesPotenciaPaineis = watch('potenciaPaineis');
  const _formValuesPerdaSistema = watch('perdaSistema');
  const _formValuesLarguraPainel = watch('larguraPainel');
  const _formValuesAlturaPainel = watch('alturaPainel');
  const _formValuesAumentoConsumo = watch('aumentoConsumo');

  return (
    <>
      {_mostrarBaseDeCalculoDialog && (
        <BaseDeCalculoConsumoDialog onClose={() => _setMostrarBaseDeCalculoDialog(false)} />
      )}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            gap: 16,
            justifyContent: 'space-between',
            flexDirection: menorQue600 ? 'column' : 'row',
          }}
        >
          <Tooltip title='Pressione para editar a base de cálculo'>
            <Button
              variant='neutral'
              color='secondary'
              startIcon={<LIcon icon={lineEdit} removeMargin size='20px' />}
              onClick={() => {
                setTagClarity(TagsEnum['Clicou em editar a base de cálculo']);
                analyticsEventTracker({ action: 'Clicou em editar a base de cálculo' });
                _setMostrarBaseDeCalculoDialog(true);
              }}
              style={{ minWidth: '160px' }}
            >
              <Typography variant='bodySM'>Base de cálculo</Typography>
            </Button>
          </Tooltip>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
              <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
                Potência painel:
              </Typography>
              <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                {decimal.format(_formValuesPotenciaPaineis, 0) || ''} W
              </Typography>
            </div>
            <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
              <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
                Perda do sistema:
              </Typography>
              <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                {decimal.format(_formValuesPerdaSistema) || ''}%
              </Typography>
            </div>
            <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
              <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
                Dimensão:
              </Typography>
              <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                {decimal.format(_formValuesLarguraPainel) || ''}x{decimal.format(_formValuesAlturaPainel) || ''} m
              </Typography>
            </div>
            {!!_formValuesAumentoConsumo && (
              <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
                <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
                  Aumento consumo:
                </Typography>
                <Typography variant='titleXXS' color={colorPalette.neutral[600]}>
                  {decimal.format(_formValuesAumentoConsumo) || ''}%
                </Typography>
              </div>
            )}
          </div>
        </Grid>
        {!grupoA && <GrupoB />}
        {grupoA && <GrupoA />}
      </Grid>
    </>
  );
};

export default memo(GeradorDePropostaDimensionamento);
