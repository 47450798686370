import { migrarMotivosPerda } from 'api/motivosPerda/motivosPerda';
import { useMemo } from 'react';
import useApp from 'store/app/app';
import useMarcarComoPerdidaDialogStore from '../marcarComoPerdidaDialog/store/marcarComoPerdidaDialog.store';

import type { IExcluirMotivoDialogFormValues, IExcluirMotivoDialogProps } from './excluirMotivoDialog.types';

export function useExcluirMotivoDialog({ motivo, handleClose, handleExcluirMotivo }: IExcluirMotivoDialogProps) {
  const { motivosPerda } = useMarcarComoPerdidaDialogStore();

  const { handleError, setLoading } = useApp();

  async function migrarPropostas(motivoDestino: number) {
    await migrarMotivosPerda({
      codigoDestino: motivoDestino,
      codigoOrigem: motivo.codigo,
    });
  }

  async function handleExcluir(values: IExcluirMotivoDialogFormValues) {
    try {
      setLoading(true);

      await migrarPropostas(values.motivoDestino?.value);
      await handleExcluirMotivo();

      handleClose();
    } catch (err) {
      handleError(err);
    }
  }

  const motivosOptions = useMemo(
    () =>
      motivosPerda
        ?.filter((motivoPerda) => motivoPerda.codigo !== motivo.codigo)
        .map((motivoPerda) => ({
          label: motivoPerda.nome,
          value: motivoPerda.codigo,
        })) ?? [],
    []
  );

  return {
    handleExcluir,
    motivosOptions,
  };
}
