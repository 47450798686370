import { create } from 'zustand';

import { VendedorFormDialogStates, VendedorFormDialogStore } from './vendedorFormDialog.store.types';

const initialState: VendedorFormDialogStates = {
  vendedorFormDialog: { aberto: false },
};

const useVendedorFormDialog = create<VendedorFormDialogStore>((set) => ({
  ...initialState,
  setVendedorFormDialog: (vendedorFormDialog) => set((state) => ({ ...state, vendedorFormDialog })),
}));

export default useVendedorFormDialog;
