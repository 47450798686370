import { useState } from 'react';

import type { IMotivoProps } from './motivo.types';
import type { IExcluirMotivoState } from '../../excluirMotivoDialog/excluirMotivoDialog.types';

import useApp from 'store/app/app';
import { excluirMotivoPerda, verificarMotivoPerda } from 'api/motivosPerda/motivosPerda';
import useMarcarComoPerdidaDialogStore from '../../marcarComoPerdidaDialog/store/marcarComoPerdidaDialog.store';
import { Typography } from '@s_mart/core';

export function useMotivo({ codigo }: Pick<IMotivoProps, 'codigo'>) {
  const { setNotification, handleError, setLoading } = useApp();
  const { buscarMotivosPerda } = useMarcarComoPerdidaDialogStore();

  const [excluirMotivoDialog, setExcluirMotivoDialog] = useState<IExcluirMotivoState>({ open: false });

  async function _verificarMotivoEmUso() {
    setLoading(true);
    try {
      const { data } = await verificarMotivoPerda(codigo);

      if (data.data.numeroRegistros > 0) {
        setExcluirMotivoDialog({ open: true });
      } else {
        handleExcluirMotivo();
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }

  function handleCloseExcluirMotivoDialog() {
    setExcluirMotivoDialog({ open: false });
  }

  async function handleExcluirMotivo() {
    try {
      await excluirMotivoPerda(codigo);

      setNotification({
        title: <Typography variant='bodyMD'>Motivo de perda excluído com sucesso!</Typography>,
      });

      buscarMotivosPerda();
    } catch (err) {
      handleError(err);
    }
  }

  async function handleOpenExcluirMotivoDialog() {
    _verificarMotivoEmUso();
  }

  return {
    excluirMotivoDialog,
    handleCloseExcluirMotivoDialog,
    handleOpenExcluirMotivoDialog,
    handleExcluirMotivo,
  };
}
