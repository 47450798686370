import { memo, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, Grid, LIcon, Typography } from '@s_mart/core';
import { Dropzone } from '@s_mart/dropzone';
import { Select, TextField } from '@s_mart/form';
import { useMediaQuery, useWindowSize } from '@s_mart/hooks';
import { lineColorPicker, lineDownload } from '@s_mart/regular-icons';
import { colorPalette } from '@s_mart/tokens';
import { downloadFile } from '@s_mart/utils';

import CoresProposta from 'components/coresProposta/coresProposta';
import SimulacaoPreviewPdfProposta from 'components/simulacaoPreviewPdfProposta/simulacaoPreviewPdfProposta';
import useAuth from 'store/auth/auth';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import { coresModelosDeProposta } from 'styles/theme/theme';
import { fileToBase64 } from 'utils/fileToBase64/fileToBase64';

import { DivExternaPreviewFakePdf, DivImagesEButtons, DivLogoBanner, ImgLogoBanner } from './content.styles';
import InserirLogoEBannerDialog from '../inserirLogoEBannerDialog/inserirLogoEBannerDialog';
import { modelosPropostasTodos } from '../editarModeloPropostaDialog.static';
import { ModeloPropostaEnum } from 'api/propostas/propostas.types';

const Content = () => {
  const { gerais } = useConfiguracoes();
  const { width } = useWindowSize();
  const {
    watch,
    setValue,
    formState: { defaultValues },
  } = useFormContext();
  const { profile } = useAuth();
  const telaMenorQue400 = useMediaQuery('(max-width: 400px)');
  const telaMenorQue480 = useMediaQuery('(max-width: 480px)');
  const telaMenorQue600 = useMediaQuery('(max-width: 600px)');
  const telaMenorQue780 = useMediaQuery('(max-width: 780px)');

  const _formValues = watch();
  const _isUsuarioSBSistemas = profile?.email?.includes('@sbsistemas.');

  const _propostaModeloPersonalizada = _formValues?.modeloProposta?.value === '2';

  const [_modalInserirLogoEBanner, _setModalInserirLogoEBanner] = useState<{
    aberto: boolean;
    variant: 'logo' | 'banner';
  }>();

  const _conteudoTratadoPropostaPersonalizada = Boolean(_isUsuarioSBSistemas && _propostaModeloPersonalizada);

  const _initialValuesDropzones = useMemo(
    () => ({
      modeloPropostaPersonalizada: defaultValues?.modeloPropostaPersonalizada?.name
        ? [{ errors: [], file: defaultValues?.modeloPropostaPersonalizada, id: 1, valid: true }]
        : undefined,
      modeloPropostaPersonalizadaAdicional: defaultValues?.modeloPropostaPersonalizadaAdicional?.name
        ? [{ errors: [], file: defaultValues?.modeloPropostaPersonalizadaAdicional, id: 1, valid: true }]
        : undefined,
    }),
    [defaultValues]
  );

  const _handleLimparDropzone = (adicional?: boolean) => {
    if (adicional) {
      setValue('modeloPropostaPersonalizadaAdicional', undefined);
      setValue('base64ModeloPropostaPersonalizadaAdicional', undefined);
    } else {
      setValue('modeloPropostaPersonalizada', undefined);
      setValue('base64ModeloPropostaPersonalizada', undefined);
    }
  };

  const _escolhaCorProposta = useMemo(
    () => (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: 4 }}>
        <Typography variant='titleXS'>Escolha a cor para a proposta</Typography>
        <div style={{ display: 'flex', gap: telaMenorQue400 ? '8px' : '16px' }}>
          <CoresProposta
            options={[
              ...coresModelosDeProposta.map((cor) => ({
                cor,
                onClick: () => setValue('cor', cor),
                selected: _formValues.cor === cor,
              })),
              {
                cor: _formValues.cor,
                selected: coresModelosDeProposta.indexOf(_formValues.cor) === -1,
                children: <LIcon icon={lineColorPicker} size='24px' />,
                onClick: (cor) => setValue('cor', cor),
                colorPicker: true,
              },
            ]}
          />
        </div>
      </div>
    ),
    [_formValues.cor, telaMenorQue400]
  );

  const _formFields = useMemo(
    () => (
      <>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: telaMenorQue480 ? '1fr' : '1fr 1fr',
            rowGap: '8px',
            columnGap: '16px',
          }}
        >
          <TextField name='whatsapp' label='Whatsapp' placeholder='54 9 9999-9999' mask='telefone' />
          {_formValues?.modeloProposta?.value !== '3' && _formValues?.modeloProposta?.value !== '1' && (
            <>
              <TextField
                name='facebook'
                label='Facebook'
                placeholder='/Solarfy'
                helperText={
                  <Typography variant='bodyXXS' color={colorPalette.neutral[100]}>
                    /contato
                  </Typography>
                }
              />
              <TextField
                name='instagram'
                label='Instagram'
                placeholder='@Solarfy'
                helperText={
                  <Typography variant='bodyXXS' color={colorPalette.neutral[100]}>
                    @contato
                  </Typography>
                }
              />
            </>
          )}
          <TextField name='site' label='Site' placeholder='www.solarfy.com.br' />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Typography variant='titleXS'>Escreva sobre a empresa</Typography>
          <TextField name='sobre' rows={5} multiline />
        </div>
      </>
    ),
    [_formValues?.modeloProposta?.value, telaMenorQue480]
  );

  useEffect(() => {
    if (gerais?.modeloProposta === ModeloPropostaEnum.PERSONALIZADA) {
      setValue('modeloProposta', modelosPropostasTodos[2]);
    }
  }, []);

  return (
    <>
      {_modalInserirLogoEBanner?.aberto && (
        <InserirLogoEBannerDialog
          variant={_modalInserirLogoEBanner?.variant}
          onClose={() => _setModalInserirLogoEBanner(undefined)}
        />
      )}
      {_conteudoTratadoPropostaPersonalizada ? (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '8px' }}>
          <div>{_isUsuarioSBSistemas && <Select name='modeloProposta' options={modelosPropostasTodos} />}</div>
          <div style={{ display: 'flex', flexDirection: telaMenorQue600 ? 'column' : undefined, gap: '32px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '8px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                <Typography variant='titleXS'>Insira o modelo da proposta do Word ou PDF</Typography>
                <Dropzone
                  accept='.docx, .pdf'
                  maxFiles={1}
                  onChange={async (value) => {
                    const _base64ModeloPropostaPersonalizada = await fileToBase64(
                      value.find((it) => it.valid === true)?.file
                    );

                    setValue('modeloPropostaPersonalizada', value.find((it) => it.valid === true)?.file);
                    setValue(
                      'base64ModeloPropostaPersonalizada',
                      _base64ModeloPropostaPersonalizada?.split('base64,')[1]
                    );
                  }}
                  initialValue={_initialValuesDropzones.modeloPropostaPersonalizada}
                  onClean={() => _handleLimparDropzone()}
                  onReset={() => _handleLimparDropzone()}
                  minHeight='160px'
                  maxHeight='160px'
                  fileItemProps={{
                    style: {
                      zoom: 0.75,
                    },
                  }}
                />
                <Typography variant='bodySM' color={colorPalette.neutral[100]}>
                  Formatos aceitos: .docx e .pdf
                </Typography>
              </div>
              {_formValues.base64ModeloPropostaPersonalizada && (
                <Button
                  variant='outlined'
                  startIcon={<LIcon icon={lineDownload} removeMargin size='22px' />}
                  onClick={() =>
                    downloadFile(
                      _formValues.base64ModeloPropostaPersonalizada,
                      _formValues.modeloPropostaPersonalizada?.name,
                      _formValues.modeloPropostaPersonalizada?.type
                    )
                  }
                >
                  Baixar modelo
                </Button>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '8px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                <Typography variant='titleXS'>Insira o modelo da proposta adicional, Word ou PDF</Typography>
                <Dropzone
                  accept='.docx, .pdf'
                  maxFiles={1}
                  onChange={async (value) => {
                    const _base64ModeloPropostaPersonalizadaAdicional = await fileToBase64(
                      value.find((it) => it.valid === true)?.file
                    );

                    setValue('modeloPropostaPersonalizadaAdicional', value.find((it) => it.valid === true)?.file);
                    setValue(
                      'base64ModeloPropostaPersonalizadaAdicional',
                      _base64ModeloPropostaPersonalizadaAdicional?.split('base64,')[1]
                    );
                  }}
                  initialValue={_initialValuesDropzones.modeloPropostaPersonalizadaAdicional}
                  onClean={() => _handleLimparDropzone(true)}
                  onReset={() => _handleLimparDropzone(true)}
                  minHeight='160px'
                  maxHeight='160px'
                  fileItemProps={{
                    style: {
                      zoom: 0.75,
                    },
                  }}
                />
                <Typography variant='bodySM' color={colorPalette.neutral[100]}>
                  Formatos aceitos: .docx e .pdf
                </Typography>
              </div>
              {_formValues.base64ModeloPropostaPersonalizadaAdicional && (
                <Button
                  variant='outlined'
                  startIcon={<LIcon icon={lineDownload} removeMargin size='22px' />}
                  onClick={() =>
                    downloadFile(
                      _formValues.base64ModeloPropostaPersonalizadaAdicional,
                      _formValues.modeloPropostaPersonalizadaAdicional?.name,
                      _formValues.modeloPropostaPersonalizadaAdicional?.type
                    )
                  }
                >
                  Baixar modelo adicional
                </Button>
              )}
            </div>
          </div>
          {_escolhaCorProposta}
          {_formFields}
        </div>
      ) : (
        <Grid container spacing={2} style={{ maxHeight: 'calc(100vh - 216px)', overflow: 'auto' }}>
          <Grid item xs={telaMenorQue780 ? 12 : 6} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {_isUsuarioSBSistemas && <Select name='modeloProposta' options={modelosPropostasTodos} />}
            <div style={{ display: 'flex', gap: '16px', flexDirection: telaMenorQue480 ? 'column' : 'row' }}>
              <DivImagesEButtons>
                <DivLogoBanner>
                  <ImgLogoBanner src={_formValues.logo} alt='logo' />
                </DivLogoBanner>
                <Button
                  variant='outlined'
                  color='neutral'
                  fullWidth
                  onClick={() => _setModalInserirLogoEBanner({ aberto: true, variant: 'logo' })}
                >
                  Selecionar logo
                </Button>
              </DivImagesEButtons>
              <DivImagesEButtons>
                <DivLogoBanner>
                  <ImgLogoBanner src={_formValues.banner} alt='banner' />
                </DivLogoBanner>
                <Button
                  variant='outlined'
                  color='neutral'
                  fullWidth
                  onClick={() => _setModalInserirLogoEBanner({ aberto: true, variant: 'banner' })}
                >
                  Selecionar banner
                </Button>
              </DivImagesEButtons>
            </div>
            {_escolhaCorProposta}
            {_formFields}
          </Grid>
          <Grid item xs={telaMenorQue780 ? 12 : 6} id='grid-preview-fake-pdf'>
            <DivExternaPreviewFakePdf
              firefox={Boolean(navigator.userAgent.match(/firefox|fxios/i))}
              width={width}
              mobile={telaMenorQue480}
            >
              <div>
                <SimulacaoPreviewPdfProposta
                  logo={_formValues.logo.split('base64,')[1]}
                  banner={_formValues.banner.split('base64,')[1]}
                  cor={_formValues.cor}
                  whatsapp={_formValues.whatsapp}
                  facebook={_formValues.facebook}
                  instagram={_formValues.instagram}
                  site={_formValues.site}
                  modeloProposta={_formValues.modeloProposta?.value}
                />
              </div>
            </DivExternaPreviewFakePdf>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(Content);
