import { memo } from 'react';
import { Portal } from '@mui/material';

import { Dialog, Button } from '@s_mart/core';
import { Form } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';

import useConsumoMensalDialog from './useConsumoMensalDialog';
import ConsumoMensalContent from './content/consumoMensalContent';
import { ConsumoMensalDialogForm } from './consumoMensalDialog.types';
import { ConsumoMensalProvider } from './consumoMensal.context';

interface ConsumoMensalDialogProps {
  onClose: () => void;
}

const ConsumoMensalDialog = ({ onClose }: ConsumoMensalDialogProps) => {
  const { handleConsumoMensalSubmit, initialValuesConsumoMensalDialog } = useConsumoMensalDialog();
  const telaMenorQue480 = useMediaQuery(`(max-width: 480px)`);

  return (
    <Portal>
      <Form<ConsumoMensalDialogForm>
        onSubmit={(values) => handleConsumoMensalSubmit(values, onClose)}
        defaultValues={initialValuesConsumoMensalDialog}
      >
        {({ formState: { isDirty } }) => (
          <Dialog
            open
            onClose={onClose}
            closeIcon
            disableBackdropClick
            disableEscapeKeyDown
            disablePortal
            maxWidth='lg'
            sx={{
              '.MuiDialog-paper': {
                padding: telaMenorQue480 ? '16px !important' : undefined,
                margin: telaMenorQue480 ? '0' : undefined,
              },
            }}
          >
            <Dialog.Header>Consumo mensal em kWh</Dialog.Header>
            <Dialog.Content>
              <ConsumoMensalContent />
            </Dialog.Content>
            <Dialog.Footer>
              <Button variant='neutral' onClick={onClose}>
                Cancelar
              </Button>
              <Button type='submit' disabled={!isDirty}>
                Salvar
              </Button>
            </Dialog.Footer>
          </Dialog>
        )}
      </Form>
    </Portal>
  );
};

const ConsumoMensalDialogWrapper = (props: ConsumoMensalDialogProps) => {
  return (
    <ConsumoMensalProvider>
      <ConsumoMensalDialog {...props} />
    </ConsumoMensalProvider>
  );
};

export default memo(ConsumoMensalDialogWrapper);
