import styled from "@emotion/styled";

import { colorPalette } from "@s_mart/tokens";
import { toRem } from "@s_mart/utils";

export const TipoDimensionamentoOptionsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
  width: 100%;
`;

export const TipoDimensionamentoDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${toRem(16)};
  border-radius: ${toRem(8)};
  border: 1px solid ${colorPalette.neutral[50]};
  gap: ${toRem(8)};

  .MuiFormControlLabel-root {
    display: flex;
    gap: ${toRem(8)};
    margin-left: 0;
    margin-right: 0;
  }
`;