import styled from '@emotion/styled';

export const DivExternaPropostasListItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  padding: 8px 0px;
`;

export const DivInternaPropostasListItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
