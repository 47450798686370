import { StoreApi } from 'zustand';

import useAuth from 'store/auth/auth';

import type { AppStore } from '../app.types';
import { NotificationProps } from '@s_mart/typed';

interface HandleError {
  error: unknown;
  set: StoreApi<AppStore>['setState'];
}

type SetNotificationError = Partial<Pick<NotificationProps, 'title' | 'message'>>;

export const handleError = ({ error, set }: HandleError) => {
  set((state) => ({ ...state, loading: false, mensagemLoading: null }));
  set((state) => ({ ...state, loadingGerador: { aberto: false, etapa: undefined, produtosCadastrados: false } }));

  const notificationErrorBase: NotificationProps = {
    title: 'Ocorreu um problema',
    message: 'Entre em contato com o suporte',
    variant: 'error',
    autoHideDuration: 5000,
  };

  const setNotificationError = ({ message, title }: SetNotificationError) => {
    set((state) => ({
      ...state,
      notification: {
        ...notificationErrorBase,
        title: title ?? notificationErrorBase.title,
        message: message ?? notificationErrorBase.message,
      },
    }));
  };

  const err = error as any;
  if (err?.name === 'AxiosError') {
    if (err?.response?.data) {
      const response = err.response.data;

      if ('authErrors' in response || 'autherrors' in response) {
        useAuth.getState().clearStore();
        window.location.reload();
      } else if ('error' in response) {
        if (response.error.includes('503 Service Temporarily Unavailable')) {
          setNotificationError({ title: 'Serviço indisponível', message: 'Tente novamente mais tarde' });
        } else {
          setNotificationError({ title: response?.error });
        }
      } else if ('erro' in response) {
        setNotificationError({ title: response?.erro?.mensagem });
      } else {
        setNotificationError({});
      }
    } else if (err?.message === 'Network Error') {
      set((state) => ({ ...state, semConexao: true }));
    } else {
      setNotificationError({});
    }
  } else {
    setNotificationError({});
  }

  setTimeout(() => set((state) => ({ ...state, notification: null })), 5000);
};
