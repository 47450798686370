import { telefoneSuporte } from 'config/constantes';

import useGeradorDeProposta from '../../store/geradorDeProposta.store';
import { GeradorDePropostaEtapa } from '../../store/geradorDeProposta.store.types';
import { useFormContext } from 'react-hook-form';

export function useFooter() {
  const { setValue, getValues } = useFormContext();
  const { setEtapa } = useGeradorDeProposta();

  function handleVoltar() {
    setValue('potenciaFieldFornecedores', Number(getValues('potenciaInstalada')));

    setEtapa(GeradorDePropostaEtapa.INSTALACAO_E_CONSUMO);
  }

  function handleNaoEncontreiFornecedor() {
    window?.open(
      `https://api.whatsapp.com/send?phone=55${telefoneSuporte.replace(
        /[^0-9]/g,
        ''
      )}&text=Olá, gostaria de sugerir um novo fornecedor para estar no Solarfy`,
      '_blank',
      'noopener noreferrer'
    );
  }

  return { handleVoltar, handleNaoEncontreiFornecedor };
}
