import { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactApexChart from 'react-apexcharts';

import { decimal } from '@s_mart/masks';

import useAuth from 'store/auth/auth';

import useGeradorDeProposta from '../../store/geradorDeProposta.store';

const GraficoConsumo = ({ cor, height, mobile }: { cor: string; height: number; mobile?: boolean }) => {
  const { watch } = useFormContext();
  const { profile } = useAuth();
  const { geradorDeProposta } = useGeradorDeProposta();

  const _formValues = watch();

  const _consumoReal = useMemo(() => {
    let _consumo = _formValues?.consumoMedio;
    if (_formValues?.tipoLigacao?.value === '3') {
      if (_formValues?.tipoDimensionamento === '0') {
        _consumo = _formValues?.consumoMedioForaPonta;
      } else {
        _consumo = _formValues?.consumoMedioForaPontaMaisPontaAjustado;
      }
    }

    return _consumo;
  }, [
    _formValues.consumoMedio,
    _formValues?.tipoLigacao,
    _formValues?.tipoDimensionamento,
    _formValues?.consumoMedioForaPonta,
    _formValues?.consumoMedioForaPontaMaisPontaAjustado,
  ]);

  const labels = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

  const handleGeracaoMensal = (values: any, irradiacao: number) => {
    if (geradorDeProposta.caminhoGerador === 'kits') {
      if (values.perdaSistema) {
        const _potenciaTotal =
          values?.kitsFotovoltaicos?.length > 1
            ? values?.kitsFotovoltaicos?.reduce(
                (a: any, b: any) =>
                  Number(a?.potenciaKitsFotovoltaicos || 0) + Number(b?.potenciaKitsFotovoltaicos || 0)
              )
            : Number(values?.kitsFotovoltaicos?.[0]?.potenciaKitsFotovoltaicos || 0);

        return Math.round(_potenciaTotal * (Number(irradiacao) / 1000) * 30 * (1 - Number(values.perdaSistema) / 100));
      }
    } else {
      if (values.potenciaPaineis && values.perdaSistema) {
        let _quantidadePaineis = Number(values.quantidadePaineis);
        if (values.tipoLigacao?.value === '3') {
          if (values.tipoDimensionamento === '0') {
            _quantidadePaineis = values.quantidadePaineisForaPonta;
          } else {
            _quantidadePaineis = values.quantidadePaineisForaPontaMaisPonta;
          }
        }

        const _geracaoMensal = Math.round(
          (Number(values.potenciaPaineis) *
            _quantidadePaineis *
            (Number(irradiacao) / 1000) *
            30 *
            (1 - Number(values.perdaSistema) / 100)) /
            1000
        );

        return _geracaoMensal;
      }
    }
  };

  const _contasValoresMensais = _formValues.consumoMensal?.map((item: any, index: number) =>
    item?.[index + 1]?.map((item: { mes: number; valor: string }) => Number(item?.valor))
  );

  const _mediaContaValoresMensais = useMemo(() => {
    if (_contasValoresMensais?.length) {
      return _contasValoresMensais?.reduce((a: number[], b: number[]) =>
        a?.map((item: number, index: number) => item + b?.[index])
      );
    }
  }, [_contasValoresMensais]);

  const _esconderConsumo = useMemo(() => {
    switch (profile?.emp) {
      case 4183:
        return true;
      default:
        return false;
    }
  }, [profile?.emp]);

  if (_consumoReal && _formValues.irradiacao) {
    return (
      <ReactApexChart
        series={
          _esconderConsumo
            ? [
                {
                  name: 'Geração',
                  data: labels.map(
                    (_: unknown, index: number) => handleGeracaoMensal(_formValues, _formValues.irradiacao[index]) || 0
                  ),
                },
              ]
            : [
                {
                  name: 'Consumo',
                  data: labels.map((_: unknown, index: number) =>
                    _formValues.consumoMensal?.length >= 1 && _formValues?.tipoLigacao?.value !== '3'
                      ? _mediaContaValoresMensais?.[index]
                      : _consumoReal
                  ),
                },
                {
                  name: 'Geração',
                  data: labels.map(
                    (_: unknown, index: number) => handleGeracaoMensal(_formValues, _formValues.irradiacao[index]) || 0
                  ),
                },
              ]
        }
        type='bar'
        options={{
          chart: {
            type: 'bar',
            dropShadow: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
            fontFamily: 'Lato',
            animations: {
              enabled: false,
            },
          },
          colors: _esconderConsumo ? [cor] : ['#545454', cor],
          title: {
            text: '',
            style: { color: 'white' },
            align: 'right',
          },
          dataLabels: {
            enabled: mobile ? false : true,
            background: { borderRadius: 6, dropShadow: { enabled: false } },
            formatter: function (value: number) {
              if (value) {
                return decimal.format(value, 0);
              } else {
                return 0;
              }
            },
            style: {
              fontWeight: 600,
              colors: ['#000000'],
              fontSize: '14px',
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                orientation: 'vertical',
                position: 'top',
              },
            },
          },
          grid: {
            row: {
              colors: ['#f4f5f7', 'transparent'],
              opacity: 0.6,
            },
          },
          xaxis: { categories: labels, labels: { style: { fontSize: mobile ? '12px' : '14px' } } },
          yaxis: {
            title: {
              text: 'kWh',
              style: { fontSize: mobile ? '12px' : '14px' },
            },
            labels: { style: { fontSize: mobile ? '12px' : '14px' } },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            markers: { radius: 2, width: 24, height: 18, offsetY: 4 },
            onItemClick: {
              toggleDataSeries: false,
            },
            showForSingleSeries: true,
            fontSize: '14px',
          },
        }}
        height={height}
      />
    );
  }

  return <></>;
};

export default memo(GraficoConsumo);
