import { Button, Dialog, Grid, Typography } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';

import useConfiguracoes from 'store/configuracoes/configuracoes';

import { useReaproveitarDadosDialogStore } from './reaproveitarDadosDialog.store';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';

export function ReaproveitarDadosDialog() {
  const { open, cliente, handleClose, callback } = useReaproveitarDadosDialogStore();
  const { recursosContratados } = useConfiguracoes();
  const isMobile = useIsMobile();

  const telaMenorQue600 = useMediaQuery('(max-width: 600px)');
  const telaMenorQue720 = useMediaQuery('(max-width: 720px)');

  const handleReaproveitar = () => {
    callback?.({ reaproveitar: true });
    handleClose();
  };

  const handleNaoReaproveitar = () => {
    callback?.({ reaproveitar: false });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      closeIcon
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='sm'
      //Dialog sem footer fica sem padding bottom
      PaperProps={{ style: { paddingBottom: '24px' } }}
    >
      <Dialog.Header>
        <Typography variant={isMobile ? 'titleMS' : 'titleMD'}>
          Reaproveitar {recursosContratados?.crm ? 'informações' : 'dados'}?
        </Typography>
      </Dialog.Header>
      <Dialog.Content>
        <Grid container>
          {!telaMenorQue600 && (
            <Grid item xs={recursosContratados?.crm ? 3 : 2}>
              <img
                src={
                  recursosContratados?.crm
                    ? '/static/pngs/reaproveitarDadosDialog/cardsEtapaKanban.png'
                    : '/static/svgs/crm/bro.svg'
                }
                alt=''
              />
            </Grid>
          )}
          <Grid item xs={!telaMenorQue600 ? (recursosContratados?.crm ? 9 : 10) : 12}>
            <Grid container gap={3}>
              <Grid item xs={12}>
                <Typography textAlign='center'>
                  {recursosContratados?.crm
                    ? 'Você já tem um lead (card) em andamento para '
                    : 'Identificamos que você já fez uma proposta para '}
                  <b>{cliente?.nome}</b>.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography textAlign='center'>
                  {recursosContratados?.crm
                    ? 'Deseja reutilizar as informações da última proposta? Caso não deseje reaproveitar, será criado um novo lead para o cliente.'
                    : 'Gostaria de reaproveitar os dados da proposta anterior?'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} flexDirection={!telaMenorQue600 ? undefined : 'column-reverse'}>
                  <Grid item xs={!telaMenorQue720 ? 5 : 12}>
                    <Button fullWidth variant='outlined' onClick={handleNaoReaproveitar}>
                      {recursosContratados?.crm ? 'Criar novo lead' : 'Não reaproveitar'}
                    </Button>
                  </Grid>
                  <Grid item xs={!telaMenorQue720 ? 7 : 12}>
                    <Button fullWidth variant='contained' onClick={handleReaproveitar}>
                      Reaproveitar informações{recursosContratados?.crm ? ' e leads' : ''}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
}
