import { memo } from 'react';

import { Button, Dialog, Grid } from '@s_mart/core';
import { Form, TextField, Checkbox } from '@s_mart/form';

import { removeCaracteresEspeciais } from 'utils/removeCaracteresEspeciais/removeCaracteresEspeciais';

import useInformacoesAdicionaisDialog from './useInformacoesAdicionaisDialog';

const InformacoesAdicionaisDialog = () => {
  const { initialValuesInformacoesAdicionaisDialog, handleSubmitInformacoesAdicionaisDialog, handleClose } =
    useInformacoesAdicionaisDialog();

  return (
    <Dialog open onClose={handleClose} disableBackdropClick disableEscapeKeyDown fullWidth maxWidth='md'>
      <Form onSubmit={handleSubmitInformacoesAdicionaisDialog} defaultValues={initialValuesInformacoesAdicionaisDialog}>
        {() => (
          <>
            <Dialog.Header>Informações adicionais</Dialog.Header>
            <Dialog.Content>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name='descricao'
                    multiline
                    rows={6}
                    parse={(value) => removeCaracteresEspeciais(value) || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox name='infoAdicionaisSalvar' label='Salvar para próximas propostas' />
                </Grid>
              </Grid>
            </Dialog.Content>
            <Dialog.Footer>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'end',
                  marginTop: '16px',
                  gap: '16px',
                }}
              >
                <Button variant='neutral' onClick={handleClose}>
                  Cancelar
                </Button>
                <Button type='submit'>Salvar</Button>
              </div>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default memo(InformacoesAdicionaisDialog);
