import { Stack } from '@mui/material';

import { Button, Dialog, LIcon, Typography } from '@s_mart/core';
import { lineWifiSlash } from '@s_mart/solid-icons';

import { corPrimariaSolarfy } from 'styles/theme/theme';
import { useSemConexaoDialog } from './useSemConexaoDialog';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { isStaging } from 'utils/isStaging/isStaging';

export function SemConexaoDialog() {
  const { open, handleClose, setOpen, emailSB } = useSemConexaoDialog();
  const isMobile = useIsMobile();

  return (
    <Dialog open={open} onClose={handleClose}>
      <Dialog.Content style={{ padding: isMobile ? '1rem' : '2rem' }}>
        <Stack gap={2} textAlign='center'>
          <Stack textAlign='center'>
            <LIcon icon={lineWifiSlash} color={corPrimariaSolarfy} size='68px' removeMargin />
            <Typography variant='titleMD'>Problemas com a internet</Typography>
          </Stack>
          <Stack gap={1.5}>
            <Typography>Sua internet pode estar muito lenta ou sem conexão.</Typography>
            <Typography>
              {isMobile
                ? 'Se estiver utilizando dados moveis, tente conectar em uma conexão wi-fi e recarregue a página.'
                : 'Verifique sua conexão e recarregue a página'}
            </Typography>
          </Stack>
          <Stack justifyContent='center'>
            <Button onClick={handleClose}>Recarregar a página</Button>
          </Stack>
          {(isStaging() || emailSB) && (
            <Stack justifyContent='center'>
              <Button color='neutral' onClick={() => setOpen(false)}>
                Cancelar
              </Button>
            </Stack>
          )}
        </Stack>
      </Dialog.Content>
    </Dialog>
  );
}
