import { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid, LIcon, Typography } from '@s_mart/core';
import { Autocomplete } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { required } from '@s_mart/rules';
import { lineExclamationTriangle } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import { ListagemProdutosTypes } from 'containers/produtos/produtos.types';
import { removeCaracteresEspeciais } from 'utils/removeCaracteresEspeciais/removeCaracteresEspeciais';

import useFieldsPainel from '../useFieldsPainel';
import { DivAlertaFieldsNome } from '../../fields.styles';

const AutoCompleteFieldNomePaineis = () => {
  const { paineis, loadingPaineis, handleBuscarPainelSelecionado } = useFieldsPainel();
  const { setValue, watch } = useFormContext();
  const telaMenorQue760 = useMediaQuery(`(max-width: 760px)`);

  const _formValuesModeloPainel = watch('modeloPainel');

  const _handleAlertaProdutoCadastrado = () => {
    if (
      typeof _formValuesModeloPainel === 'string' &&
      _paineisOptions?.find((it) => it.label === _formValuesModeloPainel)
    ) {
      return (
        <DivAlertaFieldsNome>
          <LIcon icon={lineExclamationTriangle} removeMargin size='18px' color={colorPalette.yellow[300]} />
          <Typography variant='bodyXS'>Um produto com esse nome já está cadastrado.</Typography>
        </DivAlertaFieldsNome>
      );
    }
    return undefined;
  };

  const _paineisOptions = useMemo(
    () =>
      paineis
        ?.filter((it: ListagemProdutosTypes) => it.situacao)
        ?.map((item: ListagemProdutosTypes) => ({
          label: item.nome,
          value: item.codigo,
        })),
    [paineis]
  );

  return (
    <Grid item xs={telaMenorQue760 ? 12 : 6}>
      <Autocomplete
        name='modeloPainel'
        label='Painel fotovoltaico'
        required
        textFieldProps={{
          placeholder: 'Selecione ou informe um novo painel fotovoltaico',
          inputProps: { maxLength: 90 },
        }}
        value={_formValuesModeloPainel || null}
        onChange={(value) => handleBuscarPainelSelecionado(value)}
        onInputChange={(value) => removeCaracteresEspeciais(value, 'modeloPainel', setValue)}
        rules={required}
        disableClearable
        options={_paineisOptions || []}
        loading={loadingPaineis}
      />
      {_handleAlertaProdutoCadastrado()}
    </Grid>
  );
};

export default memo(AutoCompleteFieldNomePaineis);
