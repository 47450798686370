import { AxiosResponse } from 'axios';
import { api } from '../api';
import { urls } from 'api/urls';

import type { EditarEtapaPayload, Etapa, MigrarLeadsPayload, NovaEtapaPayload } from './etapas.types';

const listarEtapas = async (): Promise<AxiosResponse<{ data: Etapa[] }>> => await api.get(`${urls.etapas}`);

const novaEtapa = async ({ nome, situacao = 1 }: NovaEtapaPayload) =>
  await api.post(`${urls.etapas}`, {
    nome,
    situacao,
  });

const dadosEtapa = async (id: number) => await api.get(`${urls.etapas}/${id}`);

const editarEtapa = async (id: EditarEtapaPayload['codigo'], payload: Omit<EditarEtapaPayload, 'codigo'>) =>
  await api.put(`${urls.etapas}/${id}`, payload);

const excluirEtapa = async (id: number) => await api.delete(`${urls.etapas}/${id}`);

const verificarLeadsEtapa = async (id: number): Promise<AxiosResponse<{ data: { numeroRegistros: number } }>> =>
  await api.get(`${urls.etapas}/${id}/verificar-leads`);

const migrarLeadsEtapa = async (payload: MigrarLeadsPayload) => await api.patch(`${urls.etapas}/migrar`, payload);

const cadastrarEtapasPadrao = async () => await api.post(`${urls.etapas}/padrao`);

export {
  listarEtapas,
  novaEtapa,
  dadosEtapa,
  editarEtapa,
  excluirEtapa,
  verificarLeadsEtapa,
  migrarLeadsEtapa,
  cadastrarEtapasPadrao,
};
