import { memo } from 'react';

import { ColorCircleButton, ColorPicker, DivIconColorPicker } from './coresProposta.styles';

import { CoresPropostaProps } from './coresProposta.types';

const CoresProposta = ({ options }: CoresPropostaProps) => {
  return (
    <>
      {options.map(({ cor, onClick, selected, children, colorPicker }, index) => {
        if (colorPicker) {
          return (
            <div style={{ position: 'relative' }} key={index}>
              <div>
                <ColorPicker
                  value={cor}
                  type='color'
                  selected={selected}
                  className='inputColorPicker'
                  onChange={(e) => onClick?.(e.target.value)}
                />
              </div>
              <DivIconColorPicker
                selected={selected}
                onClick={() => {
                  (document.querySelector('.inputColorPicker') as HTMLInputElement)?.click();
                }}
              >
                {children}
              </DivIconColorPicker>
            </div>
          );
        } else {
          return (
            <ColorCircleButton cor={cor} onClick={() => onClick?.()} selected={selected} key={index}>
              {children}
            </ColorCircleButton>
          );
        }
      })}
    </>
  );
};

export default memo(CoresProposta);
