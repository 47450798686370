import { useState } from 'react';
import { Dialog } from '@s_mart/core';

import { InformarPagamentoDialogEtapa } from './informarPagamentoDialog.types';
import { BoletoEmAberto } from './etapas/boletoEmAberto/boletoEmAberto';
import { BoletosEmAberto } from './etapas/boletosEmAberto/boletosEmAberto';
import { InformadoComoPago } from './etapas/informadoComoPago/informadoComoPago';
import { BotaoDesbloqueioUtilizado } from './etapas/botaoDesbloqueioUtilizado/botaoDesbloqueioUtilizado';
import { BoletoDisponivel } from './etapas/boletoDisponivel/boletoDisponivel';
import { InformarPagamentoDialogProvider, useInformarPagamentoDialogContext } from './informarPagamentoDialog.context';

const InformarPagamentoDialog = () => {
  const [open, setOpen] = useState(true);

  const { step, handleMarcarBoletoVisualizado } = useInformarPagamentoDialogContext();

  function handleClose() {
    setOpen(false);
    if (step === InformarPagamentoDialogEtapa.BOLETO_DISPONIVEL) {
      handleMarcarBoletoVisualizado();
    }
  }

  const conteudoMap = {
    [InformarPagamentoDialogEtapa.BOLETO_EM_ABERTO]: <BoletoEmAberto />,
    [InformarPagamentoDialogEtapa.BOLETOS_EM_ABERTO]: <BoletosEmAberto />,
    [InformarPagamentoDialogEtapa.INFORMADO_COMO_PAGO]: <InformadoComoPago handleClose={handleClose} />,
    [InformarPagamentoDialogEtapa.BOTAO_DESBLOQUEIO_UTILIZADO]: <BotaoDesbloqueioUtilizado />,
    [InformarPagamentoDialogEtapa.BOLETO_DISPONIVEL]: <BoletoDisponivel />,
  };

  const conteudo = conteudoMap[step];

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick disableEscapeKeyDown closeIcon maxWidth='xl'>
      {conteudo}
    </Dialog>
  );
};

const InformarPagamentoDialogWrapper = () => {
  return (
    <InformarPagamentoDialogProvider>
      <InformarPagamentoDialog />
    </InformarPagamentoDialogProvider>
  );
};

export { InformarPagamentoDialogWrapper as InformarPagamentoDialog };
