import { memo, useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { LIcon, Grid, Typography } from '@s_mart/core';
import { Autocomplete, TextField } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { lineChevronDown, lineTrashAlt } from '@s_mart/regular-icons';
import { composeValidators, maiorQue, required } from '@s_mart/rules';
import { lineExclamationTriangle } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import { ListagemProdutosTypes } from 'containers/produtos/produtos.types';
import { useIsAdmin } from 'hooks/useIsAdmin/useIsAdmin';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import { removeCaracteresEspeciais } from 'utils/removeCaracteresEspeciais/removeCaracteresEspeciais';

import { DivAlertaFieldsNome, DivHoverLIcon } from '../fields.styles';
import { DivChevronDetalhesKit } from './fieldsDetalhesKit.styles';
import { useFields } from '../useFields';

interface FieldsDetalhesKitProps {
  name: string;
  fields: any;
  index: number;
  kits?: ListagemProdutosTypes[];
  loadingKits: boolean;
  handleBuscarKit: (index: number, values: any) => Promise<void>;
  finalNomeFields: 'KitsFotovoltaicos' | 'KitsDeServicos';
}

const FieldsDetalhesKit = ({
  name,
  fields,
  index,
  kits,
  loadingKits,
  handleBuscarKit,
  finalNomeFields,
}: FieldsDetalhesKitProps) => {
  const methods = useFormContext();
  const isAdmin = useIsAdmin();
  const { recursosContratados } = useConfiguracoes();
  const { handleCalcularValorTotal } = useFields();
  const _telaMenorQue780 = useMediaQuery('(max-width:780px)');

  const _formValues = methods.watch();

  const kitsDeServicos = useMemo(() => finalNomeFields === 'KitsDeServicos', [finalNomeFields]);

  const _kits = useMemo(
    () =>
      kits
        ?.filter((it: ListagemProdutosTypes) => it.situacao)
        ?.map((item: ListagemProdutosTypes) => ({
          label: item.nome,
          value: item.codigo,
        })),
    [kits]
  );

  const _controleResponsividadeGridNomeKit = useCallback(() => {
    if (recursosContratados?.['valor-de-venda-unitario']) {
      if (kitsDeServicos) {
        return _telaMenorQue780 ? 9.5 : 10;
      } else {
        return _telaMenorQue780 ? 7 : 8;
      }
    } else {
      if (kitsDeServicos) {
        return 12;
      } else {
        return _telaMenorQue780 ? 9.5 : 10;
      }
    }
  }, [recursosContratados, kitsDeServicos, _telaMenorQue780]);

  useEffect(() => {
    if (recursosContratados?.['valor-de-venda-unitario']) {
      handleCalcularValorTotal({ values: _formValues, methods });
    }
  }, [
    recursosContratados?.['valor-de-venda-unitario'],
    _formValues?.[name]?.[index || 0]?.[`valorVenda${finalNomeFields}`],
  ]);

  const quantidadeFields = fields.fields?.length;

  return (
    <>
      <Grid item style={{ width: quantidadeFields > 1 ? `calc(100% - 81px)` : `calc(100% - 40px)` }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={_controleResponsividadeGridNomeKit()}
            style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
          >
            <Autocomplete
              name={`${name}.${index}.modelo${finalNomeFields}`}
              label={kitsDeServicos ? 'Kit de serviço' : 'Kit fotovoltaico'}
              disableClearable
              required
              rules={required}
              options={_kits || []}
              onChange={(value) => {
                handleBuscarKit(index, value);
                if (recursosContratados?.['valor-de-venda-unitario']) {
                  setTimeout(() => handleCalcularValorTotal({ values: _formValues, methods }), 300);
                }
              }}
              onInputChange={(value) =>
                removeCaracteresEspeciais(value, `${name}.${index}.modelo${finalNomeFields}`, methods.setValue)
              }
              loading={loadingKits}
              textFieldProps={{
                placeholder: `Selecione ou informe um novo kit ${kitsDeServicos ? 'de serviços' : 'fotovoltaico'}`,
                inputProps: {
                  maxLength: 90,
                },
                size: 'medium',
              }}
            />
            {typeof _formValues?.[name]?.[index || 0]?.[`modelo${finalNomeFields}`] === 'string' &&
            _kits?.find((it) => it.label === _formValues?.[name]?.[index || 0]?.[`modelo${finalNomeFields}`]) ? (
              <DivAlertaFieldsNome>
                <LIcon icon={lineExclamationTriangle} removeMargin size='18px' color={colorPalette.yellow[300]} />
                <Typography variant='bodyXS'>Um kit com esse nome já está cadastrado.</Typography>
              </DivAlertaFieldsNome>
            ) : undefined}
            {/*Aparece warning para KIT(s) FOTOVOLTAICO(s) que tem uma geração menor que o consumo médio */}
            {!kitsDeServicos &&
              Number(_formValues?.geracaoSistema || 0) < Number(_formValues?.consumoMedio || 0) &&
              index === 0 &&
              _formValues?.tipoDimensionamento !== '0' && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    background: colorPalette.yellow[75],
                    borderRadius: '6px',
                    padding: '2px 4px',
                  }}
                >
                  <LIcon icon={lineExclamationTriangle} removeMargin size='18px' color={colorPalette.yellow[300]} />
                  <Typography variant='bodyXS'>
                    A geração está inferior ao consumo médio, verifique a potência{' '}
                    {_formValues?.kitsFotovoltaicos?.length > 1 ? 'dos kits' : 'do kit'}.
                  </Typography>
                </div>
              )}
          </Grid>
          {!kitsDeServicos && (
            <Grid item xs={6} sm={_telaMenorQue780 ? 2.5 : 2}>
              <TextField
                name={`${name}.${index}.potencia${finalNomeFields}`}
                label='Potência'
                InputProps={{ endAdornment: <Typography variant='bodySM'>kWp</Typography> }}
                placeholder='0,00'
                required
                mask='decimal'
                rules={composeValidators([maiorQue(0), required])}
              />
            </Grid>
          )}
          {recursosContratados?.['valor-de-venda-unitario'] && (
            <Grid item xs={6} sm={_telaMenorQue780 ? 2.5 : 2}>
              <TextField
                name={`${name}.${index}.valorVenda${finalNomeFields}`}
                label='Valor de venda'
                mask='valor'
                disabled={!isAdmin}
                placeholder='R$ 0,00'
              />
            </Grid>
          )}
          {_formValues?.[name]?.[index || 0]?.mostrarDetalhes && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  name={`${name}.${index}.descricao${finalNomeFields}`}
                  label={kitsDeServicos ? 'Detalhes do kit de serviço' : 'Detalhes do kit fotovoltaico'}
                  multiline
                  rows={3}
                  placeholder={`Descreva os ${kitsDeServicos ? 'serviços' : 'equipamentos'} que compõem o kit`}
                  parse={(value) => removeCaracteresEspeciais(value) || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name={`${name}.${index}.descricaoGarantia${finalNomeFields}`}
                  label={`Garantias dos ${kitsDeServicos ? 'serviços' : 'equipamentos'}`}
                  multiline
                  rows={3}
                  placeholder={`Informe as garantias dos seus ${kitsDeServicos ? 'serviços' : 'equipamentos'}`}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item style={{ display: 'flex', gap: '8px', paddingTop: '38px', paddingLeft: '8px' }}>
        <DivChevronDetalhesKit mostrarDetalhes={_formValues?.[name]?.[index || 0]?.mostrarDetalhes}>
          <DivHoverLIcon>
            <LIcon
              icon={lineChevronDown}
              removeMargin
              size='32px'
              color={colorPalette.neutral[600]}
              onClick={() => {
                methods.setValue(
                  `${name}.${index}.mostrarDetalhes`,
                  !_formValues?.[name]?.[index || 0]?.mostrarDetalhes
                );
              }}
            />
          </DivHoverLIcon>
        </DivChevronDetalhesKit>
        {Number(fields.fields?.length) > 1 && (
          <DivHoverLIcon>
            <LIcon
              icon={lineTrashAlt}
              removeMargin
              size='32px'
              style={{ marginBottom: '24px', cursor: 'pointer' }}
              onClick={() => fields.remove(index)}
            />
          </DivHoverLIcon>
        )}
      </Grid>
    </>
  );
};

export default memo(FieldsDetalhesKit);
