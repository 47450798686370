import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { BuscarProdutoDados, ListarProdutosTypes } from 'api/produtos/produtos.types';
import { buscarProduto, listarProdutos } from 'api/produtos/produtos';
import { ListagemProdutosTypes } from 'containers/produtos/produtos.types';
import useApp from 'store/app/app';

const useFieldsOutros = () => {
  const { setValue } = useFormContext();
  const { handleError } = useApp();

  const [outros, _setOutros] = useState<ListagemProdutosTypes[]>();
  const [loadingOutros, _setLoadingOutros] = useState(false);

  const handleListarOutros = useCallback(async () => {
    _setLoadingOutros(true);
    try {
      const { data } = await listarProdutos({ tipoProduto: '2', all: true });

      if (data) {
        _setOutros(data?.data);
        setValue(
          'listagemOutros',
          data?.data.reduce((acc: ListarProdutosTypes[], item: ListarProdutosTypes) => {
            acc[item.codigo] = item;
            return acc;
          }, {})
        );
      }
      _setLoadingOutros(false);
    } catch (err) {
      _setLoadingOutros(false);
      handleError(err);
    }
  }, [handleError]);

  const handleBuscarOutroSelecionado = useCallback(
    async (index: number, values: any) => {
      if (values?.value) {
        try {
          const { data }: { data: { data: BuscarProdutoDados } } = await buscarProduto(values?.value);

          setValue(`outros.${index}.quantidadeOutros`, 1);
          setValue(`outros.${index}.garantiaOutros`, data?.data?.garantia ? String(data?.data?.garantia) : undefined);
          setValue(`outros.${index}.tipoGarantiaOutros`, data?.data?.tipoGarantia);
          setValue(`outros.${index}.valorVendaOutros`, data?.data?.valorVenda ? String(data?.data?.valorVenda) : 0);
        } catch (err) {
          handleError(err);
        }
      }
    },
    [handleError, setValue]
  );

  useEffect(() => {
    handleListarOutros();
  }, []);

  return { outros, loadingOutros, handleBuscarOutroSelecionado };
};

export default useFieldsOutros;
