import { useFormContext } from 'react-hook-form';

import { Grid, Typography } from '@s_mart/core';
import { RadioButton, RadioGroup } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { decimal } from '@s_mart/masks';
import { colorPalette } from '@s_mart/tokens';

import { TipoDimensionamentoDiv, TipoDimensionamentoOptionsDiv } from './grupoA.styles';
import { tipoDimensionamento } from '../geradorDePropostaDimensionamento.static';

const GrupoA = () => {
  const { watch } = useFormContext();
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);
  const telaMenorQue800 = useMediaQuery(`(max-width: 800px)`);

  const _formValuesGeracaoForaPonta = watch('geracaoSistemaForaPonta');
  const _formValuesGeracaoForaPontaMaisPonta = watch('geracaoSistemaForaPontaMaisPonta');
  const _formValuesQuantidadePaineisForaPonta = watch('quantidadePaineisForaPonta');
  const _formValuesQuantidadePaineisForaPontaMaisPonta = watch('quantidadePaineisForaPontaMaisPonta');
  const _formValuesPotenciaSistemaForaPonta = watch('potenciaSistemaForaPonta');
  const _formValuesPotenciaSistemaForaPontaMaisPonta = watch('potenciaSistemaForaPontaMaisPonta');
  const _formValuesAreaMinimaForaPonta = watch('areaMinimaForaPonta');
  const _formValuesAreaMinimaForaPontaMaisPonta = watch('areaMinimaForaPontaMaisPonta');
  const _formValuesConsumoACompensar = watch('consumoACompensar');
  const _formValuesDemandaContratada = watch('demandaContratada');

  return (
    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Typography variant='titleSM'>Selecione o sistema que deseja dimensionar</Typography>

      <RadioGroup name='tipoDimensionamento' sx={{ marginLeft: 0, marginRight: 0 }}>
        <TipoDimensionamentoOptionsDiv>
          {tipoDimensionamento.map((it, index) => (
            <TipoDimensionamentoDiv key={`tipo-dimensionamento-opcao-${it.value}`}>
              <RadioButton
                value={it.value}
                label={
                  index === 0 && _formValuesDemandaContratada * 0.92 === _formValuesPotenciaSistemaForaPonta
                    ? it.labelAlternativa
                    : it.label
                }
                sx={{ padding: 0 }}
              />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: telaMenorQue800 ? 'auto auto' : 'auto auto auto auto',
                  gap: telaMenorQue800 ? '8px' : undefined,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: telaMenorQue600 ? 'column' : 'row',
                    alignItems: telaMenorQue600 ? 'center' : 'end',
                  }}
                >
                  <Typography variant='bodyXS' color={colorPalette.neutral[500]}>
                    Geração sistema:
                  </Typography>
                  <Typography variant='titleXS' color='black'>
                    {index === 1
                      ? decimal.format(_formValuesGeracaoForaPontaMaisPonta)
                      : decimal.format(_formValuesGeracaoForaPonta)}{' '}
                    <Typography variant='bodyXS' color={colorPalette.neutral[500]}>
                      kWh
                    </Typography>
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: telaMenorQue600 ? 'column' : 'row',
                    alignItems: telaMenorQue600 ? 'center' : 'end',
                  }}
                >
                  <Typography variant='bodyXS' color={colorPalette.neutral[500]}>
                    Painéis:
                  </Typography>
                  <Typography variant='titleXS' color='black'>
                    {index === 1
                      ? decimal.format(_formValuesQuantidadePaineisForaPontaMaisPonta, 0)
                      : decimal.format(_formValuesQuantidadePaineisForaPonta, 0)}
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: telaMenorQue600 ? 'column' : 'row',
                    alignItems: telaMenorQue600 ? 'center' : 'end',
                  }}
                >
                  <Typography variant='bodyXS' color={colorPalette.neutral[500]}>
                    Potência sistema:
                  </Typography>
                  <Typography variant='titleXS' color='black'>
                    {index === 1
                      ? decimal.format(_formValuesPotenciaSistemaForaPontaMaisPonta)
                      : decimal.format(_formValuesPotenciaSistemaForaPonta)}{' '}
                    <Typography variant='bodyXS' color={colorPalette.neutral[500]}>
                      kWp
                    </Typography>
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: telaMenorQue600 ? 'column' : 'row',
                    alignItems: telaMenorQue600 ? 'center' : 'end',
                  }}
                >
                  <Typography variant='bodyXS' color={colorPalette.neutral[500]}>
                    Área mínima:
                  </Typography>
                  <Typography variant='titleXS' color='black'>
                    {index === 1
                      ? decimal.format(_formValuesAreaMinimaForaPontaMaisPonta)
                      : decimal.format(_formValuesAreaMinimaForaPonta)}{' '}
                    <Typography variant='bodyXS' color={colorPalette.neutral[500]}>
                      m²
                    </Typography>
                  </Typography>
                </div>
              </div>
              {index === 1 && _formValuesConsumoACompensar ? (
                <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
                  Potência necessária acima da demanda contratada. Empresa será cobrada{' '}
                  <Typography variant='titleXS'>{decimal.format(_formValuesConsumoACompensar)} kW</Typography> como
                  TUSDg.
                </Typography>
              ) : undefined}
            </TipoDimensionamentoDiv>
          ))}
        </TipoDimensionamentoOptionsDiv>
      </RadioGroup>
    </Grid>
  );
};

export default GrupoA;
