import { IExcluirMotivoDialogProps } from './excluirMotivoDialog.types';

import { Button, Dialog, Grid, Typography } from '@s_mart/core';
import { Form, Select } from '@s_mart/form';
import { required } from '@s_mart/rules';
import { useExcluirMotivoDialog } from './useExcluirMotivoDialog';

export function ExcluirMotivoDialog(props: IExcluirMotivoDialogProps) {
  const { motivosOptions, handleExcluir } = useExcluirMotivoDialog(props);

  return (
    <Dialog open onClose={props.handleClose} maxWidth='xs'>
      <Dialog.Header>
        <Typography variant='titleMD'>Excluir motivo de perda</Typography>
      </Dialog.Header>
      <Form onSubmit={handleExcluir}>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>Atualmente existem clientes neste motivo de perda que está tentando excluir.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Antes de excluir este motivo de perda, selecione um motivo para mover estes clientes.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Select
                name='motivoDestino'
                placeholder='Selecione um motivo de perda'
                options={motivosOptions}
                required
                rules={required}
              />
            </Grid>
          </Grid>
        </Dialog.Content>
        <Dialog.Footer>
          <Button type='submit'>Excluir motivo de perda</Button>
          <Button onClick={props.handleClose} variant='neutral'>
            Cancelar
          </Button>
        </Dialog.Footer>
      </Form>
    </Dialog>
  );
}
