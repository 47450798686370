import { create } from 'zustand';

import { VisualizarPropostaDialogStates, VisualizarPropostaDialogStore } from './visualizarPropostaDialog.store.types';

const initialState: VisualizarPropostaDialogStates = {
  visualizarPropostaDialog: { aberto: false },
  pdfProposta: '',
  linkProposta: '',
};

const useVisualizarPropostaDialog = create<VisualizarPropostaDialogStore>((set) => ({
  ...initialState,
  setVisualizarPropostaDialog: (visualizarPropostaDialog) => set((state) => ({ ...state, visualizarPropostaDialog })),
  setPdfProposta: (pdfProposta) => set((state) => ({ ...state, pdfProposta })),
  setLinkProposta: (linkProposta) => set((state) => ({ ...state, linkProposta })),
}));

export default useVisualizarPropostaDialog;
