import { memo } from 'react';

import { Grid, Typography } from '@s_mart/core';
import { Timeline } from '@s_mart/lab';

import useVisualizarListaPropostasDialogHistorico from './useVisualizarListaPropostasDialogHistorico';

const VisualizarListaPropostasDialogHistorico = () => {
  const { historicoAcoes, handleDataHistorico } = useVisualizarListaPropostasDialogHistorico();

  return (
    <Timeline.Root
      position='right'
      sx={{
        height: '100%',
        maxHeight: '40vh',
        overflow: 'auto',
        padding: '0px 16px',
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none',
        },
      }}
    >
      {historicoAcoes?.map((item: { data: string; descricao: string; responsavel: string }, index: number) => (
        <>
          <Timeline.Item key={index}>
            <Timeline.Separator>
              <Timeline.Dot />
              <Timeline.Connector />
            </Timeline.Separator>
            <Timeline.Content>
              <Grid container flexDirection='column'>
                <Typography variant='titleXS'>{item.descricao}</Typography>
                <Typography variant='bodyXS'>
                  há {handleDataHistorico(item.data)} {item.responsavel ? `por ${item.responsavel}` : ''}
                </Typography>
              </Grid>
            </Timeline.Content>
          </Timeline.Item>
        </>
      ))}
    </Timeline.Root>
  );
};

export default memo(VisualizarListaPropostasDialogHistorico);
