import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Drawer, LIcon } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { colorPalette } from '@s_mart/tokens';

import dayjs from 'dayjs';

import BannerCobrancas from 'components/bannerCobrancas/bannerCobrancas';
import BannerContratacao from 'components/bannerContratacao/bannerContratacao';
import BottomBar from 'components/bottomBar/bottomBar';
import Loading from 'components/loadings/loading/loading';
import LoadingGerador from 'components/loadings/loadingGerador/loadingGerador';
import useMarcarComoPerdidaDialog from 'containers/propostas/dialogs/marcarComoPerdidaDialog/store/marcarComoPerdidaDialog.store';
import { MarcarComoPerdidaDialog } from 'containers/propostas/dialogs/marcarComoPerdidaDialog/marcarComoPerdidaDialog';
import PropostaExcluirDialog from 'containers/propostas/dialogs/propostaExcluirDialog/propostaExcluirDialog';
import usePropostaExcluirDialog from 'containers/propostas/dialogs/propostaExcluirDialog/store/propostaExcluirDialog.store';
import { useNotification } from 'hooks/useNotification/useNotification';
import { useIsAdmin } from 'hooks/useIsAdmin/useIsAdmin';
import GeradorDeProposta from 'shared/modais/geradorDeProposta/geradorDeProposta';
import OnBoardingBoasVindas from 'shared/modais/onBoarding/onBoardingBoasVindas/onBoardingBoasVindas';
import OnBoardingFinalizacao from 'shared/modais/onBoarding/onBoardingFinalizacao/onBoardingFinalizacaoDialog';
import VisualizarPropostaDialog from 'shared/modais/visualizarPropostaDialog/visualizarPropostaDialog';
import useVisualizarPropostaDialog from 'shared/modais/visualizarPropostaDialog/store/visualizarPropostaDialog.store';
import VisualizarListaPropostasDialog from 'shared/modais/visualizarListaPropostasDialog/visualizarListaPropostasDialog';
import useVisualizarListaPropostasDialog from 'shared/modais/visualizarListaPropostasDialog/store/visualizarListaPropostasDialog.store';
import usePlanosDialog from 'shared/modais/planosDialog/store/planosDialog.store';
import ConvidarVendedorComoUsuarioDialog from 'shared/modais/convidarVendedorComoUsuarioDialog/convidarVendedorComoUsuarioDialog';
import useConvidarVendedorComoUsuarioDialog from 'shared/modais/convidarVendedorComoUsuarioDialog/store/convidarVendedorComoUsuarioDialog.store';
import useSaidaSemSalvarDialog from 'shared/modais/saidaSemSalvarDialog/store/saidaSemSalvarDialog.store';
import SaidaSemSalvarDialog from 'shared/modais/saidaSemSalvarDialog/saidaSemSalvarDialog';
import VendedorFormDialog from 'shared/modais/vendedorFormDialog/vendedorFormDialog';
import useVendedorFormDialog from 'shared/modais/vendedorFormDialog/store/vendedorFormDialog.store';
import NPS from 'shared/nps/nps';
import { useNPSContext } from 'shared/nps/npsContext';
import PlanosDialog from 'shared/modais/planosDialog/planosDialog';
import { AppBar } from 'shared/appBar/appBar';
import { useInformaFaturaDialog } from 'shared/modais/informaFaturaDialog/informaFaturaDialog.store';
import { InformaFaturaDialog } from 'shared/modais/informaFaturaDialog/informaFaturaDialog';
import { LojaDeRecursos } from 'shared/lojaDeRecursos/lojaDeRecursos';
import ClienteFormDialog from 'shared/modais/clienteFormDialog/clienteFormDialog';
import useClienteFormDialog from 'shared/modais/clienteFormDialog/store/clienteFormDialog.store';
import EditarModeloPropostaDialog from 'shared/modais/editarModeloPropostaDialog/editarModeloPropostaDialog';
import { InformarPagamentoDialog } from 'shared/modais/informarPagamentoDialog/informarPagamentoDialog';
import useGeradorDeProposta from 'shared/modais/geradorDeProposta/store/geradorDeProposta.store';
import { NovidadeCrmDialog } from 'shared/modais/novidadeCrmDialog/novidadeCrmDialog';
import { useNovidadeCrmDialogStore } from 'shared/modais/novidadeCrmDialog/novidadeCrmDialog.store';
import useOnBoarding from 'shared/modais/onBoarding/store/onBoarding.store';
import useApp from 'store/app/app';
import useAuth from 'store/auth/auth';
import useConfiguracoes from 'store/configuracoes/configuracoes';

import * as styled from './private.styles';
import { paginas } from './private.static';
import usePrivate from './usePrivate';
import { useIsTesteGratis } from 'hooks/useIsTesteGratis/useIsTesteGratis';
import { SemConexaoDialog } from 'shared/modais/semConexaoDialog/semConexaoDialog';
import { BoletoPronto } from 'shared/modais/planosDialog/boletoPronto/boletoPronto';
import useExcedeuBilhetagemDialog from 'shared/modais/excedeuBilhetagemDialog/excedeuBilhetagemDialog.store';
import ExcedeuBilhetagemDialog from 'shared/modais/excedeuBilhetagemDialog/excedeuBilhetagemDialog';

const Private = () => {
  // Hook com as lógicas do private
  usePrivate();

  // Hook responsável pelas notificações
  useNotification();

  const _navigate = useNavigate();
  const { loading, mensagemLoading, loadingGerador, lojaDeRecursos, semConexao } = useApp();
  const { profile } = useAuth();
  const { pathname } = useLocation();
  const { visualizarPropostaDialog } = useVisualizarPropostaDialog();
  const { visualizarListaPropostasDialog } = useVisualizarListaPropostasDialog();
  const { vendedorFormDialog } = useVendedorFormDialog();
  const { geradorDeProposta } = useGeradorDeProposta();
  const { onBoardingBoasVindas, onBoardingFinalizacao } = useOnBoarding();
  const { planosDialog, boletoProntoDialog } = usePlanosDialog();
  const { mostrarNps } = useNPSContext();
  const { convidarVendedorComoUsuarioDialog } = useConvidarVendedorComoUsuarioDialog();
  const { saidaSemSalvarDialog } = useSaidaSemSalvarDialog();
  const { clienteFormDialog } = useClienteFormDialog();
  const informarFaturaDialog = useInformaFaturaDialog();
  const marcarComoPerdidaDialog = useMarcarComoPerdidaDialog();
  const novidadeCrmDialog = useNovidadeCrmDialogStore();
  const { propostaExcluirDialog } = usePropostaExcluirDialog();
  const { excedeuBilhetagemDialog } = useExcedeuBilhetagemDialog();
  const isAdmin = useIsAdmin();
  const isTesteGratis = useIsTesteGratis();
  const {
    recursosContratados,
    meusDados,
    mostrarEditarModeloDialog,
    setMostrarEditarModeloDialog,
    dadosCobranca,
    parametros,
    loaded,
  } = useConfiguracoes();

  const _temPlanoCrm = recursosContratados?.crm;

  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  const boletosEmAberto = dadosCobranca?.find((it) => ['EMITIDO', 'BAIXADO'].includes(it?.situacao));

  const mostrarModalBoletos =
    !pathname.includes('minha-conta') &&
    ((meusDados?.situacao === 2 && dayjs(boletosEmAberto?.dataVencimento).isBefore(dayjs())) ||
      (boletosEmAberto?.dataVencimento &&
        !boletosEmAberto?.dataPagamento &&
        loaded &&
        parametros?.visualizouAvisoNovoBoleto !== dayjs().format('MM')));

  const _paginasFiltradas = isAdmin
    ? paginas.filter((it) => {
        if (!_temPlanoCrm && it.name === 'relatorios') {
          return false;
        }
        return true;
      })
    : paginas.filter((it) => {
        if (it.name === 'vendedores' || it.name === 'relatorios') {
          return false;
        }
        if (it.name === 'produtos' && profile?.tipo === 2) {
          return false;
        }
        return true;
      });

  if (pathname === '/404' || pathname === '/500') {
    return <Outlet />;
  }

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {loading && <Loading mensagem={mensagemLoading || undefined} />}
      {loadingGerador?.aberto && <LoadingGerador />}
      {visualizarPropostaDialog.aberto && <VisualizarPropostaDialog />}
      {visualizarListaPropostasDialog.aberto && <VisualizarListaPropostasDialog />}
      {onBoardingBoasVindas?.aberto && <OnBoardingBoasVindas />}
      {onBoardingFinalizacao?.aberto && <OnBoardingFinalizacao />}
      {geradorDeProposta?.aberto && <GeradorDeProposta />}
      {planosDialog?.aberto && <PlanosDialog />}
      {boletoProntoDialog?.aberto && <BoletoPronto />}
      {convidarVendedorComoUsuarioDialog?.aberto && <ConvidarVendedorComoUsuarioDialog />}
      {mostrarNps && <NPS />}
      {excedeuBilhetagemDialog.aberto && <ExcedeuBilhetagemDialog />}
      {informarFaturaDialog.isOpen && <InformaFaturaDialog />}
      {lojaDeRecursos && <LojaDeRecursos />}
      {vendedorFormDialog.aberto && <VendedorFormDialog />}
      {saidaSemSalvarDialog.aberto && <SaidaSemSalvarDialog />}
      {marcarComoPerdidaDialog.open && <MarcarComoPerdidaDialog />}
      {novidadeCrmDialog.open && <NovidadeCrmDialog />}
      {clienteFormDialog.aberto && <ClienteFormDialog />}
      {propostaExcluirDialog.aberto && <PropostaExcluirDialog />}
      {mostrarModalBoletos && <InformarPagamentoDialog />}
      {mostrarEditarModeloDialog && <EditarModeloPropostaDialog onClose={() => setMostrarEditarModeloDialog(false)} />}
      {semConexao && <SemConexaoDialog />}

      {telaMenorQue600 ? (
        <BottomBar paginas={_paginasFiltradas} />
      ) : (
        <Drawer>
          <Drawer.Header onClick={() => _navigate('/visao-geral')}>
            <img src='/static/svgs/drawer/logoHeader.svg' alt='Logo Solarfy' />
          </Drawer.Header>
          <Drawer.Content color={colorPalette.neutral[500]}>
            {_paginasFiltradas?.map(({ icon, label, path }) => (
              <Drawer.Item
                key={path}
                icon={<LIcon icon={icon} size='32px' color='white' />}
                path={`/${path}`}
                onClick={() => _navigate(`/${path}`)}
              >
                {label}
              </Drawer.Item>
            ))}
          </Drawer.Content>
        </Drawer>
      )}
      <styled.DivAppBarEMain>
        {!telaMenorQue600 ? <AppBar /> : undefined}
        {telaMenorQue600 && !isTesteGratis && <BannerCobrancas />}

        {isTesteGratis && <BannerContratacao />}
        <styled.Main
          mobile={telaMenorQue600 ?? false}
          banner={Boolean(isTesteGratis || document.getElementById('banner-cobrancas'))}
        >
          <Outlet />
        </styled.Main>
      </styled.DivAppBarEMain>
    </div>
  );
};

export default Private;
