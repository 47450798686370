export type ListarPropostasTypes = {
  cidade: string;
  cliente: string;
  codigo: number;
  codigoLead?: number;
  dataValidade?: string;
  dataGeracao: string;
  dataVisualizacao: string;
  link: string;
  nomeResponsavel: string;
  numero: number;
  situacao: number;
  tipoCliente: number;
  validade: string;
  valor?: number;
  valorTotal: number;
  vendedor: string;
};

export type GeradorDePropostaPayload = {
  alturaPainel: number;
  areaMinima: number;
  aumentoConsumo: number;
  clonada?: boolean;
  codigoCidade: number;
  codigoCliente: number;
  codigoConcessionaria: number;
  codigoLead?: number;
  codigoVendedor?: number;
  consumoMedio: number;
  consumoMedioForaPonta: number | null;
  consumoMedioPonta: number | null;
  consumoMensal?: ConsumoMensal;
  consumoMinimo?: number;
  consumoTusdg: number | null;
  custoDemandaContratada: number | null;
  degradacaoSistema: number;
  economia25: number;
  economiaAnual: Record<string, number>;
  fatorSimultaneidade?: number;
  geracaoSistema: number;
  graficoConsumo: string;
  graficoFaturas: string;
  graficoPayback: string;
  infoAdicionais: InfoAdicionais;
  larguraPainel: number;
  mediaFaturaComSistema: number;
  mediaFaturaSemSistema: number;
  mediaValorFioB: number;
  perdaSistema: number;
  potenciaInstalada: number;
  potenciaInversor: number;
  potenciaPaineis: number;
  precoKwh: number;
  produtos: ProdutoGeradorProposta[];
  qtdeDemandaContratada: number | null;
  quantidadePaineis: number;
  retornoInvestimento: string;
  sistemaDimensionado: number | null;
  tabelaPayback: string;
  tarifaDemandaContratada: number | null;
  tarifaForaPonta: number | null;
  tarifaPonta: number | null;
  tarifaTusdg: number | null;
  taxaInflacao: number;
  tipoLigacao: number;
  validade: string;
  valorGastoEnergia30AnosComSistema: number;
  valorGastoEnergia30AnosSemSistema: number;
  valorTotal: number;
  valorTusdg: number | null;
};

export interface ProdutoGeradorProposta {
  codigo?: number;
  codigoProdutoFornecedor?: number;
  descricao?: string;
  descricaoGarantia?: string;
  garantia?: number | null;
  nome: string;
  potencia?: number;
  quantidade?: number;
  tipo: number;
  tipoGarantia?: number;
  valorVenda?: number;
}

interface InfoAdicionais {
  descricao: string;
  infoAdicionaisSalvar: boolean;
}
type ConsumoMensal = {
  mes: number;
  valor: number;
  ordem: number;
}[];

export type ConsumoMensalReturn = [
  {
    '1'?: ConsumoMensalObjetoInterno[];
  },
  {
    '2'?: ConsumoMensalObjetoInterno[];
  },
  {
    '3'?: ConsumoMensalObjetoInterno[];
  },
  {
    '4'?: ConsumoMensalObjetoInterno[];
  },
  {
    '5'?: ConsumoMensalObjetoInterno[];
  }
];

interface ConsumoMensalObjetoInterno {
  mes: number;
  valor: string;
}

export type ProdutoPayload = {
  codigo: number;
  codigoProdutoFornecedor?: number;
  descricao?: string;
  descricaoGarantia?: string;
  garantia: number;
  idIntegracaoForncedor?: string;
  nome: string;
  potencia: number;
  quantidade: number;
  tipo: number;
  tipoGarantia: number;
  valorVenda?: number;
};

export type ClientePayload = {
  cidade?: string;
  codigoCidade: number | null;
  cpfCnpj?: string;
  email?: string;
  nome: string;
  nomeResponsavel?: string;
  razaoSocial?: string;
  telefone?: string;
  tipoPessoa: number;
  uf?: string;
};

export type BuscarPropostaDados = {
  alturaPainel: number;
  areaMinima: number;
  aumentoConsumo: number;
  cidade: string;
  cliente: ClientePayload;
  codigoCidade: number;
  codigoCliente: number;
  codigoConcessionaria: number;
  codigoLead?: number;
  codigoVendedor: number;
  consumoMedio: number;
  consumoMedioForaPonta: number;
  consumoMedioPonta: number;
  consumoMensal: any;
  consumoMinimo: number;
  consumoTusdg: number;
  custoDemandaContratada: number;
  degradacaoSistema: number;
  fatorSimultaneidade: number;
  geracaoSistema: number;
  infoAdicionais: string;
  larguraPainel: number;
  mediaFaturaComSistema: number;
  mediaFaturaSemSistema: number;
  mediaValorFioB: number;
  perdaSistema: number;
  potenciaInstalada: number;
  potenciaInversor: number;
  potenciaPaineis: number;
  precoKwh: number;
  produtos: ProdutoPayload[];
  qtdeDemandaContratada: number;
  quantidadePaineis: number;
  sistemaDimensionado: number;
  tarifaDemandaContratada: number;
  tarifaForaPonta: number;
  tarifaPonta: number;
  tarifaTusdg: number;
  taxaInflacao: number;
  tipoLigacao: number;
  uf: string;
  validade: string;
  valorGastoEnergia30AnosComSistema: number;
  valorGastoEnergia30AnosSemSistema: number;
  valorTotal: number;
  valorTusdg: number;
};

export type GerarPdfPropostaPayloadTypes = {
  acao: number;
  codigoProposta: number;
  hexCor?: string;
  dataInicialPreenchimento?: string;
};

export enum ModeloPropostaEnum {
  'ANTIGA' = 0,
  'NOVA' = 1,
  'PERSONALIZADA' = 2,
  'RESUMIDA' = 3,
}

export type ConfiguracoesPropostasTypes = {
  banner: string;
  hexCor: string;
  email: string;
  facebook: string;
  instagram: string;
  logotipo: string;
  modeloProposta: ModeloPropostaEnum;
  site: string;
  sobre: string;
  tipoProduto: number;
  ultInfoAdicional: string;
  ultValidadeProposta: number;
  usuariosApresentacaoGrupoA: number[];
  usuariosApresentacaoSimultaneidade: number[];
  whatsapp: string;
};

export type ListagemVendedoresProposta = {
  codigo: number;
  codigoUsuario: number;
  nome: string;
};

export type BuscarDadosBaseCalculo = {
  alturaPainel: number;
  aumentoConsumo: number;
  larguraPainel: number;
  perdaSistema: number;
  potenciaPaineis: number;
} | null;

export enum TipoAcaoProposta {
  COMPARTILHOU_PROPOSTA = 0,
  IMPRIMIU_PROPOSTA = 1,
}

export interface SalvarHistoricoAcaoProposta {
  tipoAcao: TipoAcaoProposta;
  id: number;
}

export interface AlterarSituacaoProposta {
  id: number;
  situacao: string;
  codigoMotivoPerda?: number;
}
