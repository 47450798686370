import { useEffect } from 'react';
import dayjs from 'dayjs';

import { base64ToBlob } from '@s_mart/utils';

import { baixarBoleto } from 'api/meusDados/meusDados';
import useApp from 'store/app/app';
import useConfiguracoes from 'store/configuracoes/configuracoes';

const useBotaoBoleto = () => {
  const { setLoading, handleError } = useApp();
  const { dadosCobranca, setStatusFatura } = useConfiguracoes();

  const handleAbrirBoletoPagamento = async () => {
    setLoading(true);

    try {
      const fatura = dadosCobranca?.find((it) => it.situacao.includes('EMITIDO'));
      if (fatura) {
        const res = await baixarBoleto(fatura.identificador);
        if (res.data.data.boleto) {
          const file = base64ToBlob(res.data.data.boleto, 'application/pdf');
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          setLoading(false);
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    const fatura = dadosCobranca?.find((it) => it.situacao.includes('EMITIDO'));
    const dataVencimento = dayjs(fatura?.dataVencimento).utc();

    if (dayjs(fatura?.dataVencimento).day() === 5) {
      dataVencimento.add(2, 'days');
    } else if (dayjs(fatura?.dataVencimento).day() === 6) {
      dataVencimento.add(1, 'days');
    }

    if (fatura?.situacao) {
      if (fatura.dataPagamento === null) {
        const dataAtual = dayjs();

        if (dataVencimento.format('DD/MM/YYYY') === dataAtual.format('DD/MM/YYYY')) {
          // Dia de vencimento igual ao dia de hoje
          setStatusFatura(3);
        }

        const faturaAtrasada = dataVencimento.isBefore(dataAtual);

        if (!faturaAtrasada) {
          const diff = dataVencimento.diff(dataAtual, 'days');

          if (diff > 3) {
            // Vencimento em mais de 3 dias
            setStatusFatura(1);
          } else {
            // Vencimento em menos de 3 dias
            setStatusFatura(2);
          }
        } else {
          const diff = dataAtual.diff(dataVencimento, 'days');

          if (diff === 1) {
            // Vencido em 1 dia
            setStatusFatura(4);
          } else {
            // Vencido em mais de 1 dia
            setStatusFatura(5);
          }
        }
      }
    } else {
      // Sem pendências
      setStatusFatura(0);
    }
  }, [dadosCobranca, setStatusFatura]);

  return { handleAbrirBoletoPagamento };
};

export { useBotaoBoleto };
