import type { IMotivoProps } from './motivo.types';

import { Grid, IconButton, LIcon, Typography } from '@s_mart/core';
import { lineEdit, lineTrashAlt } from '@s_mart/regular-icons';
import { useMotivo } from './useMotivo';
import { ExcluirMotivoDialog } from '../../excluirMotivoDialog/excluirMotivoDialog';

export function Motivo({ nome, codigo, openEditarMotivo }: IMotivoProps) {
  const { excluirMotivoDialog, handleExcluirMotivo, handleCloseExcluirMotivoDialog, handleOpenExcluirMotivoDialog } =
    useMotivo({ codigo });

  return (
    <>
      <Grid item xs={12}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item maxWidth='calc(100% - 80px)' display='flex' alignItems='center'>
            <Typography variant='bodyMD' style={{ wordBreak: 'break-word' }}>
              {nome}
            </Typography>
          </Grid>
          <Grid item maxWidth={80}>
            <Grid container spacing={1} justifyContent='end'>
              <Grid item>
                <IconButton
                  size='small'
                  variant='outlined'
                  color='secondary'
                  style={{ maxWidth: 32 }}
                  onClick={() => openEditarMotivo({ nome, codigo })}
                >
                  <LIcon icon={lineEdit} size='20px' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size='small'
                  variant='outlined'
                  color='secondary'
                  style={{ maxWidth: 32 }}
                  onClick={() => handleOpenExcluirMotivoDialog()}
                >
                  <LIcon icon={lineTrashAlt} size='20px' />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {excluirMotivoDialog.open && (
        <ExcluirMotivoDialog
          motivo={{ codigo, nome }}
          handleExcluirMotivo={handleExcluirMotivo}
          handleClose={handleCloseExcluirMotivoDialog}
        />
      )}
    </>
  );
}
