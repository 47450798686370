import { useCallback } from 'react';

import { telefoneComercial } from 'config/constantes';

export function useNovidadeCrmDialog() {
  const handleAbrirWhatsAppComercial = useCallback(() => {
    window?.open(
      `https://api.whatsapp.com/send?phone=55${telefoneComercial.replace(
        /[^0-9]/g,
        ''
      )}&text=Olá, gostaria de saber mais sobre o CRM!`,
      '_blank',
      'noopener noreferrer'
    );
  }, []);

  return { handleAbrirWhatsAppComercial };
}
