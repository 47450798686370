import { useNavigate } from 'react-router-dom';

import { AppBar as AppBarSM, LIcon, Typography } from '@s_mart/core';
import { lineShoppingCart } from '@s_mart/light-icons';
import { lineBoxCircleCheck, lineEdit, lineHome, lineSignOut, lineUser } from '@s_mart/regular-icons';
import { lineQuestionCircle } from '@s_mart/solid-icons';

import { useIsAdmin } from 'hooks/useIsAdmin/useIsAdmin';
import { useLogout } from 'hooks/useLogout/useLogout';
import useApp from 'store/app/app';
import useAuth from 'store/auth/auth';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import { handleAjuda } from 'utils/handleAjuda/handleAjuda';
import { ModeloPropostaEnum } from 'api/propostas/propostas.types';

import { BotaoBoleto } from './botaoBoleto/botaoBoleto';
import { BotaoLojaRecurso } from './appBar.styles';

const AppBar = () => {
  const { setLojaDeRecursos } = useApp();
  const { profile } = useAuth();
  const { gerais, setMostrarEditarModeloDialog } = useConfiguracoes();
  const _isAdmin = useIsAdmin();
  const { handleLogout } = useLogout();
  const _navigate = useNavigate();

  const _emailSB = profile?.email.includes('@sbsistemas.');
  const _propostaModeloPersonalizada = gerais?.modeloProposta === ModeloPropostaEnum.PERSONALIZADA;

  return (
    <AppBarSM>
      <AppBarSM.LeftSide>
        <BotaoBoleto />
      </AppBarSM.LeftSide>
      <AppBarSM.Help onClick={handleAjuda} icon={lineQuestionCircle} />
      {_isAdmin && (
        <BotaoLojaRecurso onClick={() => setLojaDeRecursos(true)}>
          <LIcon icon={lineShoppingCart} size='28' />
        </BotaoLojaRecurso>
      )}
      <AppBarSM.Profile title={String(profile?.nome)} user={String(profile?.nome)}>
        {_isAdmin && (
          <AppBarSM.Action icon={lineHome} onClick={() => _navigate('/minha-conta')}>
            <Typography variant='bodySM'>Minha conta</Typography>
          </AppBarSM.Action>
        )}
        {_isAdmin && (
          <AppBarSM.Action icon={lineUser} onClick={() => _navigate('/usuarios')}>
            <Typography variant='bodySM'>Usuários</Typography>
          </AppBarSM.Action>
        )}
        {((!_propostaModeloPersonalizada && _isAdmin) || _emailSB) && (
          <AppBarSM.Action icon={lineEdit} onClick={() => setMostrarEditarModeloDialog(true)}>
            <Typography variant='bodySM'>Modelo de proposta</Typography>
          </AppBarSM.Action>
        )}
        {_isAdmin && (
          <AppBarSM.Action icon={lineBoxCircleCheck} onClick={() => _navigate('/configuracoes-fornecedores')}>
            <Typography variant='bodySM'>Configurar fornecedores</Typography>
          </AppBarSM.Action>
        )}
        <AppBarSM.Action icon={lineSignOut} onClick={handleLogout}>
          <Typography variant='bodySM'>Sair</Typography>
        </AppBarSM.Action>
      </AppBarSM.Profile>
    </AppBarSM>
  );
};

export { AppBar };
