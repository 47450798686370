import axios from 'axios';
import { apiUrl } from './variables';

const api = axios.create({ baseURL: apiUrl });
const apiPublic = axios.create({ baseURL: apiUrl });
const apiForInterceptors = axios.create({ baseURL: apiUrl });

apiForInterceptors.interceptors.response.use(
  function (response) {
    if (Array.isArray(response.data)) return response.data;

    return {
      ...response.data,
      status: response.status,
    };
  },
  function (error) {
    return Promise.reject(
      error?.response?.data || {
        message: error?.message,
      }
    );
  }
);

export { api, apiPublic, apiForInterceptors };
