import dayjs from 'dayjs';

import useConfiguracoes from 'store/configuracoes/configuracoes';

const useBannerCobrancas = () => {
  const { dadosCobranca } = useConfiguracoes();

  const situacaoBoleto = () => {
    const fatura = dadosCobranca?.find((it) => it.situacao.includes('EMITIDO'));
    const dataVencimento = dayjs(fatura?.dataVencimento).utc();

    if (dayjs(fatura?.dataVencimento).day() === 5) {
      dataVencimento.add(2, 'days');
    } else if (dayjs(fatura?.dataVencimento).day() === 6) {
      dataVencimento.add(1, 'days');
    }

    if (fatura?.situacao) {
      if (fatura.dataPagamento === null) {
        const dataAtual = dayjs();

        if (dataVencimento.format('DD/MM/YYYY') === dataAtual.format('DD/MM/YYYY')) {
          // Dia de vencimento igual ao dia de hoje
          return 'Seu boleto vence hoje';
        }

        const faturaAtrasada = dataVencimento.isBefore(dataAtual);

        if (faturaAtrasada) {
          return 'Você tem boleto(s) em atraso';
        } else {
          return 'Seu boleto já está disponível';
        }
      }
    } else {
      //Boleto emitido, porém já foi pago
      return;
    }
  };

  return { situacaoBoleto };
};

export default useBannerCobrancas;
