import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import useGeradorDeProposta from '../../store/geradorDeProposta.store';

const useInformacoesAdicionaisDialog = () => {
  const { setValue, watch } = useFormContext();
  const { setGeradorDeProposta } = useGeradorDeProposta();

  const _formValues = watch();

  const initialValuesInformacoesAdicionaisDialog = useMemo(
    () => ({
      descricao: _formValues.descricao,
      infoAdicionaisSalvar: _formValues.infoAdicionaisSalvar,
    }),
    [_formValues.descricao, _formValues.infoAdicionaisSalvar]
  );

  const handleClose = useCallback(() => {
    setGeradorDeProposta({ informacoesAdicionaisDialog: false });
  }, [setGeradorDeProposta]);

  const handleSubmitInformacoesAdicionaisDialog = useCallback(
    (values: any) => {
      if (values.descricao?.trim()) {
        setValue('descricao', values.descricao.trim());
        setValue('infoAdicionaisSalvar', values.infoAdicionaisSalvar || false);
      } else {
        setValue('descricao', undefined);
      }
      setGeradorDeProposta({ informacoesAdicionaisDialog: false });
    },
    [setValue, setGeradorDeProposta]
  );

  return { initialValuesInformacoesAdicionaisDialog, handleSubmitInformacoesAdicionaisDialog, handleClose };
};

export default useInformacoesAdicionaisDialog;
