import { create } from 'zustand';

import { SaidaSemSalvarDialogStates, SaidaSemSalvarDialogStore } from './saidaSemSalvarDialog.store.types';

const initialState: SaidaSemSalvarDialogStates = {
  saidaSemSalvarDialog: { aberto: false },
};

const useSaidaSemSalvarDialog = create<SaidaSemSalvarDialogStore>((set) => ({
  ...initialState,
  setSaidaSemSalvarDialog: (saidaSemSalvarDialog) => set((state) => ({ ...state, saidaSemSalvarDialog })),
}));

export default useSaidaSemSalvarDialog;
