import { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid, LIcon, Typography } from '@s_mart/core';
import { Autocomplete } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { required } from '@s_mart/rules';
import { lineExclamationTriangle } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import { ListagemProdutosTypes } from 'containers/produtos/produtos.types';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import { removeCaracteresEspeciais } from 'utils/removeCaracteresEspeciais/removeCaracteresEspeciais';

import useFieldsInversores from '../useFieldsInversores';
import { useFields } from '../../useFields';
import { DivAlertaFieldsNome } from '../../fields.styles';

const AutoCompleteFieldNomeInversores = ({ index }: { index: number }) => {
  const { inversores, loadingInversores, handleBuscarInversorSelecionado } = useFieldsInversores();
  const { recursosContratados } = useConfiguracoes();
  const { handleCalcularValorTotal } = useFields();
  const methods = useFormContext();
  const telaMenorQue760 = useMediaQuery(`(max-width: 760px)`);

  const _formValuesInversores = methods.watch('inversores');

  const _handleAlertaProdutoCadastrado = (index: number) => {
    if (
      typeof _formValuesInversores?.[index]?.modeloInversores === 'string' &&
      _inversoresOptions?.find((it) => it.label === _formValuesInversores?.[index]?.modeloInversores)
    ) {
      return (
        <DivAlertaFieldsNome>
          <LIcon icon={lineExclamationTriangle} removeMargin size='18px' color={colorPalette.yellow[300]} />
          <Typography variant='bodyXS'>Um produto com esse nome já está cadastrado.</Typography>
        </DivAlertaFieldsNome>
      );
    }
    return undefined;
  };

  const _inversoresOptions = useMemo(
    () =>
      inversores
        ?.filter((it: ListagemProdutosTypes) => it.situacao)
        ?.map((item: ListagemProdutosTypes) => ({
          label: item.nome,
          value: item.codigo,
        })),
    [inversores]
  );

  return (
    <Grid item xs={telaMenorQue760 ? 12 : 6}>
      <Autocomplete
        name={`inversores.${index}.modeloInversores`}
        label='Modelo do inversor'
        value={_formValuesInversores?.[index]?.modeloInversores || null}
        required
        textFieldProps={{
          placeholder: 'Selecione ou informe um novo inversor',
          inputProps: { maxLength: 90 },
        }}
        onChange={(value, values) => {
          handleBuscarInversorSelecionado(index, value);
          if (recursosContratados?.['valor-de-venda-unitario']) {
            setTimeout(() => handleCalcularValorTotal({ values: values, methods }), 300);
          }
        }}
        onInputChange={(value) =>
          removeCaracteresEspeciais(value, `inversores.${index}.modeloInversores`, methods.setValue)
        }
        disableClearable
        rules={required}
        options={_inversoresOptions || []}
        loading={loadingInversores}
      />
      {_handleAlertaProdutoCadastrado(index)}
    </Grid>
  );
};

export default memo(AutoCompleteFieldNomeInversores);
