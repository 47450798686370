import { Stack, capitalize } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid';
import { LIcon, Tooltip, Typography } from '@s_mart/core';
import { valor } from '@s_mart/masks';
import { lineEye } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';
import { FornecedorEnum } from 'api/fornecedores/fornecedores.types';

export const getColunasTabelaFornecedores = (fornecedor: FornecedorEnum): GridColDef[] => {
  const colunas = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () => null,
      valueFormatter: (props: any) => {
        if (props.id && !props.api.isRowSelectable(props.id)) {
          return null;
        }
        return props.value;
      },
    },
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 2,
      valueFormatter: (props: any) => {
        if (props.id && !props.api.isRowSelectable(props.id)) {
          return <div style={{ opacity: 0.5, display: 'flex' }}>{props.value}</div>;
        }
        return props.value;
      },
    },
    {
      field: 'potencia',
      headerName: 'Potência',
      valueFormatter: (props: any) => {
        if (props.id && !props.api.isRowSelectable(props.id)) {
          return <div style={{ opacity: 0.5 }}>{`${props.value} kWp`}</div>;
        }
        return props?.value ? `${props.value} kWp` : '-';
      },
    },
    {
      field: 'tipoEstrutura',
      headerName: 'Tipo de estrutura',
      flex: 1,
      valueFormatter: (props: any) => {
        if (props.id && !props.api.isRowSelectable(props.id)) {
          return <div style={{ opacity: 0.5 }}>{capitalize(props.value)}</div>;
        }
        return props?.value ? capitalize(props.value) : '-';
      },
    },
    {
      field: 'descricaoCompleta',
      headerName: 'Detalhes',
      valueFormatter: (props: any) => {
        if (props.id && !props.api.isRowSelectable(props.id)) {
          return (
            <div style={{ opacity: 0.5 }}>
              <LIcon icon={lineEye} size='24px' />
            </div>
          );
        }

        if (fornecedor === FornecedorEnum.EDELTEC) {
          return (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: props.value }} />}
              enterDelay={100}
              enterNextDelay={100}
            >
              <div>
                <LIcon icon={lineEye} size='24px' />
              </div>
            </Tooltip>
          );
        }

        // pegar valores do props.value separando por /n
        const valores: string[] = props.value.split('\n');

        return (
          <Tooltip
            title={
              <Stack>
                {valores.map((valor, index) => (
                  <Typography key={index} variant='bodySM'>
                    {valor}
                  </Typography>
                ))}
              </Stack>
            }
            enterDelay={100}
            enterNextDelay={100}
          >
            <div>
              <LIcon icon={lineEye} size='24px' />
            </div>
          </Tooltip>
        );
      },
    },
  ];

  if (fornecedor === FornecedorEnum.EDELTEC) {
    colunas.push(
      {
        field: 'disponibilidade',
        headerName: 'Disponibilidade',
        flex: 1,
        valueFormatter: (props: any) => {
          if (props.id && !props.api.isRowSelectable(props.id)) {
            return <div style={{ opacity: 0.5 }}>{capitalize(props.value)}</div>;
          }

          return props?.value ? capitalize(props.value) : 'Não informado';
        },
      },
      {
        field: 'precoVenda',
        headerName: 'Preço de venda',
        minWidth: 130,
        valueFormatter: (props: any) => {
          if (props.id && !props.api.isRowSelectable(props.id)) {
            return (
              <Typography
                style={{ opacity: 0.2 }}
                variant='bodySM'
                color={props.value ? colorPalette.neutral[900] : colorPalette.neutral[100]}
              >
                {props.value ? valor.format(props.value) : 'Não informado'}
              </Typography>
            );
          }

          return (
            <Typography variant='bodySM' color={props.value ? colorPalette.neutral[900] : colorPalette.neutral[100]}>
              {props.value ? valor.format(props.value) : 'Não informado'}
            </Typography>
          );
        },
      }
    );
  }

  if (fornecedor === FornecedorEnum.HORUS) {
    colunas.push({
      field: 'precoCusto',
      headerName: 'Preço de custo',
      minWidth: 130,
      valueFormatter: (props: any) => {
        if (props.id && !props.api.isRowSelectable(props.id)) {
          return (
            <Typography
              style={{ opacity: 0.2 }}
              variant='bodySM'
              color={props.value ? colorPalette.neutral[900] : colorPalette.neutral[100]}
            >
              {props.value ? valor.format(props.value) : 'Não informado'}
            </Typography>
          );
        }

        return (
          <Typography variant='bodySM' color={props.value ? colorPalette.neutral[900] : colorPalette.neutral[100]}>
            {props.value ? valor.format(props.value) : 'Não informado'}
          </Typography>
        );
      },
    });
  }

  return colunas;
};
