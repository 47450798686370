import styled from '@emotion/styled';

import { toRem } from '@s_mart/utils';
import { corPrimariaSolarfy } from 'styles/theme/theme';

export const DivMensagemPrincipal = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;

  max-width: ${toRem(525)};
`;

export const DivMensagensAdicionais = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};
  width: 85%;

  max-width: ${toRem(525)};
`;

export const DivInternaMensagemAdicional = styled.div`
  display: flex;
  align-items: end;
  gap: ${toRem(8)};
`;

export const DivIconeStatusMensagem = styled.div<{ telaMobile?: boolean }>`
  display: inline-block;
  position: relative;
  background: ${corPrimariaSolarfy};

  border-radius: 50%;
  height: ${({ telaMobile }) => toRem(telaMobile ? 20 : 30)};
  width: ${({ telaMobile }) => toRem(telaMobile ? 20 : 30)};

  ::before {
    position: absolute;
    left: ${({ telaMobile }) => (telaMobile ? '-10%' : '10%')};
    top: 50%;
    height: 30%;
    width: 3px;
    background-color: #ffffff;
    content: '';
    transform: translateX(10px) rotate(-50deg);
    transform-origin: left bottom;
  }

  ::after {
    position: absolute;
    left: ${({ telaMobile }) => (telaMobile ? '-10%' : '10%')};
    bottom: 20%;
    height: 3px;
    width: 60%;
    background-color: #ffffff;
    content: '';
    transform: translateX(10px) rotate(-50deg);
    transform-origin: left bottom;
  }
`;

export const SSmallLoadingDots = styled.span<{ telaMobile?: boolean }>`
  width: ${({ telaMobile }) => toRem(telaMobile ? 4 : 6)};
  height: ${({ telaMobile }) => toRem(telaMobile ? 4 : 6)};
  border-radius: 50%;
  display: block;
  position: relative;
  background: #fff;
  box-shadow: ${({ telaMobile }) => (telaMobile ? '-6px 0 #fff, 6px 0 #fff' : '-10px 0 #fff, 10px 0 #fff')};
  box-sizing: border-box;
  margin: ${({ telaMobile }) => (telaMobile ? '0 0 2px 4px' : '0 0 4px 6px')};
  animation: shadowPulse 2s linear infinite;

  @keyframes shadowPulse {
    33% {
      background: #fff;
      box-shadow: ${({ telaMobile }) => (telaMobile ? '-6px 0 #ff3d00, 6px 0 #fff' : '-10px 0 #ff3d00, 10px 0 #fff')};
    }
    66% {
      background: #ff3d00;
      box-shadow: ${({ telaMobile }) => (telaMobile ? '-6px 0 #fff, 6px 0 #fff' : '-10px 0 #fff, 10px 0 #fff')};
    }
    100% {
      background: #fff;
      box-shadow: ${({ telaMobile }) => (telaMobile ? '-6px 0 #fff, 6px 0 #ff3d00' : '-10px 0 #fff, 10px 0 #ff3d00')};
    }
  }
`;
