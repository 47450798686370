import { create } from 'zustand';

import type { InformaFaturaDialogStore } from './informarFaturaDialog.types';

const useInformaFaturaDialog = create<InformaFaturaDialogStore>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),
}));

export { useInformaFaturaDialog };
