import { memo, useMemo } from 'react';

import { EmptyState } from '@s_mart/core';
import { DataTable } from '@s_mart/data';
import { colorPalette } from '@s_mart/tokens';

import type { ListarPropostasTypes } from 'api/propostas/propostas.types';
import { urls } from 'api/urls';
import usePropostasDataTable from 'containers/propostas/propostasDataTable/usePropostasDataTable';
import { useInfiniteQuery } from 'hooks/useInfiniteQuery/useInfiniteQuery';

import useVisualizarListaPropostasDialog from '../store/visualizarListaPropostasDialog.store';

const VisualizarListaPropostasDialogDataTable = () => {
  const { colunas } = usePropostasDataTable({});
  const { visualizarListaPropostasDialog } = useVisualizarListaPropostasDialog();

  const {
    data: _propostas,
    meta,
    hasLimitReached,
    fetchNextPage,
    param,
    isFetching,
  } = useInfiniteQuery<ListarPropostasTypes>({
    name: 'propostas',
    url: `${urls.propostas}`,
    fixedParams: {
      cliente: visualizarListaPropostasDialog.codigoCliente || undefined,
      vendedor: visualizarListaPropostasDialog.codigoVendedor || undefined,
    },
  });

  const _colunas = useMemo(
    () =>
      colunas.filter(
        (item) =>
          item.field === 'numero' ||
          item.field === 'validade' ||
          (visualizarListaPropostasDialog.codigoVendedor && item.field === 'cliente') ||
          item.field === 'valorTotal' ||
          item.field === 'dataGeracao' ||
          item.field === 'situacao' ||
          item.field === 'acoes'
      ),
    [colunas]
  );

  return (
    <div style={{ height: _propostas?.length ? '100%' : '328px', maxHeight: '40vh', overflow: 'auto' }}>
      <DataTable
        columns={_colunas}
        rows={_propostas || []}
        getRowId={(row) => row?.codigo}
        infiniteScroll={{
          chegouNoLimite: hasLimitReached,
          onFetch: () =>
            fetchNextPage({
              pageParam: {
                ...param,
                page: meta?.current_page + 1,
              },
            }),
        }}
        loading={isFetching}
        rowHeight={56}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              illustration='/static/svgs/emptyStates/emptyStatePropostas.svg'
              title='Nenhuma proposta atribuída'
              description={`Atribua propostas ao ${
                visualizarListaPropostasDialog.codigoVendedor ? 'vendedor' : 'cliente'
              } durante a geração da proposta.`}
            />
          ),
        }}
        sx={{
          '.MuiGrid-spacing-xs-1:nth-of-type(1)': {
            padding: '8px 8px 0 8px',
          },
          gridRow: {
            'div[data-rowindex]': {
              '&:nth-of-type(odd)': {
                backgroundColor: colorPalette.neutral[20],
                ':hover': {
                  backgroundColor: colorPalette.neutral[30],
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default memo(VisualizarListaPropostasDialogDataTable);
