import { useCallback, useEffect, useMemo, useState } from 'react';

import { cpfCnpj, telefone } from '@s_mart/masks';
import { lineBuilding, lineHome, lineUserTie } from '@s_mart/regular-icons';

import { buscarCliente } from 'api/clientes/clientes';
import { buscarVendedor } from 'api/vendedores/vendedores';
import useApp from 'store/app/app';

import useVisualizarListaPropostasDialog from './store/visualizarListaPropostasDialog.store';
import { InformacoesClienteTypes, InformacoesVendedorTypes } from './visualizarListaPropostasDialog.types';

const useVisualizarListaPropostasDialogFunctions = () => {
  const { handleError } = useApp();
  const { visualizarListaPropostasDialog } = useVisualizarListaPropostasDialog();

  const [_informacoesCliente, _setInformacoesCliente] = useState<InformacoesClienteTypes>();
  const [_informacoesVendedor, _setInformacoesVendedor] = useState<InformacoesVendedorTypes>();

  const informacoesCabecalho = useMemo(() => {
    const _info: any = {};
    if (_informacoesCliente?.dadosCliente) {
      _info.titulo = 'Propostas do cliente';
      _info.icone = _informacoesCliente.dadosCliente.tipoPessoa === 1 ? lineBuilding : lineHome;
      _info.nome = _informacoesCliente.dadosCliente.nome;
      _info.telefone = telefone.format(_informacoesCliente.dadosCliente.telefone);
      if (_informacoesCliente.dadosCliente.cpfCnpj) {
        _info.cpfCnpj =
          _informacoesCliente.dadosCliente.tipoPessoa === 1
            ? `CNPJ: ${cpfCnpj.format(String(_informacoesCliente.dadosCliente.cpfCnpj))}`
            : `CPF: ${cpfCnpj.format(String(_informacoesCliente.dadosCliente.cpfCnpj))}`;
      }
      if (_informacoesCliente.dadosCliente.nomeResponsavel) {
        _info.nomeResponsavel = _informacoesCliente.dadosCliente.nomeResponsavel;
      }
      if (_informacoesCliente.dadosCliente.email) {
        _info.email = _informacoesCliente.dadosCliente.email;
      }
      return _info;
    } else if (_informacoesVendedor?.dadosVendedor) {
      _info.titulo = 'Propostas do vendedor';
      _info.icone = lineUserTie;
      _info.nome = _informacoesVendedor.dadosVendedor.nome;
      _info.ativo = _informacoesVendedor.dadosVendedor.ativo ? 'Ativo' : 'Inativo';
      if (_informacoesVendedor.dadosVendedor.email) {
        _info.email = _informacoesVendedor.dadosVendedor.email;
      }
      if (_informacoesVendedor.dadosVendedor.telefone) {
        _info.telefone = telefone.format(_informacoesVendedor.dadosVendedor.telefone);
      }
      return _info;
    }
  }, [_informacoesCliente, _informacoesVendedor]);

  const handleBuscarDadosCliente = useCallback(
    async (codigoCliente: number) => {
      try {
        const { data: _dadosCliente } = await buscarCliente(codigoCliente);
        _setInformacoesCliente((_prev) => ({ ..._prev, dadosCliente: _dadosCliente.data }));
      } catch (err) {
        handleError(err);
      }
    },
    [handleError]
  );

  const handleBuscarDadosVendedor = useCallback(
    async (codigoVendedor: number) => {
      try {
        const { data: _dadosVendedor } = await buscarVendedor(codigoVendedor);
        _setInformacoesVendedor((_prev) => ({ ..._prev, dadosVendedor: _dadosVendedor.data }));
      } catch (err) {
        handleError(err);
      }
    },
    [handleError]
  );

  useEffect(() => {
    if (visualizarListaPropostasDialog.codigoCliente) {
      handleBuscarDadosCliente(visualizarListaPropostasDialog.codigoCliente);
    } else if (visualizarListaPropostasDialog.codigoVendedor) {
      handleBuscarDadosVendedor(visualizarListaPropostasDialog.codigoVendedor);
    }
  }, [visualizarListaPropostasDialog, handleBuscarDadosCliente, handleBuscarDadosVendedor]);

  return { handleBuscarDadosCliente, handleBuscarDadosVendedor, informacoesCabecalho };
};

export default useVisualizarListaPropostasDialogFunctions;
