import { create } from 'zustand';

export interface NovidadeCrmDialogStore {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

export const useNovidadeCrmDialogStore = create<NovidadeCrmDialogStore>((set) => ({
  open: false,
  handleOpen: () => set({ open: true }),
  handleClose: () => set({ open: false }),
}));
