import { Stack } from '@mui/material';
import { Button, Dialog, Typography } from '@s_mart/core';

import MaquinaCredito from 'assets/avisoBoleto/maquina-credito.svg';
import { useInformarPagamentoDialogContext } from '../../informarPagamentoDialog.context';

export function BoletosEmAberto() {
  const { handleAbrirContatoSuporte, handleAbrirMeuPlano } = useInformarPagamentoDialogContext();

  return (
    <>
      <Dialog.Header>Ops, nosso robô ainda não identificou alguns pagamentos</Dialog.Header>
      <Dialog.Content>
        <Stack direction='row' gap={3} alignItems='center' justifyContent='center' flexDirection='column'>
          <img src={MaquinaCredito} alt='Máquina de cartão de crédito com o papel de recibo saindo' />
          <Typography variant='bodyMD'>Acesse abaixo o meu plano para verificar as faturas em aberto</Typography>
          <Button sx={{ width: '100%', maxWidth: 430 }} onClick={handleAbrirMeuPlano}>
            Ir para meu plano
          </Button>
        </Stack>
      </Dialog.Content>
      <Dialog.Footer>
        <Stack direction='row' alignItems='center' justifyContent='center' width='100%'>
          <Typography variant='bodySM'>Foi um engano? Não se preocupe. Nesse caso,</Typography>
          <Button variant='text' color='secondary' onClick={handleAbrirContatoSuporte}>
            Clique aqui para falar com o suporte
          </Button>
        </Stack>
      </Dialog.Footer>
    </>
  );
}
