import type {
  ICalcularDimensionamentoGrupoBPayload,
  ICalcularDimensionamentoGrupoBRetorno,
} from './calcularDimensionamentoGrupoB.types';
import { PotenciaNaoEnviadaError } from './potencia-nao-enviada-error';

export function calcularDimensionamentoGrupoB({
  consumoMedio,
  potenciaPaineis,
  potenciaKits,
  perdaSistema,
  largura,
  altura,
  irradiacaoSolar,
  diasNoMes,
  aumentoConsumo,
  quantidadePaineis,
}: ICalcularDimensionamentoGrupoBPayload): ICalcularDimensionamentoGrupoBRetorno {
  if (!potenciaPaineis && !potenciaKits) throw new PotenciaNaoEnviadaError();

  const consumoMedioTotal = consumoMedio + consumoMedio * (aumentoConsumo / 100 || 0);
  let geracaoMensalPorPainel = 0;
  let auxQuantidadePaineis = quantidadePaineis || 0;
  let geracaoSistema = 0;
  let potenciaSistema = 0;
  let areaMinima = 0;

  if (potenciaPaineis) {
    const _gMensalBruto = (potenciaPaineis / 1000) * (irradiacaoSolar / 1000) * diasNoMes * (1 - perdaSistema / 100);
    geracaoMensalPorPainel = Number(_gMensalBruto.toFixed(2));

    if (!quantidadePaineis) {
      auxQuantidadePaineis = Math.ceil(consumoMedioTotal / geracaoMensalPorPainel);
    }

    geracaoSistema = Number((geracaoMensalPorPainel * auxQuantidadePaineis).toFixed(2));

    potenciaSistema = Number((auxQuantidadePaineis * (potenciaPaineis / 1000)).toFixed(2));

    areaMinima = Number((largura * altura * auxQuantidadePaineis).toFixed(2));
  } else if (potenciaKits) {
    geracaoSistema = Number(
      (potenciaKits * (irradiacaoSolar / 1000) * diasNoMes * (1 - perdaSistema / 100)).toFixed(2)
    );

    potenciaSistema = potenciaKits;
  }

  return {
    consumoMedioTotal,
    geracaoMensalPorPainel,
    quantidadePaineis: auxQuantidadePaineis,
    geracaoSistema,
    potenciaSistema,
    areaMinima,
  };
}
