import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Divider, Grid } from '@s_mart/core';
import { Searchable } from '@s_mart/form';
import { required } from '@s_mart/rules';

import { ListagemVendedoresProposta } from 'api/propostas/propostas.types';
import CadastrarClienteConteudo from 'components/cadastrarClienteConteudo/cadastrarClienteConteudo';
import useConfiguracoes from 'store/configuracoes/configuracoes';

import useGeradorDePropostaCliente from './useGeradorDePropostaCliente';

const GeradorDePropostaCliente = ({ vendedores }: { vendedores?: { data: ListagemVendedoresProposta[] } }) => {
  const { watch } = useFormContext();
  const { handleBuscarClienteSelecionado } = useGeradorDePropostaCliente();
  const { recursosContratados } = useConfiguracoes();

  useEffect(() => {
    handleBuscarClienteSelecionado();
  }, [handleBuscarClienteSelecionado]);

  return (
    <Grid container spacing={2}>
      <CadastrarClienteConteudo />
      {watch('cadastroAvancado') && (
        <Grid item xs={12}>
          <Divider orientation='horizontal' />
        </Grid>
      )}
      <Grid item xs={12} sm={recursosContratados?.crm ? 5 : 6}>
        <Searchable
          name='vendedor'
          label='Vendedor responsável'
          placeholder='Pesquise e selecione o vendedor'
          required
          disableClearable
          options={
            vendedores?.data?.map((item: ListagemVendedoresProposta) => ({
              label: item.nome,
              value: String(item.codigo),
            })) || []
          }
          rules={required}
        />
      </Grid>
    </Grid>
  );
};

export default GeradorDePropostaCliente;
