import { memo } from 'react';

import { Typography } from '@s_mart/core';
import { TextField } from '@s_mart/form';
import { composeValidators, maiorQue, required } from '@s_mart/rules';

const FieldPotenciaPaineis = () => {
  return (
    <TextField
      name='potenciaPaineis'
      label='Potência'
      InputProps={{ endAdornment: <Typography variant='bodySM'>W</Typography> }}
      mask='numero'
      required
      rules={composeValidators([maiorQue(0), required])}
      placeholder='0,00'
    />
  );
};

export default memo(FieldPotenciaPaineis);
