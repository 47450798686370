import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface IBottomBarContext {
  showMenu: boolean;
  handleShowMenu: (show: boolean) => void;
  paginaAtual: (path: string) => boolean;
  handleNavigate: (path: string) => void;
}

const BottomBarContext = React.createContext({} as IBottomBarContext);

const BottomBarProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const _navigate = useNavigate();

  const [showMenu, _setShowMenu] = React.useState(false);

  const paginaAtual = React.useCallback((path: string) => window?.location?.pathname === `/${path}`, []);

  const handleShowMenu = (show: boolean) => {
    _setShowMenu(show);
  };

  const handleNavigate = (path: string) => {
    if (!paginaAtual(path)) {
      _navigate(`/${path}`);
    }
    handleShowMenu(false);
  };

  return (
    <BottomBarContext.Provider
      value={{
        showMenu,
        handleShowMenu,
        paginaAtual,
        handleNavigate,
      }}
    >
      {children}
    </BottomBarContext.Provider>
  );
};

const useBottomBarContext = () => {
  const context = React.useContext<IBottomBarContext>(BottomBarContext);

  if (!context) {
    throw new Error('useBottomBarContext deve ser usado dentro de um <BottomBarProvider />');
  }

  return context;
};

export { BottomBarContext, BottomBarProvider, useBottomBarContext };
