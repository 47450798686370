import { useCallback, useEffect, useState } from 'react';

import * as vendedoresApi from 'api/vendedores/vendedores';
import type { BuscarVendedorDados } from 'api/vendedores/vendedores.types';
import useApp from 'store/app/app';

import type { VendedorFormDialogValues } from './vendedorFormDialog.types';
import useVendedorFormDialog from './store/vendedorFormDialog.store';
import useSaidaSemSalvarDialog from '../saidaSemSalvarDialog/store/saidaSemSalvarDialog.store';

const useVendedorFormDialogFunctions = () => {
  const { setLoading, handleError } = useApp();
  const [initialState, _setInitialState] = useState<VendedorFormDialogValues>({
    nome: '',
    telefone: '',
    email: '',
  });

  const { vendedorFormDialog, setVendedorFormDialog } = useVendedorFormDialog();
  const { setSaidaSemSalvarDialog } = useSaidaSemSalvarDialog();

  const onClose = useCallback(() => {
    setVendedorFormDialog({ aberto: false, codigoVendedor: undefined });
  }, [setVendedorFormDialog]);

  const handleClose = (isDirty?: boolean) => {
    if (isDirty) {
      setSaidaSemSalvarDialog({
        aberto: true,
        onClose: onClose,
        texto: 'Notamos que você está saindo deste cadastro de vendedor sem salvar.',
      });
    } else {
      onClose();
    }
  };

  const buscarDadosVendedor = useCallback(
    async (codigoVendedor: number) => {
      setLoading(true);
      try {
        const { data }: { data: { data: BuscarVendedorDados } } = await vendedoresApi.buscarVendedor(codigoVendedor);

        _setInitialState(data.data);
      } catch (err) {
        handleError(err);
      }

      setLoading(false);
    },
    [setLoading, handleError]
  );

  const handleTipoPessoa = useCallback((tipoPessoa: number) => {
    if (tipoPessoa === 1) {
      return true;
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    if (vendedorFormDialog?.codigoVendedor) {
      buscarDadosVendedor(vendedorFormDialog.codigoVendedor);
    }
  }, [vendedorFormDialog.codigoVendedor, buscarDadosVendedor]);

  return { initialState, handleTipoPessoa, handleClose };
};

export default useVendedorFormDialogFunctions;
