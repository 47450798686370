import styled from '@emotion/styled';

import { colorPalette } from '@s_mart/tokens';

export const DivExternaBottomBar = styled.div`
  display: flex;
  position: fixed;
  background: #ffffff;
  height: 74px;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.09);

  z-index: 19;
`;

export const DivItemBottomBar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: ${colorPalette.neutral[20]};
  }
`;
