import { create } from 'zustand';

import { AppStates, AppStore } from './app.types';
import { handleError } from './handleError/handleError';

const initialState: AppStates = {
  loading: false,
  mensagemLoading: null,
  loadingGerador: undefined,
  notification: null,
  lojaDeRecursos: undefined,
  semConexao: false,
};

const useApp = create<AppStore>((set) => ({
  ...initialState,
  setLoading: (loading) => set((state) => ({ ...state, loading })),
  setMensagemLoading: (mensagemLoading) => set((state) => ({ ...state, mensagemLoading })),
  setLoadingGerador: (loadingGerador) =>
    set((state) => ({ ...state, loadingGerador: { ...state.loadingGerador, ...loadingGerador } })),
  setNotification: (notification) => {
    set((state) => ({ ...state, notification }));
    if (notification) {
      setTimeout(() => set((state) => ({ ...state, notification: null })), notification.autoHideDuration ?? 3000);
    }
  },
  handleError: (error) => handleError({ error, set }),
  setLojaDeRecursos: (lojaDeRecursos) => set((state) => ({ ...state, lojaDeRecursos })),
  setSemConexao: (semConexao) => set((state) => ({ ...state, semConexao })),
  limparApp: () => {
    set(initialState);
  },
}));

export default useApp;
