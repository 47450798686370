import styled from '@emotion/styled';

export const FolhaA4 = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(29.7cm - 64px);
  width: calc(21cm - 64px);
  background-color: white;
  position: relative;
`;
