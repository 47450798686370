import styled from '@emotion/styled';

import { Button } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';

export const ButtonTipoGarantia = styled(Button)`
  background: ${colorPalette.neutral[30]};
  border-radius: 6px;
  min-height: 36px;
  height: 36px;
  min-width: 40px;

  &:hover {
    background: ${colorPalette.neutral[40]};
  }
`;

export const DivHoverLIcon = styled.div`
  height: 32px;
  border-radius: 50%;
  outline: 1px solid ${colorPalette.neutral[50]};

  &:hover {
    background: ${colorPalette.neutral[40]};
  }
`;

export const DivAlertaFieldsNome = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background: ${colorPalette.yellow[75]};
  border-radius: 6px;
  padding: 2px 4px;
`;
