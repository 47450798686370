import { useMemo } from 'react';

import { postNps } from 'api/nps/nps';
import type { NpsValues } from 'api/nps/nps.types';
import useApp from 'store/app/app';
import useAuth from 'store/auth/auth';

import { useNPSContext } from './npsContext';
import type { NPSOnFinalizarValues } from './nps.types';

const useNps = () => {
  const { profile } = useAuth();
  const { handleError, setNotification } = useApp();
  const { setMostrarNps } = useNPSContext();

  const _perfil = useMemo(
    () => ({
      codigo_empresa: profile?.emp,
      codigo_sistema: profile?.sis || 6,
      codigo_usuario: profile?.sub,
      empresa_cnpj: String(profile?.cpf_cnpj),
      sistema: 'Solarfy',
      usuario: profile?.nome,
    }),
    [profile]
  );

  const _enviarNPS = (query: NpsValues) => {
    try {
      postNps(query);
    } catch (err) {
      handleError(err);
    }
  };

  const onClose = (event?: any) => {
    if (event?.target?.tagName === 'svg') {
      const query: NpsValues = {
        ..._perfil,
        clicou_no_nps: true,
      };
      _enviarNPS(query);
    }

    setMostrarNps(false);
  };

  const onChooseGradeAndContinue = (grade?: number) => {
    const query: NpsValues = { ..._perfil, clicou_no_nps: true, nota: grade };
    _enviarNPS(query);
  };

  const onSelectGrade = (grade?: number) => {
    const query: NpsValues = { ..._perfil, clicou_no_nps: true, nota: grade };
    _enviarNPS(query);
  };

  const handleSubmit = (values: NPSOnFinalizarValues) => {
    const query: NpsValues = {
      ..._perfil,
      clicou_no_nps: true,
      nota: values.nota,
      comentario: values?.feedback || undefined,
    };
    _enviarNPS(query);
    setNotification({
      title: 'Sugestões Enviadas',
      message: 'Agradecemos muito pela sua sugestão',
    });

    setMostrarNps(false);
  };

  return { onClose, onChooseGradeAndContinue, onSelectGrade, handleSubmit };
};

export default useNps;
