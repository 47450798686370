import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { buscarHistoricoDeAcoesDasPropostas } from 'api/historicoAcoes/historicoAcoes';
import type { HistoricoAcoesTypes } from 'api/historicoAcoes/historicoAcoes.types';
import useApp from 'store/app/app';

import useVisualizarListaPropostasDialog from '../store/visualizarListaPropostasDialog.store';

const useVisualizarListaPropostasDialogHistorico = () => {
  const { handleError } = useApp();
  const { visualizarListaPropostasDialog } = useVisualizarListaPropostasDialog();

  const [historicoAcoes, _setHistoricoAcoes] = useState<HistoricoAcoesTypes>();

  const handleBuscarHistoricoAcoes = useCallback(async () => {
    if (visualizarListaPropostasDialog?.codigoCliente) {
      try {
        const { data } = await buscarHistoricoDeAcoesDasPropostas(visualizarListaPropostasDialog.codigoCliente);

        _setHistoricoAcoes(data.data);
      } catch (err) {
        handleError(err);
      }
    }
  }, [visualizarListaPropostasDialog?.codigoCliente, handleError]);

  const handleDataHistorico = useCallback((data: string) => {
    if (dayjs(data).diff(dayjs(), 'hours') * -1 <= 24) {
      return dayjs(data).diff(dayjs(), 'hours') * -1 < 1
        ? `${dayjs(data).diff(dayjs(), 'minutes') * -1} min`
        : dayjs(data).diff(dayjs(), 'hours') * -1 === 1
        ? `${dayjs(data).diff(dayjs(), 'hours') * -1} hora`
        : `${dayjs(data).diff(dayjs(), 'hours') * -1} horas`;
    } else if (dayjs(data).diff(dayjs(), 'days') * -1 <= 30) {
      return dayjs(data).diff(dayjs(), 'days') * -1 < 2
        ? `${dayjs(data).diff(dayjs(), 'days') * -1} dia`
        : `${dayjs(data).diff(dayjs(), 'days') * -1} dias`;
    } else {
      return dayjs(data).diff(dayjs(), 'months') * -1 < 2
        ? `${dayjs(data).diff(dayjs(), 'months') * -1} mês`
        : `${dayjs(data).diff(dayjs(), 'months') * -1} meses`;
    }
  }, []);

  useEffect(() => {
    handleBuscarHistoricoAcoes();
  }, [handleBuscarHistoricoAcoes]);

  return { historicoAcoes, handleDataHistorico };
};

export default useVisualizarListaPropostasDialogHistorico;
