import { ComponentType, lazy } from 'react';

const lazyWithRetry = <T extends ComponentType<any>>(
  componentImport: Parameters<typeof lazy<T>>[0],
  componentName: string
) => {
  const treatImport = () =>
    new Promise<{ default: T }>((resolve, reject) => {
      const storageItemName = `refreshed-lazy-chunks`;

      const refreshedChunks = JSON.parse(window.sessionStorage.getItem(storageItemName) || '{}');

      componentImport()
        .then((component) => {
          refreshedChunks[componentName] = false;

          resolve(component);
        })
        .catch((error) => {
          if (refreshedChunks[componentName]) {
            reject(error);
          } else {
            refreshedChunks[componentName] = true;

            window.location.reload();
          }
        })
        .finally(() => {
          window.sessionStorage.setItem(storageItemName, JSON.stringify(refreshedChunks));
        });
    });

  return lazy<T>(treatImport);
};

export default lazyWithRetry;
