import styled from '@emotion/styled';

import { toRem } from '@s_mart/utils';

export const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${toRem(4)};
  padding: ${toRem(8)};
  border-radius: ${toRem(6)};

  text-align: center;

  background-color: rgba(70, 70, 70);
  color: white;

  div.leftArrow {
    width: 10px;
    height: 10px;

    transform: rotate(45deg);
    background-color: rgba(70, 70, 70);

    position: absolute;
    bottom: -5px;
    left: calc(50% - 5px);
  }
`;
