import { AxiosRequestConfig } from 'axios';

import { api } from 'api/api';
import { urls } from 'api/urls';

import type {
  ListarLeadsResponse,
  AlterarEtapaLead,
  ListarHistoricoLeadResponse,
  ListarPropostasLeadResponse,
  ParametrosListarLeads,
  INovoLead,
} from './leads.types';

const listarLeads = async (params?: AxiosRequestConfig<ParametrosListarLeads>['params']): ListarLeadsResponse =>
  await api.get(`${urls.leads}`, { params });

const cadastrarLead = async (params?: INovoLead) => await api.post(`${urls.leads}`, params);

const alterarEtapaLead = async ({ codigoEtapaDestino, codigoLead }: AlterarEtapaLead) =>
  await api.patch(`${urls.leads}/${codigoLead}/etapa`, { codigoEtapaDestino });

const listarHistoricoLead = async (
  codigoLead: number,
  params?: AxiosRequestConfig['params']
): ListarHistoricoLeadResponse => await api.get(`${urls.leads}/${codigoLead}/historico`, { params });

const listarPropostasLead = async (codigoLead: number): ListarPropostasLeadResponse =>
  await api.get(`${urls.leads}/${codigoLead}/propostas`);

const excluirLead = async (codigoLead: number) => await api.delete(`${urls.leads}/${codigoLead}`);

export { listarLeads, cadastrarLead, alterarEtapaLead, listarHistoricoLead, listarPropostasLead, excluirLead };
