import { useCallback, useMemo } from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import type { GridColDef } from '@mui/x-data-grid';

import { LIcon, Typography, Chip } from '@s_mart/core';
import { Checkbox } from '@s_mart/form';
import { valor } from '@s_mart/masks';
import { lineBuilding, lineHome, lineListChevronDown, lineUser } from '@s_mart/regular-icons';
import { colorPalette } from '@s_mart/tokens';

import type { ListarPropostasTypes } from 'api/propostas/propostas.types';
import AcoesListagensMenu from 'components/acoesListagensMenu/acoesListagensMenu';
import { Options } from 'shared/global';
import useAuth from 'store/auth/auth';

import type { IPropostasDataTableProps } from './propostasDatatable.types';
import usePropostasFunctions from '../usePropostasFunctions';

interface UsePropostasDataTableProps extends IPropostasDataTableProps {
  fetchNextPage?: UseInfiniteQueryResult['fetchNextPage'];
  param?: Options;
}

const usePropostasDataTable = ({
  painelCliente,
  selecionarPropostaDialog,
  fetchNextPage,
  param,
}: UsePropostasDataTableProps) => {
  const { profile } = useAuth();
  const { handleInformacoesDoChip, handleMenuAcoes, handleAlertaVencimentoProposta, handleDataPropostaVisualizada } =
    usePropostasFunctions({ painelCliente });
  const { watch, setValue } = useFormContext();

  const handleRequestOrdenacao = useCallback(
    (nome: string) => {
      if (param?.order === 'asc') {
        fetchNextPage?.({ pageParam: { ...param, page: 1, sort: nome, order: 'desc' } });
      } else {
        fetchNextPage?.({ pageParam: { ...param, page: 1, sort: nome, order: 'asc' } });
      }
    },
    [param]
  );

  const _colunas: GridColDef<ListarPropostasTypes>[] = [
    {
      field: 'checkbox',
      headerName: '',
      valueFormatter: ({ value }) => (
        <Checkbox
          name='checkbox'
          checked={watch('checkbox') === value}
          onChange={() => {
            if (watch()?.checkbox === value) {
              setValue('checkbox', null);
            } else {
              setValue('checkbox', value);
            }
          }}
        />
      ),
      minWidth: 60,
      maxWidth: 60,
    },
    {
      field: 'numero',
      headerName: 'Nº',
      sortable: !painelCliente && !selecionarPropostaDialog,
      valueFormatter: ({ value }) => <Typography variant='bodySM'>{String(value).padStart(2, '0')}</Typography>,
      minWidth: 66,
      maxWidth: 66,
    },
    {
      field: 'cliente',
      headerName: 'Cliente',
      sortable: !painelCliente && !selecionarPropostaDialog,
      valueFormatter: ({ value, api, id }) => {
        const _values = api.getRow(id || 0);

        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <div style={{ display: 'flex', gap: '4px' }}>
              <LIcon icon={_values.tipoCliente === 1 ? lineBuilding : lineHome} removeMargin />
              <Typography variant='bodySM' whiteSpace='nowrap'>
                {value}
              </Typography>
            </div>
            {_values.tipoCliente === 1 && _values.nomeResponsavel && (
              <div style={{ display: 'flex', gap: '4px' }}>
                <LIcon icon={lineUser} removeMargin color={colorPalette.neutral[600]} />
                <Typography variant='bodyXS' whiteSpace='nowrap' color={colorPalette.neutral[600]}>
                  {_values.nomeResponsavel}
                </Typography>
              </div>
            )}
          </div>
        );
      },
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'valorTotal',
      headerName: 'Valor da proposta',
      sortable: !painelCliente && !selecionarPropostaDialog,
      valueFormatter: ({ value }) => <Typography variant='bodySM'>{valor.format(value)}</Typography>,
      minWidth: 150,
      flex: 0.3,
    },
    {
      field: 'valor',
      headerName: 'Valor da proposta',
      valueFormatter: ({ value }) => <Typography variant='bodySM'>{valor.format(value)}</Typography>,
      minWidth: 115,
      flex: 0.3,
    },
    {
      field: 'dataGeracao',
      headerName: 'Gerada em',
      sortable: !painelCliente && !selecionarPropostaDialog,
      valueFormatter: ({ value }) => <Typography variant='bodySM'>{dayjs(value).utc().format('DD/MM/YY')}</Typography>,
      minWidth: 110,
      flex: 0.3,
    },
    {
      field: 'dataVisualizacao',
      headerName: 'Visualizada há',
      sortable: !painelCliente && !selecionarPropostaDialog,
      valueFormatter: ({ value }) => handleDataPropostaVisualizada(value),
      minWidth: 130,
      flex: 0.3,
    },
    {
      field: 'validade',
      headerName: 'Validade',
      sortable: !painelCliente && !selecionarPropostaDialog,
      valueFormatter: ({ value, api, id }) => {
        const _values = api.getRow(id || 0);

        return handleAlertaVencimentoProposta({
          dataValidade: value,
          situacaoProposta: _values.situacao,
        });
      },
      minWidth: 108,
      flex: 0.3,
    },
    {
      field: 'dataValidade',
      headerName: 'Validade',
      valueFormatter: ({ value, api, id }) => {
        const _values = api.getRow(id || 0);

        return handleAlertaVencimentoProposta({
          dataValidade: value,
          situacaoProposta: _values.situacao,
        });
      },
      minWidth: 108,
      flex: 0.3,
    },
    {
      field: 'vendedor',
      headerName: 'Vendedor',
      sortable: !painelCliente && !selecionarPropostaDialog,
      valueFormatter: ({ value }) => (
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography variant='bodySM'>{value}</Typography>
        </div>
      ),
      minWidth: 175,
      flex: 0.6,
    },
    {
      field: 'situacao',
      headerName: 'Status',
      sortable: !painelCliente && !selecionarPropostaDialog,
      valueFormatter: ({ value }) => {
        const { texto, cor } = handleInformacoesDoChip(Number(value));

        return (
          <Chip
            label={
              <Typography variant='titleXXS' color={colorPalette.neutral[0]}>
                {texto}
              </Typography>
            }
            style={{ backgroundColor: cor, width: '60px', justifyContent: 'center' }}
            size='small'
          />
        );
      },
      minWidth: 84,
      flex: 0.3,
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      valueFormatter: ({ api, id }) => {
        const values = api.getRow(id || 0);

        return <AcoesListagensMenu acoes={handleMenuAcoes(values)} icone={lineListChevronDown} tamanhoIcone='32px' />;
      },
      minWidth: 60,
      maxWidth: 60,
    },
  ];

  const colunas = useMemo(() => {
    if (painelCliente) {
      return _colunas.filter((coluna) => {
        if (coluna.field === 'cliente') return false;
        if (coluna.field === 'vendedor') return false;
        if (coluna.field === 'checkbox') return false;
        if (coluna.field === 'valorTotal') return false;
        if (coluna.field === 'validade') return false;

        return true;
      });
    } else if (selecionarPropostaDialog) {
      return _colunas.filter((coluna) => {
        if (coluna.field === 'cliente') return false;
        if (coluna.field === 'vendedor') return false;
        if (coluna.field === 'acoes') return false;
        if (coluna.field === 'valorTotal') return false;
        if (coluna.field === 'validade') return false;

        return true;
      });
    } else {
      return _colunas.filter((coluna) => {
        if (coluna.field === 'checkbox') return false;
        if (coluna.field === 'valor') return false;
        if (coluna.field === 'dataValidade') return false;
        if (coluna.field === 'acoes' && profile?.tipo === 2) return false;

        return true;
      });
    }
  }, [_colunas]);

  return { colunas, handleRequestOrdenacao };
};

export default usePropostasDataTable;
