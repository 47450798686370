import * as React from 'react';

interface IConsumoMensalContext {
  mesesValorZerado: number[];
  handleEsconderErroValorZerado: () => void;
  handleMostrarErroValorZerado: (meses: number[]) => void;
}

const ConsumoMensalContext = React.createContext({} as IConsumoMensalContext);

const ConsumoMensalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [mesesValorZerado, setMesesValorZerado] = React.useState<number[]>([]);

  const handleEsconderErroValorZerado = () => {
    setMesesValorZerado([]);
  };

  const handleMostrarErroValorZerado = (meses: number[]) => {
    setMesesValorZerado(meses);
  };

  return (
    <ConsumoMensalContext.Provider
      value={{ mesesValorZerado, handleEsconderErroValorZerado, handleMostrarErroValorZerado }}
    >
      {children}
    </ConsumoMensalContext.Provider>
  );
};

const useConsumoMensalContext = () => {
  const context = React.useContext<IConsumoMensalContext>(ConsumoMensalContext);

  if (!context) {
    throw new Error('useConsumoMensalContext deve ser usado dentro de um <ConsumoMensalProvider />');
  }

  return context;
};

export { ConsumoMensalContext, ConsumoMensalProvider, useConsumoMensalContext };
