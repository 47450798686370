import { memo, Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Grid, LIcon } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { lineTrashAlt } from '@s_mart/regular-icons';

import useGeradorDeProposta from 'shared/modais/geradorDeProposta/store/geradorDeProposta.store';
import useConfiguracoes from 'store/configuracoes/configuracoes';

import { DivHoverLIcon } from '../fields.styles';
import AutoCompleteFieldNomeInversores from './content/autoCompleteFieldNomeInversores';
import FieldPotenciaInversores from './content/fieldPotenciaInversores';
import FieldGarantiaInversores from './content/fieldGarantiaInversores';
import FieldQuantidadeInversores from './content/fieldQuantidadeInversores';
import FieldValorVendaInversores from './content/fieldValorVendaInversores';

const FieldsInversores = () => {
  const { geradorDeProposta } = useGeradorDeProposta();
  const { recursosContratados } = useConfiguracoes();
  const telaMenorQue760 = useMediaQuery(`(max-width: 760px)`);

  const { control } = useFormContext();
  const _fieldArrayInversores = useFieldArray({ name: 'inversores', control });

  return (
    <Grid container spacing={2}>
      {_fieldArrayInversores?.fields?.map((field, index: number) => (
        <Fragment key={field.id}>
          <Grid
            item
            xs={
              Number(_fieldArrayInversores?.fields?.length) > 1 || geradorDeProposta?.caminhoGerador === 'kits'
                ? undefined
                : 12
            }
            style={{
              display: 'flex',
              width:
                Number(_fieldArrayInversores?.fields?.length) > 1 || geradorDeProposta?.caminhoGerador === 'kits'
                  ? 'calc(100% - 49px)'
                  : undefined,
            }}
          >
            <Grid container spacing={2}>
              <AutoCompleteFieldNomeInversores index={index} />
              {telaMenorQue760 ? (
                <>
                  <Grid item xs={6}>
                    <FieldPotenciaInversores index={index} />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldQuantidadeInversores index={index} />
                  </Grid>
                  <Grid item xs={6}>
                    <FieldGarantiaInversores index={index} />
                  </Grid>
                  {!!recursosContratados?.['valor-de-venda-unitario'] && (
                    <Grid item xs={6}>
                      <FieldValorVendaInversores index={index} />
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item sm={6} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={recursosContratados?.['valor-de-venda-unitario'] ? 3 : 4}>
                      <FieldPotenciaInversores index={index} />
                    </Grid>
                    <Grid item xs={recursosContratados?.['valor-de-venda-unitario'] ? 2.5 : 4}>
                      <FieldQuantidadeInversores index={index} />
                    </Grid>
                    <Grid item xs={recursosContratados?.['valor-de-venda-unitario'] ? 2.75 : 4}>
                      <FieldGarantiaInversores index={index} />
                    </Grid>
                    {!!recursosContratados?.['valor-de-venda-unitario'] && (
                      <Grid item xs={3.75}>
                        <FieldValorVendaInversores index={index} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {(Number(_fieldArrayInversores?.fields?.length) > 1 || geradorDeProposta?.caminhoGerador === 'kits') && (
            <Grid item style={{ display: 'flex', marginTop: 22 }}>
              <DivHoverLIcon>
                <LIcon
                  icon={lineTrashAlt}
                  removeMargin
                  size='32px'
                  style={{
                    cursor: 'pointer',
                    alignSelf:
                      telaMenorQue760 && !recursosContratados?.['valor-de-venda-unitario'] ? 'flex-end' : 'center',
                  }}
                  onClick={() => _fieldArrayInversores?.remove(index)}
                />
              </DivHoverLIcon>
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  );
};

export default memo(FieldsInversores);
