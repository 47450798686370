import { api } from '../api';
import { urls } from 'api/urls';

import type {
  CadastrarEditarVendedorTypes,
  ListarVendedoresTypes,
  VincularVendedorAoUsuarioTypes,
} from './vendedores.types';

const listarVendedores = async (params: ListarVendedoresTypes) =>
  await api.get(`${urls.vendedores}`, { params: params });

const cadastrarVendedor = async (payload: CadastrarEditarVendedorTypes) =>
  await api.post(`${urls.vendedores}`, payload);

const buscarVendedor = async (id: number) => await api.get(`${urls.vendedores}/${id}`);

const deletarVendedor = async (id: number) => await api.delete(`${urls.vendedores}/${id}`);

const editarVendedor = async (id: number, payload: CadastrarEditarVendedorTypes) =>
  await api.patch(`${urls.vendedores}/${id}`, payload);

const ativarInativarVendedor = async (id: number, situacao: boolean) =>
  await api.patch(`${urls.vendedores}/${id}/alterar-situacao`, undefined, {
    params: { situacao: situacao },
  });

const vincularVendedorAoUsuario = async (id: number, payload: VincularVendedorAoUsuarioTypes) =>
  await api.patch(`${urls.vendedores}/${id}/vincular-usuario`, payload, {});

const buscarCodigoVendedor = async (idUsuario: number) =>
  await api.get(`${urls.vendedores}/usuario/${idUsuario}`);

export {
  listarVendedores,
  cadastrarVendedor,
  buscarVendedor,
  buscarCodigoVendedor,
  deletarVendedor,
  editarVendedor,
  ativarInativarVendedor,
  vincularVendedorAoUsuario,
};
