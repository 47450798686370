import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Grid, Typography, Divider, LIcon } from '@s_mart/core';
import { TextField } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { composeValidators, maiorQue, menorQue, required } from '@s_mart/rules';
import { lineExclamationTriangle } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import useApp from 'store/app/app';

import { DivResultadoDimensionamento, SSwitch } from './baseDeCalculoConsumoFields.styles';
import useBaseDeCalculoConsumoFields from './useBaseDeCalculoConsumoFields';

const BaseDeCalculoConsumoFields = () => {
  const { setNotification } = useApp();
  const { setValue } = useFormContext();
  const [_tipoLigacao, _aumentoConsumoToggle, _aumentoConsumo, _perdaSistema] = useWatch({
    name: ['tipoLigacao', 'aumentoConsumoToggle', 'aumentoConsumo', 'perdaSistema'],
  });
  const telaMenorQue400 = useMediaQuery(`(max-width: 400px)`);
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);
  const telaMenorQue680 = useMediaQuery(`(max-width: 680px)`);

  const { controleConteudoRenderizado } = useBaseDeCalculoConsumoFields();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={4}>
        <TextField
          name='potenciaPaineis'
          label={`Potência${telaMenorQue400 === false ? ' dos' : ''} painéis`}
          required
          rules={composeValidators([maiorQue(0), required])}
          mask='numero'
          InputProps={{
            endAdornment: <Typography variant='bodySM'>W</Typography>,
          }}
          size='small'
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextField
          name='perdaSistema'
          label='Perda do sistema'
          required
          rules={composeValidators([maiorQue(0), menorQue(50), required])}
          mask='porcentagem'
          size='small'
        />
        {!!_perdaSistema && Number(_perdaSistema) < 10 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              background: colorPalette.yellow[75],
              borderRadius: '6px',
              padding: '2px 4px',
            }}
          >
            <LIcon icon={lineExclamationTriangle} removeMargin size='18px' color={colorPalette.yellow[300]} />
            <Typography variant='bodyXS'>Recomendado 10% ou mais</Typography>
          </div>
        )}
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField
          name='larguraPainel'
          label='Largura'
          required
          rules={composeValidators([maiorQue(0), required])}
          mask='decimal'
          InputProps={{
            endAdornment: <Typography variant='bodySM'>m</Typography>,
          }}
          size='small'
        />
      </Grid>
      <Grid item xs={6} sm={2}>
        <TextField
          name='alturaPainel'
          label='Altura'
          required
          rules={composeValidators([maiorQue(0), required])}
          mask='decimal'
          InputProps={{
            endAdornment: <Typography variant='bodySM'>m</Typography>,
          }}
          size='small'
        />
      </Grid>
      {_tipoLigacao?.value !== '3' && (
        <Grid
          item
          xs={12}
          style={{ display: 'flex', flexDirection: telaMenorQue400 ? 'column' : 'row', alignItems: 'center' }}
        >
          <SSwitch
            name='aumentoConsumoToggle'
            label='Aumento de consumo previsto'
            checked={_aumentoConsumoToggle || false}
            onClick={() => {
              if (_aumentoConsumoToggle) {
                setValue('aumentoConsumo', null);
                if (_aumentoConsumo) {
                  setNotification({ title: 'Aumento de consumo previsto desconsiderado!', variant: 'warning' });
                }
              }
            }}
          />
          {_aumentoConsumoToggle && (
            <TextField
              name='aumentoConsumo'
              placeholder='10%'
              mask='porcentagem'
              size='small'
              sx={{ maxWidth: '80px' }}
            />
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider orientation='horizontal' />
      </Grid>
      <Grid item xs={12} sm={telaMenorQue680 ? 6 : 3}>
        <DivResultadoDimensionamento mobile={Boolean(telaMenorQue600)}>
          <Typography variant='bodyXS'>Geração do sistema</Typography>
          <Typography variant='titleXS'>{controleConteudoRenderizado()?.geracao} kWh</Typography>
        </DivResultadoDimensionamento>
      </Grid>
      <Grid item xs={12} sm={telaMenorQue680 ? 6 : 3}>
        <DivResultadoDimensionamento mobile={Boolean(telaMenorQue600)}>
          <Typography variant='bodyXS'>Qtd de painéis</Typography>
          <Typography variant='titleXS'>{controleConteudoRenderizado()?.quantidadePaineis}</Typography>
        </DivResultadoDimensionamento>
      </Grid>
      <Grid item xs={12} sm={telaMenorQue680 ? 6 : 3}>
        <DivResultadoDimensionamento mobile={Boolean(telaMenorQue600)}>
          <Typography variant='bodyXS'>Potência do sistema</Typography>
          <Typography variant='titleXS'>{controleConteudoRenderizado()?.potenciaSistema} kWp</Typography>
        </DivResultadoDimensionamento>
      </Grid>
      <Grid item xs={12} sm={telaMenorQue680 ? 6 : 3}>
        <DivResultadoDimensionamento mobile={Boolean(telaMenorQue600)}>
          <Typography variant='bodyXS'>Área mínima</Typography>
          <Typography variant='titleXS'>{controleConteudoRenderizado()?.areaMinima} m²</Typography>
        </DivResultadoDimensionamento>
      </Grid>
    </Grid>
  );
};

export default memo(BaseDeCalculoConsumoFields);
