import { PlanosDialog as PlanosDialogSmart } from '@s_mart/minha-conta';

import { apiForInterceptors } from 'api/api';
import { telefoneSuporte } from 'config/constantes';
import usePlanosDialog from './store/planosDialog.store';
import usePlanosDialogFunctions from './usePlanosDialog';

const PlanosDialog = () => {
  const { planosParaMostrar, onContratar } = usePlanosDialogFunctions();
  const { setPlanosDialog } = usePlanosDialog();

  return (
    <PlanosDialogSmart
      habilitarPlanoAtual
      axiosInstance={apiForInterceptors}
      telefoneSuporte={`55${telefoneSuporte.replace(/[^0-9]/g, '')}`}
      esconderPeriodicidade
      onClose={() => setPlanosDialog({ aberto: false })}
      mostrarPlanosDeterminados={planosParaMostrar()}
      onContratarSuccess={onContratar}
    />
  );
};

export default PlanosDialog;
