import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colorPalette } from '@s_mart/tokens';
import { toRem } from '@s_mart/utils';

import { StatusType } from './botaoAppBar.types';

const CssBotaoBase = (status: StatusType, disabled: boolean) => css`
  display: flex;
  align-items: center;

  cursor: ${!disabled && 'pointer'};
  filter: ${disabled && 'invert(75%)'};

  background-color: ${status >= 4 ? colorPalette.red[75] : colorPalette.neutral[0]};
  color: ${colorPalette.neutral[900]};
  width: 31px;

  border: 1px solid ${status >= 3 ? colorPalette.red[300] : colorPalette.neutral[50]};
  border-radius: 4px;
`;

const Transition = css`
  -webkit-transition: all 0.9s;
  -moz-transition: all 0.9s;
  transition: all 0.9s;
`;

export const BotaoAberto = styled.div<{ status: StatusType; disabled: boolean }>`
  ${({ status, disabled }) => CssBotaoBase(status, disabled)}
  overflow: hidden;

  ${Transition}
  width: 100%;
  padding-right: 4px;

  &.hover {
    padding-right: 4px;
    width: 100%;
    ${Transition}
  }
`;

export const BotaoFechado = styled.div<{ status: StatusType; disabled: boolean }>`
  ${({ status, disabled }) => CssBotaoBase(status, disabled)};
`;

export const IconeBotao = styled.div`
  position: relative;
  margin-top: 2px;
`;

export const TextoBotao = styled.div`
  white-space: nowrap;
`;

export const Badge = styled.div`
  position: absolute;
  top: 0px;
  right: 3px;
  width: ${toRem(10)};
  height: ${toRem(10)};
  border-radius: 50%;
  background-color: ${colorPalette.red[300]};
`;
