import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextField } from '@s_mart/form';
import { composeValidators, required } from '@s_mart/rules';

import useConfiguracoes from 'store/configuracoes/configuracoes';

import { useFields } from '../../useFields';

const FieldQuantidadePaineis = () => {
  const methods = useFormContext();
  const { recursosContratados } = useConfiguracoes();
  const { handleCalcularValorTotal } = useFields();

  return (
    <TextField
      name='quantidadePaineis'
      label='Qtd'
      value={methods.watch('quantidadePaineis')}
      placeholder='1'
      mask='numero'
      onInputChange={() => {
        if (recursosContratados?.['valor-de-venda-unitario']) {
          handleCalcularValorTotal({ values: methods.getValues(), methods });
        }
      }}
      required
      disableIconError
      rules={composeValidators([
        {
          validate: {
            valor0: (value: string | number) => {
              if (String(value) && Number(value) === 0) {
                return 'Obrigatório';
              }
            },
          },
        },
        required,
      ])}
    />
  );
};

export default memo(FieldQuantidadePaineis);
