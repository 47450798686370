interface DefaultProps {
  potenciaInstalada: number;
}

export const kitsFotovoltaicosDefault = ({ potenciaInstalada }: DefaultProps) => [
  {
    modeloKitsFotovoltaicos: undefined,
    potenciaKitsFotovoltaicos: potenciaInstalada,
    valorVendaKitsFotovoltaicos: undefined,
    descricaoKitsFotovoltaicos: undefined,
    descricaoGarantiaKitsFotovoltaicos: undefined,
  },
];

export const kitDeServicosDefault = [
  {
    modeloKitsDeServicos: undefined,
    valorVendaKitsDeServicos: undefined,
    descricaoKitsDeServicos: undefined,
    descricaoGarantiaKitsDeServicos: undefined,
  },
];

export const inversoresDefault = ({ potenciaInstalada }: DefaultProps) => [
  {
    modeloInversores: undefined,
    potenciaInversores: potenciaInstalada,
    quantidadeInversores: '1',
    garantiaInversores: undefined,
    tipoGarantiaInversores: 1,
  },
];

export const outrosDefault = [
  {
    modeloOutros: undefined,
    quantidadeOutros: undefined,
    garantiaOutros: undefined,
    tipoGarantiaOutros: 1,
  },
];
