import { UseGeradorFunctionsReducerAction, UseGeradorFunctionsReducerState } from './geradorDeProposta.types';

export const initialValue: UseGeradorFunctionsReducerState = {};

export function reducer(
  state: UseGeradorFunctionsReducerState,
  action: UseGeradorFunctionsReducerAction
): UseGeradorFunctionsReducerState {
  switch (action.type) {
    case 'ultimoCliente':
      return { ...state, ultimoCliente: action.ultimoClientePayload };
    case 'vendedores':
      return { ...state, vendedores: action.vendedoresPayload };
    case 'produtos':
      return { ...state, produtos: action.produtosPayload };
    case 'dadosProposta':
      return { ...state, dadosProposta: action.dadosPropostaPayload };
  }
}
