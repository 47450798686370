import { memo } from 'react';
import throttle from 'lodash.throttle';

import { Grid, Typography } from '@s_mart/core';

import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';

import { HeaderContainer, SelectStyled, TextFieldStyled } from './header.styles';
import { optionsCategoriaEdeltec, optionsVoltagem } from './header.static';
import { useHeader } from './useHeader';
import { useIntegracaoFornecedoresContext } from '../geradorDePropostaIntegracaoFornecedores.context';
import { FornecedorEnum } from 'api/fornecedores/fornecedores.types';

function Header() {
  const { handleFetchNextPage, kitsSelecionados } = useIntegracaoFornecedoresContext();
  const { optionsFornecedores, qtdFornecedores, fornecedor, isLoading } = useHeader();

  const isMobile = useIsMobile();

  if (isLoading) {
    return <></>;
  }

  return (
    <HeaderContainer>
      <Grid container gap={1} alignItems='center'>
        {!isMobile && (
          <Grid item mb={0.2}>
            <Typography variant='bodySM'>Classificar por</Typography>
          </Grid>
        )}

        {qtdFornecedores > 1 && (
          <Grid item flex={isMobile ? 1 : 3}>
            <SelectStyled
              name='fornecedores'
              options={optionsFornecedores as any}
              disabled={!!kitsSelecionados?.length}
              onChange={(e) => {
                handleFetchNextPage({
                  page: 1,
                  fornecedor: e.value.codigo,
                  tipoIntegracao: e.value.tipoIntegracao,
                });
              }}
            />
          </Grid>
        )}

        <Grid item flex={1}>
          <TextFieldStyled
            name='potenciaFieldFornecedores'
            mask='decimal'
            InputProps={{ endAdornment: <Typography variant='bodySM'>kWp</Typography> }}
            onInputChange={throttle((value) => {
              const _potenciaInicial = +(Number(value) * 0.9).toFixed(2);
              const _potenciaFinal = +(Number(value) * 1.1).toFixed(2);

              if (value === '0.00' || !value) {
                handleFetchNextPage({ page: 1, potenciaLimiteInicial: undefined, potenciaLimiteFinal: undefined });
              } else {
                handleFetchNextPage({
                  page: 1,
                  potenciaLimiteInicial: _potenciaInicial,
                  potenciaLimiteFinal: _potenciaFinal,
                });
              }
            }, 500)}
          />
        </Grid>

        {fornecedor === FornecedorEnum.EDELTEC && (
          <Grid item flex={6}>
            <SelectStyled
              name='categoria'
              placeholder='Selecione uma categoria'
              clearable
              options={optionsCategoriaEdeltec}
              onChange={(e) => handleFetchNextPage({ page: 1, categoria: e?.value || undefined })}
            />
          </Grid>
        )}

        {fornecedor === FornecedorEnum.HORUS && (
          <Grid item flex={1}>
            <SelectStyled
              name='voltagem'
              options={optionsVoltagem}
              defaultValue={optionsVoltagem[1]}
              onChange={({ value }) => handleFetchNextPage({ page: 1, voltagem: value })}
            />
          </Grid>
        )}

        <Grid item flex={isMobile ? 1 : 3}>
          <TextFieldStyled
            name='search'
            className='estruturas'
            placeholder='Pesquisar'
            onInputChange={(value) => {
              if (value === '') {
                handleFetchNextPage({ page: 1, search: undefined });
              } else {
                handleFetchNextPage({ page: 1, search: value });
              }
            }}
          />
        </Grid>
      </Grid>
    </HeaderContainer>
  );
}

export default memo(Header);
