import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';

import { Grid, LIcon, Typography } from '@s_mart/core';
import { TextField } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { lineChartMixed } from '@s_mart/regular-icons';
import { composeValidators, maiorQue, menorOuIgualA, required } from '@s_mart/rules';
import { colorPalette } from '@s_mart/tokens';
import { valor } from '@s_mart/masks';

import { useIsAdmin } from 'hooks/useIsAdmin/useIsAdmin';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import useGeradorDeProposta from 'shared/modais/geradorDeProposta/store/geradorDeProposta.store';
import { useAnalytics } from 'hooks/useAnalytics/useAnalytics';
import useClarity from 'hooks/useClarity/useClarity';

import { ButtonCalculoDePayback, TooltipCustom } from './fieldsValidadeValorPayback.styles';
import { TagsEnum } from 'hooks/useClarity/useClarity.types';

interface FieldsValidadePaybackProps {
  setMostrarBaseDeCalculoDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const FieldsValidadeValorPayback = ({ setMostrarBaseDeCalculoDialog }: FieldsValidadePaybackProps) => {
  const { watch } = useFormContext();
  const { analyticsEventTracker } = useAnalytics({ category: 'Gerador de proposta - Sistema x Payback' });
  const { setTagClarity } = useClarity();
  const { recursosContratados } = useConfiguracoes();
  const { geradorDeProposta } = useGeradorDeProposta();
  const isAdmin = useIsAdmin();
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  const _formValues = watch();

  const _handleDisabledFieldValorTotal = useMemo(() => {
    if (
      recursosContratados?.['valor-de-venda-unitario'] &&
      !isAdmin &&
      _formValues.valoresArray?.every((it: number | undefined) => Boolean(it))
    ) {
      return true;
    } else {
      return false;
    }
  }, [recursosContratados, isAdmin, _formValues.valoresArray]);

  const _FieldValidade = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        name='validade'
        label={telaMenorQue600 ? 'Validade' : 'Validade da proposta'}
        InputProps={{
          endAdornment: <Typography variant='bodySM'>{_formValues.validade > 1 ? 'dias' : 'dia'}</Typography>,
          sx: {
            borderRadius: '6px',
            backgroundColor: 'white',
          },
        }}
        required
        rules={composeValidators([maiorQue(0), menorOuIgualA(180), required])}
        size='small'
        mask='numero'
        helperText={
          <Typography variant='bodyXS' color={colorPalette.neutral[600]} style={{ marginTop: '4px' }}>
            Válida até{' '}
            {Boolean(_formValues.validade) &&
              dayjs()
                .add(_formValues.validade, 'days')
                .format(telaMenorQue600 ? 'DD/MM' : 'DD/MM/YYYY')}
          </Typography>
        }
        inputProps={{ maxLength: 3 }}
      />
    </div>
  );

  const _FieldValorTotal = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        name='valorTotal'
        label={`Valor total${telaMenorQue600 ? '' : ' da proposta'}`}
        required
        mask='valor'
        value={valor.format(_formValues?.valorTotal || 0)}
        rules={composeValidators([maiorQue(0), required])}
        disabled={_handleDisabledFieldValorTotal || !geradorDeProposta?.caminhoGerador}
        sx={{
          backgroundColor: 'white',
          borderRadius: '6px',
          '.Mui-disabled': {
            backgroundColor: colorPalette.neutral[50],
            borderRadius: '6px',
            WebkitTextFillColor: _handleDisabledFieldValorTotal ? colorPalette.neutral[900] : colorPalette.neutral[500],
          },
        }}
        inputProps={{ maxLength: 17 }}
      />
    </div>
  );

  const _FieldPayback = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        name='retornoInvestimento'
        label='Payback estimado em'
        required
        disabled
        rules={{
          validate: {
            valorInvalido: (value: string) => {
              if (!value) return 'Valor total inválido';
            },
          },
        }}
        sx={{
          '.Mui-disabled': {
            backgroundColor: colorPalette.neutral[50],
            borderRadius: '6px',
            WebkitTextFillColor: colorPalette.neutral[500],
          },
        }}
      />
    </div>
  );

  const _ButtonCalculoPaybackDialog = (
    <TooltipCustom title='Cálculo de payback' placement='bottom-end'>
      <div>
        <ButtonCalculoDePayback
          variant='neutral'
          onClick={() => {
            setTagClarity(TagsEnum['Clicou no botão de cálculo de payback']);
            analyticsEventTracker({ action: 'Clicou no botão de cálculo de payback' });
            setMostrarBaseDeCalculoDialog(true);
          }}
          disabled={!_formValues.valorTotal}
          id='button-base-calculo-payback'
        >
          <LIcon icon={lineChartMixed} removeMargin size='20px' />
        </ButtonCalculoDePayback>
      </div>
    </TooltipCustom>
  );

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        padding: '8px 16px',
        background: colorPalette.neutral[30],
        borderRadius: '6px',
      }}
    >
      {telaMenorQue600 ? (
        <Grid container spacing={1}>
          <Grid item xs={7}>
            {_FieldValorTotal}
          </Grid>
          <Grid item xs={5}>
            {_FieldValidade}
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', gap: '8px' }}>
              {_FieldPayback}
              {_ButtonCalculoPaybackDialog}
            </div>
          </Grid>
        </Grid>
      ) : (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ width: '130px' }}>{_FieldValidade}</div>
          <div style={{ display: 'flex', gap: '16px' }}>
            {_FieldValorTotal}
            <div style={{ display: 'flex', gap: '8px' }}>
              {_FieldPayback}
              {_ButtonCalculoPaybackDialog}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FieldsValidadeValorPayback;
