import { memo } from 'react';

import { Button, Dialog, Typography } from '@s_mart/core';

import useSaidaSemSalvarDialog from './store/saidaSemSalvarDialog.store';
import useSaidaSemSalvarDialogFunctions from './useSaidaSemSalvarDialogFunctions';

const SaidaSemSalvarDialog = () => {
  const { saidaSemSalvarDialog } = useSaidaSemSalvarDialog();
  const { handleClose, handleSubmit } = useSaidaSemSalvarDialogFunctions();

  return (
    <Dialog open onClose={handleClose} closeIcon disableBackdropClick disableEscapeKeyDown fullWidth maxWidth='xxs'>
      <Dialog.Header>Saída sem salvar</Dialog.Header>
      <Dialog.Content>
        {typeof saidaSemSalvarDialog.texto === 'string' ? (
          <Typography variant='bodyXS'>{saidaSemSalvarDialog.texto}</Typography>
        ) : (
          <>{saidaSemSalvarDialog.texto}</>
        )}
      </Dialog.Content>
      <Dialog.Footer>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'end', marginTop: '16px', gap: '16px' }}>
          <Button onClick={handleSubmit} color='error'>
            Sair mesmo assim
          </Button>
          <Button onClick={handleClose} variant='neutral'>
            {saidaSemSalvarDialog.textoPrimaryButton || 'Voltar'}
          </Button>
        </div>
      </Dialog.Footer>
    </Dialog>
  );
};

export default memo(SaidaSemSalvarDialog);
