import { memo } from 'react';

import { Typography, Dialog, Button } from '@s_mart/core';

import usePropostaExcluirDialogFunctions from './usePropostaExcluirDialogFunctions';
import usePropostaExcluirDialog from './store/propostaExcluirDialog.store';

const PropostaExcluirDialog = () => {
  const { propostaExcluirDialog } = usePropostaExcluirDialog();
  const { handleClose, handleSubmit } = usePropostaExcluirDialogFunctions();

  return (
    <Dialog open onClose={handleClose} closeIcon disableBackdropClick disableEscapeKeyDown fullWidth maxWidth='xxs'>
      <Dialog.Header>Excluir esta proposta</Dialog.Header>
      <Dialog.Content>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Typography variant='bodyXS'>
            Você está excluindo <Typography variant='titleXXS'>permanentemente</Typography> a proposta{' '}
            <Typography variant='titleXXS'>N° {propostaExcluirDialog?.numeroProposta || 0}</Typography>.
          </Typography>
          <Typography variant='bodyXS'>Você está certo disso?</Typography>
        </div>
      </Dialog.Content>
      <Dialog.Footer>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'end', marginTop: '16px', gap: '16px' }}>
          <Button onClick={handleSubmit} color='error'>
            Excluir proposta
          </Button>
          <Button onClick={handleClose} variant='neutral'>
            Cancelar
          </Button>
        </div>
      </Dialog.Footer>
    </Dialog>
  );
};

export default memo(PropostaExcluirDialog);
