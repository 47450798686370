import { create } from 'zustand';

import {
  ConvidarVendedorComoUsuarioDialogStates,
  ConvidarVendedorComoUsuarioDialogStore,
} from './convidarVendedorComoUsuarioDialog.store.types';

const initialState: ConvidarVendedorComoUsuarioDialogStates = {
  convidarVendedorComoUsuarioDialog: { aberto: false },
};

const useConvidarVendedorComoUsuarioDialog = create<ConvidarVendedorComoUsuarioDialogStore>((set) => ({
  ...initialState,
  setConvidarVendedorComoUsuarioDialog: (convidarVendedorComoUsuarioDialog) =>
    set((state) => ({ ...state, convidarVendedorComoUsuarioDialog })),
}));

export default useConvidarVendedorComoUsuarioDialog;
