import { useCallback } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { calcularDimensionamentoGrupoB } from 'utils/calcularDimensionamentoGrupoB/calcularDimensionamentoGrupoB';

const useGeradorDePropostaCalcularDimensionamento = () => {
  const handleCalcularDimensionamentoGrupoB = useCallback((values: any, setValue: UseFormSetValue<FieldValues>) => {
    //values.primeiraRenderizacaoClonar é um initialValue definido como true quando é uma proposta clonada,
    //que não pode recalcular as informações do dimensionamento sem que haja uma alteração pelo usuário
    if (values.primeiraRenderizaoClonar) {
      //if interno é necessário pois a irradiação média não vem do back (começa como undefined), e é uma dependência dessa função,
      //portanto é necessário que o change somente ocorra se já existir esse valor
      if (values.irradiacaoMedia) {
        setValue('primeiraRenderizaoClonar', false);
      }
      //resumindo, a função apenas cálcula valores se é o fluxo normal de gerar proposta ou se é uma proposta clonada onde o usuário alterou algum
      //dos valores que estão na dependência do callback, após o front automaticamente buscar a irradiação
      return;
    }

    if (Number(values.potenciaPaineis)) {
      const { areaMinima, geracaoSistema, quantidadePaineis, potenciaSistema } = calcularDimensionamentoGrupoB({
        consumoMedio: Number(values.consumoMedio),
        potenciaPaineis: Number(values.potenciaPaineis),
        perdaSistema: Number(values.perdaSistema),
        largura: Number(values.larguraPainel),
        altura: Number(values.alturaPainel),
        irradiacaoSolar: Number(values.irradiacaoMedia),
        diasNoMes: 30,
        aumentoConsumo: Number(values.aumentoConsumo),
      });

      setValue('quantidadePaineis', String(quantidadePaineis));
      setValue('geracaoSistema', geracaoSistema);
      setValue('areaMinima', areaMinima);
      setValue('potenciaInversor', potenciaSistema);
      setValue('potenciaInstalada', potenciaSistema);
      setValue('potenciaFieldFornecedores', potenciaSistema);
    }
  }, []);

  const _handleGeracaoMensalPainelEPotenciaLimite = useCallback((values: any) => {
    let _geracaoMensalPainel = 0;
    let _potenciaLimite = 0;

    //_geracaoMensalPainel = (potencia painel * (irradiacao anual / 1000) * 30 dias no mês * (1 - (perda/100))) / 1000
    if (values.potenciaPaineis && values.irradiacaoMedia && values.perdaSistema) {
      _geracaoMensalPainel =
        (Number(values.potenciaPaineis) *
          (Number(values.irradiacaoMedia) / 1000) *
          30 *
          (1 - Number(values.perdaSistema) / 100)) /
        1000;
    }

    if (values.demandaContratada) {
      _potenciaLimite = Number(values.demandaContratada) * 0.92;
    }

    return { geracaoMensalPainel: _geracaoMensalPainel, potenciaLimite: _potenciaLimite };
  }, []);

  const _handleDimensionarConsumoForaPonta = useCallback(
    (values: any, setValue: UseFormSetValue<FieldValues>, selecionado?: boolean) => {
      //! Dimensionamento “Compensar consumo Fora Ponta”
      let _quantidadePaineisForaPonta = 0;
      let _potenciaSistemaForaPonta = 0;
      let _areaMinimaForaPonta = 0;
      let _geracaoSistemaForaPonta = 0;

      const _geracaoMensalPainel = _handleGeracaoMensalPainelEPotenciaLimite(values)?.geracaoMensalPainel;
      const _potenciaLimite = _handleGeracaoMensalPainelEPotenciaLimite(values)?.potenciaLimite;

      if (_geracaoMensalPainel && values.consumoMedioForaPonta) {
        _quantidadePaineisForaPonta = Math.floor(Number(values.consumoMedioForaPonta) / _geracaoMensalPainel);
      }

      if (values.potenciaPaineis && _quantidadePaineisForaPonta) {
        _potenciaSistemaForaPonta = (Number(values.potenciaPaineis) * _quantidadePaineisForaPonta) / 1000;
      }

      if (values.potenciaPaineis && _potenciaSistemaForaPonta > _potenciaLimite) {
        _quantidadePaineisForaPonta = Math.floor((_potenciaLimite * 1000) / Number(values.potenciaPaineis));
        _potenciaSistemaForaPonta = _potenciaLimite;
      }

      if (_quantidadePaineisForaPonta && values.larguraPainel && values.alturaPainel) {
        _areaMinimaForaPonta = _quantidadePaineisForaPonta * Number(values.larguraPainel) * Number(values.alturaPainel);
      }

      if (_geracaoMensalPainel && _quantidadePaineisForaPonta) {
        _geracaoSistemaForaPonta = _geracaoMensalPainel * _quantidadePaineisForaPonta;
      }

      setValue('quantidadePaineisForaPonta', _quantidadePaineisForaPonta);
      setValue('potenciaSistemaForaPonta', Number(_potenciaSistemaForaPonta.toFixed(2)));
      setValue('areaMinimaForaPonta', Number(_areaMinimaForaPonta.toFixed(2)));
      setValue('geracaoSistemaForaPonta', Number(_geracaoSistemaForaPonta.toFixed(2)));

      if (
        _quantidadePaineisForaPonta &&
        _geracaoSistemaForaPonta &&
        _areaMinimaForaPonta &&
        _potenciaSistemaForaPonta &&
        values.consumoMedioForaPonta &&
        selecionado
      ) {
        setValue('quantidadePaineis', _quantidadePaineisForaPonta);
        setValue('geracaoSistema', Number(_geracaoSistemaForaPonta?.toFixed(2)));
        setValue('areaMinima', Number(_areaMinimaForaPonta?.toFixed(2)));
        setValue('potenciaInstalada', Number(_potenciaSistemaForaPonta?.toFixed(2)));
        setValue('potenciaInversor', Number(_potenciaSistemaForaPonta?.toFixed(2)));
        setValue('potenciaFieldFornecedores', Number(_potenciaSistemaForaPonta?.toFixed(2)));
        setValue('consumoMedio', Number(values.consumoMedioForaPonta));
      }
    },
    []
  );

  const _handleDimensionarConsumoForaPontaMaisPonta = useCallback(
    (values: any, setValue: UseFormSetValue<FieldValues>, selecionado?: boolean) => {
      //! Dimensionamento “Compensar consumo Fora Ponta + Ponta”
      let _fatorAjuste = 0;
      let _consumoPontaAjustado = 0;
      let _consumoMedioTotal = 0;
      let _quantidadePaineisForaPontaMaisPonta = 0;
      let _potenciaSistemaForaPontaMaisPonta = 0;
      let _potenciaComCobrancaTusdG = 0;
      let _areaMinimaForaPontaMaisPonta = 0;
      let _geracaoSistemaForaPontaMaisPonta = 0;

      const _geracaoMensalPainel = _handleGeracaoMensalPainelEPotenciaLimite(values)?.geracaoMensalPainel;
      const _potenciaLimite = _handleGeracaoMensalPainelEPotenciaLimite(values)?.potenciaLimite;

      //É necessário converter o valor “Consumo Ponta” para “Consumo Fora Ponta”
      if (values.tarifaForaPonta && values.tarifaPonta) {
        _fatorAjuste = Number(values.tarifaForaPonta) / Number(values.tarifaPonta);
      }
      if (values.consumoMedioPonta && _fatorAjuste) {
        _consumoPontaAjustado = Number(values.consumoMedioPonta) / _fatorAjuste;
      }

      if (values.consumoMedioForaPonta && _consumoPontaAjustado) {
        _consumoMedioTotal = Number(values.consumoMedioForaPonta) + _consumoPontaAjustado;
      }

      if (_consumoMedioTotal && _geracaoMensalPainel) {
        _quantidadePaineisForaPontaMaisPonta = Math.ceil(_consumoMedioTotal / _geracaoMensalPainel);
      }

      if (_geracaoMensalPainel && _quantidadePaineisForaPontaMaisPonta) {
        _potenciaSistemaForaPontaMaisPonta =
          (Number(values.potenciaPaineis) * _quantidadePaineisForaPontaMaisPonta) / 1000;
      }

      if (_potenciaSistemaForaPontaMaisPonta > _potenciaLimite) {
        _potenciaComCobrancaTusdG = _potenciaSistemaForaPontaMaisPonta - _potenciaLimite;
      }

      if (_quantidadePaineisForaPontaMaisPonta && values.larguraPainel && values.alturaPainel) {
        _areaMinimaForaPontaMaisPonta =
          _quantidadePaineisForaPontaMaisPonta * Number(values.larguraPainel) * Number(values.alturaPainel);
      }

      if (_geracaoMensalPainel && _quantidadePaineisForaPontaMaisPonta) {
        _geracaoSistemaForaPontaMaisPonta = _geracaoMensalPainel * _quantidadePaineisForaPontaMaisPonta;
      }

      if (_potenciaComCobrancaTusdG > 0) {
        setValue('consumoACompensar', Number(_potenciaComCobrancaTusdG.toFixed(2)));

        if (values.tarifaTusdg) {
          const _valorAPagarTusdG = _potenciaComCobrancaTusdG * Number(values.tarifaTusdg);

          setValue('valorAPagarTusdG', Number(_valorAPagarTusdG.toFixed(2)));
        }
      } else {
        setValue('consumoACompensar', undefined);
      }

      setValue('fatorAjuste', _fatorAjuste);
      setValue('quantidadePaineisForaPontaMaisPonta', _quantidadePaineisForaPontaMaisPonta);
      setValue('consumoMedioForaPontaMaisPontaAjustado', Number(_consumoMedioTotal?.toFixed(2)));
      setValue('potenciaSistemaForaPontaMaisPonta', Number(_potenciaSistemaForaPontaMaisPonta?.toFixed(2)));
      setValue('areaMinimaForaPontaMaisPonta', Number(_areaMinimaForaPontaMaisPonta?.toFixed(2)));
      setValue('geracaoSistemaForaPontaMaisPonta', Number(_geracaoSistemaForaPontaMaisPonta?.toFixed(2)));

      if (
        _quantidadePaineisForaPontaMaisPonta &&
        _geracaoSistemaForaPontaMaisPonta &&
        _areaMinimaForaPontaMaisPonta &&
        _potenciaSistemaForaPontaMaisPonta &&
        _consumoMedioTotal &&
        selecionado
      ) {
        setValue('quantidadePaineis', _quantidadePaineisForaPontaMaisPonta);
        setValue('geracaoSistema', Number(_geracaoSistemaForaPontaMaisPonta?.toFixed(2)));
        setValue('areaMinima', Number(_areaMinimaForaPontaMaisPonta?.toFixed(2)));
        setValue('potenciaInstalada', Number(_potenciaSistemaForaPontaMaisPonta?.toFixed(2)));
        setValue('potenciaInversor', Number(_potenciaSistemaForaPontaMaisPonta?.toFixed(2)));
        setValue('potenciaFieldFornecedores', Number(_potenciaSistemaForaPontaMaisPonta?.toFixed(2)));
        setValue('consumoMedio', Number(_consumoMedioTotal?.toFixed(2)));
      }
    },
    []
  );

  const handleCalcularDimensionamentoGrupoA = useCallback((values: any, setValue: UseFormSetValue<FieldValues>) => {
    //values.primeiraRenderizacaoClonar é um initialValue definido como true quando é uma proposta clonada,
    //que não pode recalcular as informações do dimensionamento sem que haja uma alteração pelo usuário
    if (values.primeiraRenderizaoClonar) {
      //if interno é necessário pois a irradiação média não vem do back (começa como undefined), e é uma dependência dessa função,
      //portanto é necessário que o change somente ocorra se já existir esse valor
      if (values.irradiacaoMedia) {
        setValue('primeiraRenderizaoClonar', false);
      }

      if (values.tipoDimensionamento === '1') {
        _handleDimensionarConsumoForaPonta(values, setValue);
      } else if (values.tipoDimensionamento === '0') {
        _handleDimensionarConsumoForaPontaMaisPonta(values, setValue);
      }
      //resumindo, a função apenas cálcula valores se é o fluxo normal de gerar proposta ou se é uma proposta clonada onde o usuário alterou algum
      //dos valores que estão na dependência do callback, após o front automaticamente buscar a irradiação
      return;
    }

    _handleDimensionarConsumoForaPonta(values, setValue, values.tipoDimensionamento === '0');
    _handleDimensionarConsumoForaPontaMaisPonta(values, setValue, values.tipoDimensionamento === '1');
  }, []);

  return { handleCalcularDimensionamentoGrupoB, handleCalcularDimensionamentoGrupoA };
};

export default useGeradorDePropostaCalcularDimensionamento;
