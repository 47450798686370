import { create } from 'zustand';

import { PlanosDialogStates, PlanosDialogStore } from './planosDialog.store.types';

const initialState: PlanosDialogStates = {
  planosDialog: { aberto: false },
  boletoProntoDialog: { aberto: false },
};

const usePlanosDialog = create<PlanosDialogStore>((set) => ({
  ...initialState,
  setPlanosDialog: (planosDialog) => set((state) => ({ ...state, planosDialog })),
  setBoletoProntoDialog: (boletoProntoDialog) => set((state) => ({ ...state, boletoProntoDialog })),
}));

export default usePlanosDialog;
