import { Stack } from '@mui/material';
import { Button, Dialog, Typography } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';
import { toRem } from '@s_mart/utils';

import MaquinaCredito from 'assets/avisoBoleto/maquina-credito.svg';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';
import { useInformarPagamentoDialogContext } from '../../informarPagamentoDialog.context';

export function BotaoDesbloqueioUtilizado() {
  const isMobile = useIsMobile();

  const {
    linhaDigitavelComRegex,
    handleAbrirMeuPlano,
    handleAbrirContatoSuporte,
    handleCopiarLinhaDigitavel,
    handleAbrirBoletoPagamento,
  } = useInformarPagamentoDialogContext();

  return (
    <>
      <Dialog.Header>Ops, nosso robô ainda não identificou um pagamento</Dialog.Header>
      <Dialog.Content>
        <Stack
          direction='row'
          gap={4}
          alignItems='center'
          justifyContent='center'
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <img src={MaquinaCredito} alt='Máquina de cartão de crédito com o papel de recibo saindo' />
          <Stack gap={2} alignItems='center'>
            <Typography variant='bodyLG'>Abaixo está a linha digitável do boleto em atraso!</Typography>
            <Typography
              variant='bodyXS'
              sx={{
                textAlign: 'center',
                width: `calc(100% - ${toRem(32)})`,
                backgroundColor: colorPalette.neutral[30],
                padding: `${toRem(8)} ${toRem(16)}`,
                borderRadius: toRem(8),
              }}
            >
              <b>{linhaDigitavelComRegex}</b>
            </Typography>
            <Stack gap={1} alignItems='center' flexDirection={isMobile ? 'column' : 'row'} width='100%'>
              <Button fullWidth variant='outlined' style={{ minWidth: 200 }} onClick={handleAbrirBoletoPagamento}>
                Abrir boleto em nova aba
              </Button>
              <Button fullWidth style={{ minWidth: 200 }} onClick={handleCopiarLinhaDigitavel}>
                Copiar linha digitável
              </Button>
            </Stack>
            <Stack gap={1} alignItems='center' flexDirection={isMobile ? 'column' : 'row'} width='100%'>
              <Button fullWidth variant='outlined' color='secondary' onClick={handleAbrirMeuPlano}>
                Ver meus pagamentos
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Dialog.Content>
      <Dialog.Footer>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='bodySM'>
            Já faz 1 dia útil que você nos informou o pagamento, porém ainda não foi compensado.
          </Typography>
          <Button variant='text' color='secondary' onClick={handleAbrirContatoSuporte}>
            Clique aqui para falar com o suporte
          </Button>
        </Stack>
      </Dialog.Footer>
    </>
  );
}
