import { isProduction } from 'api/variables';
import ReactGA from 'react-ga4';
import useAuth from 'store/auth/auth';

type _HookAnalyticsProps = {
  category: string;
};

type _AnalyticsConfig = {
  action: string;
  label?: Record<string, any>;
  category?: string;
};

export type AnalyticsEventTracker = (config: _AnalyticsConfig) => void;

class CategoriaParaGoogleAnalyticsNecessario extends Error {
  constructor(cause?: Error['cause']) {
    super('Categoria para Google Analytics necessária', { cause });
  }
}

export const useAnalytics = ({ category }: _HookAnalyticsProps) => {
  const { profile } = useAuth();

  if (!category) {
    throw new CategoriaParaGoogleAnalyticsNecessario();
  }

  const analyticsEventTracker = ({ label = {}, ...config }: _AnalyticsConfig) => {
    if (isProduction) {
      ReactGA.event({ category, ...config, label: JSON.stringify({ emp: profile?.emp, ...label }) });
    }
  };

  return { analyticsEventTracker };
};
