import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { buscarProduto, listarProdutos } from 'api/produtos/produtos';
import { BuscarProdutoDados, ListarProdutosTypes } from 'api/produtos/produtos.types';
import { ListagemProdutosTypes } from 'containers/produtos/produtos.types';
import useApp from 'store/app/app';

const useFieldsPainel = () => {
  const { handleError } = useApp();
  const { setValue } = useFormContext();

  const [paineis, setPaineis] = useState<ListagemProdutosTypes[]>();
  const [loadingPaineis, _setLoadingPaineis] = useState(false);

  const handleListarPaineis = useCallback(async () => {
    _setLoadingPaineis(true);
    try {
      const { data } = await listarProdutos({ tipoProduto: '0', all: true });

      if (data) {
        setPaineis(data?.data);
        setValue(
          'listagemPaineis',
          data?.data.reduce((acc: ListarProdutosTypes[], item: ListarProdutosTypes) => {
            acc[item.codigo] = item;
            return acc;
          }, {})
        );
      }
      _setLoadingPaineis(false);
    } catch (err) {
      _setLoadingPaineis(false);
      handleError(err);
    }
  }, [handleError]);

  const handleBuscarPainelSelecionado = useCallback(
    async (values: { value: number; label: string }) => {
      if (values?.value) {
        try {
          const { data }: { data: { data: BuscarProdutoDados } } = await buscarProduto(values?.value);

          setValue('potenciaPaineis', String(data?.data?.potencia));
          setValue('garantiaPaineis', String(data?.data?.garantia));
          setValue('tipoGarantiaPaineis', String(data?.data?.tipoGarantia));
          setValue('valorVendaPaineis', data?.data?.valorVenda ? String(data?.data?.valorVenda) : '');
        } catch (err) {
          handleError(err);
        }
      }
    },
    [handleError, setValue]
  );

  useEffect(() => {
    handleListarPaineis();
  }, []);

  return { paineis, loadingPaineis, handleBuscarPainelSelecionado };
};

export default useFieldsPainel;
