import type { FieldValues, UseFormSetValue } from 'react-hook-form';

export const removeCaracteresEspeciais = (
  text: string,
  fieldName?: string,
  setValue?: UseFormSetValue<FieldValues>
) => {
  const _regexp = /[^ -~ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÑñ×°ºª¹²³\n]+/gim;

  if (fieldName && setValue) {
    if (text.match(_regexp)) {
      setValue(fieldName, text.replaceAll(_regexp, ''));
    } else {
      return;
    }
  } else {
    return text.replaceAll(_regexp, '');
  }
};
