import { Typography } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';

import { FolhaA4 } from '../simulacaoPreviewPdfProposta.styles';
import type { SimulacaoPreviewPdfPropostaProps } from '../simulacaoPreviewPdfProposta.types';

const SimulaCapaPropostaAntiga = ({ numero, logo, cor, banner }: SimulacaoPreviewPdfPropostaProps) => {
  return (
    <FolhaA4>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'right', alignItems: 'center', paddingRight: '32px' }}>
        <Typography variant='bodySM'>{numero || 1}</Typography>
      </div>
      <div style={{ display: 'flex', flex: 3, justifyContent: 'space-between', padding: '0px 16px 0 48px' }}>
        <Typography variant='titleMD' color={cor}>
          Proposta comercial
        </Typography>
        <img
          src={logo ? `data:image/png;base64,${logo}` : ''}
          alt='logo'
          style={{ maxHeight: '175px', maxWidth: '300px', marginRight: '64px' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flex: 8,
          backgroundImage: banner ? `url(data:image/png;base64,${banner})` : '',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPositionY: 'center',
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          flex: 4,
          flexDirection: 'column',
          gap: '16px',
          paddingLeft: '48px',
        }}
      >
        <div
          style={{
            paddingTop: '24px',
            textAlign: 'left',
          }}
        >
          <Typography variant='titleXS' color={cor}>
            Nome do cliente:{' '}
            <Typography variant='bodySM' color={colorPalette.neutral[900]}>
              Cliente exemplo
            </Typography>
          </Typography>
          <div style={{ display: 'flex', gap: '8px' }}>
            <Typography variant='titleXS' color={cor}>
              Potência instalada:{' '}
              <Typography variant='bodySM' color={colorPalette.neutral[900]}>
                4,4 kWp
              </Typography>
            </Typography>
            <Typography variant='titleXS' color={cor}>
              Contato:{' '}
              <Typography variant='bodySM' color={colorPalette.neutral[900]}>
                Telefone do cliente
              </Typography>
            </Typography>
            <Typography variant='titleXS' color={cor}>
              CPF/CNPJ:{' '}
              <Typography variant='bodySM' color={colorPalette.neutral[900]}>
                07.081.093/0001-75
              </Typography>
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
          <Typography variant='titleXS' color={cor}>
            Vendedor:{' '}
            <Typography variant='bodySM' color={colorPalette.neutral[900]}>
              Vendedor exemplo
            </Typography>
          </Typography>
          <Typography variant='titleXS' color={cor}>
            Validade da proposta:{' '}
            <Typography variant='bodySM' color={colorPalette.neutral[900]}>
              01/01/2023
            </Typography>
          </Typography>
        </div>
      </div>
    </FolhaA4>
  );
};

export default SimulaCapaPropostaAntiga;
