import { FieldValues } from 'react-hook-form';

import type { CadastrarEditarClienteTypes } from 'api/clientes/clientes.types';

function verificarValoresMudaram(values: FieldValues) {
  const { dadosCliente } = values;

  if (dadosCliente?.nome !== (values.nome?.label || values.nome)) {
    return true;
  }

  if (dadosCliente?.telefone !== values.telefone) {
    return true;
  }

  if (dadosCliente?.tipoPessoa !== Number(values.tipoPessoa)) {
    return true;
  }

  if (dadosCliente?.cpfCnpj != values.cpfCnpj) {
    return true;
  }

  if (dadosCliente?.email !== values.email) {
    return true;
  }

  if (dadosCliente?.razaoSocial !== values.razaoSocial) {
    return true;
  }

  if (dadosCliente?.nomeResponsavel !== values.nomeResponsavel) {
    return true;
  }

  if (dadosCliente.rua !== values.rua) {
    return true;
  }

  if (dadosCliente.numero != values.numero) {
    return true;
  }

  if (dadosCliente.bairro !== values.bairro) {
    return true;
  }

  if (dadosCliente.complemento !== values.complemento) {
    return true;
  }

  if (dadosCliente.uf !== values.dadosCidade?.label?.split(' - ')[1]) {
    return true;
  }

  if (dadosCliente.cidade !== values.dadosCidade?.label?.split(' - ')[0]) {
    return true;
  }

  return false;
}

export async function geradorDePropostaSubmit(values: FieldValues) {
  const valoresMudaram = verificarValoresMudaram(values);

  const payloadCliente: CadastrarEditarClienteTypes = {
    //quando é edição, o values.nome vira um objeto contendo label e value
    nome: values.nome?.label || values.nome,
    tipoPessoa: Number(values.tipoPessoa || 0),
    telefone: String(values.telefone),
  };

  if (values.cpfCnpj) {
    payloadCliente.cpfCnpj = Number(values.cpfCnpj);
  }

  if (values.email) {
    payloadCliente.email = values.email;
  }

  if (values.razaoSocial) {
    payloadCliente.razaoSocial = values.razaoSocial;
  }

  if (values.nomeResponsavel) {
    payloadCliente.nomeResponsavel = values.nomeResponsavel;
  }

  if (values.dadosCidade?.value) {
    payloadCliente.dadosCidade = {
      codigo: Number(values.dadosCidade?.value),
      cidade: values.dadosCidade?.label?.split(' - ')[0],
      uf: values.dadosCidade?.label?.split(' - ')[1],
      rua: values.rua,
      numero: values.numero,
      bairro: values.bairro,
      complemento: values.complemento,
    };
  }

  return { payloadCliente, valoresMudaram };
}
