import { memo, useEffect } from 'react';

import { Button, Dialog, Grid } from '@s_mart/core';
import { Form } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';

import CadastrarClienteConteudo from 'components/cadastrarClienteConteudo/cadastrarClienteConteudo';

import useClienteFormDialogFunctions from './useClienteFormDialogFunctions';
import useHandleClienteFormDialogSubmit from './useHandleClienteFormDialogSubmit';
import useSaidaSemSalvarDialog from '../saidaSemSalvarDialog/store/saidaSemSalvarDialog.store';
import useClienteFormDialog from './store/clienteFormDialog.store';

const ClienteFormDialog = () => {
  const { clienteFormDialog } = useClienteFormDialog();
  const { initialState, handleClose, buscarDadosCliente } = useClienteFormDialogFunctions();
  const { handleClienteFormDialogSubmit } = useHandleClienteFormDialogSubmit();
  const { setSaidaSemSalvarDialog } = useSaidaSemSalvarDialog();
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  useEffect(() => {
    if (clienteFormDialog.codigoCliente) {
      buscarDadosCliente(clienteFormDialog.codigoCliente);
    }
  }, [clienteFormDialog.codigoCliente, buscarDadosCliente]);

  return (
    <Form
      defaultValues={initialState}
      onSubmit={(values) => handleClienteFormDialogSubmit(values, initialState?.nome ? 'editar' : 'cadastrar')}
    >
      {({ formState: { isDirty } }) => (
        <Dialog
          open
          onClose={
            isDirty
              ? () =>
                  setSaidaSemSalvarDialog({
                    aberto: true,
                    onClose: handleClose,
                    texto: 'Notamos que você está saindo deste cadastro de cliente sem salvar.',
                  })
              : handleClose
          }
          closeIcon
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth='xs'
          disablePortal
        >
          <Dialog.Header>{initialState ? 'Editar cliente' : 'Novo cliente'}</Dialog.Header>
          <Dialog.Content>
            <Grid container spacing={2}>
              <CadastrarClienteConteudo />
            </Grid>
          </Dialog.Content>
          <Dialog.Footer>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'end',
                marginTop: '16px',
                gap: '16px',
              }}
            >
              <Button
                variant='neutral'
                onClick={
                  isDirty
                    ? () =>
                        setSaidaSemSalvarDialog({
                          aberto: true,
                          onClose: handleClose,
                          texto: 'Notamos que você está saindo deste cadastro de cliente sem salvar.',
                        })
                    : handleClose
                }
              >
                Cancelar
              </Button>
              <Button type='submit' disabled={!isDirty}>
                {initialState ? 'Salvar' : telaMenorQue600 ? 'Cadastrar' : 'Cadastrar cliente'}
              </Button>
            </div>
          </Dialog.Footer>
        </Dialog>
      )}
    </Form>
  );
};

export default memo(ClienteFormDialog);
