export const optionsVoltagem = [
  {
    label: '127V',
    value: 110,
  },
  {
    label: '220V',
    value: 220,
  },
  {
    label: '380V',
    value: 380,
  },
];

export const optionsCategoriaEdeltec = [
  { value: 30050200, label: 'ACESSORIOS OFF GRID' },
  { value: 30020500, label: 'BATERIA SOLAR' },
  { value: 40010400, label: 'BICICLETA ELETRICA' },
  { value: 30050600, label: 'CABO OFF GRID' },
  { value: 30030100, label: 'CABOS' },
  { value: 30020200, label: 'CARREGADOR VEICULAR' },
  { value: 30030200, label: 'CONECTORES' },
  { value: 30050400, label: 'CONTROLADOR OFF GRID' },
  { value: 30050300, label: 'DATAMANAGER' },
  { value: 45010100, label: 'EPI' },
  { value: 30060200, label: 'EST COMPL' },
  { value: 30040100, label: 'ESTRUTURAS' },
  { value: 30030300, label: 'FERR SOLAR' },
  { value: 35010100, label: 'FERRAMENTAS' },
  { value: 35010200, label: 'FERRAMENTAS MANUAIS' },
  { value: 30030900, label: 'FORA DE LINHA' },
  { value: 30030600, label: 'FUSÍVEL' },
  { value: 30060100, label: 'GERADORES' },
  { value: 30060300, label: 'GERADORES NACIONAIS' },
  { value: 30020100, label: 'INVERSORES' },
  { value: 30020300, label: 'INVERSORES HÍBRIDOS' },
  { value: 30050100, label: 'INVERSORES OFF GRID' },
  { value: 40030100, label: 'KIT AUTOS' },
  { value: 30020400, label: 'MICRO INVERSORES' },
  { value: 30050500, label: 'MONITORAMENTO OFF GRID' },
  { value: 40010200, label: 'MOTO ELÉTRICA' },
  { value: 30010100, label: 'MÓDULOS' },
  { value: 40010300, label: 'PATINETE ELÉTRICO' },
  { value: 30040000, label: 'PERIFERICO GERADOR EST' },
  { value: 30020000, label: 'PERIFERICO GERADOR INV' },
  { value: 30010000, label: 'PERIFERICO GERADOR MOD' },
  { value: 30030000, label: 'PERIFERICOS GERADOR' },
  { value: 30050000, label: 'PERIFERICOS GERADOR OFF GRID' },
  { value: 30030700, label: 'PERIFÉRICOS DIVERSOS' },
  { value: 40020400, label: 'PEÇAS BICICLETA' },
  { value: 30040300, label: 'PEÇAS ESTRUTURAS' },
  { value: 40020200, label: 'PEÇAS MOTO ELETRICA' },
  { value: 40020300, label: 'PEÇAS PATINETE' },
  { value: 40020100, label: 'PEÇAS SCOOTER/CHOPPER' },
  { value: 40010100, label: 'SCOOTER/CHOPPER ELÉTRICA' },
  { value: 30000000, label: 'SOLAR' },
  { value: 30070100, label: 'STRING BOX' },
  { value: 30030500, label: 'TRANSFORMADORES' },
];
