import { useFormContext } from 'react-hook-form';

const useAdicionarProdutoOuKitDialog = (onClose: () => void) => {
  const { setValue, watch } = useFormContext();

  const _formValues = watch();

  const handleAdicionarPainel = () => {
    setValue('painelAdicionado', true);
    onClose();
  };

  const handleAdicionarInversor = () => {
    const _novoInversor = {
      modeloInversores: undefined,
      potenciaInversores: undefined,
      quantidadeInversores: undefined,
      garantiaInversores: undefined,
      tipoGarantiaInversores: 1,
    };

    //No caminho do gerador por kits não existe valor inicial para spreadar, por isso necessita essa validação
    const _inversores =
      _formValues.inversores?.length > 0 ? [..._formValues.inversores, _novoInversor] : [_novoInversor];

    setValue('inversores', _inversores);
    onClose();
  };

  const handleAdicionarOutro = () => {
    const _novoOutro = {
      modeloOutros: undefined,
      quantidadeOutros: undefined,
      garantiaOutros: undefined,
      tipoGarantiaOutros: 1,
    };
    //No caminho do gerador por kits não existe valor inicial para spreadar, por isso necessita essa validação
    const _outros = _formValues.outros?.length > 0 ? [..._formValues.outros, _novoOutro] : [_novoOutro];
    setValue('outros', _outros);
    onClose();
  };

  const handleAdicionarKitFotovoltaico = () => {
    setValue('kitsFotovoltaicos', [
      ..._formValues.kitsFotovoltaicos,
      {
        modeloKitsFotovoltaicos: undefined,
        potenciaKitsFotovoltaicos: undefined,
        valorVendaKitsFotovoltaicos: undefined,
        descricaoKitsFotovoltaicos: undefined,
        descricaoGarantiaKitsFotovoltaicos: undefined,
      },
    ]);
    onClose();
  };

  const handleAdicionarKitDeServico = () => {
    setValue('kitsDeServicos', [
      ..._formValues.kitsDeServicos,
      {
        modeloKitsDeServicos: undefined,
        potenciaKitsDeServicos: undefined,
        valorVendaKitsDeServicos: undefined,
        descricaoKitsDeServicos: undefined,
        descricaoGarantiaKitsDeServicos: undefined,
      },
    ]);
    onClose();
  };

  return {
    handleAdicionarPainel,
    handleAdicionarInversor,
    handleAdicionarOutro,
    handleAdicionarKitFotovoltaico,
    handleAdicionarKitDeServico,
  };
};

export default useAdicionarProdutoOuKitDialog;
