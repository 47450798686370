import { useCallback } from 'react';

import * as clientesApi from 'api/clientes/clientes';
import type { CadastrarEditarClienteTypes } from 'api/clientes/clientes.types';
import { queryClient } from 'api/queryClient';
import useApp from 'store/app/app';

import type { ClienteFormDialogValues } from './clienteFormDialog.types';
import useClienteFormDialogFunctions from './useClienteFormDialogFunctions';
import useClienteFormDialog from './store/clienteFormDialog.store';

function useHandleClienteFormDialogSubmit() {
  const { setLoading, handleError, setNotification } = useApp();
  const { handleClose } = useClienteFormDialogFunctions();
  const { clienteFormDialog } = useClienteFormDialog();

  const handleClienteFormDialogSubmit = useCallback(
    async (values: ClienteFormDialogValues, tipoChamada: 'editar' | 'cadastrar') => {
      const _payload: CadastrarEditarClienteTypes = {
        nome: values.nome,
        tipoPessoa: Number(values.tipoPessoa || 0),
        telefone: String(values.telefone),
      };

      if (values.cpfCnpj) {
        _payload.cpfCnpj = Number(values.cpfCnpj);
      }
      if (values.email) {
        _payload.email = values.email;
      }
      if (values.razaoSocial) {
        _payload.razaoSocial = values.razaoSocial;
      }
      if (values.nomeResponsavel) {
        _payload.nomeResponsavel = values.nomeResponsavel;
      }
      if (values.dadosCidade?.value) {
        _payload.dadosCidade = {
          codigo: Number(values.dadosCidade?.value),
          cidade: values.dadosCidade?.label?.split(' - ')[0],
          uf: values.dadosCidade?.label?.split(' - ')[1],
          rua: values.rua,
          numero: values.numero,
          bairro: values.bairro,
          complemento: values.complemento,
        };
      }

      switch (tipoChamada) {
        default:
        case 'cadastrar': {
          setLoading(true);

          try {
            await clientesApi.cadastrarCliente(_payload);

            setNotification({ title: 'Novo cliente cadastrado!' });
            queryClient.clear();
          } catch (err) {
            handleError(err);
          }

          setLoading(false);
          handleClose();
          break;
        }

        case 'editar': {
          setLoading(true);

          try {
            if (clienteFormDialog?.codigoCliente) {
              await clientesApi.editarCliente(clienteFormDialog.codigoCliente, _payload);

              setNotification({ title: 'Alterações salvas.' });
              queryClient.clear();

              clienteFormDialog.callback?.();
            }
          } catch (err) {
            handleError(err);
          }

          setLoading(false);

          handleClose();
          break;
        }
      }
    },
    [handleClose, handleError, setLoading, setNotification, clienteFormDialog?.codigoCliente]
  );

  return { handleClienteFormDialogSubmit };
}

export default useHandleClienteFormDialogSubmit;
