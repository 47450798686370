import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { buscarProduto, listarProdutos } from 'api/produtos/produtos';
import { BuscarProdutoDados, ListarProdutosTypes } from 'api/produtos/produtos.types';
import { ListagemProdutosTypes } from 'containers/produtos/produtos.types';
import useApp from 'store/app/app';

const useFieldsInversores = () => {
  const { handleError } = useApp();
  const { setValue } = useFormContext();

  const [inversores, _setInversores] = useState<ListagemProdutosTypes[]>();
  const [loadingInversores, _setLoadingInversores] = useState(false);

  const handleListarInversores = useCallback(async () => {
    _setLoadingInversores(true);
    try {
      const { data } = await listarProdutos({ tipoProduto: '1', all: true });

      if (data) {
        _setInversores(data?.data);
        setValue(
          'listagemInversores',
          data?.data.reduce((acc: ListarProdutosTypes[], item: ListarProdutosTypes) => {
            acc[item.codigo] = item;
            return acc;
          }, {})
        );
      }
      _setLoadingInversores(false);
    } catch (err) {
      _setLoadingInversores(false);
      handleError(err);
    }
  }, [handleError]);

  const handleBuscarInversorSelecionado = useCallback(
    async (index: number, values: any) => {
      if (values?.value) {
        try {
          const { data }: { data: { data: BuscarProdutoDados } } = await buscarProduto(values?.value);

          setValue(`inversores.${index}.potenciaInversores`, String(data?.data?.potencia));
          setValue(`inversores.${index}.garantiaInversores`, String(data?.data?.garantia));
          setValue(`inversores.${index}.quantidadeInversores`, 1);
          setValue(`inversores.${index}.tipoGarantiaInversores`, data?.data?.tipoGarantia);
          setValue(
            `inversores.${index}.valorVendaInversores`,
            data?.data?.valorVenda ? String(data?.data?.valorVenda) : ''
          );
        } catch (err) {
          handleError(err);
        }
      }
    },
    [handleError, setValue]
  );

  useEffect(() => {
    handleListarInversores();
  }, []);

  return { inversores, loadingInversores, handleBuscarInversorSelecionado };
};

export default useFieldsInversores;
