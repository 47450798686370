import { telefoneSuporte } from 'config/constantes';

const useAtualizarModeloPropostaDialog = () => {
  const handleAbrirConversaComSuporte = () => {
    const _mensagem = 'Olá, gostaria de atualizar o modelo da minha proposta!';

    const _api = `https://api.whatsapp.com/send?phone=55${telefoneSuporte.replace(
      / |-|[)(]/g,
      ''
    )}&text=${_mensagem}&app_absent=0`;

    //Foi necessário passar um setTimeout no window.open para funcionar no Safari, não me pergunte o pq que da certo
    setTimeout(() => window.open(_api, '_blank', 'noopener noreferrer'), 0);
  };

  return { handleAbrirConversaComSuporte };
};

export default useAtualizarModeloPropostaDialog;
