import useAuth from 'store/auth/auth';

const useIsAdmin = () => {
  const { profile } = useAuth();

  if (profile?.tipo === 1) {
    return true;
  }

  return false;
};

export { useIsAdmin };
