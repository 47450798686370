import { create } from 'zustand';

import {
  VisualizarListaPropostasDialogStates,
  VisualizarListaPropostasDialogStore,
} from './visualizarListaPropostasDialog.store.types';

const initialState: VisualizarListaPropostasDialogStates = {
  visualizarListaPropostasDialog: { aberto: false },
};

const useVisualizarListaPropostasDialog = create<VisualizarListaPropostasDialogStore>((set) => ({
  ...initialState,
  setVisualizarListaPropostasDialog: (visualizarListaPropostasDialog) =>
    set((state) => ({ ...state, visualizarListaPropostasDialog })),
}));

export default useVisualizarListaPropostasDialog;
