import { useNavigate } from 'react-router-dom';

import { Typography, Button } from '@s_mart/core';

import { ErrorContainer } from './error500.styles';

const Error500 = () => {
  const ilustracao = '/static/svgs/error500/pessoaEFerramenta.svg';
  const navigate = useNavigate();

  return (
    <ErrorContainer>
      <img src={ilustracao} alt='imagemErro500' />
      <Typography variant='bodyXL' align='center'>
        Nosso servidor não conseguiu retornar sua solicitação.
      </Typography>
      <Typography variant='bodySM' align='center'>
        Ocorreu um erro em nossos servidores, tente novamente mais <br /> tarde.
      </Typography>
      <div>
        <Button
          onClick={() => {
            navigate('/');
            window.location.reload();
          }}
        >
          Voltar para página inicial
        </Button>
      </div>
    </ErrorContainer>
  );
};

export default Error500;
