import styled from '@emotion/styled';

export const DivChevronDetalhesKit = styled.div<{ mostrarDetalhes?: boolean }>`
  cursor: pointer;

  svg {
    transform: ${({ mostrarDetalhes }) => (mostrarDetalhes ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: 0.3s ease;
  }
`;
