import styled from '@emotion/styled';

import { Tooltip, Button } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';

export const ButtonCalculoDePayback = styled(Button)`
  border: 1px solid ${colorPalette.neutral[600]};
  margin-top: 20px;
  padding: 0;
  height: 36px;
  min-width: 36px;

  :disabled {
    border: 1px solid ${colorPalette.neutral[100]};
  }

  &:hover {
    background: ${colorPalette.neutral[50]};
  }
`;

export const TooltipCustom = styled(Tooltip)`
  .tooltip-child {
    padding: 0;
  }
`;
