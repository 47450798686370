import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Portal } from '@mui/material';

import { Button, Dialog } from '@s_mart/core';
import { Form } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';

import useBaseDeCalculoPaybackDialog, { IBaseDeCalculoPaybackSubmit } from './useBaseDeCalculoPaybackDialog';
import BaseDeCalculoPaybackDialogFields from './baseDeCalculoPaybackDialogFields/baseDeCalculoPaybackDialogFields';

const BaseDeCalculoPaybackDialog = ({ onClose }: { onClose: () => void }) => {
  const { watch } = useFormContext();
  const { handleSubmitBaseDeCalculoPayback } = useBaseDeCalculoPaybackDialog();
  const telaMenorQue480 = useMediaQuery(`(max-width: 480px)`);

  const _formAnteriorValues = watch();

  return (
    <Portal>
      <Form
        onSubmit={(values: IBaseDeCalculoPaybackSubmit) => {
          handleSubmitBaseDeCalculoPayback(values);
          onClose();
        }}
        defaultValues={_formAnteriorValues}
      >
        {() => (
          <Dialog
            open
            onClose={onClose}
            closeIcon
            disableBackdropClick
            disableEscapeKeyDown
            disablePortal
            maxWidth='lg'
            sx={{
              '.MuiDialog-paper': {
                padding: telaMenorQue480 ? '16px !important' : undefined,
                margin: telaMenorQue480 ? '0' : undefined,
              },
            }}
          >
            <Dialog.Header>Cálculo de payback</Dialog.Header>
            <Dialog.Content>
              <BaseDeCalculoPaybackDialogFields />
            </Dialog.Content>
            <Dialog.Footer>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'end',
                  marginTop: '16px',
                  gap: '16px',
                }}
              >
                <Button variant='neutral' onClick={onClose} size='small'>
                  Voltar
                </Button>
                <Button type='submit' size='small'>
                  Salvar
                </Button>
              </div>
            </Dialog.Footer>
          </Dialog>
        )}
      </Form>
    </Portal>
  );
};

export default memo(BaseDeCalculoPaybackDialog);
