import { RecursosDialog } from '@s_mart/minha-conta';

import { apiForInterceptors } from 'api/api';
import { telefoneSuporte } from 'config/constantes';
import useApp from 'store/app/app';

const LojaDeRecursos = () => {
  const { setLojaDeRecursos } = useApp();

  return (
    <RecursosDialog
      axiosInstance={apiForInterceptors}
      telefoneSuporte={`55${telefoneSuporte.replace(/\D/g, '')}`}
      sistemaNome='Solarfy'
      onClose={() => setLojaDeRecursos(false)}
    />
  );
};

export { LojaDeRecursos };
