import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from '@s_mart/core';
import { Searchable, Select } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { required } from '@s_mart/rules';

import useCidades from 'hooks/useCidades/useCidades';
import { MuiOption } from 'shared/global';

import ApresentacaoGrupoA from './apresentacaoGrupoA/apresentacaoGrupoA';
import AtualizarModeloPropostaDialog from './atualizarModeloPropostaDialog/atualizarModeloPropostaDialog';
import FluxoGrupoA from './fluxoGrupoA/fluxoGrupoA';
import FluxoGrupoB from './fluxoGrupoB/fluxoGrupoB';
import { tiposLigacao } from './geradorDePropostoConsumo.static';
import useGeradorDePropostaConsumo from './useGeradorDePropostaConsumo';
import useGeradorDeProposta from '../store/geradorDeProposta.store';
import useConfiguracoes from 'store/configuracoes/configuracoes';

const GeradorDePropostaConsumo = () => {
  const { setValue, watch } = useFormContext();
  const { handlePesquisarCidades, cidades, loadingCidades } = useCidades();
  const { geradorDeProposta } = useGeradorDeProposta();
  const { gerais } = useConfiguracoes();
  const {
    handleBuscarIrradiacao,
    handleBuscarValorTarifa,
    handleSearchConcessionarias,
    handleCloseApresentacaoGrupoADialog,
  } = useGeradorDePropostaConsumo();
  const telaMenorQue760 = useMediaQuery(`(max-width: 760px)`);
  const telaMenorQue780 = useMediaQuery(`(max-width: 780px)`);
  const telaMenorQue900 = useMediaQuery(`(max-width: 900px)`);

  const _formValuesCidadeProposta = watch('cidadeProposta');
  const _formValuesCodigoConcessionaria = watch('codigoConcessionaria');
  const _formValuesConcessionaria = watch('concessionaria');
  const _formValuesConcessionariaPrev = watch('concessionariaPrev');
  const _formValuesTipoLigacao = watch('tipoLigacao');

  const [_apresentacaoGrupoAOnboarding, _setApresentacaoGrupoAOnboarding] = useState(false);

  useEffect(() => {
    if (!_formValuesCidadeProposta?.value && !geradorDeProposta?.concessionarias?.length) {
      handleSearchConcessionarias({});
    }
    // A função handleSearch... é um throttle, portanto entrará em loop se colocada como dependência
  }, [_formValuesCidadeProposta?.value, geradorDeProposta?.concessionarias]);

  useEffect(() => {
    const _concessionaria = geradorDeProposta?.concessionarias?.find(
      (concessionaria: MuiOption) => concessionaria.value === _formValuesCodigoConcessionaria
    );

    if (_concessionaria && !_formValuesConcessionaria) {
      setValue('concessionaria', _concessionaria);
    }
  }, [geradorDeProposta?.concessionarias]);

  useEffect(() => {
    if (_formValuesCidadeProposta?.value) {
      handleBuscarIrradiacao();
      handleSearchConcessionarias({
        codigoUf: Number(String(_formValuesCidadeProposta?.value).slice(0, 2)) || undefined,
      });
    }
    // A função handleSearch... é um throttle, portanto entrará em loop se colocada como dependência
  }, [_formValuesCidadeProposta?.value, handleBuscarIrradiacao]);

  useEffect(() => {
    if (_formValuesConcessionaria) {
      handleBuscarValorTarifa({
        concessionaria: _formValuesConcessionaria,
        concessionariaPrev: _formValuesConcessionariaPrev,
      });
    }
  }, [handleBuscarValorTarifa, _formValuesConcessionaria]);

  useEffect(() => {
    if (geradorDeProposta?.abrirOnboardingGrupoA) {
      _setApresentacaoGrupoAOnboarding(true);
    }
  }, [geradorDeProposta?.abrirOnboardingGrupoA]);

  return (
    <>
      {_apresentacaoGrupoAOnboarding && (
        <ApresentacaoGrupoA
          onClose={() => {
            _setApresentacaoGrupoAOnboarding(false);
            handleCloseApresentacaoGrupoADialog();
          }}
        />
      )}
      {_formValuesTipoLigacao?.value === '3' && gerais?.modeloProposta === 0 && (
        <AtualizarModeloPropostaDialog onClose={() => setValue('tipoLigacao', undefined)} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <Searchable
                name='cidadeProposta'
                label='Cidade/UF da proposta'
                textFieldProps={{
                  placeholder: 'Pesquise e selecione a cidade',
                }}
                onInputChange={(value) => handlePesquisarCidades(value as string)}
                options={cidades.map((item) => ({
                  value: String(item.codigo),
                  label: `${item.nome} - ${item.uf}`,
                }))}
                required
                disableClearable
                rules={required}
                loading={loadingCidades}
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <Searchable
                name='concessionaria'
                label='Concessionária'
                options={geradorDeProposta?.concessionarias || []}
                textFieldProps={{
                  placeholder: 'Pesquise e selecione',
                }}
                required
                disableClearable
                rules={required}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={(() => {
                if (_formValuesTipoLigacao?.value !== '3') {
                  if (telaMenorQue760) {
                    return 4;
                  } else {
                    return 3;
                  }
                } else {
                  if (telaMenorQue780) {
                    return 6;
                  } else if (telaMenorQue900) {
                    return 4;
                  } else {
                    return 2;
                  }
                }
              })()}
            >
              <div id='select-tipo-ligacao'>
                <Select
                  name='tipoLigacao'
                  label='Tipo de ligação'
                  placeholder='Selecione'
                  required
                  options={tiposLigacao}
                  rules={required}
                />
              </div>
            </Grid>
            {_formValuesTipoLigacao?.value === '3' ? <FluxoGrupoA /> : <FluxoGrupoB />}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(GeradorDePropostaConsumo);
