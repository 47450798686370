import { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { LIcon, Typography } from '@s_mart/core';
import { Autocomplete } from '@s_mart/form';
import { lineExclamationTriangle } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import { ListagemProdutosTypes } from 'containers/produtos/produtos.types';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import { removeCaracteresEspeciais } from 'utils/removeCaracteresEspeciais/removeCaracteresEspeciais';

import useFieldsOutros from '../useFieldsOutros';
import { useFields } from '../../useFields';
import { DivAlertaFieldsNome } from '../../fields.styles';

const AutoCompleteFieldNomeOutros = ({ index }: { index: number }) => {
  const { outros, loadingOutros, handleBuscarOutroSelecionado } = useFieldsOutros();
  const { recursosContratados } = useConfiguracoes();
  const { handleCalcularValorTotal } = useFields();
  const methods = useFormContext();

  const _formValuesOutros = methods.watch('outros');

  const _outrosOptions = useMemo(
    () =>
      outros
        ?.filter((it: ListagemProdutosTypes) => it.situacao)
        ?.map((item: ListagemProdutosTypes) => ({
          label: item.nome,
          value: item.codigo,
        })),
    [outros]
  );

  return (
    <>
      <Autocomplete
        name={`outros.${index}.modeloOutros`}
        label='Serviço / outro equipamento'
        value={methods.watch(`outros.${index}.modeloOutros`) || null}
        textFieldProps={{
          placeholder: 'Selecione ou informe um novo tipo de instalação, estrutura, cabos, etc.',
          inputProps: { maxLength: 90 },
        }}
        onChange={(value, values) => {
          handleBuscarOutroSelecionado(index, value);
          if (recursosContratados?.['valor-de-venda-unitario']) {
            setTimeout(() => handleCalcularValorTotal({ values, methods }), 300);
          }
        }}
        onInputChange={(value) => removeCaracteresEspeciais(value, `outros.${index}.modeloOutros`, methods.setValue)}
        disableClearable
        options={_outrosOptions || []}
        loading={loadingOutros}
      />
      {typeof _formValuesOutros?.[index]?.modeloOutros === 'string' &&
      _outrosOptions?.find((it) => it.label === _formValuesOutros?.[index]?.modeloOutros) ? (
        <DivAlertaFieldsNome>
          <LIcon icon={lineExclamationTriangle} removeMargin size='18px' color={colorPalette.yellow[300]} />
          <Typography variant='bodyXS'>Um produto com esse nome já está cadastrado.</Typography>
        </DivAlertaFieldsNome>
      ) : undefined}
    </>
  );
};

export default memo(AutoCompleteFieldNomeOutros);
