import { create } from 'zustand';

import { ConfiguracoesStore, ConfiguracoesState } from './configuracoes.types';
import useApp from 'store/app/app';
import { atualizarParametros, buscarConfiguracoes } from 'api/configuracoes/configuracoes';

const initialState: ConfiguracoesState = {
  loaded: false,
  gerais: undefined,
  dadosCobranca: undefined,
  informacoesPlano: undefined,
  meusDados: undefined,
  mostrarEditarModeloDialog: false,
  recursosContratados: undefined,
  vendedorAtual: undefined,
  statusFatura: 0,
  parametros: undefined,
  propostasGeradas: 0,
  buscouPropostasGeradas: false,
};

const useConfiguracoes = create<ConfiguracoesStore>((set, get) => ({
  ...initialState,
  setPropostasGeradas: (propostasGeradas) => set({ propostasGeradas, buscouPropostasGeradas: true }),
  setDadosCobranca: (dadosCobranca) => set({ dadosCobranca }),
  setMeusDados: (meusDados) => set({ meusDados }),
  setStatusFatura: (statusFatura) => set({ statusFatura }),
  setRecursosContratados: (recursosContratados) => set({ recursosContratados }),
  setInformacoesPlano: (informacoesPlano) => set({ informacoesPlano }),
  setVendedorAtual: (vendedorAtual) => set({ vendedorAtual }),

  async atualizarConfiguracoesGerais() {
    try {
      const { data } = await buscarConfiguracoes();

      set((state) => ({
        ...state,
        gerais: data.data,
        parametros: data.data.parametros && JSON?.parse(data.data.parametros),
        loaded: true,
      }));
    } catch (err) {
      useApp.getState().handleError(err);
    }
  },

  async atualizarParametrosConfiguracoes(parametros) {
    try {
      const novosParametros = { ...get().parametros, ...parametros };

      await atualizarParametros({ parametros: JSON.stringify(novosParametros) });

      get().atualizarConfiguracoesGerais();
    } catch (err) {
      useApp.getState().handleError(err);
    }
  },
  setConfiguracoesGerais: (gerais) => set({ gerais }),
  setMostrarEditarModeloDialog: (mostrarEditarModeloDialog) => set({ mostrarEditarModeloDialog }),
  limparConfiguracoes: () => set(initialState),
}));

export default useConfiguracoes;
