import { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ToggleButton } from '@mui/material';

import { TextField, Searchable, Autocomplete } from '@s_mart/form';
import { Grid, Typography } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { composeValidators, email, required, telefone, cnpj, cpf } from '@s_mart/rules';
import { colorPalette } from '@s_mart/tokens';

import useCidades from 'hooks/useCidades/useCidades';
import useSearchable from 'hooks/useSearchable/useSearchable';
import useGeradorDeProposta from 'shared/modais/geradorDeProposta/store/geradorDeProposta.store';

import { SToggleButtonGroup, StyledSwitch } from './cadastrarClienteConteudo.styles';
import useCadastrarClienteConteudo from './useCadastrarClienteConteudo';

const CadastrarClienteConteudo = () => {
  const { setValue, watch } = useFormContext();

  const { handlePesquisarCidades, cidades } = useCidades();
  const { handleTipoPessoa, buscarDadosCnpj } = useCadastrarClienteConteudo();
  const { resultados, handleSearchClientes, loadingUseSearchable } = useSearchable();
  const { geradorDeProposta, setGeradorDeProposta } = useGeradorDeProposta();
  const telaMenorQue420 = useMediaQuery(`(max-width: 420px)`);
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  const _formValues = watch();

  useEffect(() => {
    if (geradorDeProposta?.aberto) {
      if (!_formValues.cidadeProposta) {
        setValue('cidadeProposta', _formValues.dadosCidade);
      }
    }
  }, [geradorDeProposta?.aberto, _formValues.cidadeProposta, _formValues.dadosCidade, setValue]);

  return (
    <>
      <Grid item xs={12}>
        <SToggleButtonGroup fullWidth={telaMenorQue600} value={_formValues.tipoPessoa ?? '0'}>
          <ToggleButton value={'0'} onClick={() => setValue('tipoPessoa', '0')}>
            <Typography
              variant='titleXS'
              color={_formValues.tipoPessoa === '1' ? colorPalette.neutral[900] : colorPalette.neutral[0]}
            >
              Pessoa
            </Typography>
          </ToggleButton>
          <ToggleButton value={'1'} onClick={() => setValue('tipoPessoa', '1')}>
            <Typography
              variant='titleXS'
              color={_formValues.tipoPessoa === '1' ? colorPalette.neutral[0] : colorPalette.neutral[900]}
            >
              Empresa
            </Typography>
          </ToggleButton>
        </SToggleButtonGroup>
      </Grid>
      <Grid item xs={telaMenorQue420 ? 12 : 6}>
        {geradorDeProposta?.aberto ? (
          <Autocomplete
            name='nome'
            label='Nome do cliente'
            textFieldProps={{
              placeholder: 'Nome completo',
            }}
            freeSolo
            forcePopupIcon
            disableClearable
            required
            options={
              resultados?.clientes?.map((value: any) => ({
                label: String(value.label),
                value: Number(value.value),
              })) || []
            }
            onInputChange={(value) => {
              if (typeof value === 'string') {
                handleSearchClientes(value);
                if (geradorDeProposta?.clienteSelecionado) {
                  setValue('tipoPessoa', '0');
                  setValue('telefone', '');
                  setValue('cadastroAvancado', undefined);
                  setValue('cpfCnpj', undefined);
                  setValue('razaoSocial', undefined);
                  setValue('nomeResponsavel', undefined);
                  setValue('email', undefined);
                  setValue('dadosCidade', undefined);
                  setValue('rua', undefined);
                  setValue('bairro', undefined);
                  setValue('numero', undefined);
                  setValue('complemento', undefined);
                  setGeradorDeProposta({ clienteSelecionado: false });
                }
              }
            }}
            onChange={() => setGeradorDeProposta({ clienteSelecionado: true })}
            rules={required}
            loading={loadingUseSearchable}
          />
        ) : (
          <TextField name='nome' label='Nome do cliente' placeholder='Nome completo' required rules={required} />
        )}
      </Grid>
      <Grid item xs={telaMenorQue420 ? 12 : 6}>
        <TextField
          name='telefone'
          label='Telefone'
          placeholder={!geradorDeProposta?.clienteSelecionado ? '(54) 9 1234-1234' : undefined}
          required
          mask='telefone'
          rules={composeValidators([telefone, required])}
        />
      </Grid>
      <Grid item xs={12} style={{ marginLeft: '8px' }}>
        <StyledSwitch
          name='cadastroAvancado'
          label={`${telaMenorQue420 ? 'C' : 'Habilitar c'}adastro completo`}
          checked={_formValues.cadastroAvancado || false}
        />
      </Grid>
      {_formValues.cadastroAvancado && (
        <>
          <Grid item xs={12} sm={5}>
            <TextField
              name='cpfCnpj'
              label={handleTipoPessoa(_formValues.tipoPessoa) ? 'CNPJ' : 'CPF'}
              placeholder={
                !geradorDeProposta?.clienteSelecionado
                  ? handleTipoPessoa(_formValues.tipoPessoa)
                    ? '_ _-_ _ _-_ _ _ / _ _ _ _-_ _'
                    : '_ _ _-_ _ _-_ _ _-_ _'
                  : undefined
              }
              onInputChange={(value) => {
                if (handleTipoPessoa(_formValues.tipoPessoa) && value) {
                  buscarDadosCnpj(value);
                }
              }}
              inputProps={{ maxLength: handleTipoPessoa(_formValues.tipoPessoa) ? 18 : 14 }}
              onPaste={({ target }) => {
                if (handleTipoPessoa(_formValues.tipoPessoa) && target) {
                  //@ts-expect-error tipagem errada, possui value no target
                  setTimeout(() => buscarDadosCnpj(target?.value), 0);
                }
              }}
              mask={handleTipoPessoa(_formValues.tipoPessoa) ? 'cnpj' : 'cpf'}
              rules={_formValues.cpfCnpj && (handleTipoPessoa(_formValues.tipoPessoa) ? cnpj : cpf)}
            />
          </Grid>
          {handleTipoPessoa(_formValues.tipoPessoa) && (
            <>
              <Grid item xs={12} sm={7}>
                <TextField name='razaoSocial' label='Razão social' />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField name='nomeResponsavel' label='Nome do responsável' />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={7}>
            <TextField
              name='email'
              label='E-mail'
              placeholder={!geradorDeProposta?.clienteSelecionado ? 'exemplo@exemplo.com.br' : undefined}
              rules={email}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Searchable
              name='dadosCidade'
              label='Cidade de instalação'
              textFieldProps={{
                placeholder: !geradorDeProposta?.clienteSelecionado ? 'Busque a cidade' : undefined,
              }}
              onInputChange={(value) => {
                handlePesquisarCidades(value as string);
              }}
              options={cidades.map((item) => ({
                label: `${item.nome} - ${item.uf}`,
                value: String(item.codigo),
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <TextField name='rua' label='Rua' />
          </Grid>
          <Grid item xs={4} sm={2}>
            <TextField name='numero' label='Número' />
          </Grid>
          <Grid item xs={8} sm={5}>
            <TextField name='bairro' label='Bairro' />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField name='complemento' label='Complemento' />
          </Grid>
        </>
      )}
    </>
  );
};

export default memo(CadastrarClienteConteudo);
