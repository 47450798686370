import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import useAuth from 'store/auth/auth';

const useSentry = () => {
  const profile = useAuth.getState().profile;

  // Não enviar erros para o Sentry no ambiente de desenvolvimento
  if (import.meta.env.MODE !== 'production') {
    return;
  }

  if (Sentry?.init && typeof Sentry.init === 'function') {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,

      integrations: [new Integrations.BrowserTracing(), new CaptureConsole({ levels: ['error'] })],

      normalizeDepth: 10,
      environment: import.meta.env.MODE,
      release: import.meta.env.VITE_SENTRY_RELEASE,

      // To set a uniform sample rate
      tracesSampleRate: 0.2,

      beforeSend(event) {
        // se o erro for do axios, não envia para o sentry
        const exceptionValue = event.exception?.values?.[0];
        if (exceptionValue?.type === 'AxiosError' || exceptionValue?.value?.includes('AxiosError')) {
          return null;
        }

        return event;
      },
    });
  }

  Sentry.configureScope((scope) => {
    scope.setUser({
      username: profile?.nome,
      id: String(profile?.emp),
      email: profile?.email,
      empNome: profile?.razao_social,
    });
  });
};

export default useSentry;
