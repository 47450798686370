import { useCallback, useState } from 'react';

import type {
  IAdicionarEditarMotivoState,
  IMotivoDePerda,
} from '../adicionarEditarMotivoDialog/adicionarEditarMotivoDialog.types';

export function useConfigurarMotivosPerdaDialog() {
  const [adicionarEditarMotivoDialog, setAdicionarEditarMotivoDialog] = useState<IAdicionarEditarMotivoState>({
    open: false,
  });

  const openAdicionarMotivo = useCallback(() => {
    setAdicionarEditarMotivoDialog({ open: true });
  }, []);

  const openEditarMotivo = useCallback((motivo: IMotivoDePerda) => {
    setAdicionarEditarMotivoDialog({ open: true, motivo });
  }, []);

  const handleCloseAdicionarEditarMotivoDialog = useCallback(() => {
    setAdicionarEditarMotivoDialog({ open: false });
  }, []);

  return {
    adicionarEditarMotivoDialog,
    openAdicionarMotivo,
    openEditarMotivo,
    handleCloseAdicionarEditarMotivoDialog,
  };
}
