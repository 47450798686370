import { useCallback } from 'react';

import * as vendedoresApi from 'api/vendedores/vendedores';
import type { CadastrarEditarVendedorTypes } from 'api/vendedores/vendedores.types';
import { queryClient } from 'api/queryClient';
import useApp from 'store/app/app';

import type { VendedorFormDialogValues } from './vendedorFormDialog.types';
import useVendedorFormDialog from './store/vendedorFormDialog.store';
import useConvidarVendedorComoUsuarioDialog from '../convidarVendedorComoUsuarioDialog/store/convidarVendedorComoUsuarioDialog.store';

function useHandleVendedorFormDialogSubmit(handleCloseVendedorFormDialog: (isDirty?: boolean) => void) {
  const { setLoading, handleError, setNotification } = useApp();
  const { setConvidarVendedorComoUsuarioDialog } = useConvidarVendedorComoUsuarioDialog();
  const { vendedorFormDialog } = useVendedorFormDialog();

  const handleVendedorFormDialogSubmit = useCallback(
    (tipoChamada: 'editar' | 'cadastrar') => async (values: VendedorFormDialogValues) => {
      const _payload: CadastrarEditarVendedorTypes = {
        nome: values.nome,
      };

      if (values.email) {
        _payload.email = values.email;
      }
      if (values.telefone) {
        _payload.telefone = String(values.telefone);
      }

      switch (tipoChamada) {
        default:
        case 'cadastrar': {
          setLoading(true);

          try {
            const { data } = await vendedoresApi.cadastrarVendedor(_payload);

            setNotification({ title: 'Novo vendedor cadastrado!' });
            handleCloseVendedorFormDialog();
            setConvidarVendedorComoUsuarioDialog({
              aberto: true,
              dados: { nome: _payload.nome, codigo: data?.data, emailVendedor: _payload.email },
              aposCadastro: true,
            });
            setLoading(false);
          } catch (err) {
            handleError(err);
          }

          break;
        }

        case 'editar': {
          setLoading(true);

          if (vendedorFormDialog?.codigoVendedor) {
            try {
              await vendedoresApi.editarVendedor(vendedorFormDialog.codigoVendedor, _payload);

              setNotification({ title: 'Alterações salvas.' });
              queryClient.clear();
              handleCloseVendedorFormDialog();
              setLoading(false);
            } catch (err) {
              handleError(err);
            }
          }

          break;
        }
      }
    },
    [
      handleCloseVendedorFormDialog,
      handleError,
      setLoading,
      setNotification,
      setConvidarVendedorComoUsuarioDialog,
      vendedorFormDialog.codigoVendedor,
    ]
  );

  return { handleVendedorFormDialogSubmit };
}

export default useHandleVendedorFormDialogSubmit;
