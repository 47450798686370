import styled from '@emotion/styled';

import { colorPalette } from '@s_mart/tokens';

export const DivTabelaPaybackRow = styled.div<{ index: number }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  background-color: ${({ index }) => (index % 2 === 0 ? colorPalette.neutral[20] : undefined)};
`;
