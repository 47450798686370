import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import type { DropzoneFile } from '@s_mart/dropzone';
import { fileToBase64, createResizedImage } from '@s_mart/utils';

const useDropzoneFieldDialogFunctions = (variant: 'logo' | 'banner') => {
  const { setValue } = useFormContext();

  const [imagemBase64, _setImagemBase64] = useState<string | ArrayBuffer | null>('');
  const [file, _setFile] = useState<DropzoneFile[]>();

  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      createResizedImage(
        file,
        variant === 'logo' ? 270 : 816,
        variant === 'logo' ? 202 : 612,
        'PNG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });

  const handleTratarImagemDropzone = async (images: DropzoneFile[]) => {
    const _dropzoneValue = images;
    const _image = images.find((it) => it.valid === true)?.file;

    if (_image) {
      const _resizedFile = await resizeFile(_image);

      _dropzoneValue[0].file = _resizedFile as File;
      _setFile(_dropzoneValue);

      if (_resizedFile) {
        const _base64Imagem = await fileToBase64(_resizedFile as File);
        _setImagemBase64(_base64Imagem);
      }
    }

    return _dropzoneValue;
  };

  const onDelete = () => {
    _setFile(undefined);
    _setImagemBase64(null);
  };

  const handleSubmitInserirLogoEBannerDialog = (variant: 'logo' | 'banner') => {
    setValue(variant, imagemBase64);
  };

  return { file, handleTratarImagemDropzone, onDelete, handleSubmitInserirLogoEBannerDialog };
};

export default useDropzoneFieldDialogFunctions;
