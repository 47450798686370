export const modelosPropostasTodos = [
  { label: 'Proposta antiga', value: '0' },
  { label: 'Proposta nova', value: '1' },
  { label: 'Proposta personalizada', value: '2' },
  { label: 'Proposta resumida', value: '3' },
];

export const modelosPropostasOptions = [
  { label: 'Proposta antiga', value: '0' },
  { label: 'Proposta nova', value: '1' },
  { label: 'Proposta resumida', value: '3' },
];
