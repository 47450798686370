import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Button } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { colorPalette } from '@s_mart/tokens';

import useBannerCobrancas from './useBannerCobrancas';

const BannerCobrancas = () => {
  const _navigate = useNavigate();
  const { situacaoBoleto } = useBannerCobrancas();
  const telaMenorQue340 = useMediaQuery('(max-width: 340px)');

  const _situacao = situacaoBoleto();

  if (!_situacao) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '40px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colorPalette.yellow[300],
        gap: '12px',
      }}
      id='banner-cobrancas'
    >
      <Typography variant={telaMenorQue340 ? 'titleXXS' : 'titleXS'} color={colorPalette.neutral[0]}>
        {_situacao}
      </Typography>
      <Button
        size='small'
        color='success'
        onClick={() => {
          _navigate('minha-conta?meu-plano');
        }}
      >
        <Typography variant={telaMenorQue340 ? 'titleXXS' : 'titleXS'} color={colorPalette.neutral[0]}>
          Verificar faturas
        </Typography>
      </Button>
    </div>
  );
};

export default memo(BannerCobrancas);
