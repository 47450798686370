export enum InformarPagamentoDialogEtapa {
  BOLETO_EM_ABERTO = 0,
  BOLETOS_EM_ABERTO = 1,
  INFORMADO_COMO_PAGO = 2,
  BOTAO_DESBLOQUEIO_UTILIZADO = 3,
  BOLETO_DISPONIVEL = 4,
}

export interface IInformarPagamentoDialogContext {
  step: InformarPagamentoDialogEtapa;
  linhaDigitavelComRegex: string;
  handleAbrirMeuPlano: () => void;
  handleAbrirContatoSuporte: () => void;
  handleCopiarLinhaDigitavel: () => void;
  handleBuscarInformacoesEmpresa: () => void;
  handleMinhaFaturaFoiPaga: () => void;
  handleAbrirBoletoPagamento: () => void;
  handleMarcarBoletoVisualizado: () => void;
}
