export const copyDialogProdutoDesatualizadoOuExcluido = [
  {
    titulo: 'Produtos desatualizados',
    descricao: 'A proposta que você está clonando possui produtos com valores de venda desatualizados.',
    descricao2: 'Gostaria de usar os últimos valores de venda cadastrados?',
    textoBotaoSecundario: 'Manter valores',
    textoBotaoPrimario: 'Atualizar valores',
  },
  {
    titulo: 'Produtos não encontrados',
    descricao: 'A proposta que você está clonando possui produtos que estão inativos ou que foram excluídos.',
    descricao2: 'Gostaria de remover estes produtos da proposta?',
    textoBotaoSecundario: 'Manter produtos',
    textoBotaoPrimario: 'Remover produtos',
  },
  {
    titulo: 'Produtos desatualizados',
    descricao:
      'A proposta que você está clonando contém produtos com valores de venda desatualizados e outros que foram inativados ou excluídos.',
    descricao2:
      'Deseja atualizar os valores de venda dos produtos existentes? Ao fazer isso, os produtos excluídos e inativos serão removidos da proposta.',
    textoBotaoSecundario: 'Manter produtos',
    textoBotaoPrimario: 'Atualizar produtos',
  },
];
