import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { Global } from '@emotion/react';

import { SmartProvider } from '@s_mart/core';

import { queryClient } from 'api/queryClient';
import { isProduction } from 'api/variables';
import useClarity from 'hooks/useClarity/useClarity';
import useSentry from 'hooks/useSentry/useSentry';
import useAuth from 'store/auth/auth';
import GlobalStyle from 'styles/globalStyle';
import { smartTheme } from 'styles/theme/theme';
import { isStaging } from 'utils/isStaging/isStaging';

import Routes from './routes/routes';
import { amoCRMScript } from './scripts/amocrm';
import { useIsTesteGratis } from 'hooks/useIsTesteGratis/useIsTesteGratis';

dayjs.locale('pt-br');

const App = () => {
  const { profile } = useAuth();
  const { initializeClarity } = useClarity();
  const isTesteGratis = useIsTesteGratis();

  useEffect(() => {
    // Inicializa o Google Analytics
    if (isProduction) {
      ReactGA.initialize('G-XTYMNCVQF7', {
        gaOptions: {
          userId: profile?.emp,
        },
      });
    }

    // Inicializa o Clarity
    initializeClarity();

    // Inicializa o Sentry
    useSentry();

    // Adiciona o script do amoCRM
    if (isTesteGratis) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = amoCRMScript;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }, []);

  // Use effect responsável por fazer o redirect para o site principal caso não esteja.
  // TODO: remover no futuro quando o secundário não for mais necessário
  useEffect(() => {
    const location = window.location.href;
    const _isStaging = isStaging();

    const oldUrlStaging = 'staging2.solarfy.com.br';
    const newUrlStaging = 'staging.solarfy.com.br';
    const oldUrlProduction = 'app2.solarfy.com.br';
    const newUrlProduction = 'app.solarfy.com.br';

    const address = location.split('/')[2];
    const rest = location.split('/')[3];

    if (_isStaging) {
      if (address === oldUrlStaging) {
        window.location.href = `https://${newUrlStaging}/${rest}`;
      }
    } else {
      if (location.split('/')[2] === oldUrlProduction) {
        window.location.href = `https://${newUrlProduction}/${rest}`;
      }
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SmartProvider theme={smartTheme}>
        <Global styles={GlobalStyle} />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </SmartProvider>
    </QueryClientProvider>
  );
};

export default App;
