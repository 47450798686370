import { CSSProperties, memo } from 'react';

import { base64ToBlob } from '@s_mart/utils';

interface IVisualizarPdf {
  title: string;
  src: string;
  style?: CSSProperties | undefined;
}

const VisualizarPdf = ({ title, src, style }: IVisualizarPdf) => {
  if (!src) return null;

  const _blob = base64ToBlob(src, 'application/pdf');
  const _url = URL.createObjectURL(_blob);

  return <iframe style={style ?? { display: 'flex', height: '100%', width: '100%' }} title={title} src={_url} />;
};

export default memo(VisualizarPdf);
