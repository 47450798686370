import { memo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid, LIcon, Tooltip, Typography } from '@s_mart/core';
import { TextField } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { composeValidators, required, maiorQue, menorQue } from '@s_mart/rules';
import { lineInfoCircle } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import ComoFuncionaSimultaneidadeDialog from 'components/comoFuncionaSimultaneidadeDialog/comoFuncionaSimultaneidadeDialog';
import GraficoPayback from 'shared/modais/geradorDeProposta/geradorDePropostaImagens/graficoPayback/graficoPayback';
import { corPrimariaSolarfy } from 'styles/theme/theme';

import useBaseDeCalculoPaybackDialogFields from './useBaseDeCalculoPaybackDialogFields';
import useGeradorDePropostaSistemaPayback from '../../useGeradorDePropostaSistemaPayback';
import useConfiguracoes from 'store/configuracoes/configuracoes';

const BaseDeCalculoPaybackDialogFields = () => {
  const { watch } = useFormContext();
  const { gerais } = useConfiguracoes();
  const { handleResponsividadeFields } = useBaseDeCalculoPaybackDialogFields();
  const telaMenorQue460 = useMediaQuery(`(max-width: 460px)`);

  //! Apenas chama esse hook para recalcular em tempo real o payback
  useGeradorDePropostaSistemaPayback();

  const [_comoFuncionaSimultaneidadeDialog, _setComoFuncionaSimultaneidadeDialog] = useState(false);

  const _formValuesCor = watch('cor');
  const _formValuesTipoLigacao = watch('tipoLigacao');

  return (
    <>
      {_comoFuncionaSimultaneidadeDialog && (
        <ComoFuncionaSimultaneidadeDialog onClose={() => _setComoFuncionaSimultaneidadeDialog(false)} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: 'grid', gridTemplateColumns: handleResponsividadeFields(), gap: '8px' }}>
          <TextField
            name='degradacaoSistema'
            label='Degradação do sistema'
            required
            rules={composeValidators([maiorQue(0), menorQue(5), required])}
            mask='porcentagem'
          />
          <TextField
            name='taxaInflacao'
            label={
              <div style={{ display: 'flex', gap: '4px' }}>
                <Typography variant='titleXXS'>Taxa de inflação</Typography>
                <Tooltip
                  title='Previsão de aumento para 2023, segundo o IBGE.'
                  PopperProps={{ popperOptions: { placement: 'top' } }}
                >
                  <div style={{ display: 'flex' }}>
                    <LIcon icon={lineInfoCircle} removeMargin size='16px' color={colorPalette.neutral[100]} />
                  </div>
                </Tooltip>
              </div>
            }
            required
            rules={composeValidators([maiorQue(0), menorQue(100), required])}
            mask='porcentagem'
          />
          {_formValuesTipoLigacao?.value !== '3' && (
            <TextField
              name='simultaneidade'
              label={
                <div style={{ display: 'flex', gap: '4px' }}>
                  <Typography variant='titleXXS'>Fator de simultaneidade</Typography>
                  <Tooltip
                    title='Quantos % da energia gerada será utilizada de forma instantânea'
                    PopperProps={{ popperOptions: { placement: 'top' } }}
                  >
                    <div style={{ display: 'flex' }}>
                      <LIcon icon={lineInfoCircle} removeMargin size='16px' color={colorPalette.neutral[100]} />
                    </div>
                  </Tooltip>
                </div>
              }
              mask='porcentagem'
              FormHelperTextProps={{
                sx: { fontSize: '10px', textDecoration: 'underline', cursor: 'pointer' },
                onClick: () => _setComoFuncionaSimultaneidadeDialog(true),
              }}
              helperText='Como funciona a simultaneidade?'
            />
          )}
          <TextField
            name='retornoInvestimento'
            label='Payback estimado em'
            disabled
            sx={{
              '.Mui-disabled': {
                WebkitTextFillColor: colorPalette.neutral[500],
              },
            }}
          />
          <TextField
            name='economia25'
            label='Economia em 25 anos'
            disabled
            mask='valor'
            sx={{
              '.Mui-disabled': {
                WebkitTextFillColor: colorPalette.neutral[500],
              },
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography variant='titleXS'>Payback estimado</Typography>
          <div
            style={{
              height: '290px',
              borderRadius: '6px',
              border: `1px solid ${colorPalette.neutral[50]}`,
              overflow: 'hidden',
            }}
          >
            <GraficoPayback
              cor={_formValuesCor || gerais?.hexCor || corPrimariaSolarfy}
              height={300}
              mobile={telaMenorQue460}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(BaseDeCalculoPaybackDialogFields);
