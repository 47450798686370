import React, { useEffect } from 'react';

import { CircularProgress, Grid } from '@s_mart/core';

import Cabecalho from './cabecalho/cabecalho';
import Header from './header/header';
import TabelaFornecedores from './tabelaFornecedores/tabelaFornecedores';
import Footer from './footer/footer';
import { IntegracaoFornecedoresProvider } from './geradorDePropostaIntegracaoFornecedores.context';

function GeradorDePropostaIntegracaoFornecedores() {
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display='flex' justifyContent='center'>
        <Cabecalho />
      </Grid>
      <Grid item xs={12}>
        <Header />
      </Grid>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid item xs={12}>
          <TabelaFornecedores />
        </Grid>
      )}
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
}

function GeradorDePropostaIntegracaoFornecedoresWrapper() {
  return (
    <IntegracaoFornecedoresProvider>
      <GeradorDePropostaIntegracaoFornecedores />
    </IntegracaoFornecedoresProvider>
  );
}

export { GeradorDePropostaIntegracaoFornecedoresWrapper as GeradorDePropostaIntegracaoFornecedores };
