import { memo } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { Grid } from '@s_mart/core';

import useFieldsKitsDeServicos from './useFieldsKitsDeServicos';
import FieldsDetalhesKit from '../fieldsDetalhesKit/fieldsDetalhesKit';

const FieldsKitsDeServicos = () => {
  const { kitsDeServico, loadingKitsDeServico, handleBuscarKitDeServicoSelecionado } = useFieldsKitsDeServicos();
  const { control } = useFormContext();

  const _fieldArrayKitsDeServicos = useFieldArray({ name: 'kitsDeServicos', control });

  return (
    <Grid container spacing={2}>
      {_fieldArrayKitsDeServicos.fields.map((field, index: number) => (
        <FieldsDetalhesKit
          name='kitsDeServicos'
          fields={_fieldArrayKitsDeServicos.fields}
          index={index}
          kits={kitsDeServico}
          loadingKits={loadingKitsDeServico}
          handleBuscarKit={handleBuscarKitDeServicoSelecionado}
          finalNomeFields='KitsDeServicos'
          key={field.id}
        />
      ))}
    </Grid>
  );
};

export default memo(FieldsKitsDeServicos);
