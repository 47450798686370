import { Stack, ToggleButton } from '@mui/material';
import { Typography } from '@s_mart/core';
import { decimal } from '@s_mart/masks';
import { colorPalette } from '@s_mart/tokens';
import { useMediaQuery } from '@s_mart/hooks';

import { useHeaderSistemaPayback } from './useHeaderSistemaPayback';
import { SStack, SToggleButtonGroup } from './headerSistemaPayback.styles';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';

export function HeaderSistemaPayback() {
  const telaMenorQue1024 = useMediaQuery('(max-width: 1024px)');
  const isMobile = useIsMobile();

  const { consumoMedio, potencia, geracao, caminhoGerador, handleCaminhoGerador } = useHeaderSistemaPayback();

  return (
    <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 1 : 2} alignItems='center'>
      <SToggleButtonGroup size='small' value={caminhoGerador}>
        <ToggleButton value={1} onClick={() => handleCaminhoGerador('kits')}>
          Kit&apos;s
        </ToggleButton>
        <ToggleButton value={0} onClick={() => handleCaminhoGerador('produtos')}>
          Itens Avulsos
        </ToggleButton>
      </SToggleButtonGroup>
      <SStack direction={isMobile ? 'column' : 'row'} spacing={2}>
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
            Consumo {!telaMenorQue1024 && 'médio'}
          </Typography>
          <Typography variant='titleXXS'>{consumoMedio} kWh</Typography>
        </Stack>
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
            Potência {!telaMenorQue1024 && 'do sistema'}
          </Typography>
          <Typography variant='titleXXS'>{decimal.format(potencia)} kWp</Typography>
        </Stack>
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
            Geração {!telaMenorQue1024 && 'do sistema'}
          </Typography>
          <Typography variant='titleXXS'>{decimal.format(geracao)} kWh</Typography>
        </Stack>
      </SStack>
    </Stack>
  );
}
