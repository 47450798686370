import { useEffect } from 'react';

import { listarFornecedores } from 'api/fornecedores/fornecedores';
import { useQuery } from 'react-query';
import { useFormContext } from 'react-hook-form';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import { FornecedorEnum } from 'api/fornecedores/fornecedores.types';

export function useHeader() {
  const { gerais } = useConfiguracoes();
  const { watch, setValue } = useFormContext();
  const { data, isLoading } = useQuery({
    queryKey: 'fornecedores',
    queryFn: async () => {
      return await listarFornecedores();
    },
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const fornecedor = watch('fornecedores')?.value?.codigo;
  const qtdFornecedores = gerais?.fornecedores?.length || 0;

  const optionsFornecedores = [
    ...(data?.data?.data
      ?.map((fornecedor) => ({
        label: fornecedor.nome,
        value: { codigo: fornecedor.codigo, tipoIntegracao: fornecedor.tipoIntegracao },
      }))
      ?.filter((it) => gerais?.fornecedores?.includes(it.value.codigo)) || []),
  ];

  useEffect(() => {
    const fornecedorPadrao = gerais?.fornecedores?.includes(FornecedorEnum.EDELTEC)
      ? optionsFornecedores.find((it) => it.value.codigo === FornecedorEnum.EDELTEC)
      : optionsFornecedores[0];

    setValue('fornecedores', fornecedorPadrao);
  }, [data]);

  return {
    data: data?.data,
    isLoading,
    optionsFornecedores,
    fornecedor,
    qtdFornecedores,
  };
}
