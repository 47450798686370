import { memo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Grid } from '@s_mart/core';

import useFieldsKitsFotovoltaicos from './useFieldsKitsFotovoltaicos';
import FieldsDetalhesKit from '../fieldsDetalhesKit/fieldsDetalhesKit';

const FieldsKitsFotovoltaicos = () => {
  const { kitsFotovoltaicos, loadingKitsFotovoltaicos, handleBuscarKitFotovoltaicoSelecionado } =
    useFieldsKitsFotovoltaicos();

  const { control } = useFormContext();
  const _fieldArrayKitsFotovoltaicos = useFieldArray({ name: 'kitsFotovoltaicos', control });

  return (
    <Grid container spacing={2}>
      {_fieldArrayKitsFotovoltaicos.fields.map((field, index: number) => (
        <FieldsDetalhesKit
          name='kitsFotovoltaicos'
          fields={_fieldArrayKitsFotovoltaicos}
          index={index}
          kits={kitsFotovoltaicos}
          loadingKits={loadingKitsFotovoltaicos}
          handleBuscarKit={handleBuscarKitFotovoltaicoSelecionado}
          finalNomeFields='KitsFotovoltaicos'
          key={field.id}
        />
      ))}
    </Grid>
  );
};

export default memo(FieldsKitsFotovoltaicos);
