import { memo } from 'react';

import { Chip, LIcon, Typography } from '@s_mart/core';
import { valor } from '@s_mart/masks';
import { lineBuilding, lineHome, lineUser } from '@s_mart/regular-icons';
import { colorPalette } from '@s_mart/tokens';

import type { ListarPropostasTypes } from 'api/propostas/propostas.types';
import usePropostasFunctions from 'containers/propostas/usePropostasFunctions';
import * as styled from 'containers/propostas/propostasList/propostasListItem/propostasListItem.styles';
import AcoesListagensMenu from 'components/acoesListagensMenu/acoesListagensMenu';

import useVisualizarListaPropostasDialog from '../../store/visualizarListaPropostasDialog.store';

const VisualizarListaPropostasDialogListItem = ({ rowItem }: { rowItem: ListarPropostasTypes }) => {
  const {
    handleInformacoesDoChip,
    handleMenuAcoes,
    handleAlertaVencimentoProposta,
    handleDataPropostaVisualizada,
    tamanhoDivClientePropostaList,
  } = usePropostasFunctions({});
  const { visualizarListaPropostasDialog } = useVisualizarListaPropostasDialog();

  return (
    <styled.DivExternaPropostasListItem>
      <styled.DivInternaPropostasListItem>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Typography variant='titleXS'>#{rowItem.numero}</Typography>
          {handleAlertaVencimentoProposta({
            dataValidade: rowItem.validade,
            situacaoProposta: rowItem.situacao,
            colorirData: true,
          })}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Chip
            label={
              <Typography variant='titleXXS' color={colorPalette.neutral[0]}>
                {handleInformacoesDoChip(rowItem.situacao).texto}
              </Typography>
            }
            style={{
              backgroundColor: handleInformacoesDoChip(rowItem.situacao).cor,
              width: '58px',
              justifyContent: 'center',
            }}
            size='small'
          />
          <AcoesListagensMenu acoes={handleMenuAcoes(rowItem)} />
        </div>
      </styled.DivInternaPropostasListItem>
      {visualizarListaPropostasDialog.codigoVendedor && (
        <styled.DivInternaPropostasListItem>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: tamanhoDivClientePropostaList,
                overflow: 'hidden',
              }}
            >
              <LIcon
                icon={rowItem.tipoCliente === 1 ? lineBuilding : lineHome}
                color={colorPalette.neutral[500]}
                removeMargin
                size='18px'
              />
              <Typography variant='bodySM' whiteSpace='nowrap'>
                {rowItem.cliente}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: tamanhoDivClientePropostaList,
                overflow: 'hidden',
              }}
            >
              <LIcon icon={lineUser} color={colorPalette.neutral[500]} removeMargin size='18px' />
              <Typography variant='bodySM' whiteSpace='nowrap'>
                {rowItem.nomeResponsavel}
              </Typography>
            </div>
          </div>
        </styled.DivInternaPropostasListItem>
      )}
      <styled.DivInternaPropostasListItem>
        <div style={{ display: 'flex', minWidth: '80px' }}>
          {handleDataPropostaVisualizada(rowItem.dataVisualizacao)}
        </div>
        <div style={{ display: 'flex', minWidth: '110px', justifyContent: 'end' }}>
          <Typography variant='bodySM'>{valor.format(rowItem.valorTotal)}</Typography>
        </div>
      </styled.DivInternaPropostasListItem>
    </styled.DivExternaPropostasListItem>
  );
};

export default memo(VisualizarListaPropostasDialogListItem);
