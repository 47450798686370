import { Button, Dialog, Grid, Typography } from '@s_mart/core';

import type { IConfigurarMotivosPerdaDialogProps } from './configurarMotivosPerdaDialog.types';
import { useConfigurarMotivosPerdaDialog } from './useConfigurarMotivosPerdaDialog';
import { Motivo } from './motivo/motivo';

import AdicionarEditarMotivoDialog from '../adicionarEditarMotivoDialog/adicionarEditarMotivoDialog';
import useMarcarComoPerdidaDialogStore from '../marcarComoPerdidaDialog/store/marcarComoPerdidaDialog.store';

export function ConfigurarMotivosPerdaDialog({ handleClose }: IConfigurarMotivosPerdaDialogProps) {
  const { motivosPerda } = useMarcarComoPerdidaDialogStore();
  const { adicionarEditarMotivoDialog, openAdicionarMotivo, openEditarMotivo, handleCloseAdicionarEditarMotivoDialog } =
    useConfigurarMotivosPerdaDialog();

  return (
    <>
      <Dialog open onClose={handleClose} maxWidth='xxs' closeIcon disableEscapeKeyDown disableBackdropClick>
        <Dialog.Header>
          <Typography variant='titleMD'>Configurar motivos de perda</Typography>
        </Dialog.Header>
        <Dialog.Content>
          <Grid container spacing={2} px={1} maxHeight={330}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {motivosPerda?.map((motivo) => (
                  <Motivo key={motivo.codigo} {...motivo} openEditarMotivo={openEditarMotivo} />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant='outlined' color='neutral' onClick={openAdicionarMotivo}>
                Nova motivo de perda
              </Button>
            </Grid>
          </Grid>
        </Dialog.Content>
        <Dialog.Footer>
          <Button onClick={handleClose} variant='neutral'>
            Cancelar
          </Button>
          <Button onClick={handleClose}>Salvar</Button>
        </Dialog.Footer>
      </Dialog>
      {adicionarEditarMotivoDialog.open && (
        <AdicionarEditarMotivoDialog
          handleClose={handleCloseAdicionarEditarMotivoDialog}
          motivo={adicionarEditarMotivoDialog.motivo}
        />
      )}
    </>
  );
}
