import { useEffect } from 'react';

import { isProduction } from 'api/variables';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import { isStaging } from 'utils/isStaging/isStaging';
import usePlanosDialog from './store/planosDialog.store';
import { postAdicionarTags } from 'api/amocrm/amocrm';
import { converterTesteGratis } from 'api/meusDados/meusDados';
import { useAnalytics } from 'hooks/useAnalytics/useAnalytics';

const usePlanosDialogFunctions = () => {
  const { setBoletoProntoDialog } = usePlanosDialog();
  const { informacoesPlano } = useConfiguracoes();
  const { analyticsEventTracker } = useAnalytics({ category: 'onboarding' });

  const planoGeradorComCRM = isProduction
    ? informacoesPlano?.codigoPlano === 170
    : informacoesPlano?.codigoPlano === 475;

  const planosParaMostrar = () => {
    if (planoGeradorComCRM) {
      return { codigoPlanoValor: isStaging() ? [1690] : [1531] };
    } else {
      return { codigoPlanoValor: isStaging() ? [1636, 1637, 1638] : [1549, 1550] };
    }
  };

  const _adicionaTagAmoCrmViuPreco = async (tipo: 'Viu Preço' | 'CONTRATOU') => {
    try {
      await postAdicionarTags([tipo]);

      if (tipo === 'Viu Preço') localStorage.setItem('tagAmoCrm.viuPreco', 'true');
    } catch (err) {
      //ignora erro ao adicionar tags já que não é crítico para o fluxo
    }
  };

  const onContratar = async () => {
    try {
      await converterTesteGratis();
    } catch (err) {
      //erro ignorado
    }
    analyticsEventTracker({ action: 'Close onboarding - Plano contratado com sucesso!' });
    _adicionaTagAmoCrmViuPreco('CONTRATOU');
    setBoletoProntoDialog({ aberto: true });
  };

  useEffect(() => {
    if (!localStorage.getItem('tagAmoCrm.viuPreco')) {
      _adicionaTagAmoCrmViuPreco('Viu Preço');
    }
  }, []);

  return {
    onContratar,
    planosParaMostrar,
  };
};

export default usePlanosDialogFunctions;
