import { useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import throttle from 'lodash.throttle';

import type { ListagemConcessionariasTypes } from 'api/concessionarias/concessionarias.types';
import { marcarApresentacaoGrupoAComoVista } from 'api/configuracoes/configuracoes';
import { listarIrradiacoes } from 'api/irradiacao/irradiacao';
import { buscarTarifaConcessionaria, listarConcessionarias } from 'api/concessionarias/concessionarias';
import { MuiOption } from 'shared/global';
import useApp from 'store/app/app';
import useAuth from 'store/auth/auth';

import useGeradorDeProposta from '../store/geradorDeProposta.store';
import useGeradorDePropostaCalcularDimensionamento from '../useGeradorDePropostaCalcularDimensionamento';
import useConfiguracoes from 'store/configuracoes/configuracoes';

const useGeradorDePropostaConsumo = () => {
  const { setValue, watch } = useFormContext();
  const { handleError } = useApp();
  const { profile } = useAuth();
  const { gerais } = useConfiguracoes();

  const isMounted = useRef(false);

  const { setGeradorDeProposta } = useGeradorDeProposta();
  const { handleCalcularDimensionamentoGrupoB, handleCalcularDimensionamentoGrupoA } =
    useGeradorDePropostaCalcularDimensionamento();

  const _formValues = watch();

  const handleBuscarIrradiacao = useCallback(async () => {
    const { data } = await listarIrradiacoes({ codigo: _formValues?.cidadeProposta?.value });

    const irradiacao = [
      data?.data[0]?.jan,
      data?.data[0]?.fev,
      data?.data[0]?.mar,
      data?.data[0]?.abr,
      data?.data[0]?.mai,
      data?.data[0]?.jun,
      data?.data[0]?.jul,
      data?.data[0]?.ago,
      data?.data[0]?.set,
      data?.data[0]?.out,
      data?.data[0]?.nov,
      data?.data[0]?.dez,
    ];

    setValue('irradiacaoMedia', data?.data[0]?.anual);
    setValue('irradiacao', irradiacao);
  }, [_formValues?.cidadeProposta?.value, setValue]);

  const handleBuscarValorTarifa = useCallback(
    async (valores: { concessionaria: MuiOption; concessionariaPrev: MuiOption }) => {
      setValue('concessionariaPrev', valores.concessionaria);

      try {
        if (valores.concessionariaPrev?.value !== valores.concessionaria?.value) {
          const { data } = await buscarTarifaConcessionaria(Number(valores?.concessionaria?.value));

          if (data?.data?.valorTarifa) {
            setValue('precoKwh', data.data.valorTarifa);
          }
          //TODO CORRIGIR NOME DESSE VALUES QUANDO EXISTIR FIELD QUE INFORMA AREA DA INSTALAÇÃO
          //VALORES ESTABELECIDOS NO BACK CONFORME TABELA DA ANEEL
          if (_formValues?.areaInstalacao === 'rural') {
            setValue('valorTarifaFioB', data.data?.valorTarifaFioBRural);
          } else {
            setValue('valorTarifaFioB', data.data?.valorTarifaFioBResidencial);
          }
        }
      } catch (err) {
        handleError(err);
      }
    },
    [setValue, handleError, _formValues?.areaInstalacao]
  );

  const handleSearchConcessionarias = throttle(async ({ term, codigoUf }: { term?: string; codigoUf?: number }) => {
    try {
      const { data } = await listarConcessionarias({ search: term, codigoUf: codigoUf, all: true });

      const _resultadoMapeado = data?.data?.map((concessionaria: ListagemConcessionariasTypes) => ({
        value: concessionaria.codigo,
        label: concessionaria.nome,
      }));
      setGeradorDeProposta({ concessionarias: _resultadoMapeado });
    } catch (err) {
      handleError(err);
    }
  });

  const handleCloseApresentacaoGrupoADialog = useCallback(async () => {
    if (profile?.sub) {
      let _listaUsuarios: number[];
      if (gerais?.usuariosApresentacaoGrupoA?.length) {
        _listaUsuarios = [...gerais.usuariosApresentacaoGrupoA, profile?.sub];
      } else {
        _listaUsuarios = [profile?.sub];
      }
      localStorage.setItem('onboardingGrupoA', _listaUsuarios.toString());

      await marcarApresentacaoGrupoAComoVista({ usuariosApresentacaoGrupoA: _listaUsuarios });
      setGeradorDeProposta({ abrirOnboardingGrupoA: false });
    }
  }, [profile?.sub, gerais?.usuariosApresentacaoGrupoA]);

  useEffect(() => {
    if (isMounted.current) {
      if (_formValues?.tipoLigacao?.value === '3') {
        handleCalcularDimensionamentoGrupoA(_formValues, setValue);
      } else {
        handleCalcularDimensionamentoGrupoB(_formValues, setValue);
      }
    } else {
      isMounted.current = true;
    }
  }, [
    handleCalcularDimensionamentoGrupoA,
    handleCalcularDimensionamentoGrupoB,
    _formValues?.potenciaPaineis,
    _formValues?.quantidadePaineis,
    _formValues?.irradiacaoMedia,
    _formValues?.perdaSistema,
    _formValues?.consumoMedio,
    _formValues?.larguraPainel,
    _formValues?.alturaPainel,
    _formValues?.aumentoConsumo,
    _formValues?.tipoLigacao,
    _formValues?.tipoDimensionamento,
    _formValues?.demandaContratada,
    _formValues?.consumoMedioForaPonta,
    _formValues?.consumoMedioPonta,
    _formValues?.tarifaDemandaContratada,
    _formValues?.tarifaForaPonta,
    _formValues?.tarifaPonta,
    _formValues?.tarifaTusdg,
    setValue,
  ]);

  return {
    handleBuscarIrradiacao,
    handleBuscarValorTarifa,
    handleSearchConcessionarias,
    handleCloseApresentacaoGrupoADialog,
  };
};

export default useGeradorDePropostaConsumo;
