import { useCallback, useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { ToggleButton } from '@mui/material';

import { Typography } from '@s_mart/core';
import { TextField } from '@s_mart/form';
import { composeValidators, email, required } from '@s_mart/rules';
import { colorPalette } from '@s_mart/tokens';

import useApp from 'store/app/app';
import { vincularVendedorAoUsuario } from 'api/vendedores/vendedores';
import { queryClient } from 'api/queryClient';

import useConvidarVendedorComoUsuarioDialog from './store/convidarVendedorComoUsuarioDialog.store';
import { SToggleButtonGroup } from 'containers/usuarios/usuariosConvidarFormDialog/usuariosConvidarFormDialog.styles';
import useConfiguracoes from 'store/configuracoes/configuracoes';

const useConvidarVendedorComoUsuarioDialogFunctions = () => {
  const { handleError, setLoading } = useApp();
  const { recursosContratados } = useConfiguracoes();
  const { convidarVendedorComoUsuarioDialog, setConvidarVendedorComoUsuarioDialog } =
    useConvidarVendedorComoUsuarioDialog();

  const [_situacao, _setSituacao] = useState<'convidado' | 'vinculado' | 'existente'>();
  const [_etapa, setEtapa] = useState(0);

  const handleClose = () => {
    if (_etapa === 0 && convidarVendedorComoUsuarioDialog.aposCadastro) {
      queryClient.clear();
    }
    setConvidarVendedorComoUsuarioDialog({ aberto: false, dados: undefined });
  };

  const handleSubmit = async (values: { email: string; tipoUsuario?: 0 | 1 | 2 }) => {
    if (_etapa === 0) {
      if (convidarVendedorComoUsuarioDialog.dados?.codigo) {
        setLoading(true);
        try {
          const { status } = await vincularVendedorAoUsuario(convidarVendedorComoUsuarioDialog.dados?.codigo, {
            email: values.email,
            nome: convidarVendedorComoUsuarioDialog.dados?.nome,
            tipo: values.tipoUsuario || 0,
          });

          if (status === 204) {
            _setSituacao('vinculado');
          }
          if (status === 200) {
            _setSituacao('convidado');
          }
          setEtapa(1);
          setLoading(false);
          queryClient.clear();
        } catch (err) {
          _setSituacao('existente');
          handleError(err);
        }
      }
    }
    if (_etapa === 1) {
      setEtapa(0);
      handleClose();
    }
  };

  const handleConteudoDialog = useCallback(
    (tipoUsuario: number, setValue: UseFormSetValue<any>) => {
      if (_etapa === 0) {
        return {
          title: 'Convidar para acessar o sistema?',
          conteudo: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              {recursosContratados?.crm && (
                <SToggleButtonGroup value={tipoUsuario ?? 0}>
                  <ToggleButton value={0} onClick={() => setValue('tipoUsuario', 0)}>
                    <Typography
                      variant='titleXS'
                      color={!tipoUsuario ? colorPalette.neutral[0] : colorPalette.neutral[900]}
                    >
                      Vendedor
                    </Typography>
                  </ToggleButton>
                  <ToggleButton value={2} onClick={() => setValue('tipoUsuario', 2)}>
                    <Typography
                      variant='titleXS'
                      color={tipoUsuario === 2 ? colorPalette.neutral[0] : colorPalette.neutral[900]}
                    >
                      Pré vendedor
                    </Typography>
                  </ToggleButton>
                </SToggleButtonGroup>
              )}
              <Typography variant='bodySM'>
                {tipoUsuario
                  ? 'O Pré-vendedor poderá criar e acessar apenas os clientes na primeira etapa e não terá permissão para visualizar ou gerar propostas.'
                  : 'O vendedor pode criar e acessar todos os clientes no funil, tendo permissão gerar propostas.'}
              </Typography>
              <TextField
                name='email'
                label='E-mail de acesso único'
                placeholder='exemplo@exemplo.com.br'
                required
                rules={composeValidators([email, required])}
              />
              {_situacao === 'existente' && (
                <Typography variant='titleXS' color={colorPalette.red[300]}>
                  Este e-mail já está sendo usado por outro usuário. Tente outra opção.
                </Typography>
              )}
            </div>
          ),
          primaryButton: 'Convidar',
          secondaryButton: 'Cancelar',
        };
      } else if (_etapa === 1) {
        return {
          title: _situacao === 'convidado' ? 'Convite enviado' : 'Usuário vinculado',
          conteudo: (
            <div style={{ display: 'flex', flex: 1, gap: '16px', alignItems: 'center' }}>
              {_situacao === 'convidado' ? (
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '24px' }}>
                  <Typography variant='titleXXS'>
                    Solicite ao novo convidado a verificação da caixa de entrada.
                  </Typography>
                  <Typography variant='bodyXS'>O convite enviado permitirá a criação da senha de acesso.</Typography>
                </div>
              ) : (
                <Typography variant='bodyXS'>Um cadastro de usuário foi vinculado a este vendedor.</Typography>
              )}
              <img src='static/svgs/onBoarding/onBoardingBroIndicando.svg' alt='Solarfy-indicando' />
            </div>
          ),
          primaryButton: _situacao === 'convidado' ? 'Concluir' : 'Entendi',
        };
      }
    },
    [_etapa, _situacao]
  );

  useEffect(() => {
    if (_situacao === 'existente') {
      setTimeout(() => _setSituacao(undefined), 5000);
    }
  }, [_situacao, _setSituacao]);

  return { handleClose, handleSubmit, handleConteudoDialog };
};

export default useConvidarVendedorComoUsuarioDialogFunctions;
