import { Button, Dialog, Grid, LIcon, Typography } from '@s_mart/core';
import { lineWhatsappBrands } from '@s_mart/solid-icons';
import { useNovidadeCrmDialogStore } from './novidadeCrmDialog.store';

import { useNovidadeCrmDialog } from './useNovidadeCrmDialog';

const NovidadeCrmDialog = () => {
  const { open, handleClose } = useNovidadeCrmDialogStore();
  const { handleAbrirWhatsAppComercial } = useNovidadeCrmDialog();

  return (
    <Dialog open={open} onClose={handleClose} closeIcon disableBackdropClick disableEscapeKeyDown maxWidth='sm'>
      <Dialog.Content>
        <Grid container gap={2} textAlign='center' justifyContent='center'>
          <Grid item xs={12}>
            <Typography variant='titleMD'>O tão aguardado CRM chegou! 🚀</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='bodyMD'>
              Agora você pode gerenciar suas oportunidades e vender mais com a ajuda do Solarfy.
            </Typography>
          </Grid>
          <Grid item xs={12} pt={1}>
            {/* import gif - P:\SBSistemas\solarfy\public\static\gifs\gif_crm.gif */}
            <img
              src='/static/gifs/gif_crm.gif'
              alt='gif_crm'
              style={{
                maxWidth: 550,
                width: '100%',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Grid container gap={1}>
              <Grid item xs={12}>
                <Button
                  startIcon={<LIcon icon={lineWhatsappBrands} size='18px' removeMargin />}
                  onClick={handleAbrirWhatsAppComercial}
                  fullWidth
                >
                  Quero saber mais
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={handleClose} variant='text' color='neutral' fullWidth>
                  Não tenho interesse no momento
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
};

export { NovidadeCrmDialog };
