import { create } from 'zustand';
import { ReaproveitarDadosDialogState, ReaproveitarDadosDialogStore } from './reaproveitarDadosDialog.types';

const initialState: ReaproveitarDadosDialogState = {
  open: false,
  cliente: undefined,
  codigoLead: undefined,
  codigoProposta: undefined,
};

export const useReaproveitarDadosDialogStore = create<ReaproveitarDadosDialogStore>((set) => ({
  ...initialState,

  handleOpen: (dados) => set({ ...dados, open: true }),
  handleClose: () => set(initialState),
}));
