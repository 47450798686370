import { api } from 'api/api';
import { urls } from 'api/urls';

import type {
  BuscarMotivoPerda,
  CriarMotivoPerda,
  EditarMotivoPerda,
  ListarMotivosPerda,
  MigrarMotivosPerda,
  VerificarMotivoPerdaResponse,
} from './motivosPerda.types';

export const listarMotivosPerda = async (): ListarMotivosPerda =>
  await api.get(`${urls.motivosPerda}`, {
    params: {
      infiniteScroll: true,
      all: true,
    },
  });

export const criarMotivoPerda = async (motivoPerda: CriarMotivoPerda) =>
  await api.post(`${urls.motivosPerda}`, motivoPerda);

export const migrarMotivosPerda = async (motivosPerda: MigrarMotivosPerda) =>
  await api.patch(`${urls.motivosPerda}/migrar`, motivosPerda);

export const buscarMotivoPerda = async (codigo: number): BuscarMotivoPerda =>
  await api.get(`${urls.motivosPerda}/${codigo}`);

export const excluirMotivoPerda = async (codigo: number) => await api.delete(`${urls.motivosPerda}/${codigo}`);

export const verificarMotivoPerda = async (codigo: number): VerificarMotivoPerdaResponse =>
  await api.get(`${urls.motivosPerda}/${codigo}/verificar-propostas`);

export const editarMotivoPerda = async (motivoPerda: EditarMotivoPerda) =>
  await api.put(`${urls.motivosPerda}/${motivoPerda.codigo}`, {
    nome: motivoPerda.nome,
  });
