import { create } from 'zustand';

import { OnBoardingStates, OnBoardingStore } from './onBoarding.store.types';

const initialState: OnBoardingStates = {
  onBoardingBoasVindas: { aberto: false },
  onBoardingFinalizacao: { aberto: false },
  etapa: 0,
  onBoardingFinalizado: true,
};

const useOnBoarding = create<OnBoardingStore>((set) => ({
  ...initialState,
  setOnBoardingBoasVindas: (onBoardingBoasVindas) => set((state) => ({ ...state, onBoardingBoasVindas })),
  setOnBoardingFinalizacao: (onBoardingFinalizacao) => set((state) => ({ ...state, onBoardingFinalizacao })),
  setEtapa: (etapa) => set((state) => ({ ...state, etapa })),
  setOnBoardingFinalizado: (onBoardingFinalizado) => set((state) => ({ ...state, onBoardingFinalizado })),
}));

export default useOnBoarding;
