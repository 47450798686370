import styled from '@emotion/styled';
import { Stack, ToggleButtonGroup } from '@mui/material';
import { toRem } from '@s_mart/utils';

import { MOBILE_BREAKPOINT } from 'hooks/useIsMobile/useIsMobile';

export const SToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 35px;
  width: 220px;
  border-radius: 6px;

  > button {
    border-radius: 6px;
    flex: 1;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

export const SStack = styled(Stack)`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 6px;

  align-items: center;
  justify-content: space-between;
  padding: 0 ${toRem(16)};
  height: 35px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: calc(100% - ${toRem(16)});
    height: auto;
    padding: ${toRem(8)} ${toRem(8)};
  }
`;
