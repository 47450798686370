import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog, CardButton } from '@s_mart/core';
import { lineSolarPanel, lineEnergy, lineTools, lineBoxOpenFull } from '@s_mart/regular-icons';

import useAdicionarProdutoOuKitDialog from './useAdicionarProdutoOuKitDialog';
import useGeradorDeProposta from '../../store/geradorDeProposta.store';

interface AdicionarProdutoOuKitDialogProps {
  onClose: () => void;
  caminhoKits?: boolean;
}

const AdicionarProdutoOuKitDialog = ({ onClose, caminhoKits }: AdicionarProdutoOuKitDialogProps) => {
  const { watch } = useFormContext();
  const { handleAdicionarPainel, handleAdicionarInversor, handleAdicionarOutro, handleAdicionarKitFotovoltaico } =
    useAdicionarProdutoOuKitDialog(onClose);
  const { geradorDeProposta } = useGeradorDeProposta();

  const _formValues = watch();
  const _formValuesKitsFotovoltaicos = watch('kitsFotovoltaicos');

  return (
    <Dialog
      open
      onClose={onClose}
      closeIcon
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      PaperProps={{ sx: { maxWidth: 850 } }}
    >
      <Dialog.Header>Selecione o tipo de produto</Dialog.Header>
      <Dialog.Content>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
            gap: '1rem',
            marginBottom: '1rem',
          }}
        >
          {caminhoKits && (
            <CardButton
              icon={lineSolarPanel}
              text='Informar painel'
              onClick={() => handleAdicionarPainel()}
              disabled={_formValues.painelAdicionado}
            />
          )}
          <CardButton
            icon={lineEnergy}
            text='Informar inversor'
            onClick={() => handleAdicionarInversor()}
            disabled={
              geradorDeProposta?.caminhoGerador === 'kits'
                ? _formValues.inversores?.length === 2
                : _formValues.inversores?.length === 4
            }
          />
          <CardButton
            icon={lineTools}
            text='Informar serviço ou outro equipamento'
            onClick={() => handleAdicionarOutro()}
            disabled={_formValues.outros?.length === 6}
          />
          {caminhoKits && (
            <CardButton
              icon={lineBoxOpenFull}
              text='Kit fotovoltaico'
              onClick={() => handleAdicionarKitFotovoltaico()}
              disabled={_formValuesKitsFotovoltaicos?.length === 2}
            />
          )}
        </div>
      </Dialog.Content>
    </Dialog>
  );
};

export default memo(AdicionarProdutoOuKitDialog);
