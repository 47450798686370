import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';

import { marcarApresentacaoSimultaneidadeComoVista } from 'api/configuracoes/configuracoes';
import type { ListarProdutosTypes } from 'api/produtos/produtos.types';
import { isProduction } from 'api/variables';
import useAuth from 'store/auth/auth';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import { calcularDimensionamentoGrupoB } from 'utils/calcularDimensionamentoGrupoB/calcularDimensionamentoGrupoB';
import { calcularPaybackGrupoB } from 'utils/calcularPaybackGrupoB/calcularPaybackGrupoB';
import { sleep } from 'utils/sleep/sleep';
import { taxaFioBLei14300 } from 'utils/taxaFioBLei14300/taxaFioBLei14300';

import type {
  TabelaPaybackBruta,
  TabelaPaybackFormatada,
  ValoresSemSistemaGrupoA,
  ValoresComSistemaGrupoA,
} from './geradorDePropostaSistemaPayback.types';
import useGeradorDeProposta from '../store/geradorDeProposta.store';
import {
  ProdutosDesatualizados,
  ProdutosExcluidos,
  ProdutosInativados,
} from './produtoDesatualizadoOuExcluidoDialog/produtoDesatualizadoOuExcluidoDialog.types';
import { useControleCaminhoGerador } from '../controleCaminhoGerador/useControleCaminhoGerador';

const useGeradorDePropostaSistemaPayback = () => {
  const methods = useFormContext();
  const { watch, setValue } = methods;
  const { handleControleCaminhoGerador } = useControleCaminhoGerador();
  const { geradorDeProposta, setGeradorDeProposta } = useGeradorDeProposta();
  const { profile } = useAuth();
  const { recursosContratados, gerais } = useConfiguracoes();

  const _formValues = watch();

  const _fieldsFotovoltaicosIndex0 = _formValues?.kitsFotovoltaicos?.[0]?.potenciaKitsFotovoltaicos;
  const _fieldsFotovoltaicosIndex1 = _formValues?.kitsFotovoltaicos?.[1]?.potenciaKitsFotovoltaicos;

  const handleCalcularGeracaoSistema = useCallback(() => {
    let _nomeFieldPotencia = 'potenciaInstalada';
    let _nomeFieldGeracao = 'geracaoSistema';
    let _nomeFieldAreaMinima = 'areaMinima';

    if (_formValues?.tipoLigacao?.value === '3') {
      if (_formValues?.tipoDimensionamento === '1') {
        _nomeFieldPotencia = 'potenciaSistemaForaPontaMaisPonta';
        _nomeFieldGeracao = 'geracaoSistemaForaPontaMaisPonta';
        _nomeFieldAreaMinima = 'areaMinimaForaPontaMaisPonta';
      } else {
        _nomeFieldPotencia = 'potenciaSistemaForaPonta';
        _nomeFieldGeracao = 'geracaoSistemaForaPonta';
        _nomeFieldAreaMinima = 'areaMinimaForaPonta';
      }
    }

    if (geradorDeProposta.caminhoGerador === 'kits') {
      let _potenciaTotal =
        _formValues?.kitsFotovoltaicos?.length > 1
          ? _formValues?.kitsFotovoltaicos?.reduce(
              (a: any, b: any) => Number(a?.potenciaKitsFotovoltaicos || 0) + Number(b?.potenciaKitsFotovoltaicos || 0)
            )
          : Number(_formValues?.kitsFotovoltaicos?.[0]?.potenciaKitsFotovoltaicos || 0);

      if (_formValues?.painelAdicionado && _formValues?.potenciaPaineis && _formValues?.quantidadePaineis) {
        _potenciaTotal =
          _potenciaTotal + Number((_formValues?.quantidadePaineis * (_formValues?.potenciaPaineis / 1000)).toFixed(2));
      }

      if (Number(_potenciaTotal)) {
        const { geracaoSistema, potenciaSistema } = calcularDimensionamentoGrupoB({
          consumoMedio: Number(_formValues.consumoMedio),
          potenciaKits: Number(_potenciaTotal),
          perdaSistema: Number(_formValues.perdaSistema),
          largura: Number(_formValues.larguraPainel),
          altura: Number(_formValues.alturaPainel),
          irradiacaoSolar: Number(_formValues.irradiacaoMedia),
          diasNoMes: 30,
          aumentoConsumo: Number(_formValues.aumentoConsumo),
        });

        setValue(_nomeFieldGeracao, String(geracaoSistema));
        setValue(_nomeFieldPotencia, String(potenciaSistema));
      }
    } else if (geradorDeProposta.caminhoGerador === 'produtos') {
      if (Number(_formValues.potenciaPaineis)) {
        const { areaMinima, geracaoSistema, potenciaSistema } = calcularDimensionamentoGrupoB({
          consumoMedio: Number(_formValues.consumoMedio),
          potenciaPaineis: Number(_formValues.potenciaPaineis),
          perdaSistema: Number(_formValues.perdaSistema),
          largura: Number(_formValues.larguraPainel),
          altura: Number(_formValues.alturaPainel),
          irradiacaoSolar: Number(_formValues.irradiacaoMedia),
          diasNoMes: 30,
          aumentoConsumo: Number(_formValues.aumentoConsumo),
          quantidadePaineis: Number(_formValues.quantidadePaineis),
        });

        setValue(_nomeFieldGeracao, String(geracaoSistema));
        setValue(_nomeFieldPotencia, String(potenciaSistema));
        setValue(_nomeFieldAreaMinima, String(areaMinima));
      }
    }
  }, [
    geradorDeProposta.caminhoGerador,
    _formValues?.painelAdicionado,
    _formValues?.larguraPainel,
    _formValues?.alturaPainel,
    _formValues?.potenciaPaineis,
    _formValues?.irradiacaoMedia,
    _formValues?.perdaSistema,
    _formValues?.quantidadePaineis,
    _formValues?.tipoLigacao,
    _formValues?.tipoDimensionamento,
    _fieldsFotovoltaicosIndex0,
    _fieldsFotovoltaicosIndex1,
    setValue,
  ]);

  const handleMontarTabelaPaybackGrupoB = useCallback(() => {
    if (
      _formValues?.valorTotal &&
      _formValues?.geracaoSistema &&
      _formValues?.precoKwh &&
      _formValues?.degradacaoSistema &&
      _formValues?.taxaInflacao
    ) {
      //! futuramente será definido pelo usuário
      let _anosTabelaPayback = 25;

      //IF para Migre que utiliza 30 anos de payback
      if (isProduction) {
        if (profile?.emp === 2338) {
          _anosTabelaPayback = 30;
        }
      } else {
        if (profile?.emp === 3862) {
          _anosTabelaPayback = 30;
        }
      }

      const { tabelaPaybackFormatadaAnual, retornoPaybackEmMeses, valorEconomizado12MesesAposInstalacao } =
        calcularPaybackGrupoB({
          consumoMedio: Number(_formValues?.consumoMedio || 0),
          degradacaoSistema: Number(_formValues?.degradacaoSistema || 0),
          geracaoSistema: Number(_formValues?.geracaoSistema || 0),
          simultaneidade: Number(_formValues?.simultaneidade || 0),
          taxaInflacao: Number(_formValues?.taxaInflacao || 0),
          valorProposta: Number(_formValues?.valorTotal || 0),
          valorTarifa: Number(_formValues?.precoKwh || 0),
          anosTabela: _anosTabelaPayback,
          valorFioB: Number(_formValues?.valorTarifaFioB || 0),
        });

      const _faturasSemSistema = tabelaPaybackFormatadaAnual.map((item) =>
        Number((Number(item.tarifaPrevista) * Number(_formValues.consumoMedio)).toFixed(2))
      );

      const _valorGastoEnergia30AnosSemSistema = _faturasSemSistema
        ?.map((it) => it * 12)
        ?.reduce((a: number, b: number) => a + b, 0);

      const _mediaFaturaSemSistema = Number(
        (_faturasSemSistema?.reduce((a: number, b: number) => a + b, 0) / tabelaPaybackFormatadaAnual.length).toFixed(2)
      );

      const _valoresComSistema = () => {
        if (
          tabelaPaybackFormatadaAnual &&
          _formValues.tipoLigacao?.value &&
          _formValues.consumoMedio &&
          _formValues.precoKwh
        ) {
          let _consumoMinimo = 0;
          const valorTarifa: number[] = [];
          const valorFioB: number[] = [];

          if (_formValues.tipoLigacao?.value === '0') {
            _consumoMinimo = 30;
          } else if (_formValues.tipoLigacao?.value === '1') {
            _consumoMinimo = 50;
          } else if (_formValues.tipoLigacao?.value === '2') {
            _consumoMinimo = 100;
          }

          tabelaPaybackFormatadaAnual.map((it, index) => {
            let _consumoReal;
            const _meses = index === 0 ? 12 - (dayjs().month() + 1) : 12;

            if (_formValues.consumoMedio - Number(it.geracao) / (_meses || 1) < _consumoMinimo) {
              _consumoReal = _consumoMinimo;
            } else {
              _consumoReal = _formValues.consumoMedio - Number(it.geracao) / (_meses || 1);
            }

            valorTarifa.push(Number((_consumoReal * (Number(it.tarifaPrevista) + it.parcelaFioBNaTarifa)).toFixed(2)));
            valorFioB.push(Number((_consumoReal * it.parcelaFioBNaTarifa).toFixed(2)));
          });

          return { tarifa: valorTarifa, fioB: valorFioB };
        }
      };

      const _valorGastoEnergia30AnosComSistema = _valoresComSistema()
        ?.tarifa?.map((it) => it * 12)
        ?.reduce((a, b) => a + b);

      const _mediaFaturaComSistema = Number(
        (
          (_valoresComSistema()?.tarifa?.reduce((a, b) => a + b) || 0) / (_valoresComSistema()?.tarifa?.length || 0)
        ).toFixed(2)
      );

      const _mediaValorFioB = Number(
        (
          (_valoresComSistema()?.fioB?.reduce((a, b) => a + b) || 0) / (_valoresComSistema()?.fioB?.length || 0)
        ).toFixed(2)
      );

      // # Formatação do payback em meses para string contendo 'x anos e y meses'
      const _retornoInvestimento = retornoPaybackEmMeses;
      const _anosDeRetorno = Math.floor(_retornoInvestimento / 12);
      const _mesesDeRetorno = Math.round(_retornoInvestimento % 12);

      const _anosDeRetornoFormatado = _anosDeRetorno > 0 ? `${_anosDeRetorno} ano${_anosDeRetorno > 1 ? 's' : ''}` : '';
      const _mesesDeRetornoFormatado =
        _mesesDeRetorno > 0
          ? `${_anosDeRetorno ? ' e ' : ''}${_mesesDeRetorno} ${_mesesDeRetorno > 1 ? 'meses' : 'mês'}`
          : '';

      const _retornoInvestimentoFormatado =
        _retornoInvestimento === 0 ? 'Mais de 25 anos' : `${_anosDeRetornoFormatado}${_mesesDeRetornoFormatado}`;

      setValue('retornoInvestimento', _retornoInvestimentoFormatado);
      setValue('tabelaPaybackDados', tabelaPaybackFormatadaAnual);
      setValue('faturasSemSistema', _faturasSemSistema);
      setValue('valorGastoEnergia30AnosSemSistema', _valorGastoEnergia30AnosSemSistema);
      setValue('mediaFaturaSemSistema', _mediaFaturaSemSistema);
      setValue('faturasComSistema', _valoresComSistema()?.tarifa);
      setValue('valorGastoEnergia30AnosComSistema', _valorGastoEnergia30AnosComSistema);
      setValue('mediaFaturaComSistema', _mediaFaturaComSistema);
      setValue('valoresFioBComSistema', _valoresComSistema()?.fioB);
      setValue('mediaValorFioB', _mediaValorFioB);
      setValue('valorEconomizado12MesesAposInstalacao', valorEconomizado12MesesAposInstalacao);
    }
  }, [
    _formValues?.consumoMedio,
    _formValues?.valorTotal,
    _formValues?.geracaoSistema,
    _formValues?.precoKwh,
    _formValues?.degradacaoSistema,
    _formValues?.taxaInflacao,
    _formValues?.valorTarifaFioB,
    _formValues?.simultaneidade,
    profile?.emp,
    setValue,
    taxaFioBLei14300,
  ]);

  const handleMontarTabelaPaybackGrupoA = useCallback(() => {
    const _anosTabelaPayback = 25;

    const _tabelaPaybackBruta: TabelaPaybackBruta = [];
    //tabelaPaybackFormatada deixa apenas 2 casas decimais
    let _tabelaPaybackFormatada: TabelaPaybackFormatada = [];

    const _arrayValoresSemSistema: ValoresSemSistemaGrupoA = [];

    const _arrayValoresComSistema: ValoresComSistemaGrupoA = [];

    for (let i = 0; i <= _anosTabelaPayback; i++) {
      if (i === 0) {
        let _mediaPagaForaPonta = 0;
        let _mediaPagaPonta = 0;
        let _custoDemandaContratada = 0;
        let _mediaFaturaSemSistema = 0;

        if (_formValues?.consumoMedioForaPonta && _formValues?.tarifaForaPonta) {
          _mediaPagaForaPonta = Number(_formValues.consumoMedioForaPonta) * Number(_formValues.tarifaForaPonta) * 12;
        }
        if (_formValues?.consumoMedioPonta && _formValues?.tarifaPonta) {
          _mediaPagaPonta = Number(_formValues.consumoMedioPonta) * Number(_formValues.tarifaPonta) * 12;
        }
        if (_formValues?.demandaContratada && _formValues?.tarifaDemandaContratada) {
          _custoDemandaContratada =
            Number(_formValues?.demandaContratada) * Number(_formValues?.tarifaDemandaContratada) * 12;
        }
        if (_mediaPagaForaPonta && _mediaPagaPonta && _custoDemandaContratada) {
          _mediaFaturaSemSistema = (_mediaPagaForaPonta + _mediaPagaPonta + _custoDemandaContratada) / 12;
        }

        _arrayValoresSemSistema[i] = {
          mediaPagaForaPonta: _mediaPagaForaPonta,
          mediaPagaPonta: _mediaPagaPonta,
          custoDemandaContratada: _custoDemandaContratada,
          mediaFaturaSemSistema: _mediaFaturaSemSistema,
        };
      } else {
        const _mediaPagaForaPonta =
          _arrayValoresSemSistema[i - 1].mediaPagaForaPonta * (1 + Number(_formValues?.taxaInflacao) / 100);
        const _mediaPagaPonta =
          _arrayValoresSemSistema[i - 1].mediaPagaPonta * (1 + Number(_formValues?.taxaInflacao) / 100);
        const _custoDemandaContratada = _arrayValoresSemSistema[i - 1].custoDemandaContratada;
        const _mediaFaturaSemSistema = (_mediaPagaForaPonta + _mediaPagaPonta + _custoDemandaContratada) / 12;

        _arrayValoresSemSistema[i] = {
          mediaPagaForaPonta: _mediaPagaForaPonta,
          mediaPagaPonta: _mediaPagaPonta,
          custoDemandaContratada: _custoDemandaContratada,
          mediaFaturaSemSistema: _mediaFaturaSemSistema,
        };
      }

      if (_formValues?.tipoDimensionamento === '1') {
        if (i === 0) {
          const _geracao = Number(_formValues?.geracaoSistema) * 12;
          const _tarifa = Number(_formValues?.tarifaForaPonta || 0) + Number(_formValues?.tarifaPonta || 0);

          const _geracaoExcedente = _geracao - Number(_formValues?.consumoMedioForaPonta) * 12;
          const _creditoPonta = _geracaoExcedente * _formValues?.fatorAjuste;
          let _consumoPontaFinal = Number(_formValues?.consumoMedioPonta || 0) * 12;
          if (_creditoPonta > 0) {
            _consumoPontaFinal = _consumoPontaFinal - _creditoPonta;
          }
          const _valorConsumoPontaPagar = _consumoPontaFinal * Number(_formValues?.tarifaPonta || 0);
          const _valorAPagarTusdG = (_formValues?.valorAPagarTusdG || 0) * 12;
          const _mediaPagarContaDeLuz =
            (_valorConsumoPontaPagar + _valorAPagarTusdG + _arrayValoresSemSistema[i]?.custoDemandaContratada) / 12;

          _tabelaPaybackBruta[i] = {
            valor: Number(_formValues.valorTotal || 0) * -1,
            geracao: _geracao,
            economiaAnual: (_arrayValoresSemSistema[i].mediaFaturaSemSistema - _mediaPagarContaDeLuz) * 12,
            tarifa: _tarifa,
            ano: dayjs().get('year'),
          };

          _arrayValoresComSistema[i] = {
            geracaoExcedente: _geracaoExcedente,
            creditoPonta: _creditoPonta,
            consumoPontaFinal: _consumoPontaFinal,
            valorConsumoPontaPagar: _valorConsumoPontaPagar,
            valorPagarTusdG: _valorAPagarTusdG,
            mediaPagarContaDeLuz: _mediaPagarContaDeLuz,
            tarifaPonta: Number(_formValues?.tarifaPonta || 0),
          };
        } else {
          const _geracaoAtual =
            _tabelaPaybackBruta[i - 1].geracao -
            _tabelaPaybackBruta[i - 1].geracao * (Number(_formValues?.degradacaoSistema) / 100);
          const _tarifaAtual = _tabelaPaybackBruta[i - 1].tarifa * (1 + Number(_formValues?.taxaInflacao) / 100);
          const _tarifaPontaAtual =
            (_arrayValoresComSistema[i - 1]?.tarifaPonta || 0) * (1 + Number(_formValues?.taxaInflacao) / 100);

          const _geracaoExcedente = _geracaoAtual - Number(_formValues?.consumoMedioForaPonta || 0) * 12;
          const _creditoPonta = _geracaoExcedente * _formValues?.fatorAjuste;
          let _consumoPontaFinal = Number(_formValues?.consumoMedioPonta || 0) * 12;
          if (_creditoPonta > 0) {
            _consumoPontaFinal = _consumoPontaFinal - _creditoPonta;
          }
          const _valorConsumoPontaPagar = _consumoPontaFinal * _tarifaPontaAtual;
          const _valorAPagarTusdG =
            (_arrayValoresComSistema[i - 1]?.valorPagarTusdG || 0) * ((1 * Number(_formValues?.taxaInflacao)) / 100);
          const _mediaPagarContaDeLuz =
            (_valorConsumoPontaPagar + _valorAPagarTusdG + _arrayValoresSemSistema[i]?.custoDemandaContratada) / 12;

          _tabelaPaybackBruta[i] = {
            valor: _tabelaPaybackBruta[i - 1].valor + _tabelaPaybackBruta[i - 1].economiaAnual,
            geracao: _geracaoAtual,
            economiaAnual: (_arrayValoresSemSistema[i].mediaFaturaSemSistema - _mediaPagarContaDeLuz) * 12,
            tarifa: _tarifaAtual,
            ano: _tabelaPaybackBruta[i - 1].ano + 1,
          };

          _arrayValoresComSistema[i] = {
            geracaoExcedente: _geracaoExcedente,
            creditoPonta: _creditoPonta,
            consumoPontaFinal: _consumoPontaFinal,
            valorConsumoPontaPagar: _valorConsumoPontaPagar,
            valorPagarTusdG: _valorAPagarTusdG,
            mediaPagarContaDeLuz: _mediaPagarContaDeLuz,
            tarifaPonta: _tarifaPontaAtual,
          };
        }
      } else {
        if (i === 0) {
          const _geracao = Number(_formValues?.geracaoSistema) * 12;

          const _potenciaPagarForaPonta = (_geracao - Number(_formValues?.consumoMedioForaPonta || 0) * 12) * -1;
          const _totalPagarForaPonta = _potenciaPagarForaPonta * Number(_formValues?.tarifaForaPonta || 0);
          const _mediaPagarContaDeLuz =
            (_totalPagarForaPonta +
              _arrayValoresSemSistema[i]?.mediaPagaPonta +
              _arrayValoresSemSistema[i]?.custoDemandaContratada) /
            12;

          _tabelaPaybackBruta[i] = {
            valor: Number(_formValues.valorTotal || 0) * -1,
            geracao: _geracao,
            economiaAnual: (_arrayValoresSemSistema[i].mediaFaturaSemSistema - _mediaPagarContaDeLuz) * 12,
            tarifa: Number(_formValues?.tarifaForaPonta || 0),
            ano: dayjs().get('year'),
          };

          _arrayValoresComSistema[i] = {
            potenciaPagarForaPonta: _potenciaPagarForaPonta,
            totalPagarForaPonta: _totalPagarForaPonta,
            mediaPagarContaDeLuz: _mediaPagarContaDeLuz,
          };
        } else {
          const _geracaoAtual =
            _tabelaPaybackBruta[i - 1].geracao -
            _tabelaPaybackBruta[i - 1].geracao * (Number(_formValues.degradacaoSistema) / 100);
          const _tarifaAtual = _tabelaPaybackBruta[i - 1].tarifa * (1 + Number(_formValues.taxaInflacao) / 100);

          const _potenciaPagarForaPonta = (_geracaoAtual - Number(_formValues?.consumoMedioForaPonta || 0) * 12) * -1;
          const _totalPagarForaPonta = _potenciaPagarForaPonta * _tarifaAtual;
          const _mediaPagarContaDeLuz =
            (_totalPagarForaPonta +
              _arrayValoresSemSistema[i]?.mediaPagaPonta +
              _arrayValoresSemSistema[i]?.custoDemandaContratada) /
            12;

          _tabelaPaybackBruta[i] = {
            valor: _tabelaPaybackBruta[i - 1].valor + _tabelaPaybackBruta[i - 1].economiaAnual,
            geracao: _geracaoAtual,
            economiaAnual: (_arrayValoresSemSistema[i].mediaFaturaSemSistema - _mediaPagarContaDeLuz) * 12,
            tarifa: _tarifaAtual,
            ano: _tabelaPaybackBruta[i - 1].ano + 1,
          };

          _arrayValoresComSistema[i] = {
            potenciaPagarForaPonta: _potenciaPagarForaPonta,
            totalPagarForaPonta: _totalPagarForaPonta,
            mediaPagarContaDeLuz: _mediaPagarContaDeLuz,
          };
        }
      }
    }

    _tabelaPaybackFormatada = _tabelaPaybackBruta.map((item) => ({
      valor: item.valor?.toFixed(2),
      geracao: item.geracao?.toFixed(2),
      economiaAnual: item.economiaAnual?.toFixed(2),
      tarifa: item.tarifa?.toFixed(2),
      ano: item.ano,
    }));

    const _faturasSemSistema = _arrayValoresSemSistema.map((it) => Number(it.mediaFaturaSemSistema.toFixed(2)));

    const _valorGastoEnergia30AnosSemSistema = _faturasSemSistema
      ?.map((it) => it * 12)
      ?.reduce((a: number, b: number) => a + b, 0);

    const _mediaFaturaSemSistema = Number(
      (_faturasSemSistema?.reduce((a: number, b: number) => a + b, 0) / _tabelaPaybackFormatada.length).toFixed(2)
    );

    const _faturasComSistema = _arrayValoresComSistema.map((it) => Number(it.mediaPagarContaDeLuz.toFixed(2)));

    const _valorGastoEnergia30AnosComSistema = _faturasComSistema
      ?.map((it) => it * 12)
      ?.reduce((a: number, b: number) => a + b, 0);

    const _mediaFaturaComSistema = Number(
      (_faturasComSistema?.reduce((a: number, b: number) => a + b, 0) / _tabelaPaybackFormatada.length).toFixed(2)
    );

    setValue('tabelaPaybackDados', _tabelaPaybackFormatada);
    setValue('faturasSemSistema', _faturasSemSistema);
    setValue('valorGastoEnergia30AnosSemSistema', Number(_valorGastoEnergia30AnosSemSistema.toFixed(2)));
    setValue('mediaFaturaSemSistema', Number(_mediaFaturaSemSistema.toFixed(2)));
    setValue('faturasComSistema', _faturasComSistema);
    setValue('valorGastoEnergia30AnosComSistema', Number(_valorGastoEnergia30AnosComSistema.toFixed(2)));
    setValue('mediaFaturaComSistema', Number(_mediaFaturaComSistema.toFixed(2)));
  }, [
    _formValues?.consumoMedioForaPonta,
    _formValues?.tarifaForaPonta,
    _formValues?.consumoMedioPonta,
    _formValues?.tarifaPonta,
    _formValues?.demandaContratada,
    _formValues?.tarifaDemandaContratada,
    _formValues?.tipoDimensionamento,
    _formValues?.geracaoSistema,
    _formValues?.fatorAjuste,
    _formValues?.valorAPagarTusdG,
    _formValues?.valorTotal,
    _formValues?.degradacaoSistema,
    _formValues?.taxaInflacao,
  ]);

  const handleRetornoPaybackGrupoA = useCallback(() => {
    if (_formValues?.tabelaPaybackDados?.length && _formValues?.tipoLigacao?.value === '3') {
      //retorno = filtra posições do array até o valor permanecer negativo
      const _retorno = _formValues.tabelaPaybackDados.filter(
        (item: { valor: string; geracao: string; economiaAnual: string; tarifa: string }) => Number(item.valor) < 0
      );
      //anos = tamanho do array filtrado (indica que levou essa quantidade de anos para o payback, faltando os meses)
      const _limitePayback = _retorno.length;
      //meses = valor ano anterior / (economia ANUAL (do ano em questão) / 12), arrendonda-se o valor para o mês cheio
      const _meses = Math.round(
        -_formValues.tabelaPaybackDados[_limitePayback - 1]?.valor /
          (_formValues.tabelaPaybackDados[_limitePayback]?.economiaAnual / 12)
      );

      let _retornoInvestimento = '';

      //_limitePayback -1 para ficar correto na renderização
      const _anos = _limitePayback - 1;

      if (_anos < 0 || (_anos === 0 && _meses === 0)) {
        _retornoInvestimento = '';
      } else {
        if (_anos > 0) {
          _retornoInvestimento = `${_anos > 1 ? `${_anos} anos` : `${_anos} ano`}`;
        }
        if (_meses > 0 && _meses <= 12) {
          if (_retornoInvestimento !== '') {
            _retornoInvestimento += ' e ';
          }
          _retornoInvestimento += `${_meses > 1 ? `${_meses} meses` : `${_meses} mês`}`;
        }
      }

      setValue('retornoInvestimento', _retornoInvestimento);
    }
  }, [_formValues?.tabelaPaybackDados, setValue, _formValues?.tipoLigacao]);

  const _handleSetarEconomiasAnuais = useCallback(() => {
    if (_formValues?.tabelaPaybackDados) {
      const _economiaAnual = _formValues?.tabelaPaybackDados?.map((item: any) => Number(item?.economiaAnual));

      const _economiaEm25Anos = _economiaAnual?.reduce((a: number, b: number) => a + b);

      //transforma o array de economias anuais em um objeto {'0': _economiaAnual[0], '1': _economiaAnual[1], ...}
      const _economiaAnualFormatadas = Object.assign(
        {},
        _economiaAnual.map((item: number, index: number) => {
          //IF para Migre, pois eles precisam do valor dos primeiros 12 meses após a instalação
          //em um campo da proposta personalizada deles
          if (isProduction && profile?.emp === 2338 && index === 0) {
            return _formValues?.valorEconomizado12MesesAposInstalacao;
          } else {
            return item;
          }
        })
      );

      setValue('economia25', Number(_economiaEm25Anos?.toFixed(2)));
      setValue('economiaAnual', _economiaAnualFormatadas);
    }
  }, [_formValues?.tabelaPaybackDados, _formValues?.valorEconomizado12MesesAposInstalacao]);

  const handleCloseApresentacaoSimultaneidadeDialog = useCallback(async () => {
    if (profile?.sub) {
      let _listaUsuarios: number[];
      if (gerais?.usuariosApresentacaoSimultaneidade?.length) {
        _listaUsuarios = [...gerais.usuariosApresentacaoSimultaneidade, profile?.sub];
      } else {
        _listaUsuarios = [profile?.sub];
      }
      localStorage.setItem('onboardingSimultaneidade', _listaUsuarios.toString());

      await marcarApresentacaoSimultaneidadeComoVista({ usuariosApresentacaoSimultaneidade: _listaUsuarios });
      setGeradorDeProposta({ abrirOnboardingSimultaneidade: false });
    }
  }, [profile?.sub, gerais?.usuariosApresentacaoSimultaneidade]);

  const compararValorProdutoDesatualizado = (valorProdutoProposta?: number, valorProdutoAtual?: number) => {
    return Boolean(valorProdutoAtual && valorProdutoProposta !== valorProdutoAtual);
  };

  const handleOpenProdutoDesatualizadoOuExcluidoAoClonarDialog = useCallback(async () => {
    if (
      geradorDeProposta.clonar?.codigo &&
      recursosContratados?.['valor-de-venda-unitario'] &&
      geradorDeProposta.abrirProdutoExcluidoOuPrecoDesatualizadoDialog?.aberto === undefined
    ) {
      const _produtosDesatualizados: ProdutosDesatualizados[] = [];
      const _produtosExcluidos: ProdutosExcluidos[] = [];
      const _produtosInativados: ProdutosInativados[] = [];

      const listagensNaoBuscadas =
        _formValues.listagemKitsFotovoltaicos === undefined &&
        _formValues.listagemKitsDeServicos === undefined &&
        _formValues.listagemOutros === undefined &&
        _formValues.listagemPaineis === undefined &&
        _formValues.listagemInversores === undefined;

      if (listagensNaoBuscadas && geradorDeProposta.caminhoGerador === 'kits') return;

      if (_formValues.passouValidacaoProdutoDesatualizado === 'true') return;

      if (_formValues.kitsFotovoltaicos?.length && _formValues.listagemKitsFotovoltaicos) {
        _formValues.kitsFotovoltaicos.forEach((it: any, index: number) => {
          const _produtoAtualKitsFotovoltaicos: ListarProdutosTypes = _formValues.listagemKitsFotovoltaicos[it.codigo];

          if (_produtoAtualKitsFotovoltaicos?.codigo) {
            if (
              compararValorProdutoDesatualizado(
                Number(it.valorVendaKitsFotovoltaicos || 0),
                _produtoAtualKitsFotovoltaicos?.valorVenda
              ) &&
              _produtoAtualKitsFotovoltaicos.situacao
            ) {
              _produtosDesatualizados.push({
                name: 'kitsFotovoltaicos',
                index: index,
                values: {
                  modeloKitsFotovoltaicos: {
                    label: _produtoAtualKitsFotovoltaicos.nome,
                    value: _produtoAtualKitsFotovoltaicos.codigo,
                  },
                  potenciaKitsFotovoltaicos: _produtoAtualKitsFotovoltaicos.potencia,
                  valorVendaKitsFotovoltaicos: _produtoAtualKitsFotovoltaicos.valorVenda,
                  descricaoKitsFotovoltaicos: _produtoAtualKitsFotovoltaicos.descricao,
                  descricaoGarantiaKitsFotovoltaicos: _produtoAtualKitsFotovoltaicos.descricaoGarantia,
                },
              });
            } else if (!_produtoAtualKitsFotovoltaicos.situacao) {
              _produtosInativados.push({ name: 'kitsFotovoltaicos', index: index });
            }
          } else if (!_produtoAtualKitsFotovoltaicos?.codigoFornecedor && !it?.codigoProdutoFornecedor) {
            _produtosExcluidos.push({ name: 'kitsFotovoltaicos', index: index });
          }
        });
      }

      if (_formValues.kitsDeServicos?.length && _formValues.listagemKitsDeServicos) {
        _formValues.kitsDeServicos.forEach((it: any, index: number) => {
          const _produtoAtualKitsDeServicos = _formValues.listagemKitsDeServicos[it.codigo];

          if (_produtoAtualKitsDeServicos?.codigo) {
            if (
              compararValorProdutoDesatualizado(
                Number(it.valorVendaKitsDeServicos || 0),
                _produtoAtualKitsDeServicos?.valorVenda
              ) &&
              _produtoAtualKitsDeServicos.situacao
            ) {
              _produtosDesatualizados.push({
                name: 'kitsDeServicos',
                index: index,
                values: {
                  modeloKitsDeServicos: {
                    label: _produtoAtualKitsDeServicos.nome,
                    value: _produtoAtualKitsDeServicos.codigo,
                  },
                  valorVendaKitsDeServicos: _produtoAtualKitsDeServicos.valorVenda,
                  descricaoKitsDeServicos: _produtoAtualKitsDeServicos.descricao,
                  descricaoGarantiaKitsDeServicos: _produtoAtualKitsDeServicos.descricaoGarantia,
                },
              });
            } else if (!_produtoAtualKitsDeServicos.situacao) {
              _produtosInativados.push({ name: 'kitsDeServicos', index: index });
            }
          } else {
            _produtosExcluidos.push({ name: 'kitsDeServicos', index: index });
          }
        });
      }

      if (_formValues.modeloPainel?.value && _formValues.listagemPaineis) {
        const _produtoAtualPaineis: ListarProdutosTypes = _formValues.listagemPaineis[_formValues.modeloPainel?.value];

        if (_produtoAtualPaineis?.codigo) {
          if (
            compararValorProdutoDesatualizado(
              Number(_formValues.valorVendaPaineis || 0),
              _produtoAtualPaineis?.valorVenda
            ) &&
            _produtoAtualPaineis.situacao
          ) {
            _produtosDesatualizados.push({
              name: 'painel',
              values: {
                modeloPainel: { label: _produtoAtualPaineis.nome, value: _produtoAtualPaineis.codigo },
                potenciaPaineis: _produtoAtualPaineis.potencia,
                garantiaPaineis: _produtoAtualPaineis.garantia,
                tipoGarantiaPaineis: _produtoAtualPaineis.tipoGarantia,
                valorVendaPaineis: _produtoAtualPaineis.valorVenda,
              },
            });
          } else if (!_produtoAtualPaineis.situacao) {
            _produtosInativados.push({ name: 'painel' });
          }
        } else {
          _produtosExcluidos.push({ name: 'painel' });
        }
      }

      if (_formValues.inversores?.length && _formValues.listagemInversores) {
        _formValues.inversores.forEach((it: any, index: number) => {
          const _produtoAtualInversores: ListarProdutosTypes = _formValues.listagemInversores[it.codigo];

          if (_produtoAtualInversores?.codigo) {
            if (
              compararValorProdutoDesatualizado(
                Number(it.valorVendaInversores || 0),
                _produtoAtualInversores?.valorVenda
              ) &&
              _produtoAtualInversores.situacao
            ) {
              _produtosDesatualizados.push({
                name: 'inversores',
                index: index,
                values: {
                  modeloInversores: {
                    label: _produtoAtualInversores.nome,
                    value: _produtoAtualInversores.codigo,
                  },
                  quantidadeInversores: Number(it.quantidadeInversores || 1),
                  potenciaInversores: _produtoAtualInversores.potencia,
                  garantiaInversores: _produtoAtualInversores.garantia,
                  tipoGarantiaInversores: _produtoAtualInversores.tipoGarantia,
                  valorVendaInversores: _produtoAtualInversores.valorVenda,
                },
              });
            } else if (!_produtoAtualInversores.situacao) {
              _produtosInativados.push({ name: 'inversores', index: index });
            }
          } else {
            _produtosExcluidos.push({ name: 'inversores', index: index });
          }
        });
      }

      if (_formValues.outros?.length && _formValues.listagemOutros) {
        _formValues.outros.forEach((it: any, index: number) => {
          const _produtoAtualOutros: ListarProdutosTypes = _formValues.listagemOutros[it.codigo];

          if (_produtoAtualOutros?.codigo) {
            if (
              compararValorProdutoDesatualizado(Number(it.valorVendaOutros || 0), _produtoAtualOutros?.valorVenda) &&
              _produtoAtualOutros.situacao
            ) {
              _produtosDesatualizados.push({
                name: 'outros',
                index: index,
                values: {
                  modeloOutros: {
                    label: _produtoAtualOutros.nome,
                    value: _produtoAtualOutros.codigo,
                  },
                  quantidadeOutros: Number(it.quantidadeOutros || 0),
                  garantiaOutros: _produtoAtualOutros.garantia,
                  valorVendaOutros: _produtoAtualOutros.valorVenda,
                },
              });
            } else if (!_produtoAtualOutros.situacao) {
              _produtosInativados.push({ name: 'outros', index: index });
            }
          } else {
            _produtosExcluidos.push({ name: 'outros', index: index });
          }
        });
      }

      await sleep(200);

      if (_produtosDesatualizados?.length || _produtosExcluidos?.length || _produtosInativados?.length) {
        setGeradorDeProposta({
          abrirProdutoExcluidoOuPrecoDesatualizadoDialog: {
            aberto: true,
            produtosDesatualizados: _produtosDesatualizados ? _produtosDesatualizados : undefined,
            produtosExcluidos: _produtosExcluidos ? _produtosExcluidos : undefined,
            produtosInativados: _produtosInativados ? _produtosInativados : undefined,
          },
        });
      }
    }
  }, [
    _formValues.listagemKitsFotovoltaicos,
    _formValues.listagemKitsDeServicos,
    _formValues.listagemPaineis,
    _formValues.listagemInversores,
    _formValues.listagemOutros,
  ]);

  useEffect(() => {
    handleRetornoPaybackGrupoA();
  }, [handleRetornoPaybackGrupoA]);

  useEffect(() => {
    handleOpenProdutoDesatualizadoOuExcluidoAoClonarDialog();
  }, [handleOpenProdutoDesatualizadoOuExcluidoAoClonarDialog]);

  useEffect(() => {
    if (!geradorDeProposta?.caminhoGerador) {
      if (_formValues?.tipoProduto === 0) {
        handleControleCaminhoGerador({ caminhoGerador: 'produtos', methods, values: _formValues });
      }
      if (_formValues?.tipoProduto === 1) {
        handleControleCaminhoGerador({ caminhoGerador: 'kits', methods, values: _formValues });
      }
    }
  }, [_formValues?.tipoProduto, handleControleCaminhoGerador, geradorDeProposta?.caminhoGerador]);

  useEffect(() => {
    _handleSetarEconomiasAnuais();
  }, [_handleSetarEconomiasAnuais]);

  useEffect(() => {
    handleCalcularGeracaoSistema();
  }, [handleCalcularGeracaoSistema]);

  useEffect(() => {
    if (_formValues?.tipoLigacao?.value === '3') {
      handleMontarTabelaPaybackGrupoA();
    } else {
      handleMontarTabelaPaybackGrupoB();
    }
  }, [handleMontarTabelaPaybackGrupoB, handleMontarTabelaPaybackGrupoA, _formValues?.tipoLigacao]);

  return { handleControleCaminhoGerador, handleCloseApresentacaoSimultaneidadeDialog };
};

export default useGeradorDePropostaSistemaPayback;
