import { useFormContext } from 'react-hook-form';
import useGeradorDeProposta from '../../store/geradorDeProposta.store';
import { useControleCaminhoGerador } from '../../controleCaminhoGerador/useControleCaminhoGerador';
import { useMemo } from 'react';

export function useHeaderSistemaPayback() {
  const methods = useFormContext();
  const { geradorDeProposta } = useGeradorDeProposta();
  const { handleControleCaminhoGerador } = useControleCaminhoGerador();

  const _formValuesTipoLigacao = methods.getValues('tipoLigacao');
  const _formValuesTipoDimensionamento = methods.getValues('tipoDimensionamento');

  const tipoDimensionamento = useMemo(() => {
    if (_formValuesTipoLigacao?.value === '3') {
      if (_formValuesTipoDimensionamento === '1') {
        return 'foraPontaMaisPonta';
      } else {
        return 'foraPonta';
      }
    } else {
      return 'geral';
    }
  }, [_formValuesTipoLigacao, _formValuesTipoDimensionamento]);

  const caminhoGerador = geradorDeProposta?.caminhoGerador === 'kits' ? 1 : 0;

  function handleCaminhoGerador(caminho: 'kits' | 'produtos') {
    methods.setValue('passouValidacaoProdutoDesatualizado', 'true');

    handleControleCaminhoGerador({ caminhoGerador: caminho, methods, values: methods.getValues() });
  }

  const _formValuesConsumoMedioForaPontaMaisPontaAjustado = methods.getValues('consumoMedioForaPontaMaisPontaAjustado');
  const _formValuesConsumoMedioForaPonta = methods.getValues('consumoMedioForaPonta');
  const _formValuesConsumoMedio = methods.getValues('consumoMedio');
  const consumoMedio = useMemo(() => {
    switch (tipoDimensionamento) {
      case 'foraPontaMaisPonta':
        return _formValuesConsumoMedioForaPontaMaisPontaAjustado;
      case 'foraPonta':
        return _formValuesConsumoMedioForaPonta;
      default:
        return _formValuesConsumoMedio;
    }
  }, [
    tipoDimensionamento,
    _formValuesConsumoMedioForaPontaMaisPontaAjustado,
    _formValuesConsumoMedioForaPonta,
    _formValuesConsumoMedio,
  ]);

  const _formValuesPotenciaSistemaForaPonta = methods.getValues('potenciaSistemaForaPonta');
  const _formValuesPotenciaSistemaForaPontaMaisPonta = methods.getValues('potenciaSistemaForaPontaMaisPonta');
  const _formValuesPotenciaInstalada = methods.getValues('potenciaInstalada');
  const _formValuesPotenciaInversor = methods.getValues('potenciaInversor');

  const potencia = useMemo(() => {
    switch (tipoDimensionamento) {
      case 'foraPontaMaisPonta':
        return _formValuesPotenciaSistemaForaPontaMaisPonta;
      case 'foraPonta':
        return _formValuesPotenciaSistemaForaPonta;
      default:
        return _formValuesPotenciaInstalada || _formValuesPotenciaInversor;
    }
  }, [
    tipoDimensionamento,
    _formValuesPotenciaSistemaForaPontaMaisPonta,
    _formValuesPotenciaSistemaForaPonta,
    _formValuesPotenciaInstalada,
    _formValuesPotenciaInversor,
  ]);

  const _formValuesGeracaoSistemaForaPonta = methods.getValues('geracaoSistemaForaPonta');
  const _formValuesGeracaoSistemaForaPontaMaisPonta = methods.getValues('geracaoSistemaForaPontaMaisPonta');
  const _formValuesGeracaoSistema = methods.getValues('geracaoSistema');

  const geracao = useMemo(() => {
    switch (tipoDimensionamento) {
      case 'foraPontaMaisPonta':
        return _formValuesGeracaoSistemaForaPontaMaisPonta;
      case 'foraPonta':
        return _formValuesGeracaoSistemaForaPonta;
      default:
        return _formValuesGeracaoSistema;
    }
  }, [
    tipoDimensionamento,
    _formValuesGeracaoSistemaForaPontaMaisPonta,
    _formValuesGeracaoSistemaForaPonta,
    _formValuesGeracaoSistema,
  ]);

  return { consumoMedio, potencia, geracao, caminhoGerador, handleCaminhoGerador };
}
