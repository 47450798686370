import dayjs from 'dayjs';
import { memo, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { LIcon, Tooltip, Typography } from '@s_mart/core';
import { Checkbox, TextField } from '@s_mart/form';
import { numeroPontuacao } from '@s_mart/masks';
import { lineMinus, linePlus } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import { meses } from 'config/constantes';
import { corPrimariaSolarfy } from 'styles/theme/theme';

import useConsumoMensalContent from './useConsumoMensalContent';
import { ConsumoMensalDialogForm } from '../consumoMensalDialog.types';
import { lineExclamationTriangle } from '@s_mart/regular-icons';
import { Stack, capitalize } from '@mui/material';
import { useConsumoMensalContext } from '../consumoMensal.context';

const ConsumoMensalContent = () => {
  const { control, setValue, watch } = useFormContext<ConsumoMensalDialogForm>();
  const { mesesValorZerado, handleEsconderErroValorZerado } = useConsumoMensalContext();
  const _contasFieldArray = useFieldArray({ name: 'contas', control });
  const { handleInserirRemoverConta, handleConsumoMedio } = useConsumoMensalContent();

  const _formValues = watch();

  useEffect(() => {
    const mesesValorZerado = [] as number[];

    _formValues?.contas?.forEach((conta) => {
      conta.forEach((valor, j) => {
        if (Number(valor || 0) === 0) {
          mesesValorZerado.push(j);
        }
      });
    });

    if (!mesesValorZerado.length) {
      handleEsconderErroValorZerado();
    }
  }, [_formValues?.contas]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '8px' }}>
      <>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div style={{ display: 'flex', minWidth: '100px' }}>
            <Typography variant='titleXS'>Meses</Typography>
          </div>
          {_formValues?.contas?.map((_: any, i: number) => (
            <div style={{ display: 'flex', minWidth: '110px', justifyContent: 'space-between' }} key={`conta-${i + 1}`}>
              <Typography variant='titleXS'>Conta {i + 1}</Typography>
              <Tooltip title='Adicionar mais uma conta mês a mês'>
                <div>
                  <LIcon
                    icon={_formValues?.contas?.length === i + 1 && i + 1 !== 5 ? linePlus : lineMinus}
                    size='16px'
                    color={
                      _formValues?.contas?.length === i + 1 && i + 1 !== 5
                        ? colorPalette.neutral[0]
                        : corPrimariaSolarfy
                    }
                    removeMargin
                    style={{
                      background:
                        _formValues?.contas?.length === i + 1 && i + 1 !== 5
                          ? corPrimariaSolarfy
                          : colorPalette.neutral[0],
                      border: `1px solid ${corPrimariaSolarfy}`,
                      borderRadius: '3px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleInserirRemoverConta(i, _formValues?.contas?.length, _contasFieldArray)}
                  />
                </div>
              </Tooltip>
            </div>
          ))}
          <div style={{ display: 'flex', minWidth: '100px' }}>
            <Typography variant='titleXS'>Irradiação</Typography>
          </div>
        </div>
        {meses.map((value, index) => (
          <div style={{ display: 'flex', gap: '8px' }} key={value}>
            <div style={{ display: 'flex', minWidth: '100px' }}>
              <Typography variant='bodySM'>{value}</Typography>
            </div>
            {_contasFieldArray?.fields?.map((_, i: number) => (
              <div
                style={{ display: 'flex', width: '110px', flexDirection: 'column', gap: '4px' }}
                key={`contas[${i}][${index}]`}
              >
                <TextField
                  name={`contas[${i}][${index}]`}
                  size='small'
                  style={{ width: '110px' }}
                  //Esse value={numeroPontuacao.format(String(watch(`contas.${i}.${index}`) || ''))} é necessário
                  //para que re-renderize os fields quando houver o setValue do checkbox "Repetir"
                  value={numeroPontuacao.format(String(watch(`contas.${i}.${index}`) || ''))}
                  parse={(value) => String(numeroPontuacao.parse(value))}
                  inputProps={{ maxLength: 7 }}
                  InputProps={{
                    endAdornment: (
                      <Typography variant='bodyXS' style={{ color: colorPalette.neutral[100] }}>
                        kWh
                      </Typography>
                    ),
                  }}
                />
                {index === 0 && !!Number(_formValues?.contas?.[i]?.[index]) && (
                  <Checkbox
                    name={`repetir[${i}]`}
                    label='Repetir'
                    size='small'
                    onClick={() => {
                      setValue(
                        `contas.${i}`,
                        _formValues?.repetir?.[i]
                          ? [_formValues?.contas?.[i]?.[0], ...Array(11).fill('')]
                          : Array(12).fill(_formValues?.contas?.[i]?.[0])
                      );
                    }}
                  />
                )}
              </div>
            ))}
            <div style={{ display: 'flex', minWidth: '100px' }}>
              <TextField
                name={`irradiacao[${index}]`}
                size='small'
                disabled
                style={{ width: '90px' }}
                mask='numeroPontuacao'
              />
            </div>
          </div>
        ))}
        {!mesesValorZerado.length && (
          <div style={{ display: 'flex', gap: '4px', marginTop: '8px' }}>
            <Typography variant='titleXXS'>Consumo médio:</Typography>
            <Typography variant='bodyXS'>{handleConsumoMedio(_formValues?.contas)} kWh</Typography>
          </div>
        )}
        {mesesValorZerado.length > 0 && (
          <Stack direction='row' sx={{ bgcolor: '#FFDFCD', borderRadius: '8px', padding: '4px 8px 4px 8px' }}>
            <LIcon icon={lineExclamationTriangle} />
            <Typography variant='bodyXS'>
              <b>
                {mesesValorZerado?.length === 1
                  ? `${capitalize(dayjs().month(mesesValorZerado[0]).format('MMMM'))} está `
                  : 'Há meses com '}
                0 kWh
              </b>
              , informe o consumo para um dimensionamento correto.
            </Typography>
          </Stack>
        )}
      </>
    </div>
  );
};

export default memo(ConsumoMensalContent);
