import { useCallback, useState } from 'react';

import * as clientesApi from 'api/clientes/clientes';
import type { ICliente } from 'api/clientes/clientes.types';
import useApp from 'store/app/app';
import { formataCpfCnpj } from 'utils/formataCpfCnpj/formataCpfCnpj';

import type { ClienteFormDialogValues } from './clienteFormDialog.types';
import useClienteFormDialog from './store/clienteFormDialog.store';

const useClienteFormDialogFunctions = () => {
  const { setLoading, handleError } = useApp();
  const { setClienteFormDialog } = useClienteFormDialog();

  const [initialState, _setInitialState] = useState<ClienteFormDialogValues>();

  const handleClose = () => {
    setClienteFormDialog({ aberto: false, codigoCliente: undefined });
  };

  const buscarDadosCliente = useCallback(
    async (codigo: number) => {
      setLoading(true);
      try {
        const { data }: { data: { data: ICliente } } = await clientesApi.buscarCliente(codigo);

        const temCadastroAvancado = () => {
          if (
            data.data.codigoCidade ||
            data.data.cpfCnpj ||
            data.data.nomeResponsavel ||
            data.data.razaoSocial ||
            data.data.email
          ) {
            return true;
          } else {
            return false;
          }
        };

        if (data.data.codigoCidade) {
          _setInitialState({
            ...data.data,
            dadosCidade: {
              label: `${data.data.cidade} - ${data.data.uf}`,
              key: data.data.codigoCidade,
              value: data.data.codigoCidade,
            },
            cpfCnpj: formataCpfCnpj(data.data.cpfCnpj),
            cadastroAvancado: temCadastroAvancado(),
            tipoPessoa: String(data.data.tipoPessoa || 0),
          });
        } else {
          _setInitialState({
            ...data.data,
            cpfCnpj: formataCpfCnpj(data.data.cpfCnpj),
            cadastroAvancado: temCadastroAvancado(),
            tipoPessoa: String(data.data.tipoPessoa || 0),
          });
        }
      } catch (err) {
        handleError(err);
      }

      setLoading(false);
    },
    [setLoading, handleError]
  );

  return { initialState, buscarDadosCliente, handleClose };
};

export default useClienteFormDialogFunctions;
