import { memo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, Grid, LIcon, Typography } from '@s_mart/core';
import { TextField } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { composeValidators, maiorQue, required } from '@s_mart/rules';
import { lineExclamationTriangle } from '@s_mart/solid-icons';
import { colorPalette } from '@s_mart/tokens';

import ConsumoMensalDialog from '../consumoMensalDialog/consumoMensalDialog';
import GeradorDePropostaDimensionamento from '../geradorDePropostaDimensionamento/geradorDePropostaDimensionamento';

const FluxoGrupoB = () => {
  const { watch } = useFormContext();
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);
  const telaMenorQue760 = useMediaQuery(`(max-width: 760px)`);

  const [_mostrarConsumoMensalDialog, _setMostrarConsumoMensalDialog] = useState(false);

  const _formValuesPrecoKwh = watch('precoKwh');
  const _formValuesIrradiacaoMedia = watch('irradiacaoMedia');
  const _formValuesConsumoMedio = watch('consumoMedio');
  const _formValuesTipoLigacao = watch('tipoLigacao');

  return (
    <>
      {_mostrarConsumoMensalDialog && <ConsumoMensalDialog onClose={() => _setMostrarConsumoMensalDialog(false)} />}
      <Grid
        item
        xs={6}
        sm={(() => {
          if (telaMenorQue760) {
            return 4;
          }
          return 2;
        })()}
        style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
      >
        <TextField
          name='precoKwh'
          label='Valor da tarifa'
          required
          InputProps={{
            startAdornment: (
              <Typography variant='bodySM' style={{ paddingTop: '1px', paddingLeft: '6px', marginRight: '-4px' }}>
                R$
              </Typography>
            ),
          }}
          rules={composeValidators([
            {
              validate: {
                valorMinimo: (value: string | number) => {
                  if (String(value) && Number(value) < 0.1) {
                    return `Valor mínimo de ${0.1}`;
                  }
                },
              },
            },
            {
              validate: {
                valorMaximo: (value: string | number) => {
                  if (String(value) && Number(value) > 5) {
                    return `Valor máximo de ${5}`;
                  }
                },
              },
            },
            required,
          ])}
          mask='decimal3'
          inputProps={{ maxLength: 6 }}
        />
        {((_formValuesPrecoKwh >= 0.1 && _formValuesPrecoKwh < 0.5) ||
          (_formValuesPrecoKwh >= 1.5 && _formValuesPrecoKwh < 5)) && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              background: colorPalette.yellow[75],
              borderRadius: '6px',
              padding: '2px 4px',
            }}
          >
            <LIcon icon={lineExclamationTriangle} removeMargin size='18px' color={colorPalette.yellow[300]} />
            <Typography variant='bodyXS'>Valor {_formValuesPrecoKwh < 0.5 ? 'baixo' : 'elevado'}</Typography>
          </div>
        )}
      </Grid>
      <Grid
        item
        xs={6}
        sm={(() => {
          if (telaMenorQue760) {
            return 4;
          }
          return 3;
        })()}
      >
        <TextField
          name='consumoMedio'
          label='Consumo médio'
          required
          mask='numeroPontuacao'
          rules={composeValidators([maiorQue(0), required])}
          inputProps={{ maxLength: 8 }}
          InputProps={{ endAdornment: <Typography variant='bodySM'>kWh</Typography> }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={(() => {
          if (telaMenorQue760) {
            return 6;
          }
          return 4;
        })()}
        style={{
          display: 'flex',
          marginTop: telaMenorQue600 ? '8px' : '20px',
        }}
      >
        <Button
          variant='neutral'
          onClick={() => _setMostrarConsumoMensalDialog(true)}
          fullWidth
          style={{ height: '38px' }}
        >
          Informar consumo mensal
        </Button>
      </Grid>
      {/* Só aparece os valores do dimensionamento depois de ter as informações de IRRADIAÇÃO E CONSUMO MÉDIO para o grupo B */}
      {Boolean(_formValuesIrradiacaoMedia && Number(_formValuesConsumoMedio)) &&
        _formValuesTipoLigacao?.value !== '3' && (
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <GeradorDePropostaDimensionamento />
          </Grid>
        )}
    </>
  );
};

export default memo(FluxoGrupoB);
