import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { array12Posicoes } from './consumoMensalDialog.static';
import { ConsumoMensalDialogForm, Contas } from './consumoMensalDialog.types';
import { useConsumoMensalContext } from './consumoMensal.context';

const useConsumoMensalDialog = () => {
  const { setValue, watch } = useFormContext();
  const { handleMostrarErroValorZerado } = useConsumoMensalContext();

  const _formValues = watch();

  const initialValuesConsumoMensalDialog = useMemo(() => {
    let contas = [array12Posicoes];

    if (Object?.values(_formValues?.contas || [])?.length) {
      contas = Object?.values(_formValues?.contas);
    }

    if (_formValues?.consumoMensal?.length) {
      contas = _formValues?.consumoMensal?.map((item: any, i: number) => item?.[i + 1]?.map((it: any) => it?.valor));
    }

    return {
      contas: contas,
      irradiacao: _formValues.irradiacao,
    };
  }, [_formValues?.consumoMensal, _formValues.irradiacao, _formValues.contas]);

  const handleConsumoMedio = useCallback((contas: Contas) => {
    return (
      Math.round(
        contas
          ?.map((item) => {
            if (item?.every((value) => value === undefined)) {
              return 0;
            }
            return item?.reduce((a, b) => Number(a || 0) + Number(b || 0)) || 0;
          })
          ?.reduce((a: any, b: any) => Number(a || 0) + Number(b || 0)) / 12
      ) || 0
    );
  }, []);

  const handleTamanhoConsumoMensalDialog = useCallback((numeroContas: number) => {
    switch (numeroContas) {
      default:
      case 0:
      case 1:
      case 2:
        return 'xs';
      case 3:
      case 4:
        return 'sm';
      case 5:
        return 'md';
    }
  }, []);

  const handleConsumoMensalSubmit = (values: any, onClose: () => void) => {
    const mesesValorZerado = [] as number[];

    (values?.contas as ConsumoMensalDialogForm['contas'])?.forEach((conta) => {
      conta.forEach((valor, j) => {
        if (Number(valor || 0) === 0) {
          mesesValorZerado.push(j);
        }
      });
    });

    if (mesesValorZerado.length) {
      handleMostrarErroValorZerado(mesesValorZerado);
      return;
    }

    const consumoMensalPayload = values?.contas
      .map(
        (item: number[], i: number) =>
          !item.every((valor) => valor === undefined) && {
            [i + 1]: item?.map((it: number, j: number) => ({
              mes: j + 1,
              valor: it,
            })),
          }
      )
      .filter((it: any) => !!it);

    setValue('consumoMensal', consumoMensalPayload);
    setValue('consumoMedio', String(handleConsumoMedio(values.contas)));
    onClose();
  };

  return {
    handleConsumoMedio,
    handleTamanhoConsumoMensalDialog,
    handleConsumoMensalSubmit,
    initialValuesConsumoMensalDialog,
  };
};

export default useConsumoMensalDialog;
