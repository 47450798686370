import { memo } from 'react';

import { CircularProgress, Typography } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';

import { SLoadingDots } from './loading.styles';
import { Backdrop } from '../loadings.styles';

const Loading = ({ mensagem }: { mensagem?: string }) => {
  return (
    <Backdrop>
      <CircularProgress size={60} />
      {mensagem && (
        <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center', gap: '24px' }}>
          <Typography variant='titleMD' color={colorPalette.neutral[0]}>
            {mensagem}
          </Typography>
          <SLoadingDots className='loader' />
        </div>
      )}
    </Backdrop>
  );
};

export default memo(Loading);
