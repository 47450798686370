import { api } from '../api';
import { urls } from 'api/urls';

import {
  CadastrarEditarClienteTypes,
  ListagemSimplesClientesTypes,
  ListarClientesTypes,
  UltimaPropostaAtivaResponse,
} from './clientes.types';
import { AxiosResponse } from 'axios';

const listarClientes = async (params: ListarClientesTypes) => await api.get(`${urls.clientes}`, { params: params });

const cadastrarCliente = async (payload: CadastrarEditarClienteTypes) => await api.post(`${urls.clientes}`, payload);

const deletarCliente = async (id: number) => await api.delete(`${urls.clientes}/${id}`);

const editarCliente = async (id: number, payload: CadastrarEditarClienteTypes) =>
  await api.patch(`${urls.clientes}/${id}`, payload);

const buscarCliente = async (id: number) => await api.get(`${urls.clientes}/${id}`);

const listarClientesSelect = async (params: {
  search?: string;
}): Promise<AxiosResponse<{ data: ListagemSimplesClientesTypes[] }>> =>
  await api.get(`${urls.clientes}/filtro`, { params: params });

const ultimaPropostaAtiva = async (id: number): UltimaPropostaAtivaResponse =>
  await api.get(`${urls.clientes}/${id}/ultima-proposta-ativa`);

export {
  listarClientes,
  cadastrarCliente,
  deletarCliente,
  editarCliente,
  buscarCliente,
  listarClientesSelect,
  ultimaPropostaAtiva,
};
