import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { IOpcoesCores } from './coresProposta.types';

const selectedCss = css`
  width: 40px;
  height: 40px;
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
`;

export const ColorCircleButton = styled.div<IOpcoesCores>`
  width: 36px;
  height: 36px;

  border-radius: 50%;
  border: none;
  cursor: pointer;

  color: white;
  background-color: ${({ cor }) => cor};
  transition: all 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ selected }) => selected && selectedCss};
`;

export const ColorPicker = styled.input<{ selected?: boolean }>`
  &::-webkit-appearance {
    display: none;
  }

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
  }

  background-color: transparent;
  border: none;
  width: 36px;
  height: 36px;
  transition: all 0.2s ease-in-out;

  ${({ selected }) => selected && selectedCss};
`;

const selectedCssInput = css`
  width: 39px;
  height: 39px;
  transition: all 0.2s ease-in-out;
`;

export const DivIconColorPicker = styled.div<{ selected?: boolean }>`
  position: absolute;

  top: 0;
  width: 36px;
  height: 36px;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  transition: all 0.2s ease-in-out;
  ${({ selected }) => selected && selectedCssInput};
`;
