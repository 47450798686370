import styled from '@emotion/styled';

import { toRem } from '@s_mart/utils';

import { corPrimariaSolarfy } from 'styles/theme/theme';

export const MenuPageDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  inset: 0;
  background-color: white;
  position: absolute;
  z-index: 18;
  gap: ${toRem(10)};

  .header {
    display: flex;
    padding: ${toRem(8)};
    align-items: center;
    gap: ${toRem(16)};
    background-color: ${corPrimariaSolarfy};
    border-radius: 0 0 ${toRem(8)} ${toRem(8)};

    .icone {
      display: flex;
      padding: ${toRem(6)};
      background-color: white;
      border-radius: ${toRem(4)};
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 0 ${toRem(16)};
    gap: ${toRem(10)};
  }
`;
