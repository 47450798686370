import styled from '@emotion/styled';
import { Select, TextField } from '@s_mart/form';
import { colorPalette } from '@s_mart/tokens';
import { toRem } from '@s_mart/utils';

import { MOBILE_BREAKPOINT } from 'hooks/useIsMobile/useIsMobile';

export const HeaderContainer = styled.div`
  background: ${colorPalette.neutral[30]};

  display: flex;
  align-items: center;

  padding: ${toRem(8)};
  gap: ${toRem(8)};
  border-radius: ${toRem(8)};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    background: transparent;
    padding: 0;
  }
`;

export const SelectStyled = styled(Select)`
  min-width: ${toRem(170)};
  width: 100%;

  background-color: white;
  border-radius: ${toRem(8)};
`;

export const TextFieldStyled = styled(TextField)`
  min-width: ${toRem(170)};

  background-color: white;
  border-radius: ${toRem(8)};
`;
