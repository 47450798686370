const titulo = (titulo: string, extra?: 'imprimir' | 'saberMais' | 'fatura') => {
  switch (extra) {
    case 'imprimir':
      return (
        <>
          {titulo}
          <b> Clique para imprimir.</b>
        </>
      );
    case 'saberMais':
      return (
        <>
          {titulo}
          <b> clique para saber mais.</b>
        </>
      );
    case 'fatura':
      return (
        <>
          {titulo}
          <b> Visualizar fatura.</b>
        </>
      );

    default:
      return `${titulo}`;
  }
};

export const tituloBotao = (telaMenorQue800: boolean) => {
  if (telaMenorQue800) {
    return [
      titulo('Fatura disponível'),
      titulo('Fatura vencendo'),
      titulo('Sua fatura vence hoje'),
      titulo('Sua fatura está vencida'),
      titulo('Pendências financeiras'),
      titulo('Aguardando confirmação'),
    ];
  } else {
    return [
      titulo('Já está disponível sua fatura deste mês.', 'imprimir'),
      titulo('Sua fatura está próxima de vencer.', 'imprimir'),
      titulo('Sua fatura vence hoje. Evite o bloqueio.', 'imprimir'),
      titulo('Sua fatura está vencida, evite o bloqueio do seu sistema.', 'imprimir'),
      titulo('Existem pendências financeiras.', 'fatura'),
      titulo('Aguardando confirmação do pagamento, podendo levar até 1 dia útil.'),
    ];
  }
};
