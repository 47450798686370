import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { buscarProduto, listarProdutos } from 'api/produtos/produtos';
import { BuscarProdutoDados, ListarProdutosTypes } from 'api/produtos/produtos.types';
import { ListagemProdutosTypes } from 'containers/produtos/produtos.types';
import useApp from 'store/app/app';

const useFieldsKitsDeServico = () => {
  const { setValue } = useFormContext();
  const { handleError } = useApp();

  const [kitsDeServico, _setKitsDeServico] = useState<ListagemProdutosTypes[]>();
  const [loadingKitsDeServico, _setLoadingKitsDeServico] = useState(false);

  const handleListarKitsDeServico = useCallback(async () => {
    _setLoadingKitsDeServico(true);
    try {
      const { data } = await listarProdutos({ tipoProduto: '4', all: true });

      if (data) {
        _setKitsDeServico(data?.data);
        setValue(
          'listagemKitsDeServicos',
          data?.data.reduce((acc: ListarProdutosTypes[], item: ListarProdutosTypes) => {
            acc[item.codigo] = item;
            return acc;
          }, {})
        );
      }
      _setLoadingKitsDeServico(false);
    } catch (err) {
      _setLoadingKitsDeServico(false);
      handleError(err);
    }
  }, [handleError]);

  const handleBuscarKitDeServicoSelecionado = useCallback(
    async (index: number, values: any) => {
      if (values?.value) {
        try {
          const { data }: { data: { data: BuscarProdutoDados } } = await buscarProduto(values?.value);

          setValue(`kitsDeServicos.${index}.descricaoKitsDeServicos`, data?.data?.descricao || '');
          setValue(`kitsDeServicos.${index}.descricaoGarantiaKitsDeServicos`, data?.data?.descricaoGarantia || '');
          setValue(
            `kitsDeServicos.${index}.valorVendaKitsDeServicos`,
            data?.data?.valorVenda ? String(data?.data?.valorVenda) : ''
          );
          setValue(
            `kitsDeServicos.${index}.mostrarDetalhes`,
            Boolean(data?.data?.descricao || data?.data?.descricaoGarantia)
          );
        } catch (err) {
          handleError(err);
        }
      }
    },
    [handleError, setValue]
  );

  useEffect(() => {
    handleListarKitsDeServico();
  }, []);

  return { kitsDeServico, loadingKitsDeServico, handleBuscarKitDeServicoSelecionado };
};

export default useFieldsKitsDeServico;
