import { memo } from 'react';
import { Portal } from '@mui/material';

import { Button, Dialog } from '@s_mart/core';
import { Form } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';

import GraficoConsumo from 'shared/modais/geradorDeProposta/geradorDePropostaImagens/graficoConsumo/graficoConsumo';
import { corPrimariaSolarfy } from 'styles/theme/theme';

import BaseDeCalculoConsumoFields from './baseDeCalculoConsumoFields/baseDeCalculoConsumoFields';
import useBaseDeCalculoConsumoDialog, { IBaseDeCalculoConsumoSubmit } from './useBaseDeCalculoConsumoDialog';
import useConfiguracoes from 'store/configuracoes/configuracoes';

const BaseDeCalculoConsumoDialog = ({ onClose }: { onClose: () => void }) => {
  const { initialValues, handleSubmitBaseCalculoConsumo } = useBaseDeCalculoConsumoDialog();
  const { gerais } = useConfiguracoes();

  const telaMenorQue480 = useMediaQuery(`(max-width: 480px)`);

  return (
    <Portal>
      <Form
        onSubmit={(values: IBaseDeCalculoConsumoSubmit) => {
          handleSubmitBaseCalculoConsumo(values);
          onClose();
        }}
        defaultValues={initialValues}
        disableDefaultValuesUpdate
      >
        {({ watch }) => (
          <Dialog
            open
            onClose={onClose}
            closeIcon
            disableBackdropClick
            disableEscapeKeyDown
            disablePortal
            maxWidth='sm'
            sx={{
              '.MuiDialog-paper': {
                margin: telaMenorQue480 ? '0' : undefined,
              },
            }}
          >
            <Dialog.Header>Base de cálculo</Dialog.Header>
            <Dialog.Content>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '16px' }}>
                <BaseDeCalculoConsumoFields />
                <GraficoConsumo
                  cor={watch('cor') || gerais?.hexCor || corPrimariaSolarfy}
                  height={250}
                  mobile={telaMenorQue480}
                />
              </div>
            </Dialog.Content>
            <Dialog.Footer>
              <Button variant='neutral' onClick={onClose}>
                Voltar
              </Button>
              <Button type='submit'>Salvar</Button>
            </Dialog.Footer>
          </Dialog>
        )}
      </Form>
    </Portal>
  );
};

export default memo(BaseDeCalculoConsumoDialog);
