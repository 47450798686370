import { memo, useMemo } from 'react';

import { coresModelosDeProposta } from 'styles/theme/theme';

import type { SimulacaoPreviewPdfPropostaProps } from './simulacaoPreviewPdfProposta.types';
import SimulaCapaPropostaAntiga from './propostaAntiga/simulaCapaPropostaAntiga';
import SimulaCapaPropostaNova from './propostaNova/simulaCapaPropostaNova';

const SimulacaoPreviewPdfProposta = ({
  logo,
  banner,
  cor,
  numero,
  modeloProposta,
  site,
  whatsapp,
}: SimulacaoPreviewPdfPropostaProps) => {
  const _corModeloProposta = useMemo(() => {
    if (cor) {
      return cor;
    } else {
      return coresModelosDeProposta[0];
    }
  }, [cor]);

  if (modeloProposta === '0') {
    return <SimulaCapaPropostaAntiga numero={numero} logo={logo} banner={banner} cor={_corModeloProposta} />;
  } else {
    return (
      <SimulaCapaPropostaNova
        numero={numero}
        logo={logo}
        banner={banner}
        cor={_corModeloProposta}
        site={site}
        whatsapp={whatsapp}
      />
    );
  }
};

export default memo(SimulacaoPreviewPdfProposta);
