import { useCallback, useEffect, useState } from 'react';

import { base64ToBlob } from '@s_mart/utils';

import { gerarFaturaPrepago } from 'api/meusDados/meusDados';
import { isProduction } from 'api/variables';
import useApp from 'store/app/app';

import { boletoTeste } from './boletoPronto.static';

const useBoletoPronto = () => {
  const { setNotification } = useApp();

  const [fatura, setFatura] = useState<{
    linhaDigitavel: string;
    pdfBoleto: string;
  }>({ linhaDigitavel: '', pdfBoleto: '' });
  const [isError, setIsError] = useState(false);

  const handleCopiarLinhaDigitavel = useCallback(() => {
    navigator.clipboard.writeText(fatura.linhaDigitavel);
    setNotification({ title: 'Código copiado com sucesso!', autoHideDuration: 2000 });
  }, [fatura.linhaDigitavel, setNotification]);

  const handleImprimirBoleto = useCallback(() => {
    const file = base64ToBlob(fatura.pdfBoleto, 'application/pdf');
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }, [fatura.pdfBoleto]);

  const _gerarCobranca = useCallback(async () => {
    try {
      if (!isProduction) {
        setFatura({
          linhaDigitavel: '12345678901234567890123456789012345678901234567890',
          pdfBoleto: boletoTeste,
        });
      } else {
        const response = await gerarFaturaPrepago();
        setFatura(response.data.data);
      }
    } catch (error) {
      setIsError(true);
    }
  }, []);

  useEffect(() => {
    _gerarCobranca();
  }, [_gerarCobranca]);

  return {
    isError,
    handleCopiarLinhaDigitavel,
    handleImprimirBoleto,
    fatura,
  };
};

export { useBoletoPronto };
