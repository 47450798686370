import { create } from 'zustand';
import { jwtDecode } from 'jwt-decode';

import { setLocalToken, removeLocalToken, getLocalToken } from 'helpers/localStorage/localStorage';
import { api, apiForInterceptors } from 'api/api';

import type { AuthStates, AuthStore } from './auth.types';

const defaultState: AuthStates = {
  token: null,
  isAuthenticated: false,
};

function valoresIniciais() {
  try {
    const token = getLocalToken();

    if (!token) {
      return defaultState;
    }

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    apiForInterceptors.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return {
      token: token,
      isAuthenticated: true,
      profile: jwtDecode(token) as AuthStates['profile'],
    };
  } catch (error) {
    setLocalToken(null);
    return defaultState;
  }
}

const useAuth = create<AuthStore>((set) => ({
  ...valoresIniciais(),
  setToken: (token) => {
    setLocalToken(token);

    set(() => {
      if (!token) {
        return defaultState;
      }

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      apiForInterceptors.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return {
        token: token,
        isAuthenticated: true,
        profile: jwtDecode(token),
      };
    });
  },
  clearStore: () => {
    removeLocalToken();
    set(defaultState);
    api.defaults.headers.common['Authorization'] = '';
    apiForInterceptors.defaults.headers.common['Authorization'] = '';
  },
}));

export default useAuth;
