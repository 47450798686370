import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { colorPalette } from '@s_mart/tokens';
import { Typography } from '@s_mart/core';
import { useMediaQuery } from '@mui/material';

import { InformacoesCabecalhoDiv, InformacoesCabecalhoDivsInternas } from './cabecalho.styles';

function Cabecalho() {
  const { getValues } = useFormContext();
  const telaMenorQue415 = useMediaQuery('(max-width: 415px)');

  const _consumoMedio = getValues('consumoMedio');
  const _potenciaIdeal = getValues('potenciaInstalada') || getValues('potenciaInversor');

  return (
    <InformacoesCabecalhoDiv>
      <InformacoesCabecalhoDivsInternas>
        <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
          Consumo{!telaMenorQue415 && ' médio '}:
        </Typography>
        <Typography variant='titleXS' color={colorPalette.neutral[600]}>
          {_consumoMedio} kWh
        </Typography>
        <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
          Potência {!telaMenorQue415 && ' do sistema'}:
        </Typography>
        <Typography variant='titleXS' color={colorPalette.neutral[600]}>
          {_potenciaIdeal} kWp
        </Typography>
      </InformacoesCabecalhoDivsInternas>
    </InformacoesCabecalhoDiv>
  );
}

export default memo(Cabecalho);
