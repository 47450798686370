import { useMemo } from 'react';
import { Button, Dialog, Typography } from '@s_mart/core';

import { copyDialogProdutoDesatualizadoOuExcluido } from './produtoDesatualizadoOuExcluidoDialog.static';
import useProdutoDesatualizadoOuExcluidoDialog from './useProdutoDesatualizadoOuExcluidoDialog';
import useGeradorDeProposta from '../../store/geradorDeProposta.store';
import { VariacoesCopyDialogProduto } from '../../store/geradorDeProposta.store.types';

const ProdutoDesatualizadoOuExcluidoDialog = () => {
  const { geradorDeProposta } = useGeradorDeProposta();
  const { handleClose, handleSubmit } = useProdutoDesatualizadoOuExcluidoDialog();

  const _variant = useMemo(() => {
    const _desatualizados =
      geradorDeProposta.abrirProdutoExcluidoOuPrecoDesatualizadoDialog?.produtosDesatualizados?.length || 0;
    const _excluidos = geradorDeProposta.abrirProdutoExcluidoOuPrecoDesatualizadoDialog?.produtosExcluidos?.length || 0;
    const _inativados =
      geradorDeProposta.abrirProdutoExcluidoOuPrecoDesatualizadoDialog?.produtosInativados?.length || 0;

    if (_desatualizados > 0 && (_excluidos > 0 || _inativados > 0)) {
      return VariacoesCopyDialogProduto.DESATUALIZADOEEXCLUIDO;
    } else if (_desatualizados > 0) {
      return VariacoesCopyDialogProduto.DESATUALIZADO;
    } else if (_excluidos > 0 || _inativados > 0) {
      return VariacoesCopyDialogProduto.EXCLUIDO;
    }

    return VariacoesCopyDialogProduto.DESATUALIZADOEEXCLUIDO;
  }, [geradorDeProposta]);

  return (
    <Dialog open onClose={handleClose} disableBackdropClick disableEscapeKeyDown>
      <Dialog.Header>{copyDialogProdutoDesatualizadoOuExcluido[_variant].titulo}</Dialog.Header>
      <Dialog.Content>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '16px' }}>
          <Typography variant='bodySM'>{copyDialogProdutoDesatualizadoOuExcluido[_variant].descricao}</Typography>
          <Typography variant='bodySM'>{copyDialogProdutoDesatualizadoOuExcluido[_variant].descricao2}</Typography>
        </div>
      </Dialog.Content>
      <Dialog.Footer>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'end', gap: '16px' }}>
          <Button variant='neutral' onClick={handleClose}>
            {copyDialogProdutoDesatualizadoOuExcluido[_variant].textoBotaoSecundario}
          </Button>
          <Button onClick={handleSubmit}>
            {copyDialogProdutoDesatualizadoOuExcluido[_variant].textoBotaoPrimario}
          </Button>
        </div>
      </Dialog.Footer>
    </Dialog>
  );
};

export default ProdutoDesatualizadoOuExcluidoDialog;
