import { create } from 'zustand';

export interface ExcedeuBilhetagemStore extends ExcedeuBilhetagemDialogStates {
  setExcedeuBilhetagemDialog: ({ aberto }: { aberto: boolean }) => void;
}

export interface ExcedeuBilhetagemDialogStates {
  excedeuBilhetagemDialog: { aberto: boolean };
}

const useExcedeuBilhetagemDialog = create<ExcedeuBilhetagemStore>((set) => ({
  ...{ excedeuBilhetagemDialog: { aberto: false } },
  setExcedeuBilhetagemDialog: (excedeuBilhetagemDialog) => set((state) => ({ ...state, excedeuBilhetagemDialog })),
}));

export default useExcedeuBilhetagemDialog;
