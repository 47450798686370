import { ListarPropostasTypes } from 'api/propostas/propostas.types';

import { Concessionarias, IAlertaBaseCalculo } from '../geradorDeProposta.types';
import {
  ProdutosDesatualizados,
  ProdutosExcluidos,
} from '../geradorDePropostaSistemaPayback/produtoDesatualizadoOuExcluidoDialog/produtoDesatualizadoOuExcluidoDialog.types';

export enum GeradorDePropostaEtapa {
  'DADOS_CLIENTE' = 0,
  'INSTALACAO_E_CONSUMO' = 1,
  'INTEGRACAO_FORNECEDORES' = 2,
  'SISTEMA_PAYBACK' = 3,
}

export interface GeradorDePropostaStates {
  etapa: GeradorDePropostaEtapa;
  geradorDeProposta: GeradorDePropostaInfo;
}

export interface GeradorDePropostaStore extends GeradorDePropostaStates {
  setGeradorDeProposta: ({
    aberto,
    clienteSelecionado,
    alertaBaseCalculo,
    informacoesAdicionaisDialog,
    onBoarding,
    clonar,
    caminhoGerador,
    concessionarias,
    submit,
    previousProdutosOuKits,
    abrirOnboardingSimultaneidade,
    abrirOnboardingGrupoA,
  }: GeradorDePropostaInfo) => void;
  setEtapa: (etapa: GeradorDePropostaEtapa) => void;
}

export interface GeradorDePropostaInfo {
  aberto?: boolean;
  clienteSelecionado?: boolean;
  alertaBaseCalculo?: IAlertaBaseCalculo;
  informacoesAdicionaisDialog?: boolean;
  onBoarding?: boolean;
  clonar?: ListarPropostasTypes;
  caminhoGerador?: 'produtos' | 'kits';
  concessionarias?: Concessionarias[];
  submit?: boolean;
  previousProdutosOuKits?: {
    kitsFotovoltaicos?: KitsFotovoltaicos[];
    kitsDeServicos?: KitsDeServicos[];
    paineis?: Paineis;
    inversores?: Inversores[];
    outros?: Outros[];
    painelAdicionado?: boolean;
    valorTotalKits?: number;
  };
  abrirOnboardingSimultaneidade?: boolean;
  abrirOnboardingGrupoA?: boolean;
  cliente?: {
    nome: string;
    codigo: number;
  };
  callbackTelaCrm?: () => void;
  abrirProdutoExcluidoOuPrecoDesatualizadoDialog?: {
    aberto: boolean;
    produtosDesatualizados?: ProdutosDesatualizados[];
    produtosExcluidos?: ProdutosExcluidos[];
    produtosInativados?: ProdutosExcluidos[];
  };
}

export enum VariacoesCopyDialogProduto {
  DESATUALIZADO = 0,
  EXCLUIDO = 1,
  DESATUALIZADOEEXCLUIDO = 2,
}

interface Option {
  value: number | string;
  label: string;
}

interface KitsFotovoltaicos {
  descricaoGarantiaKitsFotovoltaicos?: string | null;
  descricaoKitsFotovoltaicos?: string | null;
  modeloKitsFotovoltaicos: string | Option;
  mostrarDetalhes: boolean;
  potenciaKitsFotovoltaicos: string | number;
  valorVendaKitsFotovoltaicos: string | number;
  codigo?: number;
}

interface KitsDeServicos {
  descricaoGarantiaKitsDeServicos?: string | null;
  descricaoKitsDeServicos?: string | null;
  modeloKitsDeServicos: string | Option;
  mostrarDetalhes: boolean;
  valorVendaKitsDeServicos: string | number;
  codigo?: number;
}

interface Paineis {
  modeloPainel?: string | Option;
  potenciaPaineis?: number | string;
  quantidadePaineis?: number;
  garantiaPaineis?: number;
  tipoGarantiaPaineis?: number;
  valorVendaPaineis?: number | string;
}

interface Inversores {
  garantiaInversores: string | number;
  modeloInversores: string | Option;
  potenciaInversores: string | number;
  quantidadeInversores: number;
  tipoGarantiaInversores: number;
  valorVendaInversores?: string | number;
  codigo?: number;
}

interface Outros {
  garantiaOutros: string | number;
  modeloOutros: string | Option;
  quantidadeOutros: number;
  tipoGarantiaOutros: number;
  valorVendaOutros?: string | number;
  codigo?: number;
}
