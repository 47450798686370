import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import useGeradorDeProposta from 'shared/modais/geradorDeProposta/store/geradorDeProposta.store';

export interface IBaseDeCalculoConsumoSubmit {
  potenciaPaineis: number;
  perdaSistema: number;
  larguraPainel: number;
  alturaPainel: number;
  aumentoConsumoToggle?: boolean;
  aumentoConsumo?: number;
  geracaoSistema: string;
  potenciaInstalada: string;
  areaMinima: string;
  cor: string;
}

const useBaseDeCalculoConsumoDialog = () => {
  const { setValue, watch } = useFormContext();
  const { setGeradorDeProposta } = useGeradorDeProposta();

  const _formValues = watch();

  const initialValues = useMemo(
    () => ({
      ..._formValues,
      aumentoConsumoToggle: Boolean(_formValues.aumentoConsumo),
    }),
    [_formValues]
  );

  const handleSubmitBaseCalculoConsumo = useCallback(
    (values: IBaseDeCalculoConsumoSubmit) => {
      setValue('potenciaPaineis', values?.potenciaPaineis);
      setValue('perdaSistema', values?.perdaSistema);
      setValue('larguraPainel', values?.larguraPainel);
      setValue('alturaPainel', values?.alturaPainel);
      setValue('aumentoConsumo', values?.aumentoConsumo);

      setGeradorDeProposta({ alertaBaseCalculo: { dimensionamento: false } });
    },
    [setValue, setGeradorDeProposta]
  );

  return { initialValues, handleSubmitBaseCalculoConsumo };
};

export default useBaseDeCalculoConsumoDialog;
