import styled from '@emotion/styled';

import { colorPalette } from '@s_mart/tokens';

export const BotaoLojaRecurso = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;
  cursor: pointer;

  height: calc(100% - 14px);
  border-radius: 4px;

  &:hover {
    background-color: ${colorPalette.neutral[30]};
  }
`;
