import { lineChartPie } from '@s_mart/light-icons';
import { lineFileInvoiceDollar, lineGaugeMax, lineSolarPanel, lineUsers, lineUserTie } from '@s_mart/regular-icons';
import { PathSourceProps } from '@s_mart/typed';

interface IPagina {
  key: number;
  path: string;
  name: string;
  label: string;
  icon: PathSourceProps;
}

export const paginas: IPagina[] = [
  {
    key: 0,
    path: 'visao-geral',
    name: 'visao-geral',
    label: 'Visão geral',
    icon: lineGaugeMax,
  },
  {
    key: 1,
    path: 'propostas',
    name: 'propostas',
    label: 'Propostas',
    icon: lineFileInvoiceDollar,
  },
  {
    key: 2,
    path: 'clientes',
    name: 'clientes',
    label: 'Clientes',
    icon: lineUsers,
  },
  {
    key: 3,
    path: 'produtos',
    name: 'produtos',
    label: 'Produtos',
    icon: lineSolarPanel,
  },
  {
    key: 4,
    path: 'vendedores',
    name: 'vendedores',
    label: 'Vendedores',
    icon: lineUserTie,
  },
  {
    key: 5,
    path: 'relatorios',
    name: 'relatorios',
    label: 'Relatórios',
    icon: lineChartPie,
  },
];
