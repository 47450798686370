import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  atualizarConfiguracoesModeloProposta,
  atualizarModeloPropostaPadrao,
  buscarConfiguracoesModelosPropostas,
} from 'api/configuracoes/configuracoes';
import { AtualizarModeloPropostaProps, ModelosPersonalizadosTypes } from 'api/configuracoes/configuracoes.types';
import useApp from 'store/app/app';
import useAuth from 'store/auth/auth';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import { base64ToFile } from 'utils/base64ToFile/base64ToFile';
import { modelosPropostasTodos } from './editarModeloPropostaDialog.static';
import { ModeloPropostaEnum } from 'api/propostas/propostas.types';

const useEditarModeloPropostaDialog = () => {
  const { setLoading, setNotification, handleError } = useApp();
  const { profile } = useAuth();
  const { recursosContratados, gerais, atualizarConfiguracoesGerais } = useConfiguracoes();

  const [_modelosPersonalizados, _setModelosPersonalizados] = useState<ModelosPersonalizadosTypes | undefined>();
  const _propostaModeloPersonalizada = gerais?.modeloProposta === ModeloPropostaEnum.PERSONALIZADA;

  const handleBuscarModelosPersonalizados = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await buscarConfiguracoesModelosPropostas();
      _setModelosPersonalizados(data);
      setLoading(false);
    } catch (err) {
      handleError(err);
    }
  }, []);

  const _filePropostaInitialValue = useMemo(() => {
    let _normal: File | undefined = undefined;
    let _adicional: File | undefined = undefined;

    if (_modelosPersonalizados?.arquivoModeloPerso) {
      if (_modelosPersonalizados?.arquivoModeloPerso.startsWith('JVBERi0')) {
        _normal = base64ToFile(
          _modelosPersonalizados?.arquivoModeloPerso,
          `Proposta personalizada ${profile?.emp}.pdf`,
          'application/pdf'
        );
      } else {
        _normal = base64ToFile(
          _modelosPersonalizados?.arquivoModeloPerso,
          `Proposta personalizada ${profile?.emp}.docx`,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      }
    }

    if (_modelosPersonalizados?.arquivoModeloPersoAdicional) {
      if (_modelosPersonalizados?.arquivoModeloPersoAdicional.startsWith('JVBERi0')) {
        _adicional = base64ToFile(
          _modelosPersonalizados?.arquivoModeloPersoAdicional,
          `Proposta personalizada adicional ${profile?.emp}.pdf`,
          'application/pdf'
        );
      } else {
        _adicional = base64ToFile(
          _modelosPersonalizados?.arquivoModeloPersoAdicional,
          `Proposta personalizada adicional ${profile?.emp}.docx`,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      }
    }

    return { normal: _normal, adicional: _adicional };
  }, [_modelosPersonalizados, profile?.emp]);

  const initialValues = useMemo(() => {
    let _modeloProposta = modelosPropostasTodos[1];
    if (gerais?.modeloProposta === null) {
      if (_propostaModeloPersonalizada) {
        _modeloProposta = modelosPropostasTodos[2];
      }
    } else if (gerais?.modeloProposta || gerais?.modeloProposta === 0) {
      _modeloProposta = modelosPropostasTodos[gerais?.modeloProposta];
    }

    return {
      banner: `data:image/png;base64,${gerais?.banner}`,
      base64ModeloPropostaPersonalizada: _modelosPersonalizados?.arquivoModeloPerso,
      base64ModeloPropostaPersonalizadaAdicional: _modelosPersonalizados?.arquivoModeloPersoAdicional,
      cor: gerais?.hexCor,
      facebook: gerais?.facebook,
      instagram: gerais?.instagram,
      logo: `data:image/png;base64,${gerais?.logotipo}`,
      modeloProposta: _modeloProposta,
      modeloPropostaPersonalizada: _filePropostaInitialValue?.normal,
      modeloPropostaPersonalizadaAdicional: _filePropostaInitialValue?.adicional,
      site: gerais?.site,
      sobre: gerais?.sobre,
      whatsapp: gerais?.whatsapp,
    };
  }, [gerais, _filePropostaInitialValue, _modelosPersonalizados]);

  const handleSubmitEditarModeloProposta = async (values: any, onClose: () => void) => {
    setLoading(true);
    try {
      const payloadAtualizarModelo: AtualizarModeloPropostaProps = {
        arquivoModeloPerso: values?.base64ModeloPropostaPersonalizada || null,
        arquivoModeloPersoAdicional: values?.base64ModeloPropostaPersonalizadaAdicional || null,
        hexCor: values?.cor || -1,
        banner: values?.banner,
        logo: values?.logo,
        site: values?.site || null,
        sobre: values?.sobre || null,
        whatsapp: values?.whatsapp && String(values?.whatsapp),
        facebook: values?.facebook || null,
        instagram: values?.instagram || null,
      };
      await atualizarConfiguracoesModeloProposta(payloadAtualizarModelo);

      if (localStorage.getItem('modeloProposta')) {
        localStorage.removeItem('modeloProposta');
      }

      if (initialValues.modeloProposta?.value !== values.modeloProposta?.value) {
        await atualizarModeloPropostaPadrao({ modeloProposta: Number(values.modeloProposta?.value) });
      }

      setNotification({ title: 'Configurações salvas para as próximas propostas.' });
      setLoading(false);
      atualizarConfiguracoesGerais();
      onClose();
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    if (profile?.email.includes('@sbsistemas.') && _propostaModeloPersonalizada) {
      handleBuscarModelosPersonalizados();
    }
  }, [profile?.email, recursosContratados]);

  return { handleSubmitEditarModeloProposta, initialValues };
};

export default useEditarModeloPropostaDialog;
