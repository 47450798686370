import { memo } from 'react';
import { Grid, Typography } from '@s_mart/core';

import EmptyImagem from 'assets/fornecedores/emptyFornecedores.svg';

const EmptyState = () => {
  return (
    <div style={{ height: '300px' }}>
      <Grid container justifyContent='center' textAlign='center'>
        <Grid item xs={12}>
          <img src={EmptyImagem} alt='emptyImagem' />
        </Grid>
        <Grid item maxWidth={500}>
          <Typography variant='bodySM'>
            Não encontramos nenhum kit para o sistema dimensionado. Tenter usar filtros diferentes. Caso não encontre um
            kit, você pode avançar sem kit de fornecedor.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(EmptyState);
