import { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Typography } from '@s_mart/core';
import { TextField } from '@s_mart/form';
import { valor } from '@s_mart/masks';
import { colorPalette } from '@s_mart/tokens';
import { ellipsis } from '@s_mart/utils';

import { useIsAdmin } from 'hooks/useIsAdmin/useIsAdmin';

import { useFields } from '../../useFields';

const FieldValorVendaOutros = ({ index }: { index: number }) => {
  const methods = useFormContext();
  const { handleCalcularValorTotal } = useFields();
  const isAdmin = useIsAdmin();

  const _formValuesOutros = methods.watch('outros');

  useEffect(() => {
    handleCalcularValorTotal({ values: methods.getValues(), methods });
  }, [_formValuesOutros?.[index || 0]?.valorVendaOutros]);

  const value = methods.watch(`outros.${index}.valorVendaOutros`);

  return (
    <TextField
      name={`outros.${index}.valorVendaOutros`}
      label='Valor de venda'
      value={value ? valor.format(value) : undefined}
      helperText={
        <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
          {ellipsis(
            `Total: ${valor.format(
              (_formValuesOutros?.[index || 0]?.valorVendaOutros || 0) *
                (_formValuesOutros?.[index || 0]?.quantidadeOutros || 1)
            )}`,
            19
          )}
        </Typography>
      }
      mask='valor'
      placeholder='R$ 0,00'
      disabled={!isAdmin}
    />
  );
};

export default memo(FieldValorVendaOutros);
