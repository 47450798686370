import {
  lineChartPie,
  lineHome,
  lineQuestionCircle,
  lineShoppingCart,
  lineSignIn,
  lineUser,
  lineSolarPanel,
  lineUserTie,
} from '@s_mart/light-icons';

import { useIsAdmin } from 'hooks/useIsAdmin/useIsAdmin';
import { useLogout } from 'hooks/useLogout/useLogout';
import useApp from 'store/app/app';
import useAuth from 'store/auth/auth';
import useConfiguracoes from 'store/configuracoes/configuracoes';
import { handleAjuda } from 'utils/handleAjuda/handleAjuda';

import { IMenuPages } from './menuPage.types';
import { useBottomBarContext } from '../bottomBar.context';
import { lineBoxCircleCheck, lineEdit } from '@s_mart/regular-icons';
import { ModeloPropostaEnum } from 'api/propostas/propostas.types';
const useMenuPage = () => {
  const { handleShowMenu, handleNavigate } = useBottomBarContext();
  const { setLojaDeRecursos } = useApp();
  const { profile } = useAuth();
  const isAdmin = useIsAdmin();
  const { gerais, recursosContratados, setMostrarEditarModeloDialog } = useConfiguracoes();
  const { handleLogout } = useLogout();

  const _emailSB = profile?.email.includes('@sbsistemas.');
  const _propostaModeloPersonalizada = gerais?.modeloProposta === ModeloPropostaEnum.PERSONALIZADA;

  const menuPages: IMenuPages = [
    {
      icon: lineHome,
      label: 'Minha conta',
      path: 'minha-conta',
      onClick: () => handleNavigate('minha-conta'),
      hide: !isAdmin,
    },
    { icon: lineUser, label: 'Usuários', path: 'usuarios', onClick: () => handleNavigate('usuarios'), hide: !isAdmin },
    {
      icon: lineSolarPanel,
      label: 'Produtos',
      path: 'produtos',
      onClick: () => handleNavigate('produtos'),
      hide: profile?.tipo === 2,
    },
    {
      icon: lineUserTie,
      label: 'Vendedores',
      path: 'vendedores',
      onClick: () => handleNavigate('vendedores'),
      hide: !isAdmin,
    },
    {
      icon: lineChartPie,
      label: 'Relatórios',
      path: 'relatorios',
      onClick: () => handleNavigate('relatorios'),
      hide: !recursosContratados?.crm || !isAdmin,
    },
    {
      icon: lineShoppingCart,
      label: 'Loja de recursos',
      onClick: () => {
        handleShowMenu(false);
        setLojaDeRecursos(true);
      },
      hide: !isAdmin,
    },
    {
      icon: lineEdit,
      label: 'Modelo de proposta',
      onClick: () => {
        handleShowMenu(false);
        setMostrarEditarModeloDialog(true);
      },
      hide: (!_emailSB && _propostaModeloPersonalizada) || !isAdmin,
    },
    {
      icon: lineBoxCircleCheck,
      label: 'Configurar fornecedores',
      onClick: () => handleNavigate('configuracoes-fornecedores'),
      hide: !isAdmin,
      path: '/configuracoes-fornecedores',
    },
    { icon: lineQuestionCircle, label: 'Ajuda', onClick: () => handleAjuda() },
    {
      icon: lineSignIn,
      label: 'Sair',
      onClick: () => {
        handleShowMenu(false);
        handleLogout();
      },
    },
  ];

  return { menuPages };
};

export default useMenuPage;
