import { css } from '@emotion/react';
import { colorPalette } from '@s_mart/tokens';

import { MOBILE_BREAKPOINT } from 'hooks/useIsMobile/useIsMobile';

export const ScrollbarStyled = css`
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
  }

  &::-webkit-scrollbar-track {
    background: ${colorPalette.neutral[30]};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-corner {
    background: ${colorPalette.neutral[30]};
  }

  &::-webkit-scrollbar-thumb {
    background: ${colorPalette.neutral[50]};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${colorPalette.neutral[100]};
  }
`;

const GlobalStyle = css`
  html,
  body {
    background-color: ${colorPalette.neutral[20]};
  }

  * {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif !important;
  }

  .MuiFormHelperText-root {
    margin: 0px !important;
    margin-top: 4px;
  }

  .MuiInputBase-adornedEnd {
    > span {
      padding-right: 8px;
    }
  }

  .SnackbarContainer-top {
    margin-top: 49px;
  }

  table {
    ${ScrollbarStyled}
  }

  ${ScrollbarStyled}
`;

export default GlobalStyle;
