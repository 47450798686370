import { memo } from 'react';

import { Form } from '@s_mart/form';
import { Dialog, Button } from '@s_mart/core';

import useConvidarVendedorComoUsuarioDialogFunctions from './useConvidarVendedorComoUsuarioDialogFunctions';
import useConvidarVendedorComoUsuarioDialog from './store/convidarVendedorComoUsuarioDialog.store';

const ConvidarVendedorComoUsuarioDialog = () => {
  const { convidarVendedorComoUsuarioDialog } = useConvidarVendedorComoUsuarioDialog();
  const { handleClose, handleSubmit, handleConteudoDialog } = useConvidarVendedorComoUsuarioDialogFunctions();

  return (
    <Dialog open onClose={handleClose} closeIcon disableBackdropClick disableEscapeKeyDown maxWidth='xs'>
      <Form onSubmit={handleSubmit} defaultValues={{ email: convidarVendedorComoUsuarioDialog.dados?.emailVendedor }}>
        {({ watch, setValue }) => {
          const _conteudo = handleConteudoDialog(watch('tipoUsuario'), setValue);

          return (
            <>
              <Dialog.Header>{_conteudo?.title}</Dialog.Header>
              <Dialog.Content>{_conteudo?.conteudo}</Dialog.Content>
              <Dialog.Footer>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'end',
                    marginTop: '16px',
                    gap: '16px',
                  }}
                >
                  {_conteudo?.secondaryButton && (
                    <Button variant='neutral' onClick={handleClose} size='small'>
                      {_conteudo?.secondaryButton}
                    </Button>
                  )}
                  <Button type='submit' size='small'>
                    {_conteudo?.primaryButton}
                  </Button>
                </div>
              </Dialog.Footer>
            </>
          );
        }}
      </Form>
    </Dialog>
  );
};

export default memo(ConvidarVendedorComoUsuarioDialog);
