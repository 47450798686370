import { useNavigate } from 'react-router-dom';

import { LIcon, Typography } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { lineBars } from '@s_mart/regular-icons';
import { colorPalette } from '@s_mart/tokens';

import { corPrimariaSolarfy } from 'styles/theme/theme';

import { BottomBarProvider, useBottomBarContext } from './bottomBar.context';
import * as styled from './bottomBar.styles';
import type { BottomBarProps } from './bottomBar.types';
import MenuPage from './menuPage/menuPage';

const BottomBar = ({ paginas }: BottomBarProps) => {
  const _navigate = useNavigate();
  const _telaMenorQue375 = useMediaQuery('(max-width: 375px)');
  const { showMenu, handleShowMenu, paginaAtual } = useBottomBarContext();

  return (
    <>
      {showMenu && <MenuPage />}
      <styled.DivExternaBottomBar>
        {paginas
          .filter((_, index) => index <= 2)
          .map(({ icon, label, path }, index) => (
            <styled.DivItemBottomBar
              onClick={() => {
                if (!paginaAtual(path)) {
                  _navigate(`/${path}`);
                }
                handleShowMenu(false);
              }}
              key={`${path}-${index}`}
            >
              <LIcon
                icon={icon}
                size={_telaMenorQue375 ? '28px' : '34px'}
                removeMargin
                color={paginaAtual(path) && !showMenu ? corPrimariaSolarfy : colorPalette.neutral[100]}
              />
              <Typography
                variant={_telaMenorQue375 ? 'bodyXS' : 'bodySM'}
                color={paginaAtual(path) && !showMenu ? corPrimariaSolarfy : colorPalette.neutral[100]}
              >
                {label}
              </Typography>
            </styled.DivItemBottomBar>
          ))}
        <styled.DivItemBottomBar onClick={() => handleShowMenu(!showMenu)} key={'bottomBar-menu'}>
          <LIcon
            icon={lineBars}
            size={_telaMenorQue375 ? '28px' : '34px'}
            removeMargin
            color={showMenu ? corPrimariaSolarfy : colorPalette.neutral[100]}
          />
          <Typography
            variant={_telaMenorQue375 ? 'bodyXS' : 'bodySM'}
            color={showMenu ? corPrimariaSolarfy : colorPalette.neutral[100]}
          >
            Menu
          </Typography>
        </styled.DivItemBottomBar>
      </styled.DivExternaBottomBar>
    </>
  );
};

const BottomBarWrapper = ({ paginas }: BottomBarProps) => {
  return (
    <BottomBarProvider>
      <BottomBar paginas={paginas} />
    </BottomBarProvider>
  );
};

export default BottomBarWrapper;
