import styled from '@emotion/styled';

import { Switch } from '@s_mart/form';
import { colorPalette } from '@s_mart/tokens';

export const DivResultadoDimensionamento = styled.div<{ mobile: boolean }>`
  display: flex;
  flex-direction: ${({ mobile }) => (mobile ? 'row' : 'column')};
  gap: ${({ mobile }) => (mobile ? '8px' : undefined)};
  justify-content: ${({ mobile }) => (mobile ? 'center' : undefined)};
  align-items: center;
  border: 1px solid ${colorPalette.neutral[40]};
  border-radius: 8px;
  padding: 8px 12px;
  background: ${colorPalette.neutral[20]};
`;

export const SSwitch = styled(Switch)`
  margin-left: 3px;
  .MuiSwitch-thumb {
    outline: 1px solid ${colorPalette.neutral[50]};
  }
`;
