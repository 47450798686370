import { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';

import { valor } from '@s_mart/masks';

const GraficoFaturas = ({ cor }: { cor: string }) => {
  const { watch } = useFormContext();

  const _formValuesTabelaPaybackValues = watch('tabelaPaybackDados');
  const _formValuesFaturaSemSistema = watch('faturasSemSistema');
  const _formValuesFaturaComSistema = watch('faturasComSistema');
  const _formValuesValoresFioB = watch('valoresFioBComSistema');
  const _formValuesTipoLigacao = watch('tipoLigacao');

  const _categorias = useMemo(() => {
    if (_formValuesTabelaPaybackValues) {
      return _formValuesTabelaPaybackValues
        .filter((_: any, index: number) => index < 7)
        .map((_: any, index: number) => dayjs().add(index, 'year').get('year'));
    }
  }, [_formValuesTabelaPaybackValues]);

  const _series = useMemo(() => {
    const _valoresPadrao = [
      {
        name: 'Média da fatura sem sistema',
        data: _formValuesFaturaSemSistema?.filter((_: any, index: number) => index < 7),
      },
      {
        name: 'Média da fatura com sistema',
        data: _formValuesFaturaComSistema?.filter((_: any, index: number) => index < 7),
      },
    ];

    if (_formValuesTipoLigacao?.value === '3') {
      return _valoresPadrao;
    } else {
      return [
        ..._valoresPadrao,
        {
          name: 'Valor referente ao Fio B',
          data: _formValuesValoresFioB?.filter((_: any, index: number) => index < 7),
        },
      ];
    }
  }, [_formValuesFaturaSemSistema, _formValuesFaturaComSistema, _formValuesValoresFioB, _formValuesTipoLigacao]);

  if (!_formValuesTabelaPaybackValues) {
    return <></>;
  } else {
    return (
      <div id='grafico-faturas' style={{ position: 'absolute', right: '12500px' }}>
        <div style={{ width: '583px' }}>
          <ReactApexChart
            type='bar'
            series={_series}
            options={{
              chart: {
                type: 'bar',
                height: 310,
                toolbar: {
                  show: false,
                },
              },
              colors: _formValuesTipoLigacao?.value === '3' ? ['#545454', cor] : ['#545454', cor, '#990000'],
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '70%',
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                colors: ['transparent'],
                width: 6,
              },
              grid: {
                row: {
                  colors: ['#f4f5f7', 'transparent'],
                  opacity: 0.6,
                },
              },
              xaxis: {
                categories: _categorias,
              },
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return valor.format(value);
                  },
                  style: { fontSize: '12px' },
                },
              },
              fill: {
                opacity: 1,
              },
              tooltip: { enabled: false },
            }}
          />
        </div>
      </div>
    );
  }
};

export default memo(GraficoFaturas);
