import { useFormContext } from 'react-hook-form';

import useApp from 'store/app/app';

import useGeradorDeProposta from '../../store/geradorDeProposta.store';
import { sleep } from 'utils/sleep/sleep';
import { useControleCaminhoGerador } from '../../controleCaminhoGerador/useControleCaminhoGerador';

const useProdutoDesatualizadoOuExcluidoDialog = () => {
  const { setLoading } = useApp();
  const methods = useFormContext();
  const { setValue, getValues } = methods;
  const { geradorDeProposta, setGeradorDeProposta } = useGeradorDeProposta();
  const { setarValoresCaminhoAnterior } = useControleCaminhoGerador();

  const _produtosDesatualizados =
    geradorDeProposta.abrirProdutoExcluidoOuPrecoDesatualizadoDialog?.produtosDesatualizados?.sort((a, b) =>
      a?.index && b?.index && a.index < b.index ? 1 : -1
    );
  const _produtosExcluidos = geradorDeProposta.abrirProdutoExcluidoOuPrecoDesatualizadoDialog?.produtosExcluidos?.sort(
    (a, b) => (a?.index && b?.index && a.index < b.index ? 1 : -1)
  );
  const _produtosInativados =
    geradorDeProposta.abrirProdutoExcluidoOuPrecoDesatualizadoDialog?.produtosInativados?.sort((a, b) =>
      a?.index && b?.index && a.index < b.index ? 1 : -1
    );

  const handleClose = () => {
    setGeradorDeProposta({ abrirProdutoExcluidoOuPrecoDesatualizadoDialog: { aberto: false } });
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (_produtosDesatualizados?.length) {
      _produtosDesatualizados.map((it) => {
        if (it.name === 'painel') {
          setValue('modeloPainel', it.values?.modeloPainel);
          setValue('potenciaPaineis', it.values?.potenciaPaineis);
          setValue('garantiaPaineis', it.values?.garantiaPaineis);
          setValue('valorVendaPaineis', it.values?.valorVendaPaineis);
        } else {
          setValue(`${it.name}.${it.index}`, it.values);
        }
      });
    }
    if (_produtosExcluidos?.length) {
      _produtosExcluidos.map((it) => {
        if (it.name === 'painel') {
          setValue('modeloPainel', '');
          setValue('garantiaPaineis', '');
          setValue('valorVendaPaineis', '');
        } else {
          const _field = getValues(it.name);

          if (_field.length === 1) {
            Object.keys(_field?.[0])?.forEach((item) => {
              if (item) {
                setValue(`${it.name}.${it.index}.${item}`, '');
              }
            });
          } else {
            setValue(
              it.name,
              _field.filter((_: any, index: number) => index !== it.index)
            );
          }
        }
      });
    }
    if (_produtosInativados?.length) {
      _produtosInativados.map((it) => {
        if (it.name === 'painel') {
          setValue('modeloPainel', '');
          setValue('garantiaPaineis', '');
          setValue('valorVendaPaineis', '');
        } else {
          const _field = getValues(it.name);

          if (_field.length === 1) {
            Object.keys(_field?.[0])?.forEach((item) => {
              if (item) {
                setValue(`${it.name}.${it.index}.${item}`, '');
              }
            });
          } else {
            setValue(
              it.name,
              _field.filter((_: any, index: number) => index !== it.index)
            );
          }
        }
      });
    }
    await sleep(250);

    setarValoresCaminhoAnterior({
      caminhoGerador: geradorDeProposta.caminhoGerador === 'kits' ? 'produtos' : 'kits',
      methods,
      values: getValues(),
    });
    setLoading(false);

    handleClose();
  };

  return { handleClose, handleSubmit };
};

export default useProdutoDesatualizadoOuExcluidoDialog;
