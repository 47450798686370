import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { getCnpj } from 'api/cnpj/cnpj';
import useApp from 'store/app/app';

const useCadastrarClienteConteudo = () => {
  const { setLoading, setMensagemLoading, setNotification } = useApp();
  const [_previousCnpj, _setPreviousCnpj] = useState<string | undefined>();

  const { setValue } = useFormContext();

  const handleTipoPessoa = useCallback((tipoPessoa: string) => {
    if (tipoPessoa === '1') {
      return true;
    } else {
      return false;
    }
  }, []);

  const buscarDadosCnpj = async (value: string) => {
    //length 18 pois inclui os pontos, barra e hífen
    if (value?.length === 14 && value !== _previousCnpj) {
      try {
        setLoading(true);
        setMensagemLoading('Buscando dados do CNPJ');
        _setPreviousCnpj(value);

        const {
          data: { data },
        } = await getCnpj(value.replace(/[^0-9]/g, ''));

        setValue('razaoSocial', data?.nome);
        if (data?.email) setValue('email', data.email);
        setValue('dadosCidade', { label: `${data?.municipio} - ${data?.uf}`, value: String(data?.municipio_codigo) });
        if (data?.logradouro) setValue('rua', data.logradouro);
        if (data?.numero) setValue('numero', data.numero);
        if (data?.bairro) setValue('bairro', data.bairro);
        if (data?.complemento) setValue('complemento', data.complemento);
      } catch (err) {
        setNotification({ title: 'CNPJ não encontrado', variant: 'error' });
      } finally {
        setMensagemLoading('');
        setLoading(false);
      }
    }
  };

  return { handleTipoPessoa, buscarDadosCnpj };
};

export default useCadastrarClienteConteudo;
