import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useGridApiRef } from '@mui/x-data-grid';

import { urls } from 'api/urls';
import {
  FornecedorEnum,
  ListarProdutosFornecedoresParams,
  TipoIntegracaoEnum,
  TipoProdutoEnum,
} from 'api/fornecedores/fornecedores.types';
import { useInfiniteQuery } from 'hooks/useInfiniteQuery/useInfiniteQuery';
import { Meta } from 'shared/global';
import { FetchNextPageOptions } from 'react-query';
import useConfiguracoes from 'store/configuracoes/configuracoes';

export interface IIntegracaoFornecedoresContext {
  kitsSelecionados: any[];
  alterarKitsSelecionados: React.Dispatch<React.SetStateAction<any[]>>;
  apiRef: ReturnType<typeof useGridApiRef>;
  data: any[];
  meta: Meta;
  hasLimitReached: boolean;
  param: Record<string, any>;
  isLoading: boolean;
  isFetching: boolean;
  handleFetchNextPage: (params: Record<string, any>) => void;
  fornecedor: FornecedorEnum;
}

const IntegracaoFornecedoresContext = React.createContext({} as IIntegracaoFornecedoresContext);

const IntegracaoFornecedoresProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { watch } = useFormContext();
  const [kitsSelecionados, alterarKitsSelecionados] = React.useState<any[]>([]);
  const [alterouFiltros, setAlterouFiltros] = React.useState(false);
  const { gerais } = useConfiguracoes();
  const apiRef = useGridApiRef();

  const _potencia = watch('potenciaFieldFornecedores');
  const _fornecedor = watch('fornecedores')?.value;
  const _voltagem = watch('voltagem')?.value;
  const _search = watch('search');
  const _categoria = watch('categoria')?.value;

  const potenciaLimiteInicial = _potencia ? +(_potencia * 0.9).toFixed(2) : undefined;
  const potenciaLimiteFinal = _potencia ? +(_potencia * 1.1).toFixed(2) : undefined;

  const fornecedorPadrao = gerais?.fornecedores?.includes(FornecedorEnum.EDELTEC)
    ? FornecedorEnum.EDELTEC
    : gerais?.fornecedores?.[0];
  const fornecedor = _fornecedor?.codigo || fornecedorPadrao;

  const fixedParams: ListarProdutosFornecedoresParams = {
    tipoProduto: fornecedor === FornecedorEnum.HORUS ? TipoProdutoEnum.KIT_FOTO : undefined,
    potenciaLimiteInicial: potenciaLimiteInicial || undefined,
    potenciaLimiteFinal: potenciaLimiteFinal || undefined,
    fornecedor,
    tipoIntegracao: fornecedor === FornecedorEnum.EDELTEC ? TipoIntegracaoEnum.API : TipoIntegracaoEnum.MANUAL,
    voltagem: fornecedor === FornecedorEnum.HORUS ? _voltagem || 220 : undefined,
    search: _search || undefined,
    categoria: fornecedor === FornecedorEnum.EDELTEC && _categoria ? _categoria : undefined,
  };

  const { data, isFetching, hasLimitReached, param, meta, fetchNextPage } = useInfiniteQuery({
    name: 'listarProdutosFornecedores',
    url: `${urls.fornecedores}/produtos`,
    fixedParams,
  });

  const isLoading = alterouFiltros && isFetching;

  React.useEffect(() => {
    setAlterouFiltros(true);
  }, [_fornecedor, _potencia, _categoria, _voltagem, _search]);

  React.useEffect(() => {
    if (!isFetching) setAlterouFiltros(false);
  }, [isFetching]);

  const handleFetchNextPage = React.useCallback(
    (options: FetchNextPageOptions) => {
      fetchNextPage({ pageParam: { ...param, ...options } });
    },
    [fetchNextPage]
  );

  return (
    <IntegracaoFornecedoresContext.Provider
      value={{
        apiRef,
        kitsSelecionados,
        data: data.map((it) => ({ ...it, codigo: it?.idFornecedor || String(it?.codigo) })) || [],
        meta,
        hasLimitReached,
        param,
        isLoading,
        isFetching,
        handleFetchNextPage,
        alterarKitsSelecionados,
        fornecedor,
      }}
    >
      {children}
    </IntegracaoFornecedoresContext.Provider>
  );
};

const useIntegracaoFornecedoresContext = () => {
  const context = React.useContext<IIntegracaoFornecedoresContext>(IntegracaoFornecedoresContext);

  if (!context) {
    throw new Error('useIntegracaoFornecedoresContext deve ser usado dentro de um <IntegracaoFornecedoresProvider />');
  }

  return context;
};

export { IntegracaoFornecedoresProvider, useIntegracaoFornecedoresContext };
