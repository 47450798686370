import { memo } from 'react';

import { Button, Dialog } from '@s_mart/core';
import { Form } from '@s_mart/form';

import { useAnalytics } from 'hooks/useAnalytics/useAnalytics';

import useOnBoardingFinalizacao from './useOnBoardingFinalizacao';
import ConfirmacaoDadosContratacao from 'shared/modais/onBoarding/onBoardingFinalizacao/confirmacaoDadosContratacao/confirmacaoDadosContratacao';

const OnBoardingFinalizacaoDialog = () => {
  const { handleCloseOnBoardingFinalizacao, handleSubmit } = useOnBoardingFinalizacao();
  const { analyticsEventTracker } = useAnalytics({ category: 'onboarding' });

  return (
    <Dialog
      onClose={() => {
        analyticsEventTracker({ action: 'Close onboarding - Você gerou sua primeira proposta!' });

        handleCloseOnBoardingFinalizacao();
      }}
      open
      disableBackdropClick
      disableEscapeKeyDown
      closeIcon
    >
      <Form onSubmit={handleSubmit}>
        {() => (
          <>
            <Dialog.Header>Você gerou sua primeira proposta!</Dialog.Header>
            <Dialog.Content>
              <ConfirmacaoDadosContratacao />
            </Dialog.Content>

            <Dialog.Footer>
              <div style={{ display: 'flex', flex: 1, justifyContent: 'end', marginTop: '16px' }}>
                <Button type='submit' color='success' variant='contained'>
                  Confirmar dados
                </Button>
              </div>
            </Dialog.Footer>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default memo(OnBoardingFinalizacaoDialog);
