import { Stack } from '@mui/material';
import { Button, Dialog, Typography } from '@s_mart/core';
import { toRem } from '@s_mart/utils';
import { colorPalette } from '@s_mart/tokens';

import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';

import MaquinaCredito from 'assets/avisoBoleto/maquina-credito.svg';
import { useInformarPagamentoDialogContext } from '../../informarPagamentoDialog.context';

export function BoletoDisponivel() {
  const { linhaDigitavelComRegex, handleCopiarLinhaDigitavel, handleAbrirBoletoPagamento } =
    useInformarPagamentoDialogContext();
  const isMobile = useIsMobile();

  return (
    <>
      <Dialog.Header>Seu boleto já está disponível!</Dialog.Header>
      <Dialog.Content>
        <Stack
          direction='row'
          gap={4}
          alignItems='center'
          justifyContent='center'
          flexDirection={isMobile ? 'column' : 'row'}
          mb={2}
        >
          <img src={MaquinaCredito} alt='Máquina de cartão de crédito com o papel de recibo saindo' />
          <Stack gap={2} alignItems='center'>
            <Typography variant='bodyLG'>Abaixo está a linha digitável do boleto em aberto</Typography>
            <Typography
              variant='bodyXS'
              sx={{
                textAlign: 'center',
                width: `calc(100% - ${toRem(32)})`,
                backgroundColor: colorPalette.neutral[30],
                padding: `${toRem(8)} ${toRem(16)}`,
                borderRadius: toRem(8),
              }}
            >
              <b>{linhaDigitavelComRegex}</b>
            </Typography>
            <Stack gap={1} alignItems='center' flexDirection={isMobile ? 'column' : 'row'} width='100%'>
              <Button fullWidth variant='outlined' style={{ minWidth: 200 }} onClick={handleAbrirBoletoPagamento}>
                Abrir boleto em nova aba
              </Button>
              <Button fullWidth style={{ minWidth: 200 }} onClick={handleCopiarLinhaDigitavel}>
                Copiar linha digitável
              </Button>
            </Stack>
            <Typography variant='bodyXS' sx={{ textAlign: 'center' }}>
              Você pode acessar seu pagamentos a qualquer momento na tela “meu plano” nas configurações.
            </Typography>
          </Stack>
        </Stack>
      </Dialog.Content>
    </>
  );
}
