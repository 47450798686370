import { useCallback } from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';

import { array12Posicoes } from '../consumoMensalDialog.static';
import { ConsumoMensalDialogForm, Contas } from '../consumoMensalDialog.types';

const useConsumoMensalContent = () => {
  const handleConsumoMedio = useCallback((contas: Contas) => {
    return (
      Math.round(
        contas
          ?.map((item) => {
            if (item?.every((value) => value === undefined)) {
              return 0;
            }
            return item?.reduce((a, b) => Number(a || 0) + Number(b || 0)) || 0;
          })
          ?.reduce((a: any, b: any) => Number(a || 0) + Number(b || 0)) / 12
      ) || 0
    );
  }, []);

  const handleInserirRemoverConta = useCallback(
    (index: number, numeroContas: number, fieldArray: UseFieldArrayReturn<ConsumoMensalDialogForm, 'contas', 'id'>) => {
      if (index + 1 === numeroContas && index < 4) {
        fieldArray.append([array12Posicoes]);
      } else {
        fieldArray.remove(index);
      }
    },
    []
  );

  return {
    handleConsumoMedio,
    handleInserirRemoverConta,
  };
};

export default useConsumoMensalContent;
