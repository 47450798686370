import { clarity } from 'react-microsoft-clarity';

import useAuth from 'store/auth/auth';
import { isStaging } from 'utils/isStaging/isStaging';

import { TagsEnum } from './useClarity.types';

const useClarity = () => {
  const { profile } = useAuth();

  const _codigoClarity = 'dvoz2pbml7';

  const initializeClarity = () => {
    if (!isStaging() && !clarity.hasStarted()) {
      clarity.init(_codigoClarity);
    }
  };

  const setTagClarity = (tag: TagsEnum) => {
    if (clarity.hasStarted()) {
      clarity.setTag(tag, tag);
      if (profile?.emp) {
        clarity.identify(String(profile?.emp), {});
      }
    }
  };

  return { initializeClarity, setTagClarity };
};

export default useClarity;
