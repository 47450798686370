import { logout } from 'api/auth/auth';
import useApp from 'store/app/app';
import useAuth from 'store/auth/auth';
import useConfiguracoes from 'store/configuracoes/configuracoes';

import { queryClient } from 'api/queryClient';

const useLogout = () => {
  const { limparApp, setLoading } = useApp();
  const { clearStore } = useAuth();
  const { limparConfiguracoes } = useConfiguracoes();

  const handleLogout = async () => {
    setLoading(true);
    try {
      const { status } = await logout();
      if (status === 200) {
        limparConfiguracoes();
        limparApp();
        queryClient.clear();
        clearStore();
        setLoading(false);
      }
    } catch (error) {
      limparConfiguracoes();
      limparApp();
      queryClient.clear();
      clearStore();
    }
  };

  return { handleLogout };
};

export { useLogout };
