import styled from '@emotion/styled';
import { toRem } from '@s_mart/utils';

import { MOBILE_BREAKPOINT } from 'hooks/useIsMobile/useIsMobile';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${toRem(16)};

  > div:last-of-type {
    flex: 1;

    display: flex;
    justify-content: flex-end;
    gap: ${toRem(8)};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column-reverse;
    gap: ${toRem(16)};

    > div:last-of-type {
      width: 100%;
      flex-direction: column-reverse;
    }
  }
`;
