import { memo } from 'react';

import { TextField } from '@s_mart/form';
import { composeValidators, required } from '@s_mart/rules';

import useConfiguracoes from 'store/configuracoes/configuracoes';

import { useFields } from '../../useFields';
import { useFormContext } from 'react-hook-form';

const FieldQuantidadeInversores = ({ index }: { index: number }) => {
  const { recursosContratados } = useConfiguracoes();
  const methods = useFormContext();
  const { handleCalcularValorTotal } = useFields();

  return (
    <TextField
      name={`inversores.${index}.quantidadeInversores`}
      label='Qtd'
      value={methods.watch(`inversores.${index}.quantidadeInversores`)}
      placeholder='1'
      mask='numero'
      onInputChange={() => {
        if (recursosContratados?.['valor-de-venda-unitario']) {
          handleCalcularValorTotal({ values: methods.getValues(), methods });
        }
      }}
      required
      disableIconError
      rules={composeValidators([
        {
          validate: {
            valor0: (value: string | number) => {
              if (String(value) && Number(value) === 0) {
                return 'Obrigatório';
              }
            },
          },
        },
        required,
      ])}
    />
  );
};

export default memo(FieldQuantidadeInversores);
