import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextField } from '@s_mart/form';
import { required } from '@s_mart/rules';

import { ButtonTipoGarantia } from '../../fields.styles';

const FieldGarantiaInversores = ({ index }: { index: number }) => {
  const { setValue, watch } = useFormContext();

  const _formValuesInversores = watch('inversores');

  return (
    <TextField
      name={`inversores.${index}.garantiaInversores`}
      label='Garantia'
      value={watch(`inversores.${index}.garantiaInversores`)}
      placeholder='20'
      InputProps={{
        endAdornment: (
          <ButtonTipoGarantia
            variant='neutral'
            onClick={() =>
              setValue(
                `inversores.${index}.tipoGarantiaInversores`,
                _formValuesInversores?.[index || 0]?.tipoGarantiaInversores === 0 ? 1 : 0
              )
            }
          >
            {_formValuesInversores?.[index || 0]?.tipoGarantiaInversores === 0 ? 'Meses' : 'Anos'}
          </ButtonTipoGarantia>
        ),
      }}
      required
      mask='numero'
      rules={required}
    />
  );
};

export default memo(FieldGarantiaInversores);
