import { useFormContext } from 'react-hook-form';

import { useMediaQuery } from '@s_mart/hooks';

const useBaseDeCalculoPaybackDialogFields = () => {
  const { watch } = useFormContext();
  const telaMenorQue440 = useMediaQuery(`(max-width: 440px)`);
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);
  const telaMenorQue930 = useMediaQuery(`(max-width: 930px)`);

  const _formValuesTipoLigacao = watch('tipoLigacao');

  const handleResponsividadeFields = () => {
    if (telaMenorQue930) {
      if (telaMenorQue600) {
        if (telaMenorQue440) {
          return '1fr';
        }
        return '1fr 1fr';
      }
      return '1fr 1fr 1fr';
    } else {
      if (_formValuesTipoLigacao?.value === '3') {
        return '1fr 1fr 1fr 1fr';
      } else {
        return '1fr 1fr 1fr 1fr 1fr';
      }
    }
  };

  return { handleResponsividadeFields };
};

export default useBaseDeCalculoPaybackDialogFields;
