import dayjs from 'dayjs';

import {
  ICalcularPaybackGrupoBPayload,
  ICalcularPaybackGrupoBRetorno,
  IItemTabelaPaybackBrutoMensal,
  IItemTabelaPaybackFormatadaAnual,
} from './calcularPaybackGrupoB.types';
import { taxaFioBLei14300 } from 'utils/taxaFioBLei14300/taxaFioBLei14300';

export function calcularPaybackGrupoB({
  consumoMedio,
  valorTarifa,
  valorFioB,
  degradacaoSistema,
  taxaInflacao,
  simultaneidade,
  valorProposta,
  geracaoSistema,
  anosTabela = 25,
}: ICalcularPaybackGrupoBPayload): ICalcularPaybackGrupoBRetorno {
  const tabelaPaybackBrutoMensal: IItemTabelaPaybackBrutoMensal[] = [];
  const tabelaPaybackFormatadaAnual: IItemTabelaPaybackFormatadaAnual[] = [];

  const _anosTabelaPayback = anosTabela;
  const _mesesTabelaPayback = _anosTabelaPayback * 12;
  let _valorEconomizadoAnual = 0;
  let _energiaGeradaAnual = 0;
  const _valorProposta = valorProposta * -1;
  let _retornoPaybackEmMeses = 0;
  let _valorEconomizado12MesesAposInstalacao = 0;

  // # Calculo de relacao entre tarifa e fio B
  const _relacaoFioBNaTarifa = valorFioB ? Number((valorFioB / valorTarifa).toFixed(4)) : 0.28;

  // # Calculo de degradação
  const _degradacaoMensal = Number((degradacaoSistema / 100 / 12).toFixed(4)); //0.0007 ao mês

  for (let i = 0; i <= _mesesTabelaPayback; i++) {
    // # Anos e meses da tabela
    const anoAtual = dayjs().year();
    const ano = dayjs().add(i, 'month').year();
    const anoTabela = ano - anoAtual + 1;
    const mes = dayjs().add(i, 'month').month() + 1;

    // # Calculo tarifa prevista
    let _tarifaPrevista = valorTarifa;
    if (anoTabela > 1) {
      if (mes === 1) {
        const tarifaAnoAnterior = tabelaPaybackBrutoMensal[i - 1].tarifaPrevista;
        _tarifaPrevista = Number((tarifaAnoAnterior + tarifaAnoAnterior * (taxaInflacao / 100)).toFixed(4));
      } else {
        _tarifaPrevista = Number(tabelaPaybackBrutoMensal[i - 1].tarifaPrevista.toFixed(4));
      }
    }

    // # Calculo tarifa liquida
    const _taxacaoFioB = taxaFioBLei14300(ano);
    const _porcentagemFioBNaTarifa = _taxacaoFioB * _relacaoFioBNaTarifa;
    const _porcentagemSimultaneidade = 1 - simultaneidade / 100;
    let _tarifaLiquida;

    if (mes === 1 || i === 0) {
      _tarifaLiquida = Number(
        (_tarifaPrevista * (1 - _porcentagemFioBNaTarifa * _porcentagemSimultaneidade)).toFixed(4)
      );
    } else {
      _tarifaLiquida = tabelaPaybackBrutoMensal[i - 1]?.tarifaLiquida;
    }

    // # Energia gerada no mês
    let _energiaGerada = geracaoSistema;
    if (i !== 0) {
      _energiaGerada = Number((tabelaPaybackBrutoMensal[i - 1].energiaGeradaMes * (1 - _degradacaoMensal)).toFixed(2));
    }

    // # Valor economizado
    const _valorEconomizado = Number((_energiaGerada * _tarifaLiquida).toFixed(2));

    // # Valor acumulado
    let _valorAcumulado = _valorEconomizado;

    if (i !== 0) {
      _valorAcumulado += Number(tabelaPaybackBrutoMensal[i - 1].valorAcumulado.toFixed(2));
    }

    // # Tabela bruta mensal
    tabelaPaybackBrutoMensal.push({
      ano: anoTabela,
      mes: mes,
      tarifaPrevista: _tarifaPrevista,
      valorLuzPrevista: Number((consumoMedio * _tarifaPrevista).toFixed(2)),
      energiaGeradaMes: _energiaGerada,
      taxacaoFioB: _taxacaoFioB,
      tarifaLiquida: _tarifaLiquida,
      valorEconomizado: _valorEconomizado,
      valorAcumulado: Number(_valorAcumulado.toFixed(2)),
    });

    // # Valor economizado 12 meses após instalação
    if (i <= 11) {
      _valorEconomizado12MesesAposInstalacao += _valorEconomizado;
    }

    // # Valor economizado anual
    if (mes === 1) {
      _valorEconomizadoAnual = _valorEconomizado;
      _energiaGeradaAnual = _energiaGerada;
    } else {
      _valorEconomizadoAnual += _valorEconomizado;
      _energiaGeradaAnual += _energiaGerada;
    }

    // # Tabela formatada anual
    if (mes === 12) {
      tabelaPaybackFormatadaAnual.push({
        ano: anoTabela,
        geracao: Number(_energiaGeradaAnual.toFixed(2)),
        economiaAnual: Number(_valorEconomizadoAnual.toFixed(2)),
        valor: Number((_valorProposta + _valorAcumulado).toFixed(2)),
        tarifaPrevista: _tarifaPrevista,
        parcelaFioBNaTarifa: Number((_tarifaPrevista * _porcentagemFioBNaTarifa).toFixed(4)),
      });
    }

    // # Retorno payback em meses
    if (0 <= _valorProposta + _valorAcumulado) {
      if (!_retornoPaybackEmMeses) {
        _retornoPaybackEmMeses = i;
      }
    }
  }

  return {
    tabelaPaybackBrutoMensal,
    tabelaPaybackFormatadaAnual,
    retornoPaybackEmMeses: _retornoPaybackEmMeses,
    valorEconomizado12MesesAposInstalacao: Number(_valorEconomizado12MesesAposInstalacao.toFixed(2)),
  };
}
