import { AxiosResponse } from 'axios';

import { api } from 'api/api';
import { urls } from 'api/urls';

import type {
  Fornecedores,
  ListarFornecedoresParams,
  ListarProdutosFornecedoresParams,
  PayloadFornecedoresAtivos,
  ProdutosFornecedores,
} from './fornecedores.types';
import { Meta } from 'shared/global';

export async function listarProdutosFornecedores(params: ListarProdutosFornecedoresParams): Promise<
  AxiosResponse<{
    data: {
      data: ProdutosFornecedores[];
      meta: Meta;
    };
  }>
> {
  return await api.get(`${urls.fornecedores}/produtos`, { params });
}

export async function listarFornecedores(
  params?: ListarFornecedoresParams
): Promise<AxiosResponse<{ data: Fornecedores[] }>> {
  return await api.get(`${urls.fornecedores}`, { params });
}

export async function patchFornecedoresAtivos(
  data: PayloadFornecedoresAtivos
): Promise<AxiosResponse<{ data: PayloadFornecedoresAtivos }>> {
  return await api.patch(`${urls.configuracoes}/fornecedores`, data);
}

export async function deleteFornecedorApi(id: number) {
  return await api.delete(`${urls.configuracoes}/fornecedores/${id}`);
}
