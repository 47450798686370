import { NPS as NPSSmart } from '@s_mart/nps';

import useNps from './useNps';

const NPS = () => {
  const { onClose, onChooseGradeAndContinue, onSelectGrade, handleSubmit } = useNps();

  return (
    <NPSSmart
      sistemaNome='Solarfy'
      onFinalizar={handleSubmit}
      onClose={onClose}
      onEnviarNota={onChooseGradeAndContinue}
      onSelecionarNota={onSelectGrade}
    />
  );
};

export default NPS;
