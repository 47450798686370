import { memo, Fragment } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { Grid, LIcon } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { lineTrashAlt } from '@s_mart/regular-icons';

import useGeradorDeProposta from 'shared/modais/geradorDeProposta/store/geradorDeProposta.store';
import useConfiguracoes from 'store/configuracoes/configuracoes';

import AutoCompleteFieldNomeOutros from './content/autoCompleteFieldNomeOutros';
import FieldQuantidadeOutros from './content/fieldQuantidadeOutros';
import FieldGarantiaOutros from './content/fieldGarantiaOutros';
import FieldValorVendaOutros from './content/fieldValorVendaOutros';
import { DivHoverLIcon } from '../fields.styles';

const FieldsOutros = () => {
  const { geradorDeProposta } = useGeradorDeProposta();
  const { recursosContratados } = useConfiguracoes();
  const telaMenorQue760 = useMediaQuery(`(max-width: 760px)`);
  const telaMenorQue960 = useMediaQuery(`(max-width: 960px)`);

  const { control } = useFormContext();
  const _fieldArrayOutros = useFieldArray({ name: 'outros', control });

  const _controleFlexDivExterna = () => {
    if (recursosContratados?.['valor-de-venda-unitario']) {
      if (telaMenorQue960) {
        return { divNome: 6.8, divDetalhes: 5.2, divQtd: 2.75 };
      } else {
        return { divNome: 7.58, divDetalhes: 4.42, divQtd: 2.75 };
      }
    } else {
      if (telaMenorQue960) {
        return { divNome: 8.325, divDetalhes: 4, divQtd: 3.4 };
      } else {
        return { divNome: 8.085, divDetalhes: 3.915, divQtd: 3.4 };
      }
    }
  };

  return (
    <Grid container spacing={2}>
      {_fieldArrayOutros?.fields.map((field, index: number) => (
        <Fragment key={field.id}>
          <Grid
            item
            xs={
              Number(_fieldArrayOutros?.fields?.length) > 1 || geradorDeProposta?.caminhoGerador === 'kits'
                ? undefined
                : 12
            }
            style={{
              display: 'flex',
              width:
                Number(_fieldArrayOutros?.fields?.length) > 1 || geradorDeProposta?.caminhoGerador === 'kits'
                  ? 'calc(100% - 49px)'
                  : undefined,
              gap: '16px',
            }}
            key={field.id}
          >
            {telaMenorQue760 ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AutoCompleteFieldNomeOutros index={index} />
                </Grid>
                <Grid item xs={6}>
                  <FieldQuantidadeOutros index={index} />
                </Grid>
                <Grid item xs={6}>
                  <FieldGarantiaOutros index={index} />
                </Grid>
                {recursosContratados?.['valor-de-venda-unitario'] && (
                  <Grid item xs={12}>
                    <FieldValorVendaOutros index={index} />
                  </Grid>
                )}
              </Grid>
            ) : (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', flex: _controleFlexDivExterna()?.divNome }}>
                  <AutoCompleteFieldNomeOutros index={index} />
                </div>
                <div style={{ display: 'flex', flex: _controleFlexDivExterna()?.divDetalhes, gap: '16px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: _controleFlexDivExterna()?.divQtd,
                    }}
                  >
                    <FieldQuantidadeOutros index={index} />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
                    <FieldGarantiaOutros index={index} />
                  </div>
                  {recursosContratados?.['valor-de-venda-unitario'] && (
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 4.5 }}>
                      <FieldValorVendaOutros index={index} />
                    </div>
                  )}
                </div>
              </>
            )}
          </Grid>
          {(Number(_fieldArrayOutros?.fields?.length) > 1 || geradorDeProposta?.caminhoGerador === 'kits') && (
            <Grid item style={{ display: 'flex', marginTop: 22 }}>
              <DivHoverLIcon>
                <LIcon
                  icon={lineTrashAlt}
                  removeMargin
                  size='32px'
                  style={{
                    cursor: 'pointer',
                    alignSelf:
                      telaMenorQue760 && !recursosContratados?.['valor-de-venda-unitario'] ? 'flex-end' : 'center',
                  }}
                  onClick={() => _fieldArrayOutros?.remove(index)}
                />
              </DivHoverLIcon>
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  );
};

export default memo(FieldsOutros);
