import { api } from 'api/api';
import { urls } from 'api/urls';
import { isProduction } from 'api/variables';

const postAdicionarTags = async (tags: string[]) => {
  if (isProduction) {
    const response = await api.post(`${urls.amocrm}/adicionar-tags`, {
      tags: tags,
    });
    return response.data;
  }
};

export { postAdicionarTags };
