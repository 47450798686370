import { useEffect } from 'react';
import isEqual from 'lodash.isequal';

import { postAdicionarTags } from 'api/amocrm/amocrm';
import { finalizarOnBoarding } from 'api/onBoarding/onBoarding';
import { atualizarDadosEmpresa } from 'api/meusDados/meusDados';
import type { ConfirmacaoDadosContratacaoValues } from 'shared/modais/onBoarding/onBoardingFinalizacao/confirmacaoDadosContratacao/confirmacaoDadosContratacao.types';
import useApp from 'store/app/app';
import useConfiguracoes from 'store/configuracoes/configuracoes';

import useOnBoarding from '../store/onBoarding.store';
import usePlanosDialog from 'shared/modais/planosDialog/store/planosDialog.store';

const useOnBoardingFinalizacao = () => {
  const { setLoading, setNotification, handleError } = useApp();
  const { setPlanosDialog } = usePlanosDialog();
  const { setMeusDados, meusDados } = useConfiguracoes();
  const { setOnBoardingFinalizacao, setOnBoardingFinalizado, onBoardingFinalizacao } = useOnBoarding();

  async function _adicionaTagAmoCrmViuPreco() {
    if (onBoardingFinalizacao.aberto) {
      try {
        localStorage.setItem('tagAmoCrm.viuPreco', 'true');
        await postAdicionarTags(['Viu Preço']);
      } catch (err) {
        //ignora erro ao adicionar tags já que não é crítico para o fluxo
      }
    }
  }

  const handleCloseOnBoardingFinalizacao = async () => {
    setOnBoardingFinalizacao({ aberto: false });
    setOnBoardingFinalizado(true);
    await finalizarOnBoarding();
    setPlanosDialog({ aberto: true });
  };

  const handleSubmit = async (values: ConfirmacaoDadosContratacaoValues) => {
    try {
      const _payload = {
        ...values,
        regimeTributario: values?.regimeTributario || 1,
        codigoCidade: Number(values?.cidade?.value),
        cidade: values?.cidade?.label?.split(' - ')[0],
        uf: values?.cidade?.label?.split(' - ')[1],
      };

      if (!isEqual(meusDados, _payload)) {
        setLoading(true);
        await atualizarDadosEmpresa(_payload);
        setMeusDados(_payload);
        setNotification({ title: 'Dados atualizados!' });

        setLoading(false);
      }
      handleCloseOnBoardingFinalizacao();
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('tagAmoCrm.viuPreco')) {
      _adicionaTagAmoCrmViuPreco();
    }
  }, []);

  return {
    handleCloseOnBoardingFinalizacao,
    handleSubmit,
  };
};

export default useOnBoardingFinalizacao;
