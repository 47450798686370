import styled from '@emotion/styled';
import { colorPalette } from '@s_mart/tokens';

import { MOBILE_BREAKPOINT } from 'hooks/useIsMobile/useIsMobile';

export const InformacoesCabecalhoDiv = styled.div<{ telaMenor?: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid ${colorPalette.neutral[50]};
  gap: 24px;
  max-width: 400px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: none;
  }
`;

export const InformacoesCabecalhoDivsInternas = styled.div<{ telaMenor?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
