import { create } from 'zustand';

import type { IPainelClienteState, IPainelClienteStore } from './painelCliente.types';

import { buscarCliente } from 'api/clientes/clientes';
import { listarHistoricoLead, listarPropostasLead } from 'api/leads/leads';
import { TipoAtividadeLeadEnum } from 'api/atividades/atividade.types';
import useApp from 'store/app/app';

const initialState: IPainelClienteState = {
  telaCliente: { open: false },
  cliente: null,
  propostas: [],
  atividades: [],
};

const usePainelClienteStore = create<IPainelClienteStore>((set, get) => ({
  ...initialState,

  handleOpenTelaCliente: async ({ idCliente, idEtapa, idLead }) => {
    try {
      if (!idCliente) {
        throw new Error('Cliente não informado');
      }

      useApp.getState().setLoading(true);

      get().handleBuscarInfoCliente(idCliente);
      get().handleBuscarAtividades({ idCliente, idLead });

      set({
        telaCliente: {
          open: true,
          idLead,
          idEtapa,
        },
      });
    } catch (err) {
      useApp.getState().setNotification({
        variant: 'warning',
        title: 'Erro ao buscar cliente',
        message: 'Não foi possível abrir o painel do cliente, tente novamente mais tarde.',
      });
    } finally {
      useApp.getState().setLoading(false);
    }
  },

  handleBuscarAtividades: async ({ idCliente, idLead }) => {
    if (!idCliente && !idLead && !get().telaCliente.idLead) return;

    try {
      const { data } = await listarHistoricoLead(idLead || get().telaCliente.idLead || 0, {
        codigoCliente: idCliente || get().cliente?.codigo,
      });

      set({
        atividades: data?.data?.map((atividade) => ({
          ...atividade,
          tipoAtividade: String(atividade.tipoAtividade) as TipoAtividadeLeadEnum,
        })),
      });
    } catch (err) {
      useApp.getState().handleError(err);
    }
  },

  handleCloseTelaCliente: () => {
    set(initialState);
  },

  handleBuscarInfoCliente: async (idCliente) => {
    const { data } = await buscarCliente(idCliente);

    set({
      cliente: {
        ...data.data,
        codigo: idCliente,
      },
    });
  },

  handleBuscarPropostas: async (idLead) => {
    useApp.getState().setLoading(true);
    try {
      const _codigo = idLead || get().telaCliente.idLead;

      if (!_codigo) throw new Error('Lead não informado');

      const { data } = await listarPropostasLead(_codigo);

      set({ propostas: data?.data });

      return data.data;
    } catch (err) {
      useApp.getState().handleError(err);
    } finally {
      useApp.getState().setLoading(false);
    }
  },

  setTelaCliente: (telaCliente) => {
    set((prev) => {
      return {
        telaCliente: {
          ...prev.telaCliente,
          ...telaCliente,
        },
      };
    });
  },
}));

export { usePainelClienteStore };
