import { memo } from 'react';

import { CircularProgress, Typography } from '@s_mart/core';

import useApp from 'store/app/app';

import { mensagensLoadingGerador } from './loadingGerador.static';
import {
  DivMensagemPrincipal,
  DivMensagensAdicionais,
  DivInternaMensagemAdicional,
  DivIconeStatusMensagem,
  SSmallLoadingDots,
} from './loadingGerador.styles';
import { Backdrop } from '../loadings.styles';
import { useMediaQuery } from '@s_mart/hooks';

const LoadingGerador = () => {
  const { loadingGerador } = useApp();
  const _telaMenorQue600 = useMediaQuery('(max-width: 600px)');

  return (
    <Backdrop>
      <DivMensagemPrincipal>
        <Typography variant={_telaMenorQue600 ? 'titleMS' : 'titleXL'} color='white'>
          Estamos gerando sua proposta.
        </Typography>
        <Typography variant={_telaMenorQue600 ? 'titleMS' : 'titleXL'} color='white'>
          Aguarde alguns instantes.
        </Typography>
      </DivMensagemPrincipal>
      <DivMensagensAdicionais>
        {mensagensLoadingGerador.map((it, index) => {
          if (!loadingGerador?.produtosCadastrados && index === 0) {
            return null;
          }
          if (typeof loadingGerador?.etapa === 'number' && index <= loadingGerador.etapa) {
            return (
              <DivInternaMensagemAdicional key={`div-${it?.texto}`}>
                {index !== loadingGerador?.etapa ? (
                  <DivIconeStatusMensagem telaMobile={_telaMenorQue600} />
                ) : (
                  <CircularProgress size={_telaMenorQue600 ? 20 : 30} />
                )}
                <Typography variant={_telaMenorQue600 ? 'bodySM' : 'titleMS'} color='white' noWrap>
                  {index === loadingGerador?.etapa ? it?.texto : it?.textoFinalizado}
                </Typography>
                {index === loadingGerador?.etapa && <SSmallLoadingDots telaMobile={_telaMenorQue600} />}
              </DivInternaMensagemAdicional>
            );
          }
          return null;
        })}
      </DivMensagensAdicionais>
    </Backdrop>
  );
};

export default memo(LoadingGerador);
