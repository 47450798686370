import { FieldValues, UseFormReturn } from 'react-hook-form';

import useConfiguracoes from 'store/configuracoes/configuracoes';

import useGeradorDeProposta from '../../store/geradorDeProposta.store';

interface CalcularValorTotal {
  values: FieldValues;
  methods: UseFormReturn;
}

const useFields = () => {
  const { geradorDeProposta } = useGeradorDeProposta();
  const { recursosContratados } = useConfiguracoes();

  const handleCalcularValorTotal = ({ values, methods }: CalcularValorTotal) => {
    if (recursosContratados?.['valor-de-venda-unitario']) {
      let valorTotal = 0;

      //variável utilizada para saber se o field valorTotal deve ficar disabled
      const valoresArray: number[] = [];

      if (
        (geradorDeProposta.caminhoGerador === 'produtos' && values.valorVendaPaineis) ||
        (geradorDeProposta.caminhoGerador === 'kits' && values.painelAdicionado)
      ) {
        const valorVendaPaineis = Number(values?.valorVendaPaineis || 0) * Number(values?.quantidadePaineis || 0);
        valorTotal += valorVendaPaineis;

        valoresArray.push(valorVendaPaineis);
      }

      values.inversores?.forEach((inversor: any) => {
        const valorVendaInversores =
          Number(inversor?.valorVendaInversores || 0) * Number(inversor?.quantidadeInversores || 0);
        valorTotal += valorVendaInversores;

        valoresArray.push(valorVendaInversores);
      });

      values.outros?.forEach((outro: any) => {
        const valorVendaOutros = Number(outro?.valorVendaOutros || 0) * Number(outro?.quantidadeOutros || 1);
        valorTotal += valorVendaOutros;

        valoresArray.push(valorVendaOutros);
      });

      values.kitsFotovoltaicos?.forEach((kitsFotovoltaicos: any) => {
        const valorVendaKitsFotovoltaicos = Number(kitsFotovoltaicos?.valorVendaKitsFotovoltaicos || 0);
        valorTotal += valorVendaKitsFotovoltaicos;

        valoresArray.push(valorVendaKitsFotovoltaicos);
      });

      values.kitsDeServicos?.forEach((kitsDeServicos: any) => {
        const valorVendaKitsDeServicos = Number(kitsDeServicos?.valorVendaKitsDeServicos || 0);
        valorTotal += valorVendaKitsDeServicos;

        valoresArray.push(valorVendaKitsDeServicos);
      });

      methods.setValue('valoresArray', valoresArray);
      methods.setValue('valorTotal', valorTotal);
    }
  };

  return { handleCalcularValorTotal };
};

export { useFields };
