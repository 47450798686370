import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { toRem } from '@s_mart/utils';

import { corPrimariaSolarfy } from 'styles/theme/theme';

export const OverlayContainer = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.5);
`;

export const BotaoBoletoContainer = styled.div<{ telaMobile: boolean }>`
  width: ${toRem(40)};
  height: ${toRem(40)};

  margin-left: ${({ telaMobile }) => (telaMobile ? toRem(4) : toRem(74))};

  position: absolute;
  top: 3px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
  border-radius: 6px;
  border: 1px solid ${corPrimariaSolarfy};
`;

export const DialogContainer = styled.div<{ telaMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${toRem(16)};

  padding: ${toRem(10)};
  border-radius: ${toRem(16)};

  position: absolute;

  top: ${({ telaMobile }) => (telaMobile ? toRem(60) : toRem(80))};
  left: ${({ telaMobile }) => (telaMobile ? toRem(46) : toRem(115))};
  max-width: ${({ telaMobile }) => (telaMobile ? toRem(230) : toRem(450))};

  background-color: white;

  img {
    max-width: 150px;
  }
`;

const TooltipMobileCss = css`
  top: -5px;
  left: calc(50% - 5px);
`;

export const TooltipContainer = styled.div<{ telaMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${toRem(4)};
  padding: ${toRem(16)};
  border-radius: ${toRem(6)};

  text-align: center;

  background-color: rgba(70, 70, 70);
  color: white;

  div.leftArrow {
    width: 10px;
    height: 10px;

    position: absolute;

    transform: rotate(45deg);
    background-color: rgba(70, 70, 70);
    top: 10;
    left: -5px;

    ${({ telaMobile }) => telaMobile && TooltipMobileCss}
  }
`;
