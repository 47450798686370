import { memo } from 'react';

import { Dialog, Grid, Typography } from '@s_mart/core';

const ComoFuncionaSimultaneidadeDialog = ({ onClose }: { onClose: () => void }) => {
  return (
    <Dialog
      open
      onClose={onClose}
      closeIcon
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      //zIndex necessário para que o tour do onboarding
      //de simultaneidade não fique por cima da Dialog
      style={{ zIndex: 201 }}
    >
      <Dialog.Header>Como funciona a simultaneidade?</Dialog.Header>
      <Dialog.Content>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='bodySM'>
              A simultaneidade se refere ao período em que a instalação está produzindo energia e, ao mesmo tempo, esta
              energia está sendo usada pelo proprietário.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src='/static/svgs/gerador/graficoSimultaneidade.svg'
              alt='exemplo-grafico-simultaneidade'
              style={{ maxWidth: '100%' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='bodySM'>
              Segundo a Lei 14.300, a energia consumida de forma simultânea não passa pelo medidor de energia, e por
              isso não sofre cobrança de Fio B.
            </Typography>
          </Grid>
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
};

export default memo(ComoFuncionaSimultaneidadeDialog);
