import { Stack } from '@mui/material';
import { Button, Dialog, Typography } from '@s_mart/core';

import OkSvg from 'assets/avisoBoleto/ok.svg';
import { useIsMobile } from 'hooks/useIsMobile/useIsMobile';

interface InformadoComoPagoProps {
  handleClose: () => void;
}

export function InformadoComoPago({ handleClose }: InformadoComoPagoProps) {
  const isMobile = useIsMobile();

  return (
    <>
      <Dialog.Header>Pendencia informada como pago!</Dialog.Header>
      <Dialog.Content>
        <Stack
          direction='row'
          gap={2}
          alignItems='center'
          justifyContent='center'
          flexDirection={isMobile ? 'column' : 'row'}
          mb={2}
        >
          <img src={OkSvg} alt='Mulher sentada fazendo sinal de ok com a mão' />
          <Stack gap={2} maxWidth={480}>
            <Typography variant='bodyLG'>Obrigado por nos avisar que você efetuou o pagamento!</Typography>
            <Typography variant='bodyLG'>
              Seu sistema será liberado por 1 dia útil enquanto o seu pagamento compensa.
            </Typography>
            <Button onClick={handleClose}>Entendi</Button>
          </Stack>
        </Stack>
      </Dialog.Content>
    </>
  );
}
