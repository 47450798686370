import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useApp from 'store/app/app';
import useAuth from 'store/auth/auth';

export function useSemConexaoDialog() {
  const _navigate = useNavigate();

  const { semConexao, setSemConexao } = useApp();
  const { profile } = useAuth();

  const emailSB = profile?.email.includes('@sbsistemas.');

  const [open, setOpen] = useState(semConexao);

  function handleClose() {
    setOpen(false);

    setTimeout(() => {
      setSemConexao(false);

      _navigate('/');

      window.location.reload();
    }, 100);
  }

  return { open, handleClose, setOpen, emailSB };
}
