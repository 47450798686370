import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactApexChart from 'react-apexcharts';

import { valor } from '@s_mart/masks';

const GraficoPayback = ({ cor, height, mobile }: { cor: string; height: number; mobile?: boolean }) => {
  const { watch } = useFormContext();

  const _formValues = watch();

  if (_formValues.tabelaPaybackDados) {
    return (
      <ReactApexChart
        series={[
          {
            data: _formValues.tabelaPaybackDados?.map(
              (item: { valor: string; geracao: string; economiaAnual: string; tarifa: string }) => Number(item.valor)
            ),
            name: 'Investimento',
            color: '#545454',
          },
          {
            data: [],
            name: 'Economia',
            color: cor,
          },
        ]}
        type='bar'
        options={{
          chart: {
            type: 'bar',
            fontFamily: 'Lato',
            dropShadow: {
              enabled: false,
            },
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          grid: {
            row: {
              colors: ['#f4f5f7', 'transparent'],
              opacity: 0.6,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 0,
              columnWidth: '80%',
              colors: {
                ranges: [
                  {
                    from: -1000000000,
                    to: 0,
                    color: '#545454',
                  },
                  {
                    from: 0,
                    to: 10000000000,
                    color: cor,
                  },
                ],
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            y: {
              formatter: (item) => String(valor.format(item)),
            },
          },
          title: {
            text: '',
            style: { color: 'white' },
            align: 'right',
            floating: true,
          },
          xaxis: {
            categories: _formValues.tabelaPaybackDados?.map((_: unknown, index: number) => `Ano ${index}`),
            labels: { style: { fontSize: mobile ? '12px' : '14px' } },
          },
          yaxis: {
            title: {
              text: 'Retorno de investimento',
              style: { fontSize: mobile ? '12px' : '14px' },
            },
            decimalsInFloat: 0,
            labels: { style: { fontSize: mobile ? '12px' : '14px' } },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            markers: { radius: 2, width: 24, height: 18, offsetY: 4 },
            onItemClick: {
              toggleDataSeries: false,
            },
            fontSize: '14px',
          },
        }}
        height={height}
      />
    );
  }

  return <></>;
};

export default memo(GraficoPayback);
