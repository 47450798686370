import { Button, LIcon, Typography } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { lineBarcode } from '@s_mart/solid-icons';

import useAuth from 'store/auth/auth';
import { corPrimariaSolarfy } from 'styles/theme/theme';

import { useInformaFaturaDialog } from './informaFaturaDialog.store';
import {
  BotaoBoletoContainer,
  DialogContainer,
  OverlayContainer,
  TooltipContainer,
} from './informaFaturaDialog.styles';

const InformaFaturaDialog = () => {
  const { profile } = useAuth();
  const { setIsOpen } = useInformaFaturaDialog();
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  return (
    <OverlayContainer>
      <BotaoBoletoContainer telaMobile={telaMenorQue600}>
        <LIcon icon={lineBarcode} size='24px' color={corPrimariaSolarfy} />
      </BotaoBoletoContainer>
      <DialogContainer telaMobile={telaMenorQue600}>
        <div
          style={{ display: 'flex', gap: 8, flexDirection: telaMenorQue600 ? 'column' : 'row', alignItems: 'center' }}
        >
          <img src='static/svgs/onBoarding/onBoardingBroMegafone.svg' alt='Megafone' />
          <TooltipContainer telaMobile={telaMenorQue600}>
            <div className='leftArrow' />
            <Typography variant='titleSM'>{profile?.nome}</Typography>
            <Typography variant='bodySM'>
              Agora você pode acompanhar a situação de suas faturas de forma fácil! Sempre que houver faturas em aberto,
              aparecerão como um botão aqui!
            </Typography>
          </TooltipContainer>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              setIsOpen(false);
              window.location.reload();
            }}
          >
            Entendi
          </Button>
        </div>
      </DialogContainer>
    </OverlayContainer>
  );
};

export { InformaFaturaDialog };
