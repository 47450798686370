import { memo } from 'react';

import { Button, Dialog, Grid, Typography } from '@s_mart/core';
import { Dropzone } from '@s_mart/dropzone';
import { colorPalette } from '@s_mart/tokens';

import { InserirLogoEBannerDialogTypes } from './inserirLogoEBannerDialog.types';
import useInserirLogoEBannerDialog from './useInserirLogoEBannerDialog';

const InserirLogoEBannerDialog = ({ onClose, variant }: InserirLogoEBannerDialogTypes) => {
  const { file, handleTratarImagemDropzone, onDelete, handleSubmitInserirLogoEBannerDialog } =
    useInserirLogoEBannerDialog(variant);
  return (
    <Dialog open onClose={onClose} closeIcon disableBackdropClick>
      <Dialog.Header>{variant === 'banner' ? 'Inserir banner' : 'Inserir logotipo'}</Dialog.Header>
      <Dialog.Content>
        <Grid container>
          <Grid item xs={12}>
            <Dropzone
              accept='.jpg, .png, .jpeg'
              maxFiles={1}
              tratamentoFilesOnChange={handleTratarImagemDropzone}
              onClean={onDelete}
              onReset={onDelete}
              minHeight='160px'
              maxHeight='160px'
              maxFileSize={1048576} // 1MB
              fileItemProps={{ style: { zoom: 0.75 } }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
              Formatos aceitos:{' '}
              <Typography variant='titleXXS' color={colorPalette.neutral[100]}>
                JPEG, JPG ou PNG.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
              Resolução recomendada:{' '}
              <Typography variant='titleXXS' color={colorPalette.neutral[100]}>
                {variant === 'banner' ? '1024px x 768px' : '640px x 480px'} (proporção 4:3)
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='bodyXS' color={colorPalette.neutral[100]}>
              Limite de tamanho:{' '}
              <Typography variant='titleXXS' color={colorPalette.neutral[100]}>
                1 MB
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Dialog.Content>
      <Dialog.Footer>
        <Button variant='neutral' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          onClick={() => {
            if (typeof file !== 'undefined' && file.length > 0) {
              handleSubmitInserirLogoEBannerDialog(variant);
            }
            onClose();
          }}
        >
          Confirmar
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default memo(InserirLogoEBannerDialog);
