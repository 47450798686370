import { TextField } from '@s_mart/form';
import { useFormContext } from 'react-hook-form';
import { memo } from 'react';

import useConfiguracoes from 'store/configuracoes/configuracoes';

import { useFields } from '../../useFields';

const FieldQuantidadeOutros = ({ index }: { index: number }) => {
  const methods = useFormContext();
  const { recursosContratados } = useConfiguracoes();
  const { handleCalcularValorTotal } = useFields();

  return (
    <TextField
      name={`outros.${index}.quantidadeOutros`}
      label='Qtd'
      value={methods.watch(`outros.${index}.quantidadeOutros`)}
      placeholder='1'
      mask='numero'
      onInputChange={() => {
        if (recursosContratados?.['valor-de-venda-unitario']) {
          handleCalcularValorTotal({ values: methods.getValues(), methods });
        }
      }}
    />
  );
};

export default memo(FieldQuantidadeOutros);
