import { SmartProviderProps } from '@s_mart/core';
import { colorPalette } from '@s_mart/tokens';

export const corPrimariaSolarfy = '#FF5C00';
export const coresModelosDeProposta = ['#FF5C00', '#FFAB00', '#04BFAD', '#009F4A', '#045BA8'];

export const smartTheme = {
  palette: {
    primary: {
      main: corPrimariaSolarfy,
      contrastText: colorPalette.neutral[0],
    },
    secondary: {
      main: colorPalette.neutral[900],
      contrastText: colorPalette.neutral[0],
    },
    success: {
      main: colorPalette.green[300],
      contrastText: colorPalette.neutral[0],
    },
    error: {
      main: colorPalette.red[300],
    },
  },
} as SmartProviderProps['theme'];
