import { useCallback, useEffect } from 'react';
import { useInfiniteQuery as useInfiniteQueryRQ } from 'react-query';

import { api } from 'api/api';
import { queryClient } from 'api/queryClient';
import type { Meta, Options } from 'shared/global';
import useApp from 'store/app/app';

import type { IUseInfiniteQuery } from './useInfiniteQuery.types';

export function useInfiniteQuery<Data = any>({ name, url, options, fixedParams }: IUseInfiniteQuery) {
  const { handleError } = useApp();

  const _pageSize: { tamanho?: number } = {};

  const { data, isLoading, error, fetchNextPage, isFetching } = useInfiniteQueryRQ(
    [name],
    async ({ pageParam }) => {
      if (pageParam?.pageSize) {
        _pageSize.tamanho = pageParam.pageSize;
      }

      return await api.get(url, {
        params: {
          ...fixedParams,
          ...pageParam,
          infiniteScroll: true,
        },
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      staleTime: 0,
      ...options,
    }
  );

  // Pega informações dos parâmetros da query
  const lastParam = data?.pageParams?.[data?.pageParams?.length - 2] as Options;
  const param = data?.pageParams?.[data?.pageParams?.length - 1] as Options;

  // Faz tratamento das páginas para voltar data da listagem
  const _paginas = data?.pages;
  let dataList: Data[] = [];
  _paginas?.forEach((page) => {
    const _page = page?.data?.data?.data || page?.data?.data;

    if (_page) {
      if (param?.page === 1 || !param?.page) {
        dataList = _page;
      } else {
        dataList.push(..._page);
      }
    }
  });

  const _retornoUltimaPagina = _paginas?.[(_paginas?.length || 0) - 1]?.data;
  const meta: Meta = _retornoUltimaPagina?.data?.meta ?? _retornoUltimaPagina?.meta;

  const hasLimitReached =
    Boolean(!meta || _retornoUltimaPagina?.data?.length < (_pageSize?.tamanho || 15)) ||
    Boolean(_retornoUltimaPagina?.data?.data?.length < (_pageSize?.tamanho || 15));

  // Função para recarregar o cache da query
  const handleReset = useCallback(() => {
    if (param?.page && param?.page > 3) {
      queryClient.resetQueries([name || url]);
    } else {
      queryClient.refetchQueries([name || url]);
    }
  }, [name, url, param?.page]);

  // useEffect responsável por limpar a lista nos momentos necessários
  useEffect(() => {
    if (lastParam !== param && (!param?.page || param?.page === 1)) {
      queryClient.setQueryData(name ?? url, (data: any) => {
        const ultimaPagina = data?.pages?.[data?.pages?.length - 1];

        return { ...data, pages: [ultimaPagina] };
      });
    }
  }, [lastParam, param, url, name]);

  // Faz tratamento de erro caso o endpoint apresente algum problema
  useEffect(() => {
    if (error) handleError(error);
  }, [handleError, error]);

  return { data: dataList, meta, isLoading, fetchNextPage, lastParam, param, hasLimitReached, handleReset, isFetching };
}
