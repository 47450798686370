import { useMediaQuery } from '@s_mart/hooks';
import { lineBarcode } from '@s_mart/solid-icons';

import BotaoAppBar from 'components/botaoAppBar/botaoAppBar';
import useConfiguracoes from 'store/configuracoes/configuracoes';

import { tituloBotao } from './botaoBoleto.static';
import { useBotaoBoleto } from './useBotaoBoleto';

const BotaoBoleto = () => {
  const { statusFatura } = useConfiguracoes();
  const { handleAbrirBoletoPagamento } = useBotaoBoleto();
  const telaMenorQue800 = useMediaQuery(`(max-width: 800px)`);

  return (
    <div style={{ paddingLeft: 8 }}>
      <BotaoAppBar
        texto={tituloBotao(telaMenorQue800)[statusFatura - 1]}
        onClick={handleAbrirBoletoPagamento}
        icone={lineBarcode}
        status={statusFatura}
      />
    </div>
  );
};

export { BotaoBoleto };
