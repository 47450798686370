import { api } from 'api/api';
import { urls } from 'api/urls';

import type {
  AtualizarModeloPropostaProps,
  MarcarApresentacaoSimultaneidadeComoVistaData,
  MarcarApresentacaoGrupoAComoVistaData,
  ModelosPersonalizadosTypes,
  AtualizarParametrosData,
} from './configuracoes.types';

const buscarConfiguracoes = async () => await api.get(`${urls.configuracoes}`);

const atualizarConfiguracoesModeloProposta = async (data: AtualizarModeloPropostaProps) =>
  await api.post(`${urls.configuracoes}/modelo-proposta`, data);

const atualizarModeloPropostaPadrao = async (data: { modeloProposta: number }) =>
  await api.patch(`${urls.configuracoes}/modelo-proposta`, data);

const buscarConfiguracoesModelosPropostas = async () =>
  await api.get<ModelosPersonalizadosTypes>(`${urls.configuracoes}/modelos`);

const marcarApresentacaoSimultaneidadeComoVista = async (data: MarcarApresentacaoSimultaneidadeComoVistaData) =>
  await api.patch(`${urls.configuracoes}/apresentacao-simultaneidade`, data);

const marcarApresentacaoGrupoAComoVista = async (data: MarcarApresentacaoGrupoAComoVistaData) =>
  await api.patch(`${urls.configuracoes}/apresentacao-grupo-a`, data);

const atualizarParametros = async (data: AtualizarParametrosData) =>
  await api.patch(`${urls.configuracoes}/parametros`, data);

export {
  buscarConfiguracoes,
  atualizarConfiguracoesModeloProposta,
  atualizarModeloPropostaPadrao,
  buscarConfiguracoesModelosPropostas,
  marcarApresentacaoSimultaneidadeComoVista,
  marcarApresentacaoGrupoAComoVista,
  atualizarParametros,
};
