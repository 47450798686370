import { isStaging } from 'utils/isStaging/isStaging';

export const token = (append?: string) => `id_token${append || ''}`;

const _isStaging = isStaging();
export const isProduction = !_isStaging;

export const apiUrl = isProduction ? 'https://api.solarfy.com.br' : 'https://apistaging.solarfy.com.br';

export const parseOptions = <T extends object>(options: T) => {
  let params = '';
  if (options) {
    Object.keys(options).forEach((option, index) => {
      const value = Object.values(options)[index];
      if (value !== null || value !== undefined) {
        params += `${index !== 0 ? '&' : ''}${option}=${value}`;
      }
      return null;
    });
  }

  return params;
};
