import { memo, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Popover } from '@mui/material';

import { Grid, LIcon, Typography, Button, ButtonProps, EmptyState, Dialog } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';
import { lineLink, linePrint, lineWhatsApp } from '@s_mart/regular-icons';
import { colorPalette } from '@s_mart/tokens';
import { base64ToBlob } from '@s_mart/utils';

import VisualizarPdf from 'components/visualizarPdf/visualizarPdf';

import useVisualizarPropostaDialog from './store/visualizarPropostaDialog.store';
import useVisualizarPropostaDialogFunctions from './useVisualizarPropostaDialogFunctions';
import { IPopoverCopiarLink } from './visualizarPropostaDialog.types';
import useGeradorDeProposta from '../geradorDeProposta/store/geradorDeProposta.store';

dayjs.extend(utc);

const VisualizarPropostaDialog = () => {
  const { visualizarPropostaDialog, pdfProposta } = useVisualizarPropostaDialog();
  const {
    handleVisualizarPdfProposta,
    handleCompartilharPorImpressao,
    handleCompartilharPorWhatsApp,
    handleCompartilharPorCopiarLink,
    handleClose,
    handleAbrirPdf,
  } = useVisualizarPropostaDialogFunctions();
  const { setGeradorDeProposta } = useGeradorDeProposta();
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);
  const telaMenorQue760 = useMediaQuery(`(max-width: 760px)`);

  const [_popoverCopiarLinkSafari, setPopoverCopiarLinkSafari] = useState<IPopoverCopiarLink>({ open: null });
  const anchorDivPopoverOnBoarding = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (visualizarPropostaDialog?.dadosProposta?.codigo && !visualizarPropostaDialog?.dadosProposta?.pdf) {
      handleVisualizarPdfProposta(visualizarPropostaDialog?.dadosProposta?.codigo);
    }
  }, [
    visualizarPropostaDialog?.dadosProposta?.codigo,
    visualizarPropostaDialog?.dadosProposta?.pdf,
    handleVisualizarPdfProposta,
  ]);

  const _urlPdf = useMemo(
    () =>
      URL.createObjectURL(
        base64ToBlob(pdfProposta || visualizarPropostaDialog?.dadosProposta?.pdf || '', 'application/pdf')
      ),
    [pdfProposta, visualizarPropostaDialog?.dadosProposta?.pdf]
  );

  return (
    <>
      {_popoverCopiarLinkSafari.open && (
        <Popover
          open
          onClose={() => setPopoverCopiarLinkSafari({ open: null })}
          anchorEl={_popoverCopiarLinkSafari.open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', padding: '16px', gap: '8px' }}>
            <Typography variant='titleXS'>Selecione o texto e copie:</Typography>
            <Typography variant='bodySM'>{_popoverCopiarLinkSafari.link}</Typography>
          </div>
        </Popover>
      )}
      <Dialog open onClose={handleClose} closeIcon disableBackdropClick disableEscapeKeyDown fullWidth maxWidth='md'>
        <Dialog.Header>
          <Typography variant='titleMD' style={{ fontSize: telaMenorQue600 ? '20px' : undefined }}>
            Visualizar e compartilhar
          </Typography>
        </Dialog.Header>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: telaMenorQue760 ? 'column' : 'row',
                  justifyContent: 'space-between',
                  border: `1px solid ${colorPalette.neutral[40]}`,
                  borderRadius: '8px',
                  padding: '8px 16px',
                }}
              >
                <Typography variant='titleSM'>
                  <Typography variant='bodyMD' color={colorPalette.neutral[100]}>
                    Cliente:{' '}
                  </Typography>
                  {visualizarPropostaDialog?.dadosProposta?.cliente}
                </Typography>
                <Typography variant='titleSM'>
                  <Typography variant='bodyMD' color={colorPalette.neutral[100]}>
                    Gerada por:{' '}
                  </Typography>
                  {visualizarPropostaDialog?.dadosProposta?.vendedor}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  border: telaMenorQue600 ? undefined : `1px solid ${colorPalette.neutral[40]}`,
                  borderRadius: '8px',
                  padding: '8px',
                }}
              >
                {!telaMenorQue600 && <Typography variant='titleXS'>Compartilhar por:</Typography>}
                <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                  <Button
                    variant='outlined'
                    color={'neutral' as ButtonProps['color']}
                    onClick={handleCompartilharPorWhatsApp}
                    style={{ minWidth: 'min-content' }}
                  >
                    <div style={{ display: 'flex', gap: '12px' }}>
                      <LIcon icon={lineWhatsApp} removeMargin size='20px' /> {!telaMenorQue600 && 'WhatsApp'}
                    </div>
                  </Button>
                  <Button
                    variant='outlined'
                    color={'neutral' as ButtonProps['color']}
                    onClick={handleCompartilharPorImpressao}
                    style={{ minWidth: 'min-content' }}
                  >
                    <div style={{ display: 'flex', gap: '12px' }}>
                      <LIcon icon={linePrint} removeMargin size='20px' /> {!telaMenorQue600 && 'Impressão'}
                    </div>
                  </Button>
                  <Button
                    variant='outlined'
                    color={'neutral' as ButtonProps['color']}
                    onClick={(event) =>
                      handleCompartilharPorCopiarLink(event.currentTarget, setPopoverCopiarLinkSafari)
                    }
                    style={{ minWidth: 'min-content' }}
                  >
                    <div style={{ display: 'flex', gap: '12px' }}>
                      <LIcon icon={lineLink} removeMargin size='20px' /> {!telaMenorQue600 && 'Copiar link'}
                    </div>
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                ref={anchorDivPopoverOnBoarding}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  textAlign: 'center',
                  overflow: 'hidden',
                }}
              >
                {pdfProposta === 'erro' ? (
                  <EmptyState
                    illustration='/static/svgs/emptyStates/emptyStateVisualizarProposta.svg'
                    title='Esta proposta precisa ser atualizada'
                    description='Algumas informações contidas nesta proposta precisam da sua atenção.'
                    footer={
                      <Button
                        onClick={() => {
                          setGeradorDeProposta({
                            aberto: true,
                            clonar: visualizarPropostaDialog?.dadosProposta,
                          });
                          handleClose();
                        }}
                      >
                        Atualizar proposta
                      </Button>
                    }
                  />
                ) : telaMenorQue600 === false ? (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '470px' }}>
                    <VisualizarPdf
                      title='pdf'
                      src={pdfProposta || visualizarPropostaDialog?.dadosProposta?.pdf || ''}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '16px',
                    }}
                  >
                    <img src='/static/svgs/emptyStates/emptyStateVisualizarPropostaMobile.svg' alt='mobilePDF' />
                    <Typography variant='titleXS'>Visualize a proposta no botão abaixo</Typography>
                    <Button
                      onClick={() => {
                        if (navigator?.userAgent?.toLowerCase().includes('android')) {
                          handleAbrirPdf(_urlPdf);
                        } else {
                          window.open(_urlPdf);
                        }
                      }}
                    >
                      Abrir PDF
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Dialog.Content>
        <Dialog.Footer>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'end', marginTop: '16px' }}>
            <Button onClick={handleClose}>Concluir</Button>
          </div>
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

export default memo(VisualizarPropostaDialog);
