import { memo } from 'react';

import { Typography } from '@s_mart/core';
import { TextField } from '@s_mart/form';
import { composeValidators, maiorQue, required } from '@s_mart/rules';
// import { useFormContext } from 'react-hook-form';

const FieldPotenciaInversores = ({ index }: { index: number }) => {
  // const { watch } = useFormContext();

  return (
    <TextField
      name={`inversores.${index}.potenciaInversores`}
      label='Potência'
      // value={watch(`inversores.${index}.potenciaInversores`)}
      InputProps={{ endAdornment: <Typography variant='bodySM'>kW</Typography> }}
      mask='decimal'
      required
      rules={composeValidators([maiorQue(0), required])}
      placeholder='0,00'
    />
  );
};

export default memo(FieldPotenciaInversores);
