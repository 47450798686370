import styled from '@emotion/styled';

export const DadosDimensionamentoGrupoBDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;

    @media screen and (min-width: 600px) {
      &.geracao {
        min-width: 208px;
      }
      &.potencia {
        min-width: 190px;
      }
      &.quantidade {
        min-width: 112px;
      }
      &.area {
        min-width: 162px;
      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
`;
