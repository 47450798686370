import { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from '@s_mart/core';
import { useMediaQuery } from '@s_mart/hooks';

import { useIsMount } from 'hooks/useIsMount/useIsMount';
import useConfiguracoes from 'store/configuracoes/configuracoes';

import { useFields } from '../useFields';
import AutoCompleteFieldNomePainel from './content/autoCompleteFieldNomePaineis';
import FieldPotenciaPaineis from './content/fieldPotenciaPaineis';
import FieldQuantidadePaineis from './content/fieldQuantidadePaineis';
import FieldGarantiaPaineis from './content/fieldGarantiaPaineis';
import FieldValorVendaPaineis from './content/fieldValorVendaPaineis';

const FieldsPainel = () => {
  const { recursosContratados } = useConfiguracoes();
  const { handleCalcularValorTotal } = useFields();
  const methods = useFormContext();
  const isMount = useIsMount();
  const telaMenorQue760 = useMediaQuery(`(max-width: 760px)`);

  const _formValuesValorVendaPaineis = methods.watch('valorVendaPaineis');

  useEffect(() => {
    if (recursosContratados?.['valor-de-venda-unitario'] && isMount) {
      handleCalcularValorTotal({ values: methods.getValues(), methods });
    }
  }, [recursosContratados, _formValuesValorVendaPaineis]);

  return (
    <Grid container spacing={2}>
      <AutoCompleteFieldNomePainel />
      {telaMenorQue760 ? (
        <>
          <Grid item xs={6}>
            <FieldPotenciaPaineis />
          </Grid>
          <Grid item xs={6}>
            <FieldQuantidadePaineis />
          </Grid>
          <Grid item xs={6}>
            <FieldGarantiaPaineis />
          </Grid>
          {!!recursosContratados?.['valor-de-venda-unitario'] && (
            <Grid item xs={6}>
              <FieldValorVendaPaineis />
            </Grid>
          )}
        </>
      ) : (
        <Grid item sm={6} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={recursosContratados?.['valor-de-venda-unitario'] ? 3 : 4}>
              <FieldPotenciaPaineis />
            </Grid>
            <Grid item xs={recursosContratados?.['valor-de-venda-unitario'] ? 2.5 : 4}>
              <FieldQuantidadePaineis />
            </Grid>
            <Grid item xs={recursosContratados?.['valor-de-venda-unitario'] ? 2.75 : 4}>
              <FieldGarantiaPaineis />
            </Grid>
            {!!recursosContratados?.['valor-de-venda-unitario'] && (
              <Grid item xs={3.75}>
                <FieldValorVendaPaineis />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(FieldsPainel);
