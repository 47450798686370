import { useCallback, useState } from 'react';
import throttle from 'lodash.throttle';

import * as cidadesApi from 'api/cidades/cidades';
import useApp from 'store/app/app';

import type { CidadesValues } from './useCidades.types';

const useCidades = () => {
  const { handleError } = useApp();

  const [loadingCidades, _setLoadingCidades] = useState(false);
  const [cidades, _setCidades] = useState<CidadesValues[]>([]);
  const [cidadeSelecionada, setCidadeSelecionada] = useState<Partial<CidadesValues>>();

  const handlePesquisarCidades = useCallback(
    throttle(
      async (search: string) => {
        _setLoadingCidades(true);
        try {
          const { data } = await cidadesApi.listarCidades({ search });
          _setCidades(data.data);
        } catch (err) {
          handleError(err);
        }
        _setLoadingCidades(false);
      },
      500,
      { leading: false }
    ),
    []
  );

  return { handlePesquisarCidades, cidades, cidadeSelecionada, setCidadeSelecionada, loadingCidades };
};

export default useCidades;
