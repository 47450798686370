import { api } from '../api';
import { urls } from 'api/urls';
import { Options } from 'shared/global';

const listarConcessionarias = async (params: Options) => await api.get(`${urls.concessionarias}`, { params: params });

const buscarTarifaConcessionaria = async (id: number) =>
  await api.get(`${urls.concessionarias}-tarifas?codigoConcessionaria=${id}`);

export { listarConcessionarias, buscarTarifaConcessionaria };
