export enum TagsEnum {
  'Primeiro acesso' = 'Primeiro acesso',
  'Botão Quero contratar' = 'Botão Quero contratar',
  'Cadastro de produtos' = 'Cadastro de produtos',
  'Botão Contratar plano' = 'Botão Contratar plano',
  'NPS' = 'NPS',
  'Solicitar desbloqueio' = 'Solicitar desbloqueio',
  'Botão Editar modelo' = 'Botão Editar modelo',
  'Clicou em editar a base de cálculo' = 'Clicou em editar a base de cálculo',
  'Clicou no botão de cálculo de payback' = 'Clicou no botão de cálculo de payback',
}
