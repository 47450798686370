export enum TipoProdutoEnum {
  'PAINEL' = 0,
  'INVERSOR' = 1,
  'OUTROS' = 2,
  'KIT_FOTO' = 3,
  'KIT_SERVIÇO' = 4,
}

export enum TipoIntegracaoEnum {
  'MANUAL' = 0,
  'API' = 1,
}

export enum FornecedorEnum {
  'HORUS' = 1,
  'EDELTEC' = 2,
}

export interface ListarProdutosFornecedoresParams {
  search?: string;
  potenciaLimiteInicial?: number;
  potenciaLimiteFinal?: number;
  voltagem?: number;
  fornecedor: string;
  tipoIntegracao: TipoIntegracaoEnum;
  categoria?: string;
  tipoProduto?: TipoProdutoEnum;
  sort?: string;
  order?: string;
}

export interface ProdutosFornecedores {
  categoria?: string;
  codigo: number;
  descricaoCompleta: string;
  disponibilidade?: string;
  idFornecedor?: string;
  nome: string;
  nomeFornecedor: string;
  potencia: number;
  precoCusto: number | null;
  precoVenda?: number;
  tipoEstrutura: string;
}
export interface ListarFornecedoresParams {
  search?: string;
}

export interface Fornecedores {
  codigo: number;
  nome: string;
  tipoIntegracao: TipoIntegracaoEnum;
}

export interface ConfiguracaoApiFornecedor {
  apiKey: string;
  codigo: number;
  margemLucro?: number;
  secret: string;
}

export interface PayloadFornecedoresAtivos {
  fornecedores: number[];
  configuracao?: ConfiguracaoApiFornecedor;
}
