import { api } from '../api';
import { urls } from 'api/urls';

import { CadastrarEditarProdutoTypes } from './produtos.types';
import { Options } from 'shared/global';

const listarProdutos = async (params: Options) => await api.get(`${urls.produtos}`, { params: params });

const cadastrarProduto = async (payload: CadastrarEditarProdutoTypes) => await api.post(`${urls.produtos}`, payload);

const deletarProduto = async (id: number) => await api.delete(`${urls.produtos}/${id}`);

const editarProduto = async (id: number, payload: CadastrarEditarProdutoTypes) =>
  await api.patch(`${urls.produtos}/${id}`, payload);

const buscarProduto = async (id: number) => await api.get(`${urls.produtos}/${id}`);

const ativarInativarProduto = async (id: number, situacao: boolean) =>
  await api.patch(`${urls.produtos}/${id}/alterar-situacao`, undefined, {
    params: { situacao: situacao },
  });

export { listarProdutos, cadastrarProduto, deletarProduto, editarProduto, buscarProduto, ativarInativarProduto };
