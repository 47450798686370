import { useCallback } from 'react';

import { finalizarOnBoarding } from 'api/onBoarding/onBoarding';
import useGeradorDeProposta from 'shared/modais/geradorDeProposta/store/geradorDeProposta.store';

import OnBoardingBoasVindasConteudo from './onBoardingBoasVindasConteudo';
import useOnBoarding from '../store/onBoarding.store';
import { cadastrarEtapasPadrao } from 'api/etapas/etapas';

const useOnBoardingBoasVindas = () => {
  const { setOnBoardingBoasVindas, setOnBoardingFinalizado } = useOnBoarding();
  const { setGeradorDeProposta } = useGeradorDeProposta();

  const handleCadastrarEtapasPadrao = useCallback(async () => {
    try {
      await cadastrarEtapasPadrao();
    } catch (err) {
      //Ignorar erro caso o endpoint retorne com algum problema
    }
  }, []);

  const handleCloseOnBoardingBoasVindas = useCallback(async () => {
    setOnBoardingBoasVindas({ aberto: false });
    setOnBoardingFinalizado(true);
    handleCadastrarEtapasPadrao();

    await finalizarOnBoarding();
  }, [setOnBoardingBoasVindas, setOnBoardingFinalizado]);

  const _handleAbrirGerador = () => {
    setGeradorDeProposta({ aberto: true, onBoarding: true });
    setOnBoardingBoasVindas({ aberto: false });
  };

  const handleProximoESubmit = () => {
    handleCadastrarEtapasPadrao();
    _handleAbrirGerador();
  };

  const handleControleOnBoardingBoasVindas = useCallback(() => {
    return {
      title: 'Bem-vindo ao Solarfy',
      tamanhoModal: 'xs',
      conteudo: <OnBoardingBoasVindasConteudo />,
    };
  }, []);

  return { handleCloseOnBoardingBoasVindas, handleProximoESubmit, handleControleOnBoardingBoasVindas };
};

export default useOnBoardingBoasVindas;
