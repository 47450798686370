import type { IAdicionarEditarMotivoDialogProps } from './adicionarEditarMotivoDialog.types';

import { Button, Dialog, Grid, Typography } from '@s_mart/core';
import { Form, TextField } from '@s_mart/form';
import { composeValidators, maxLength, requiredWithTrim } from '@s_mart/rules';
import { memo, useMemo } from 'react';
import { useAdicionarEditarMotivoDialog } from './useAdicionarEditarMotivoDialog';

const AdicionarEditarMotivoDialog = ({ handleClose, motivo }: IAdicionarEditarMotivoDialogProps) => {
  const { handleAdicionarEditarMotivo } = useAdicionarEditarMotivoDialog({ handleClose, motivo });

  const defaultValues = useMemo(() => {
    return { nomeMotivo: motivo?.nome };
  }, []);

  return (
    <Dialog open onClose={handleClose} maxWidth='xs' fullWidth disableEscapeKeyDown disableBackdropClick>
      <Form onSubmit={handleAdicionarEditarMotivo} defaultValues={defaultValues}>
        <Dialog.Header>
          <Typography variant='titleMD'>{motivo ? 'Editar' : 'Novo'} motivo de perda</Typography>
        </Dialog.Header>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name='nomeMotivo'
                label={motivo ? 'Defina um novo motivo de perda' : 'Defina um novo motivo de perda'}
                required
                rules={composeValidators([requiredWithTrim, maxLength(50)])}
              />
            </Grid>
          </Grid>
        </Dialog.Content>
        <Dialog.Footer>
          <Button onClick={handleClose} variant='neutral'>
            Cancelar
          </Button>
          <Button type='submit'>Salvar</Button>
        </Dialog.Footer>
      </Form>
    </Dialog>
  );
};

export default memo(AdicionarEditarMotivoDialog);
