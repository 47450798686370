import { usePainelClienteStore } from 'shared/painelCliente/painelCliente.store';
import { deletarProposta } from 'api/propostas/propostas';
import { queryClient } from 'api/queryClient';
import useApp from 'store/app/app';

import usePropostaExcluirDialog from './store/propostaExcluirDialog.store';

const usePropostaExcluirDialogFunctions = () => {
  const { setNotification, handleError } = useApp();
  const { propostaExcluirDialog, setPropostaExcluirDialog } = usePropostaExcluirDialog();
  const { telaCliente, handleBuscarAtividades, handleBuscarPropostas } = usePainelClienteStore();

  const handleClose = () => {
    setPropostaExcluirDialog({ aberto: false, codigoProposta: undefined, numeroProposta: undefined });
  };

  const handleSubmit = async () => {
    try {
      if (propostaExcluirDialog.codigoProposta) {
        const { status } = await deletarProposta(propostaExcluirDialog.codigoProposta);

        if (status === 200 || status === 204) {
          setNotification({ title: 'Proposta excluída.' });
          queryClient.clear();

          if (telaCliente.open) {
            handleBuscarAtividades({});
            handleBuscarPropostas();
          }

          handleClose();
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  return { handleSubmit, handleClose };
};

export default usePropostaExcluirDialogFunctions;
