import { memo } from 'react';

import { Button, Dialog, Grid } from '@s_mart/core';
import { Form, TextField } from '@s_mart/form';
import { useMediaQuery } from '@s_mart/hooks';
import { email, required, telefone } from '@s_mart/rules';

import useHandleVendedorFormDialogSubmit from './useHandleVendedorFormDialogSubmit';
import useVendedorFormDialogFunctions from './useVendedorFormDialogFunctions';

const VendedorFormDialog = () => {
  const { initialState, handleClose } = useVendedorFormDialogFunctions();
  const { handleVendedorFormDialogSubmit } = useHandleVendedorFormDialogSubmit(handleClose);
  const telaMenorQue600 = useMediaQuery(`(max-width: 600px)`);

  return (
    <Form
      onSubmit={handleVendedorFormDialogSubmit(initialState.nome ? 'editar' : 'cadastrar')}
      defaultValues={initialState}
    >
      {({ formState: { isDirty } }) => (
        <Dialog
          open
          onClose={() => handleClose(isDirty)}
          closeIcon
          disableEscapeKeyDown
          disableBackdropClick
          fullWidth
          maxWidth='xs'
          disablePortal
        >
          <>
            <Dialog.Header>{initialState.nome ? 'Editar vendedor' : 'Novo vendedor'}</Dialog.Header>
            <Dialog.Content>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name='nome'
                    label='Nome do vendedor'
                    placeholder='Nome completo'
                    required
                    mask='nome'
                    rules={required}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField name='email' label='E-mail' placeholder='exemplo@exemplo.com.br' rules={email} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name='telefone'
                    label='Telefone'
                    placeholder='(54) 9 1234-1234'
                    mask='telefone'
                    rules={telefone}
                  />
                </Grid>
              </Grid>
            </Dialog.Content>
            <Dialog.Footer>
              <div style={{ display: 'flex', flex: 1, justifyContent: 'end', gap: '16px', marginTop: '16px' }}>
                <Button onClick={() => handleClose(isDirty)} variant='neutral'>
                  Cancelar
                </Button>
                <Button type='submit'>
                  {initialState.nome ? 'Salvar' : telaMenorQue600 ? 'Cadastrar' : 'Cadastrar vendedor'}
                </Button>
              </div>
            </Dialog.Footer>
          </>
        </Dialog>
      )}
    </Form>
  );
};

export default memo(VendedorFormDialog);
