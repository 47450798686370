import styled from '@emotion/styled';
import { ToggleButtonGroup } from '@mui/material';

import { corPrimariaSolarfy } from 'styles/theme/theme';

export const SToggleButtonGroup = styled(ToggleButtonGroup)`
  height: 32px;

  button[aria-pressed='true'] {
    background: ${corPrimariaSolarfy};

    &:hover {
      background: #ff3c00;
    }
  }
`;
