import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Typography } from '@s_mart/core';
import { valor, decimal } from '@s_mart/masks';

import { IItemTabelaPaybackFormatadaAnual } from 'utils/calcularPaybackGrupoB/calcularPaybackGrupoB.types';

import { DivTabelaPaybackRow } from './tabelaPayback.styles';

const TabelaPayback = () => {
  const { watch } = useFormContext();

  const _formValuesTabelaPaybackDados = watch('tabelaPaybackDados');
  const _formValuesTipoLigacao = watch('tipoLigacao');

  if (_formValuesTabelaPaybackDados?.length) {
    return (
      <div id='tabela-payback' style={{ position: 'absolute', right: '7500px' }}>
        <table style={{ width: '750px', background: 'white' }} cellSpacing={0} cellPadding={0}>
          <tr>
            <th>
              <Typography variant='titleSM'>Anos</Typography>
            </th>
            <th>
              <Typography variant='titleSM'>Valor</Typography>
            </th>
            <th>
              <Typography variant='titleSM'>Geração</Typography>
            </th>
            <th>
              <Typography variant='titleSM'>Economia anual</Typography>
            </th>
          </tr>
          {_formValuesTabelaPaybackDados.map((item: IItemTabelaPaybackFormatadaAnual, index: number) => {
            const _ano = _formValuesTipoLigacao?.value === '3' ? index : item.ano;
            if (_ano <= 10 || (_ano >= 15 && _ano % 5 === 0)) {
              return (
                <tr key={_ano}>
                  <td>
                    <DivTabelaPaybackRow index={_ano}>
                      <Typography variant='titleXS' whiteSpace='nowrap'>
                        {_ano}
                      </Typography>
                    </DivTabelaPaybackRow>
                  </td>
                  <td>
                    <DivTabelaPaybackRow index={_ano}>
                      <Typography variant='bodySM' whiteSpace='nowrap'>
                        {valor.format(Number(item.valor || 0))}
                      </Typography>
                    </DivTabelaPaybackRow>
                  </td>
                  <td>
                    <DivTabelaPaybackRow index={_ano}>
                      <Typography variant='bodySM' whiteSpace='nowrap'>
                        {decimal.format(item.geracao)} kWh
                      </Typography>
                    </DivTabelaPaybackRow>
                  </td>
                  <td>
                    <DivTabelaPaybackRow index={_ano}>
                      <Typography variant='bodySM' whiteSpace='nowrap'>
                        {valor.format(Number(item.economiaAnual || 0))}
                      </Typography>
                    </DivTabelaPaybackRow>
                  </td>
                </tr>
              );
            }
            return undefined;
          })}
        </table>
      </div>
    );
  }

  return <></>;
};

export default memo(TabelaPayback);
